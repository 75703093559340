import { createApi } from '@reduxjs/toolkit/query/react';
import { baseUrls, createBaseQuery } from './apiHelper';
import { ISharedDeal } from '../schemas';


export const sharedDealsApiSlice = createApi({
  reducerPath: 'api-shared-deals',
  tagTypes: ['SharedDeal'],
  baseQuery: createBaseQuery(baseUrls.sharedDeals),
  endpoints: (build) => ({
    getSharedDeals: build.query<ISharedDeal[], void>({
      providesTags: (sharedDeals) => sharedDeals?.map(sd => ({ type: 'SharedDeal', id: sd._id })) ?? [],
      query: () => '/shared-deals',
    }),

    updateSharedDeal: build.mutation<{message: string}, {sharedDealId: string, dealId: string, changes: Partial<ISharedDeal>}>({
      invalidatesTags: (_result, _error, meta) => [{ type: 'SharedDeal', id: meta.sharedDealId }],
      query: ({sharedDealId, changes}) => {
        return {
          url: `/shared-deals/${sharedDealId}`,
          method: 'PATCH',
          body: changes,
        };
      },
    }),
  }),
})

export const {
  useGetSharedDealsQuery,
  
  useUpdateSharedDealMutation,
} = sharedDealsApiSlice;
