import Typography from '@mui/material/Typography';
import PageContainer from '../PageContainer';
import EquityMarketplaceDataGrid from '../EquityMarketplaceDataGrid';
import EquityMarketplaceFilters from '../EquityMarketplaceFilters';
import { Box } from '@mui/material';


export default function EquityDeals() {
  return (
    <PageContainer>
      <Typography sx={{
        marginBottom: '40px',
        fontWeight: '400',
        fontSize: '28px',
        lineHeight: '34.24px',
      }}>
        {`Equity Marketplace`}
      </Typography>

      <Box sx={{
        paddingBottom: '24px',
      }}>
        <EquityMarketplaceFilters />
      </Box>

      <Box sx={{
        height: '60vh'
      }}>
        <EquityMarketplaceDataGrid />
      </Box>
    </PageContainer>
  )
}
