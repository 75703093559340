import { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAppDispatch } from '../../app/hooks';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Logo from '../../images/light-creed-logo.png';
import { loggedIn } from '../../features/auth-slice';
import PageContainer from '../PageContainer';
import { useLazyGetCurrentAccountQuery, useLazyGetCurrentAccountTeamQuery, useLazyGetCurrentUserQuery } from '../../features/accounts-api';
import mixpanel from 'mixpanel-browser';
import { useCheckoutMutation } from '../../features/payments-api';
import { getDefaultPathForAccountType } from '../../utils/utils';
import LoadingButton from '../LoadingButton';
import { IAccountTeam } from '../../schemas';
import PasswordField from '../PasswordField';


export default function Login(): JSX.Element {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [getAccount] = useLazyGetCurrentAccountQuery();
  const [getTeam] = useLazyGetCurrentAccountTeamQuery();
  const [getUser] = useLazyGetCurrentUserQuery();

  const [checkout] = useCheckoutMutation();
 
  const handleSubmit = useCallback(async (event: React.SyntheticEvent) => {
    event.preventDefault();

    setLoading(true);
    try {
      const signinRes = await Auth.signIn(email, password);
      setError('');
      console.log('Logged in', signinRes);
      
      dispatch(loggedIn(email));

      const [account, user] = await Promise.all([
        getAccount().unwrap(),
        getUser().unwrap(),
      ]);

      let team: IAccountTeam | undefined = undefined;
      try {
        team = await getTeam().unwrap();
      } catch {}

      try {
        mixpanel.identify(user._id);
        mixpanel.people.set({
          $email: user.email,
          $name: `${user.firstName} ${user.lastName}`,
          'Account ID': account._id,
          'Account Type': account.type,
        });
        mixpanel.track('login');
      } catch {}

      const userInfo = team?.userInfo[user._id];
      const isOwner = account.level === 'individual' || userInfo?.accessLevel === 'owner';

      if (isOwner && account?.isOnboarding) {
        const response = await checkout().unwrap();
        window.location.assign(response.url);
      } else {
        if (account.profileComplete) {
          const redirectPath = searchParams.get('redirect');
          if (redirectPath != null) {
            navigate(redirectPath);
          } else {
            const defaultPath = getDefaultPathForAccountType(account?.type);
            navigate(defaultPath);
          }
        } else {
          navigate('/organization');
        }
      }
    } catch (error: any) {
      console.error(error);
      setError(error.message);
      setLoading(false);
    }
  }, [email, password, dispatch, getAccount, getUser, getTeam, checkout, searchParams, navigate]);
  
  return (
    <PageContainer headerProps={{showBanners: false}}>
      <form onSubmit={handleSubmit}>
        <Grid container style={{marginTop: 100}}>
          <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
            <img src={Logo} alt='CREED' style={{width: 100, marginBottom: 50}}/>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
            <TextField
              style={{width: 300}}
              required
              id='email'
              type='email'
              label='Your Email'
              value={email}
              onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
            />
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
            <PasswordField
              style={{width: 300}}
              required
              id='password'
              label='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <LoadingButton 
              variant='contained' 
              type='submit'
              loading={loading}
              style={{height: '100%', width: 300}}>
              {'Login'}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            {
              error !== ''
              ? <div><Typography variant='error'>{error}</Typography></div>
              : null
            }
            <div>
              <Typography
                variant='body2'
                style={{
                  marginTop: 8
                }}>
                <a href='/reset-pwd'>
                  {'Reset my password'}
                </a>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </form>
    </PageContainer>
  )
}
