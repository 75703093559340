import { PayloadAction, createSlice } from "@reduxjs/toolkit";


interface DealsState {
  readonly createdDealIds: string[];
}

const initialState: DealsState = {
  createdDealIds: [],
};

const dealsSlice = createSlice({
  name: 'deals',
  initialState: initialState,
  reducers: {
    markDealCreated: (state, action: PayloadAction<{
      dealId: string,
    }>) => {
      state.createdDealIds.push(action.payload.dealId);
    },
    clearCreatedDeal: (state, action: PayloadAction<{
      dealId: string,
    }>) => {
      state.createdDealIds = state.createdDealIds.filter(i => i !== action.payload.dealId);
    }
  }
});

export const {markDealCreated, clearCreatedDeal} = dealsSlice.actions;
export default dealsSlice.reducer;
