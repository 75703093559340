import React, { useState, useCallback, createContext, useEffect, PropsWithChildren } from 'react';
import PageContainer from '../PageContainer';
import DealDiligenceHeader from './DealDiligenceHeader';
import { IDeal, IDocumentRequest } from '../../schemas';
import DealAccessDialog from '../Dialogs/DealAccessDialog/DealAccessDialog';
import AcceptDocumentRequestDialog from './AcceptDocumentRequestDialog';
import CreateDocumentRequestDialog from './CreateDocumentRequestDialog';
import DeleteDocumentRequestDialog from './DeleteDocumentRequestDialog';
import PreviewFileDialog from './PreviewFileDialog';
import RejectDocumentRequestDialog from './RejectDocumentRequestDialog';
import SubmitDocumentRequestDialog from './SubmitDocumentRequestDialog';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import { showChatForThread, closeChat } from '../../features/messaging-slice';
import { useSetDealDocumentRequestStatusMutation } from '../../features/document-requests-api';
import ConfidentialConfirmationDialog from './ConfidentialConfirmationDialog';
import DocumentRequestAccessDialog from './DocumentRequestAccessDialog';
import UnsubmitDocumentRequestDialog from './UnsubmitDocumentRequestDialog';
import MakeDocumentRequestConfidentialDialog from './MakeDocumentRequestPriorityDialog';


export const DealActionContext = createContext<{
  makeDocumentRequestConfidential?: (documentRequest: IDocumentRequest) => void;
  manageDocumentRequestAccess?: (documentRequest: IDocumentRequest) => void;
  setDocumentRequestAsPriority?: (documentRequest: IDocumentRequest) => void;
  deleteDocumentRequest?: (documentRequest: IDocumentRequest) => void;
  previewFile?: (title: string | undefined, url: string) => void;
  createDocumentRequestClicked?: () => void;
  submitDocumentRequestClicked?: (documentRequest: IDocumentRequest) => void;
  unsubmitDocumentRequestClicked?: (documentRequest: IDocumentRequest) => void;
  acceptDocumentRequestClicked?: (documentRequest: IDocumentRequest) => void;
  rejectDocumentRequestClicked?: (documentRequest: IDocumentRequest) => void;
}>({});

type Props = PropsWithChildren<{
  readonly deal?: IDeal;
  readonly loading?: boolean;
}>;

export default function DealDiligenceBase({deal, loading, children}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [selectedDocumentRequest, setSelectedDocumentRequest] = useState<IDocumentRequest | undefined>();
  const [previewingFile, setPreviewingFile] = useState<{title?: string, url: string} | undefined>();
  const [dealInvitationsOpen, setDealInvitationsOpen] = useState(false);
  const [makeConfidentialOpen, setMakeConfidentialOpen] = useState(false);
  const [manageDocRequestAccessOpen, setManageDocRequestAccessOpen] = useState(false);
  const [makeDocumentRequestPriorityOpen, setMakeDocumentRequestPriorityOpen] = useState(false);
  const [deleteDocumentRequestOpen, setDeleteDocumentRequestOpen] = useState(false);
  const [createDocRequestOpen, setCreateDocRequestOpen] = useState(false);
  const [submitDocRequestOpen, setSubmitDocRequestOpen] = useState(false);
  const [unsubmitDocRequestOpen, setUnsubmitDocRequestOpen] = useState(false);
  const [acceptDocRequestOpen, setAcceptDocRequestOpen] = useState(false);
  const [rejectDocRequestOpen, setRejectDocRequestOpen] = useState(false);

  const {data: account} = useGetCurrentAccountQuery();

  const [setDocumentRequestStatus] = useSetDealDocumentRequestStatusMutation();

  const makeDocumentRequestConfidential = useCallback((documentRequest: IDocumentRequest) => {
    setSelectedDocumentRequest(documentRequest);
    setMakeConfidentialOpen(true);
  }, [setSelectedDocumentRequest, setMakeConfidentialOpen]);

  const manageDocumentRequestAccess = useCallback((documentRequest: IDocumentRequest) => {
    setSelectedDocumentRequest(documentRequest);
    setManageDocRequestAccessOpen(true);
  }, [setSelectedDocumentRequest, setManageDocRequestAccessOpen]);

  const setDocumentRequestAsPriority = useCallback((documentRequest: IDocumentRequest) => {
    setSelectedDocumentRequest(documentRequest);
    setMakeDocumentRequestPriorityOpen(true);
  }, [setSelectedDocumentRequest, setMakeDocumentRequestPriorityOpen]);

  const deleteDocumentRequest = useCallback((documentRequest: IDocumentRequest) => {
    setSelectedDocumentRequest(documentRequest);
    setDeleteDocumentRequestOpen(true);
  }, [setSelectedDocumentRequest, setDeleteDocumentRequestOpen]);

  const previewFile = useCallback((title: string | undefined, url: string) => {
    setPreviewingFile({title, url});
  }, [setPreviewingFile]);

  const createDocumentRequestClicked = useCallback(() => {
    setCreateDocRequestOpen(true);
  }, [setCreateDocRequestOpen]);

  const submitDocumentRequestClicked = useCallback(async (documentRequest: IDocumentRequest) => {
    setSelectedDocumentRequest(documentRequest);
    setSubmitDocRequestOpen(true);
  }, [setSelectedDocumentRequest, setSubmitDocRequestOpen]);

  const unsubmitDocumentRequestClicked = useCallback(async (documentRequest: IDocumentRequest) => {
    setSelectedDocumentRequest(documentRequest);
    setUnsubmitDocRequestOpen(true);
  }, [setSelectedDocumentRequest, setUnsubmitDocRequestOpen]);

  const acceptDocumentRequestClicked = useCallback(async (documentRequest: IDocumentRequest) => {
    setSelectedDocumentRequest(documentRequest);
    setAcceptDocRequestOpen(true);
  }, [setSelectedDocumentRequest, setAcceptDocRequestOpen]);

  const rejectDocumentRequestClicked = useCallback(async (documentRequest: IDocumentRequest) => {
    setSelectedDocumentRequest(documentRequest);
    setRejectDocRequestOpen(true);
  }, [setSelectedDocumentRequest, setRejectDocRequestOpen]);

  const submitDocumentRequest = useCallback(async () => {
    if (deal?._id == null || selectedDocumentRequest == null) {
      return;
    }

    try {
      await setDocumentRequestStatus({
        dealId: deal._id,
        documentRequestId: selectedDocumentRequest._id,
        status: 'submitted',
      }).unwrap();

      dispatch(snacked({
        message: 'Files submitted',
        severity: 'success',
      }));
    } catch {
      dispatch(snacked({
        message: 'Error submitting files',
        severity: 'error',
      }));
    } finally {
      setSubmitDocRequestOpen(false);
    }
  }, [selectedDocumentRequest, dispatch, setDocumentRequestStatus, deal, setSubmitDocRequestOpen]);

  const unsubmitDocumentRequest = useCallback(async () => {
    if (deal?._id == null || selectedDocumentRequest == null) {
      return;
    }

    try {
      await setDocumentRequestStatus({
        dealId: deal._id,
        documentRequestId: selectedDocumentRequest._id,
        status: 'pending',
      }).unwrap();

      dispatch(snacked({
        message: 'Files un-submitted',
        severity: 'success',
      }));
    } catch {
      dispatch(snacked({
        message: 'Error un-submitting files',
        severity: 'error',
      }));
    } finally {
      setSubmitDocRequestOpen(false);
    }
  }, [selectedDocumentRequest, dispatch, setDocumentRequestStatus, deal, setSubmitDocRequestOpen]);

  const acceptDocumentRequest = useCallback(async () => {
    if (deal?._id == null || selectedDocumentRequest == null) {
      return;
    }
        
    try {
      await setDocumentRequestStatus({
        dealId: deal._id,
        documentRequestId: selectedDocumentRequest._id,
        status: 'accepted',
      }).unwrap();

      dispatch(snacked({
        message: 'Files accepted',
        severity: 'success',
      }));
    } catch {
      dispatch(snacked({
        message: 'Error accepting files',
        severity: 'error',
      }));
    } finally {
      setAcceptDocRequestOpen(false);
    }
  }, [deal?._id, selectedDocumentRequest, setAcceptDocRequestOpen, dispatch, setDocumentRequestStatus]);

  const rejectDocumentRequest = useCallback(async (rejectionReason: string) => {
    if (deal?._id == null || selectedDocumentRequest == null) {
      return;
    }
        
    try {
      await setDocumentRequestStatus({
        dealId: deal._id,
        documentRequestId: selectedDocumentRequest._id,
        status: 'rejected',
        rejectionReason: rejectionReason,
      }).unwrap();

      dispatch(snacked({
        message: 'Files rejected',
        severity: 'success',
      }));
    } catch {
      dispatch(snacked({
        message: 'Error rejecting files',
        severity: 'error',
      }));
    } finally {
      setRejectDocRequestOpen(false);
    }
  }, [setDocumentRequestStatus, selectedDocumentRequest, deal?._id, setRejectDocRequestOpen, dispatch]);

  useEffect(() => {
    if (account?.currentSubscription?.isActive && deal?.threadId) {
      dispatch(showChatForThread({
        key: 'project-chat',
        threadId: deal.threadId,
        windowTitle: 'Project chatroom',
        windowSubtitle: 'Group chat for all project participants',
        closable: false,
        minimizable: true,
        minimized: true,
      }));
    }

    return () => {
      dispatch(closeChat({ key: 'project-chat' }));
    };
  }, [deal?.threadId, account?.currentSubscription?.isActive, dispatch]);
  
  return (
    <PageContainer
      contentSx={{
        paddingBottom: '105px',
      }}
      loading={loading}>

      <DealDiligenceHeader
        deal={deal}
        manageAccessClicked={account?._id === deal?.accountId ? () => setDealInvitationsOpen(true) : undefined} />

      <DealActionContext.Provider value={{
        makeDocumentRequestConfidential,
        manageDocumentRequestAccess,
        setDocumentRequestAsPriority,
        deleteDocumentRequest,
        previewFile,
        createDocumentRequestClicked,
        submitDocumentRequestClicked,
        unsubmitDocumentRequestClicked,
        acceptDocumentRequestClicked,
        rejectDocumentRequestClicked,
      }}>
        {children}
      </DealActionContext.Provider>

      <DealAccessDialog
        open={dealInvitationsOpen}
        deal={deal}
        onClose={() => setDealInvitationsOpen(false)} />

      <ConfidentialConfirmationDialog
        open={makeConfidentialOpen}
        documentRequest={selectedDocumentRequest}
        onClose={() => setMakeConfidentialOpen(false)} />

      <DocumentRequestAccessDialog
        open={manageDocRequestAccessOpen}
        documentRequest={selectedDocumentRequest}
        onClose={() => setManageDocRequestAccessOpen(false)} />

      <MakeDocumentRequestConfidentialDialog
        open={makeDocumentRequestPriorityOpen}
        documentRequest={selectedDocumentRequest}
        onClose={() => setMakeDocumentRequestPriorityOpen(false)} />

      <DeleteDocumentRequestDialog
        open={deleteDocumentRequestOpen}
        documentRequest={selectedDocumentRequest}
        onClose={() => setDeleteDocumentRequestOpen(false)} />

      <PreviewFileDialog
        open={previewingFile != null}
        title={previewingFile?.title}
        fileUrl={previewingFile?.url}
        onClose={() => setPreviewingFile(undefined)} />

      <CreateDocumentRequestDialog
        open={createDocRequestOpen}
        deal={deal}
        onClose={() => setCreateDocRequestOpen(false)} />

      <SubmitDocumentRequestDialog
        open={submitDocRequestOpen}
        documentRequest={selectedDocumentRequest}
        onClose={() => setSubmitDocRequestOpen(false)}
        onSubmitClicked={submitDocumentRequest} />

      <UnsubmitDocumentRequestDialog
        open={unsubmitDocRequestOpen}
        documentRequest={selectedDocumentRequest}
        onClose={() => setUnsubmitDocRequestOpen(false)}
        onUnsubmitClicked={unsubmitDocumentRequest} />

      <AcceptDocumentRequestDialog
        open={acceptDocRequestOpen}
        documentRequest={selectedDocumentRequest}
        onClose={() => setAcceptDocRequestOpen(false)}
        onAcceptClicked={acceptDocumentRequest} />

      <RejectDocumentRequestDialog 
        open={rejectDocRequestOpen}
        documentRequest={selectedDocumentRequest}
        onClose={() => setRejectDocRequestOpen(false)}
        onRejectClicked={rejectDocumentRequest} />
    </PageContainer>
  );
}
