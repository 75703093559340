import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from "@mui/material";
import CREEDDialogTitle from "../CREEDDialogTitle";
import { ReactNode } from "react";


interface Props extends DialogProps {
  title: string;
  customContent?: ReactNode;
  actions?: (params: {close: () => void}) => ReactNode;
}

export default function TextDialog({title, customContent: content, actions, ...dialogProps}: Props): JSX.Element {
  return (
    <Dialog
      {...dialogProps}
      fullWidth>
      <CREEDDialogTitle
        title={title}
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent>
        {typeof content === 'string' ? (
          <DialogContentText sx={{
            whiteSpace: 'pre-wrap',
            overflowWrap: 'anywhere',
          }}>
            {content}
          </DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions>
        {actions != null ? (
          actions({
            close: () => dialogProps.onClose ? dialogProps.onClose({}, 'escapeKeyDown') : null,
          })
        ) : (
          <Button
            sx={{
              flex: 1,
            }}
            onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
            {'Done'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
