import { Theme } from '@mui/material/styles';
import sharedStylesClasses from '../../theme/sharedStylesClasses';


const classes = (theme: Theme) => ({
  ...sharedStylesClasses(theme),
  dealUploadCtr: {

  },
  containerPadding: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  dealCtr: {
    width: '100%',
  },
  dealTitle: {
    textAlign: 'left',
    // marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      textAlign: 'left'
    }
  },
  dealSubtitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  shareButton: {
    background: '#333333',
    color: '#ffffff',
    '&:hover': {
      background: '#333333',
      color: '#ffffff',
    }
  },
  dealInfoBox: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }
  },
  dealImageClickableBox: {
    display: 'flex',
    position: 'relative',
    width: '50%',
    aspectRatio: '3/2',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    }
  },
  dealImage: {
    display: 'flex',
    borderRadius: 8.0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    '&:hover': {
      opacity: 0.5,
    },
    
    [theme.breakpoints.down('md')]: {
      marginTop: '32px',
    },
  },
  dealImageOverlay: {
    display: 'block',
    position: 'absolute',
    inset: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
  },
  dealImageOverlayContentBox: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dealImageLoadingIndicator: {
    position: 'absolute',
    alignSelf: 'center',
  },
  dealImageUploadText: {
    color: 'white',
  },
  dealImagePlaceholder: {
    width: '100%',
    borderRadius: '8px',
    backgroundColor: '#EBEBEB',
    
    [theme.breakpoints.down('md')]: {
      marginTop: '32px',
    },
  },
  dealInfoFieldsBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    rowGap: '6px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingTop: 4,
    }
  },
  uploadDropZone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    rowGap: '16px',
    backgroundColor: '#3333330D',
    borderRadius: theme.spacing(1),
  },
  filesCtr: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  filesListCtr: {
    display: 'flex',
    flexDirection: 'column'
  },
  fileLine: {
    display: 'inline-flex',
    alignItems: 'center',
    height: theme.spacing(6),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
});

export default classes;
