import React, { useCallback, useState, useRef, useEffect } from 'react';
import PageContainer from '../PageContainer';
import { useAppSelector } from '../../app/hooks';
import { CircularProgress, Box, Grid, Typography, Button } from '@mui/material';
import AuthForm, { AuthData, AuthType } from '../AuthForm';
import { useNavigate, useParams } from 'react-router-dom';


export default function AcceptTeamInvitation(): JSX.Element {

  const navigate = useNavigate();
  const { invitationId } = useParams();

  const { shouldBeLoggedIn } = useAppSelector(state => state.auth);

  const [state, setState] = useState<'accepting' | 'auth' | 'error' | 'accepted'>('accepting');
  const [errorMessage, setErrorMessage] = useState('');

  // Rerenders higher up the tree (or React strict mode) is causing the useEffect
  // to run twice when it should only be running once so keep track of whether
  // or not it has been run already
  const invitationAcceptedRef = useRef(false);

  const onAuthSuccess = useCallback(async (type: AuthType, data: AuthData) => {
    switch (type) {
      case 'login':
        setState('accepting');
        if (!invitationAcceptedRef.current) {
          setErrorMessage('You are already a member of a team, please contact support@creed.tech for assistance.');
          setState('error');
        }
        break;
      case 'register':
        // Registration process already accepting invite, so no need to do it again
        navigate(`/signup-complete?email=${encodeURIComponent(data.email)}`);
        break;
    }
  }, [setState, navigate]);

  const renderContent = useCallback(() => {
    switch (state) {
      case 'accepting':
        return (
          <CircularProgress />
        );
      case 'auth':
        return (
          <AuthForm
            variant='user-only'
            metadata={{
              teamInvitationId: invitationId,
            }}
            onSuccess={onAuthSuccess} />
        );
      case 'error':
        return (
          <Box sx={{width: '300px'}} alignItems='center'>
            <Grid container>
              <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
                <Typography>
                  {'Error'}
                </Typography>
                {errorMessage !== '' &&
                  <Typography variant='error'>
                    {errorMessage}
                  </Typography>
                }
              </Grid>
            </Grid>
          </Box>
        );
      case 'accepted':
        return (
          <Box sx={{width: '300px'}} alignItems='center'>
            <Grid container>
              <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
                <Typography>
                  {'The invitation has been accepted.'}
                </Typography>
                <Button 
                  variant='contained'
                  sx={{marginTop: 2}}
                  onClick={() => navigate('/home')}>
                  {'Go to home'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        );
    }
  }, [state, errorMessage, navigate, onAuthSuccess, invitationId]);

  useEffect(() => {
    if (shouldBeLoggedIn) {
      if (!invitationAcceptedRef.current) {
        setErrorMessage('You are already a member of a team, please contact support@creed.tech for assistance.');
        setState('error');
      }
    } else {
      setState('auth');
    }
  }, [shouldBeLoggedIn]);

  return (
    <PageContainer>
      <Box sx={{ display: 'flex' }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ paddingTop: '5vh', }}>
          <Grid item xs={3}>
            {renderContent()}
          </Grid>   
        </Grid> 
      </Box>
    </PageContainer>
  );
}
