import React from 'react';
import { IconButton } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


interface Props {
  readonly onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function DialogCloseButton({onClick}: Props): JSX.Element {
  return (
    <IconButton
      sx={{
        width: '24px',
        height: '24px',
        color: '#333333',
        background: '#3333330A',
      }}
      onClick={onClick}>
      <CloseRoundedIcon sx={{
        fontSize: '17px'
      }} />
    </IconButton>
  );
}
