import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { ILender } from '../../schemas';


interface Props {
  readonly lender?: ILender;
  readonly message: string;
  readonly onMessageChange: (message: string) => void;
}

export default function MessageContent({message, lender, onMessageChange}: Props): JSX.Element {

  return (
    <Box>
      <TextField
        InputProps={{
          style: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17px',
          }
        }}
        placeholder='Type message here...'
        multiline
        fullWidth
        size='small'
        variant='outlined'
        color='secondary'
        rows={6}
        value={message}
        onChange={e => onMessageChange(e.target.value)} />

      <Typography sx={{
        marginTop: '16px',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#33333380',
      }}>
        {'What happens next?'}
      </Typography>

      <Typography sx={{
        marginTop: '4px',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#33333380',
      }}>
        {`This project will be delivered to ${lender != null ? lender['Lender'] : ''} on your behalf on CREED chat, where the conversation will continue. In the meantime you can keep uploading more documents to help improve your odds of financing.`}
      </Typography>
    </Box>
  );
}
