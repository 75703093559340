import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IAccount, IInterestRateData } from "../schemas";
import { baseUrls, createBaseQuery } from "./apiHelper";


export const utilsApiSlice = createApi({
  reducerPath: 'api-utils',
  tagTypes: ['SponsorDirectory', 'InvestorDirectory'],
  baseQuery: createBaseQuery(baseUrls.utils),
  endpoints: (build) => ({
    getInterestRates: build.query<IInterestRateData[], void>({
      query: () => '/utils/interest-rates',
    }),
    getSponsorAccountDirectory: build.query<Pick<IAccount, '_id' | 'profileImageUrl' | 'profile'>[], void>({
      providesTags: ['SponsorDirectory'],
      query: () => '/utils/sponsor-directory',
    }),
    getInvestorAccountDirectory: build.query<Pick<IAccount, '_id' | 'profile'>[], void>({
      providesTags: ['InvestorDirectory'],
      query: () => '/utils/investor-directory',
    })
  })
});

export const {
  useGetInterestRatesQuery,
  useGetSponsorAccountDirectoryQuery,
  useGetInvestorAccountDirectoryQuery,
} = utilsApiSlice;
