import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { useDefaultPath } from '../../utils/hooks';
import PageContainer from '../PageContainer';


export default function Index(): JSX.Element {
  const navigate = useNavigate();

  const defaultPath = useDefaultPath(true);
  const shouldBeLoggedIn = useAppSelector(state => state.auth.shouldBeLoggedIn);

  useEffect(() => {
    if (shouldBeLoggedIn && defaultPath != null) {
      navigate(defaultPath);
    } else if (!shouldBeLoggedIn) {
      navigate('/login');
    }
  }, [shouldBeLoggedIn, navigate, defaultPath]);

  return (
    <PageContainer />
  );
}
