import React, { useMemo } from 'react';
import { Chip, ChipProps } from '@mui/material';


interface Props {
  readonly status: string;
}

export default function LenderStatusChip({status}: Props): JSX.Element {
  
  const label = useMemo(() => {
    switch (status) {
      case 'under-review':    return 'Under review';
      case 'quoted':          return 'Quoted';
      case 'app-negotiation': return 'Application Negotiation';
      case 'rejected':        return 'Pass';
      default:                return 'Unknown';
    }
  }, [status]);

  const props: ChipProps = useMemo(() => {
    switch (status) {
      case 'under-review':
        return {
          color: 'info',
          sx: {
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '15.9px',
          }
        };
      case 'quoted':
      case 'app-negotiation':
        return {
          color: 'success',
          sx: {
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '15.9px',
          }
        };
      case 'rejected':
        return {
          color: 'error',
          sx: {
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '15.9px',
          }
        };
      default:
        return {};
    }
  }, [status]);
  
  return (
    <Chip
      {...props}
      label={label} />
  );
}
