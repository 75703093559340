import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogProps, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import CREEDDialogTitle from "../../CREEDDialogTitle";
import { IDeal } from "../../../schemas";
import LoadingButton from "../../LoadingButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import CommentRow from "../../CommentRow";
import { useAppDispatch } from "../../../app/hooks";
import { snacked } from "../../../features/snackMessage-slice";
import { useRecordContactedLenderFeedbackMutation } from "../../../features/deals-api";


interface Props extends DialogProps {
  deal?: IDeal;
  lenderName?: string;
  lenderLogoUrl?: string;
}

export default function SponsorRecordLenderFeedbackDialog({deal, lenderName, lenderLogoUrl, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [status, setStatus] = useState<'pending' | 'accepted' | 'rejected' | null>();
  const [comment, setComment] = useState('');

  const [recordFeedback, { isLoading: isRecordFeedbackLoading }] = useRecordContactedLenderFeedbackMutation();

  const lender = useMemo(() => {
    return deal?.contactedLenders?.find(l => l.name === lenderName);
  }, [deal?.contactedLenders, lenderName]);

  const feedback = useMemo(() => {
    if (lender?.feedback == null) {
      return undefined;
    }

    const feedback = [...lender.feedback];
    feedback.sort((a, b) => Date.parse(b.createdDt) - Date.parse(a.createdDt));
    
    return feedback;
  }, [lender?.feedback]);

  const formSubmitted = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deal?._id == null || lender == null || status == null) {
      return;
    }

    try {
      await recordFeedback({
        dealId: deal._id,
        lenderName: lender.name,
        status: status,
        message: comment,
      }).unwrap();

      setStatus(null);
      setComment('');

      dispatch(snacked({
        message: 'Feedback recorded',
        severity: 'success',
      }));
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed recording lender feedback',
        severity: 'error',
      }));
    }
  }, [comment, deal?._id, dispatch, lender, recordFeedback, status]);

  useEffect(() => {
    setStatus(null);
    setComment('');
  }, [lenderName]);

  return (
    <Dialog
      maxWidth='lg'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='Record Feedback'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <form onSubmit={formSubmitted}>
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
          }}>
            <Avatar
              sx={{
                width: '112px',
                height: '112px',
              }}
              variant='rounded'
              src={lenderLogoUrl} />
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}>
              <Typography sx={{
                fontWeight: '500',
                fontSize: '18px',
              }}>
                {lender?.name}
              </Typography>
              <Chip
                sx={{
                  alignSelf: 'flex-start',
                }}
                color={lender?.status === 'accepted' ? 'success' : lender?.status === 'rejected' ? 'error' : 'info'}
                label={lender?.status === 'accepted' ? 'Interested' : lender?.status === 'rejected' ? 'Declined' : 'Pending'} />
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
          }}>
            <Box sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 1,
            }}>
              <Tooltip
                title={null}
                placement='top'
                arrow>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 0.5,
                  alignItems: 'center',
                  fontWeight: '500',
                  fontSize: '14px',
                  alignSelf: 'flex-start',
                }}>
                  <Typography sx={{
                    fontWeight: 'inherit',
                    fontSize: 'inherit',
                  }}>
                    {'Previous Feedback'}
                  </Typography>
                </Box>
              </Tooltip>
              {feedback != null && feedback.length > 0 ? (
                <Box sx={{
                  overflowY: 'auto',
                  maxHeight: '512px',
                  paddingRight: 1,
                }}>
                  {feedback.map((fb, index) => (
                    <CommentRow
                      name={`Update ${feedback.length - index}`}
                      comment={fb.message}
                      date={fb.createdDt}
                      hideAvatar />
                  ))}
                </Box>
              ) : (
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '12px',
                }}>
                  {'No feedback has been recorded for this lender yet'}
                </Typography>
              )}
            </Box>
            <Box sx={{
              display: 'flex',
              flex: 2,
              flexDirection: 'column',
              gap: 2,
            }}>
              <Tooltip
                title={null}
                placement='top'
                arrow>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 0.5,
                  alignItems: 'center',
                  fontWeight: '500',
                  fontSize: '14px',
                  alignSelf: 'flex-start',
                }}>
                  <Typography sx={{
                    fontWeight: 'inherit',
                    fontSize: 'inherit',
                  }}>
                    {'New Feedback'}
                  </Typography>
                </Box>
              </Tooltip>
              <FormControl
                fullWidth
                required>
                <InputLabel>
                  {'Lender Status'}
                </InputLabel>
                <Select
                  label='Lender Status'
                  value={status}
                  onChange={e => setStatus(e.target.value as typeof status)}>
                  <MenuItem value='pending'>{'Pending'}</MenuItem>
                  <MenuItem value='accepted'>{'Interested'}</MenuItem>
                  <MenuItem value='rejected'>{'Declined'}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label='Comment (optional)'
                fullWidth
                multiline
                minRows={10}
                maxRows={14}
                value={comment}
                onChange={e => setComment(e.target.value)} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              flex: 1,
            }}
            onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
            {'Close'}
          </Button>
          <LoadingButton
            sx={{
              flex: 1,
            }}
            type='submit'
            variant='contained'
            loading={isRecordFeedbackLoading}>
            {'Record Feedback'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
