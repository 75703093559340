import { createApi } from '@reduxjs/toolkit/query/react';
import { baseUrls, createBaseQuery } from './apiHelper';
import { IAccount, IAccountEquityMarketplaceFilters, IAccountProfile, IAccountTeam, IAccountTeamInvitation, IUser, TeamMemberAccessLevel } from '../schemas';
import { dealsApiSlice } from './deals-api';
import { messagingApiSlice } from './messaging-api';
import { documentRequestsApiSlice } from './document-requests-api';
import { invitationsApiSlice } from './invitations-api';
import { notificationsApiSlice } from './notifications-api';
import { paymentsApiSlice } from './payments-api';
import { utilsApiSlice } from './utils-api';


// define api slice for base server and then different endpoints inside of that for each suburl we want to hit
export const accountsApiSlice = createApi({
  reducerPath: 'api-account',
  tagTypes: ['User', 'Account', 'Team', 'TeamInvitations', 'EquityMarketplaceFilters'],
  baseQuery: createBaseQuery(baseUrls.main),
  endpoints: (build) => ({
    getCurrentAccount: build.query<IAccount, void>({
      providesTags: ['Account'],
      query: () => '/accounts/current',
    }),
    getCurrentUser: build.query<IUser, void>({
      providesTags: ['User'],
      query: () => '/users/current',
    }),
    getCurrentAccountTeam: build.query<IAccountTeam, void>({
      providesTags: ['Team'],
      query: () => '/accounts/current/team',
    }),
    getCurrentAccountTeamInvitations: build.query<IAccountTeamInvitation[], void>({
      providesTags: ['TeamInvitations'],
      query: () => '/accounts/current/team/invitations',
    }),
    getTearSheetDownloadUrl: build.query<{url: string}, {fileKey: string}>({
      query: ({fileKey}) => `/accounts/profile/tear-sheet/${encodeURIComponent(fileKey)}`,
    }),
    getCurrentAccountEquityMarketplaceFilters: build.query<IAccountEquityMarketplaceFilters, void>({
      providesTags: ['EquityMarketplaceFilters'],
      query: () => '/accounts/current/equity-marketplace-filters',
    }),

    getProfileImageUploadUrl: build.mutation<{url: string}, {fileName: string, contentType: string}>({
      query: ({fileName, contentType}) => {
        return {
          url: '/accounts/profile/upload',
          method: 'POST',
          body: {
            fileKey: fileName,
            contentType: contentType,
          }
        };
      }
    }),
    getTearSheetUploadUrl: build.mutation<{url: string}, {fileName: string, contentType: string}>({
      query: ({fileName, contentType}) => {
        return {
          url: '/accounts/profile/tear-sheet/upload',
          method: 'POST',
          body: {
            fileName,
            contentType,
          }
        };
      }
    }),
    deleteTearSheet: build.mutation<{message: string}, {fileKey: string}>({
      invalidatesTags: ['Account'],
      query: ({fileKey}) => {
        return {
          url: `/accounts/profile/tear-sheet/${encodeURIComponent(fileKey)}`,
          method: 'DELETE',
        };
      },
      onQueryStarted: async ({fileKey}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          accountsApiSlice.util.updateQueryData('getCurrentAccount', undefined, (draft) => {
            draft.tearSheets = draft.tearSheets?.filter(f => f.key !== fileKey);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    updateCurrentUser: build.mutation<{message: string}, {accountId: string}>({
      invalidatesTags: ['User', 'Account', 'Team', 'TeamInvitations'],
      query: ({accountId}) => {
        return {
          url: '/users/current',
          method: 'PATCH',
          body: {
            accountId,
          }
        };
      },
      onQueryStarted: async ({accountId}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          accountsApiSlice.util.updateQueryData('getCurrentUser', undefined, (draft) => {
            draft.accountId = accountId;
          })
        );

        try {
          await queryFulfilled;
          if (accountId != null) {
            dispatch(dealsApiSlice.util.resetApiState());
            dispatch(messagingApiSlice.util.resetApiState());
            dispatch(documentRequestsApiSlice.util.resetApiState());
            dispatch(invitationsApiSlice.util.resetApiState());
            dispatch(notificationsApiSlice.util.resetApiState());
            dispatch(paymentsApiSlice.util.resetApiState());
            dispatch(utilsApiSlice.util.resetApiState());
          }
        } catch {
          patchResult.undo();
        }
      }
    }),
    updateCurrentAccount: build.mutation<{message: string, status: string}, Partial<IAccount>>({
      invalidatesTags: ['Account'],
      query: (params) => {
        return {
          url: '/accounts/current',
          method: 'PATCH',
          body: params,
        };
      }
    }),
    updateAccountProfile: build.mutation<{message: string}, {profile: IAccountProfile}>({
      invalidatesTags: ['Account'],
      query: ({profile}) => {
        return {
          url: '/accounts/profile/',
          method: 'PUT',
          body: profile,
        };
      },
      // onQueryStarted: async ({profile}, {dispatch, queryFulfilled}) => {
      //   const patchResult = dispatch(
      //     accountsApiSlice.util.updateQueryData('getCurrentAccount', undefined, (draft) => {
      //       draft.profile = profile;
      //     })
      //   );

      //   try {
      //     await queryFulfilled;
      //     dispatch(snacked({
      //       message: 'Profile updated',
      //       severity: 'success',
      //     }));
      //   } catch {
      //     patchResult.undo();
      //     dispatch(snacked({
      //       message: 'Network error. Please try again.',
      //       severity: 'error',
      //     }));
      //   }
      // }
    }),
    inviteUserToTeam: build.mutation<{message: string}, {email: string, accessLevel: TeamMemberAccessLevel}>({
      invalidatesTags: ['TeamInvitations'],
      query: ({email, accessLevel}) => {
        return {
          url: '/accounts/current/team',
          method: 'POST',
          body: {
            email: email,
            accessLevel: accessLevel,
          }
        };
      },
      onQueryStarted: async ({email, accessLevel}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          accountsApiSlice.util.updateQueryData('getCurrentAccountTeamInvitations', undefined, (draft) => {
            draft = [
              ...draft,
              {
                _id: '',
                teamId: '',
                email: email,
                accessLevel: accessLevel,
                invitingUserId: '',
                status: 'pending',
                createdDt: new Date().toISOString(),
              }
            ];
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    cancelTeamInvitation: build.mutation<{message: string}, {invitationId: string}>({
      invalidatesTags: ['TeamInvitations'],
      query: ({invitationId}) => {
        return {
          url: `/accounts/current/team/invitations/${invitationId}`,
          method: 'DELETE',
        };
      },
      onQueryStarted: async ({invitationId}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          accountsApiSlice.util.updateQueryData('getCurrentAccountTeamInvitations', undefined, (draft) => {
            draft = draft.filter(inv => inv._id !== invitationId);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    updateTeamMemberAccess: build.mutation<{message: string}, {userId: string, accessLevel: TeamMemberAccessLevel}>({
      invalidatesTags: ['Team'],
      query: ({userId, accessLevel}) => {
        return {
          url: '/accounts/current/team/users',
          method: 'PATCH',
          body: {
            userId: userId,
            accessLevel: accessLevel,
          }
        };
      },
      onQueryStarted: async ({userId, accessLevel}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          accountsApiSlice.util.updateQueryData('getCurrentAccountTeam', undefined, (draft) => {
            draft.userInfo[userId] = {
              ...draft.userInfo[userId],
              accessLevel: accessLevel,
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    removeTeamAccess: build.mutation<{message: string}, {userId: string}>({
      invalidatesTags: ['Team'],
      query: ({userId}) => {
        return {
          url: '/accounts/current/team/users',
          method: 'DELETE',
          body: {
            userId: userId,
          }
        };
      },
      onQueryStarted: async ({userId}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          accountsApiSlice.util.updateQueryData('getCurrentAccountTeam', undefined, (draft) => {
            const userInfo = {...draft.userInfo};
            delete userInfo[userId];
            
            draft = {
              ...draft,
              userIds: draft.userIds.filter(uid => uid !== userId),
              users: draft.users?.filter(u => u._id !== userId),
              userInfo: userInfo,
            };
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    updateCurrentAccountEquityMarketplaceFilters: build.mutation<{message: string, status: string}, Omit<IAccountEquityMarketplaceFilters, '_id' | 'accountId' | 'createdDt' | 'updatedDt'>>({
      invalidatesTags: ['EquityMarketplaceFilters'],
      query: (params) => {
        return {
          url: '/accounts/current/equity-marketplace-filters',
          method: 'PUT',
          body: params,
        };
      },
    }),
  })
})

export const {
  useGetCurrentAccountQuery,
  useLazyGetCurrentAccountQuery,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useGetCurrentAccountTeamQuery,
  useLazyGetCurrentAccountTeamQuery,
  useGetCurrentAccountTeamInvitationsQuery,
  useLazyGetTearSheetDownloadUrlQuery,
  useGetCurrentAccountEquityMarketplaceFiltersQuery,

  useGetProfileImageUploadUrlMutation,
  useGetTearSheetUploadUrlMutation,
  useDeleteTearSheetMutation,
  useUpdateCurrentUserMutation,
  useUpdateCurrentAccountMutation,
  useUpdateAccountProfileMutation,
  useInviteUserToTeamMutation,
  useCancelTeamInvitationMutation,
  useUpdateTeamMemberAccessMutation,
  useRemoveTeamAccessMutation,
  useUpdateCurrentAccountEquityMarketplaceFiltersMutation,
} = accountsApiSlice;
