import React, { useState, useCallback, forwardRef, useMemo, useContext, useEffect } from 'react';
import { Box, Typography, IconButton, Collapse, Tab, Tabs, Button, Divider, Menu, MenuItem, Avatar, TextField, Badge, Tooltip, Chip } from '@mui/material';
import Card from '../Card';
import DocumentRequestStatusChip from './DocumentRequestStatusChip';
import DocumentRequestStatusIndicator from './DocumentRequestStatusIndicator';
import FileUploadBox from '../FileUploadBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DocumentRequestStatus, IFile, IDocumentRequest, IDeal, IAccount, IDocumentRequestEventParticipant } from '../../schemas';
import FileIcon from '../FileIcon';
import { humanFileSize, uploadFileToUrl } from '../../utils/utils';
import FileRow from '../FileRow';
import { useCreateDealFileUploadUrlMutation, useDeleteDealFileMutation, useLazyGetDealFileDownloadUrlQuery } from '../../features/deals-api';
import { useAppDispatch } from '../../app/hooks';
import axios from 'axios';
import { snacked } from '../../features/snackMessage-slice';
import fileDownload from 'js-file-download';
import NorthIcon from '@mui/icons-material/North';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import { useGetNotificationsQuery, useSetNotificationReadMutation } from '../../features/notifications-api';
import { DealActionContext } from './DealDiligenceBase';
import { useAddDocumentRequestEventMutation, useDeleteDocumentRequestFileMutation, useGetDealDocumentRequestUploadUrlMutation, useLazyGetDocumentRequestAttachmentUrlQuery, useLazyGetDocumentRequestFileUrlQuery } from '../../features/document-requests-api';
import CommentRow from '../CommentRow';


export interface DocumentRequestSectionData {
  readonly key: string;
  readonly title: string;
  readonly description?: string;
  readonly status?: DocumentRequestStatus;
  readonly files: IFile[];
  readonly documentRequest?: IDocumentRequest;
}

interface Props {
  readonly open?: boolean;
  readonly closable?: boolean;
  readonly deal?: IDeal;
  readonly participants?: IAccount[];
  readonly section: DocumentRequestSectionData;
}

const DocumentRequestSection = forwardRef<HTMLElement, Props>(({
  open: propsOpen,
  closable,
  deal,
  participants,
  section,
}, ref): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    makeDocumentRequestConfidential,
    manageDocumentRequestAccess,
    setDocumentRequestAsPriority,
    deleteDocumentRequest,
    previewFile,
    submitDocumentRequestClicked,
    unsubmitDocumentRequestClicked,
    acceptDocumentRequestClicked,
    rejectDocumentRequestClicked,
  } = useContext(DealActionContext);

  const [open, setOpen] = useState(propsOpen ?? false);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [menuAnchorElem, setMenuAnchorElem] = useState<HTMLElement | undefined>();
  const [fileMenuAnchorElem, setFileMenuAnchorElem] = useState<HTMLElement | undefined>();
  const [selectedFile, setSelectedFile] = useState<IFile | undefined>();
  const [currentComment, setCurrentComment] = useState('');

  const {data: account} = useGetCurrentAccountQuery();
  const {data: notifications} = useGetNotificationsQuery();
  const [getDocumentRequestAttachmentUrl] = useLazyGetDocumentRequestAttachmentUrlQuery();
  
  const [getDocumentRequestFileDownloadUrl] = useLazyGetDocumentRequestFileUrlQuery();
  const [getDealFileDownloadUrl] = useLazyGetDealFileDownloadUrlQuery();
  const [getDocumentRequestUploadUrl] = useGetDealDocumentRequestUploadUrlMutation();
  const [createDealFileUploadUrl] = useCreateDealFileUploadUrlMutation();
  const [deleteDocumentRequestFile] = useDeleteDocumentRequestFileMutation();
  const [deleteFile] = useDeleteDealFileMutation();
  const [addDocumentRequestEvent] = useAddDocumentRequestEventMutation();
  const [setNotificationRead] = useSetNotificationReadMutation();

  const canUploadFiles = account?.currentSubscription?.isActive
    && (section.documentRequest == null || section.status === 'pending' || section.status === 'rejected')
    && (account?.type === 'sponsor' || section.documentRequest == null);

  const messageEvents = useMemo(() => {
    if (section.documentRequest == null) {
      return [];
    }

    const events = section.documentRequest.events?.filter(e => e.message != null);
    return events ?? [];
  }, [section.documentRequest]);

  const participantsMap = useMemo(() => {
    let map = participants?.reduce((obj, account) => {
      return {
        ...obj,
        [account._id]: {
          accountId: account._id,
          accountName: account.name,
          accountType: account.type,
          accountProfileImageUrl: account.profileImageUrl,
        },
      };
    }, {} as Record<string, IDocumentRequestEventParticipant>);

    if (section.documentRequest != null) {
      const participantCacheMap = section.documentRequest.eventParticipants?.reduce((obj, participant) => {
        return {
          ...obj,
          [participant.accountId]: participant,
        };
      }, {} as Record<string, IDocumentRequestEventParticipant>);

      map = {
        ...participantCacheMap,
        ...map ?? {},
      };
    }

    return map ?? {};
  }, [participants, section.documentRequest]);

  const unreadCommentNotifications = useMemo(() => {
    if (section.documentRequest == null) {
      return undefined;
    }

    return notifications?.filter(n => !n.isRead && n.type === 'document_request_commented' && n.documentRequestId === section.documentRequest?._id);
  }, [notifications, section.documentRequest]);

  const submitClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (section.documentRequest == null || submitDocumentRequestClicked == null) {
      return;
    }

    submitDocumentRequestClicked(section.documentRequest);
  }, [section.documentRequest, submitDocumentRequestClicked]);

  const unsubmitClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (section.documentRequest == null || unsubmitDocumentRequestClicked == null) {
      return;
    }

    unsubmitDocumentRequestClicked(section.documentRequest);
  }, [section.documentRequest, unsubmitDocumentRequestClicked]);

  const approveClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (section.documentRequest == null || acceptDocumentRequestClicked == null) {
      return;
    }

    acceptDocumentRequestClicked(section.documentRequest);
  }, [section.documentRequest, acceptDocumentRequestClicked]);

  const commentClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    if (section.documentRequest == null || rejectDocumentRequestClicked == null) {
      return;
    }

    rejectDocumentRequestClicked(section.documentRequest);
  }, [section.documentRequest, rejectDocumentRequestClicked]);

  const moreClicked = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorElem(event.currentTarget);
  }, [setMenuAnchorElem]);

  const copyLinkClicked = useCallback(async () => {
    if (deal == null) {
      return;
    }

    const docRequestId = section.documentRequest != null ? section.documentRequest._id : 'general';
    const url = `${window.location.protocol}//${window.location.host}/deal/${deal._id}/documents/${docRequestId}`;
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(url);
    } else {
      document.execCommand('copy', true, url);
    }

    setMenuAnchorElem(undefined);

    dispatch(snacked({
      message: 'Link copied to clipboard',
      severity: 'info',
    }));
  }, [dispatch, deal, section.documentRequest]);

  const makeConfidentialClicked = useCallback(() => {
    if (section.documentRequest == null) {
      return;
    }

    if (makeDocumentRequestConfidential != null) {
      makeDocumentRequestConfidential(section.documentRequest);
    }

    setMenuAnchorElem(undefined);
  }, [section.documentRequest, makeDocumentRequestConfidential, setMenuAnchorElem]);

  const setAsPriorityClicked = useCallback(() => {
    if (section.documentRequest == null) {
      return;
    }

    if (setDocumentRequestAsPriority != null) {
      setDocumentRequestAsPriority(section.documentRequest);
    }

    setMenuAnchorElem(undefined);
  }, [section.documentRequest, setDocumentRequestAsPriority, setMenuAnchorElem]);

  const manageAccessClicked = useCallback(() => {
    if (section.documentRequest == null) {
      return;
    }

    if (manageDocumentRequestAccess != null) {
      manageDocumentRequestAccess(section.documentRequest);
    }

    setMenuAnchorElem(undefined);
  }, [section.documentRequest, manageDocumentRequestAccess, setMenuAnchorElem]);

  const deleteClicked = useCallback(() => {
    if (section.documentRequest == null) {
      return;
    }

    if (deleteDocumentRequest != null) {
      deleteDocumentRequest(section.documentRequest);
    }

    setMenuAnchorElem(undefined);
  }, [setMenuAnchorElem, deleteDocumentRequest, section.documentRequest]);

  const onDocumentRequestFilesDropped = useCallback(async (acceptedFiles: File[]) => {
    if (deal?._id == null) {
      return;
    }

    if (section.documentRequest != null) {
      // Upload files to the document request
      setLoading(true);

      try {
        const uploadUrlResponses = await Promise.all(acceptedFiles.map(f => getDocumentRequestUploadUrl({
          dealId: deal?._id!,
          documentRequestId: section.documentRequest!._id,
          fileName: f.name,
          contentType: f.type,
        }).unwrap()));
  
        await Promise.all(uploadUrlResponses.map((response, index) => {
          if (!response.url) {
            return null;
          }
          
          const file = acceptedFiles[index];
          return axios.put(response.url, file, {
            headers: {
              'Content-Type': file.type,
            }
          });
        }));
      } catch (error) {
        console.error(error);
        dispatch(snacked({
          message: 'Failed uploading files',
          severity: 'error',
        }));
      } finally {
        setLoading(false);
      }
    } else {
      // Upload files directly to deal (General)
      setLoading(true);

      try {
        const uploadUrlResponses = await Promise.all(acceptedFiles.map(file => createDealFileUploadUrl({
          dealId: deal?._id!,
          fileType: 'file',
          fileName: file.name,
          contentType: file.type,
        }).unwrap()));

        await Promise.all(
          uploadUrlResponses.map((response, index) => uploadFileToUrl(response.url, acceptedFiles[index]))
        );
      } catch (error) {
        console.error(error);
        dispatch(snacked({
          message: 'Failed uploading files',
          severity: 'error',
        }));
      } finally {
        setLoading(false);
      }
    }
  }, [deal?._id, section.documentRequest, getDocumentRequestUploadUrl, dispatch, createDealFileUploadUrl]);

  const previewFileClicked = useCallback(async (file: IFile) => {
    if (deal?._id == null) {
      return;
    }

    let url: string | undefined = undefined;
    if (section.documentRequest != null) {
      const result = await getDocumentRequestFileDownloadUrl({
        dealId: deal._id,
        documentRequestId: section.documentRequest._id,
        fileKey: file.key,
      }).unwrap();

      url = result.url;
    } else {
      const result = await getDealFileDownloadUrl({
        dealId: deal._id,
        fileType: 'file',
        fileKey: file.key,
      }).unwrap();

      url = result.url;
    }

    if (previewFile != null && url != null) {
      previewFile(file.name, url);
    }
  }, [deal?._id, section.documentRequest, previewFile, getDocumentRequestFileDownloadUrl, getDealFileDownloadUrl]);

  const downloadFileClicked = useCallback(async (file: IFile) => {
    if (deal?._id == null) {
      return;
    }

    let url: string | undefined = undefined;
    if (section.documentRequest != null) {
      const result = await getDocumentRequestFileDownloadUrl({
        dealId: deal._id,
        documentRequestId: section.documentRequest._id,
        fileKey: file.key,
      }).unwrap();

      url = result.url;
    } else {
      const result = await getDealFileDownloadUrl({
        dealId: deal._id,
        fileType: 'file',
        fileKey: file.key,
      }).unwrap();

      url = result.url;
    }

    if (url) {
      const signedUrlResponse = await axios.get(url, {
        responseType: 'blob'
      });
  
      const { data: blob } = signedUrlResponse;
      fileDownload(blob, file.name ?? file.key);
    }
  }, [deal?._id, section.documentRequest, getDocumentRequestFileDownloadUrl, getDealFileDownloadUrl]);

  const fileMenuClicked = useCallback((event: React.MouseEvent<HTMLElement>, file: IFile) => {
    setSelectedFile(file);
    setFileMenuAnchorElem(event.currentTarget);
  }, [setSelectedFile, setFileMenuAnchorElem]);

  const copyFileLinkClicked = useCallback(async () => {
    if (deal == null || selectedFile == null) {
      setFileMenuAnchorElem(undefined);
      setSelectedFile(undefined);
      return;
    }

    const docReqId = section.documentRequest == null ? 'general' : section.documentRequest._id;

    const url = `${window.location.protocol}//${window.location.host}/deal/${deal._id}/documents/${docReqId}?file=${encodeURIComponent(selectedFile.key)}`;
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(url);
    } else {
      document.execCommand('copy', true, url);
    }

    setFileMenuAnchorElem(undefined);
    setSelectedFile(undefined);

    dispatch(snacked({
      message: 'Link copied to clipboard',
      severity: 'info',
    }));
  }, [deal, selectedFile, section.documentRequest, setFileMenuAnchorElem, setSelectedFile, dispatch]);

  const deleteFileClicked = useCallback(async () => {
    if (deal?._id == null || selectedFile == null) {
      setFileMenuAnchorElem(undefined);
      setSelectedFile(undefined);
      return;
    }

    try {
      if (section.documentRequest != null) {
        deleteDocumentRequestFile({
          dealId: deal._id,
          documentRequestId: section.documentRequest._id,
          fileKey: selectedFile.key,
        });
      } else {
        deleteFile({
          id: deal._id,
          fileKey: selectedFile.key,
        });
      }
    } catch {

    } finally {
      setFileMenuAnchorElem(undefined);
      setSelectedFile(undefined);
    }
  }, [deal, deleteFile, section, setFileMenuAnchorElem, setSelectedFile, deleteDocumentRequestFile, selectedFile]);

  const documentRequestAttachmentClicked = useCallback(async (file: IFile) => {
    if (deal?._id == null || section.documentRequest == null) {
      return;
    }

    const {url} = await getDocumentRequestAttachmentUrl({
      dealId: deal._id,
      documentRequestId: section.documentRequest._id,
      fileKey: file.key,
    }).unwrap();

    if (url) {
      const signedUrlResponse = await axios.get(url, {
        responseType: 'blob'
      });
  
      const { data: blob } = signedUrlResponse;
      fileDownload(blob, file.name ?? file.key);
    }
  }, [deal, getDocumentRequestAttachmentUrl, section.documentRequest]);

  const addCommentClicked = useCallback(() => {
    if (deal?._id == null || section.documentRequest == null || account == null || !currentComment) {
      return;
    }

    addDocumentRequestEvent({
      dealId: deal._id,
      documentRequestId: section.documentRequest._id,
      message: currentComment,
      senderAccount: account,
    });

    setCurrentComment('');
  }, [currentComment, setCurrentComment, account, addDocumentRequestEvent, deal?._id, section.documentRequest]);

  useEffect(() => {
    if (currentTab === 1 && unreadCommentNotifications != null) {
      for (let notification of unreadCommentNotifications) {
        setNotificationRead({
          notificationId: notification._id as string,
          isRead: true 
        });
      }
    }
  }, [currentTab, setNotificationRead, unreadCommentNotifications]);

  return (
    <Card
      ref={ref}
      sx={{
        padding: '20px',
        paddingLeft: '22px',
        paddingRight: '22px',
      }}>
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onClick={() => closable !== false ? setOpen(!open) : null}>
        <Box sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          gap: '14px',
        }}>
          <DocumentRequestStatusIndicator status={section.status ?? 'pending'} />

          <Tooltip
            title={
              section.documentRequest?.securityLevel === 'confidential'
                ? 'This document request is confidential. It\'s only visible to you, the sponsor/lender and the stakeholders invited to it.'
                : ''
            }
            placement='top'
            arrow>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '44px',
              height: '44px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '8px',
              background: '#3333330A',
            }}>
              {section.documentRequest?.securityLevel === 'confidential' ? (
                <LockOutlinedIcon
                  sx={{
                    fontSize: '20px',
                    color: '#333333',
                  }} />
              ) : (
                <FolderOutlinedIcon
                  sx={{
                    fontSize: '20px',
                    color: '#333333',
                  }} />
              )}
            </Box>
          </Tooltip>

          <Typography sx={{
            textOverflow: 'ellipsis',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '19.57px',
          }}>
            {section.title}
          </Typography>

          {section.status != null &&
            <DocumentRequestStatusChip
              status={section.status}
              accountType={account?.type} />
          }

          {((account?.type === 'lender' && section.documentRequest?.isLenderPriority) || (account?.type === 'sponsor' && section.documentRequest?.isSponsorPriority)) &&
            <Chip
              sx={{
                fontWeight: '500',
                fontSize: '13px',
              }}
              label={`${account.type === 'lender' ? 'Lender' : 'Borrower'} Priority Item`}
              color='error' />
          }

          {((account?.type === 'lender' && section.documentRequest?.isSponsorPriority) || (account?.type === 'sponsor' && section.documentRequest?.isLenderPriority)) &&
            <Chip
              sx={{
                fontWeight: '500',
                fontSize: '13px',
              }}
              label={`${account.type === 'lender' ? 'Borrower' : 'Lender'} Priority Item`}
              color='info' />
          }
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          {account?.currentSubscription?.isActive
            && section.documentRequest != null
            && (section.documentRequest.files ?? []).length > 0
            && (section.status === 'pending' || section.status === 'rejected')
            && account?.type === 'sponsor' &&
            <Button
              sx={{
                height: '31px',
                marginRight: '20px',
              }}
              variant='contained'
              onClick={submitClicked}>
              {'Submit'}
            </Button>
          }

          {account?.currentSubscription?.isActive
            && section.documentRequest != null
            && section.status === 'submitted'
            && account?.type === 'sponsor' &&
            <Button
              sx={{
                height: '31px',
                marginRight: '20px',
              }}
              variant='contained'
              onClick={unsubmitClicked}>
              {'Un-submit'}
            </Button>
          }

          {account?.currentSubscription?.isActive
            && section.documentRequest != null
            && account?._id !== deal?.accountId
            && account.type === 'lender' &&
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '16px',
              paddingRight: '20px',
            }}>
              {section.status === 'submitted' &&
                <>
                  <Button
                    sx={{
                      height: '31px',
                    }}
                    variant='contained'
                    onClick={approveClicked}>
                    {'Approve'}
                  </Button>

                  <Button
                    sx={{
                      height: '31px',
                      color: '#ffffff',
                      background: 'linear-gradient(90.87deg, #333333 47.63%, #DDCFB2 152.29%)',
                    }}
                    variant='contained'
                    onClick={commentClicked}>
                    {'Comment'}
                  </Button>
                </>
              }
            </Box>
          }

          <IconButton
            sx={{
              marginRight: closable !== false ? '20px' : 0,
              width: '28px',
              height: '28px',
            }}
            color='info'
            onClick={moreClicked}>
            <MoreHorizIcon fontSize='small' />
          </IconButton>

          {closable !== false &&
            <IconButton onClick={() => setOpen(!open)}>
              {open ? (
                <ExpandMoreIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          }
        </Box>
      </Box>

      <Collapse in={open}>
        <Box sx={{
          paddingTop: '24px',
        }}>
          {section.documentRequest != null &&
            <Tabs
              indicatorColor='primary'
              value={currentTab}
              onChange={(e, value) => setCurrentTab(value)}>
              <Tab label='Documents' />
              <Tab
                label='Comments'
                icon={
                  Boolean(unreadCommentNotifications) ?
                    <Badge
                      sx={{
                        paddingLeft: Boolean(unreadCommentNotifications) ? '8px' : 0,
                        fontWeight: '500',
                        fontSize: '13px',
                      }}
                      invisible={!Boolean(unreadCommentNotifications)}
                      badgeContent={unreadCommentNotifications?.length}
                      color='primary' />
                  : undefined
                }
                iconPosition='end' />
            </Tabs>
          }

          <div
            role="tabpanel"
            hidden={section.documentRequest != null && currentTab !== 0}
            id={`tabpanel-dashboard`}
            aria-labelledby={`tab-dashboard`}>
            {currentTab === 0 && (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '24px',
                rowGap: '24px',
              }}>
                {section.description &&
                  <Typography sx={{
                    whiteSpace: 'pre-wrap',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '17.12px',
                    color: '#333333BF',
                  }}>
                    {section.description}
                  </Typography>
                }

                {section.documentRequest?.attachments &&
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                    <Typography sx={{
                      fontWeight: '500',
                      fontSize: '12px',
                      lineHeight: '14.68px',
                      color: '#33333380',
                    }}>
                      {'ATTACHMENTS'}
                    </Typography>

                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: '8px',
                      paddingTop: '8px',
                    }}>
                      {section.documentRequest.attachments.map(attachment => (
                        <Box
                          key={attachment.key}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '60px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 1,
                            padding: '12px',
                            borderRadius: '8px',
                            border: '1px solid rgba(51, 51, 51, 0.08)',
                          }}>
                          <Box sx={{
                             display: 'flex',
                             flexDirection: 'row',
                             alignItems: 'center',
                          }}>
                            <FileIcon
                              contentType={attachment.contentType}
                              color={'#33333380'} />

                            <Box sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              paddingLeft: '16px',
                              rowGap: '4px',
                            }}>
                              <Typography sx={{
                                // flex: 1,
                                fontWeight: '400',
                                fontSize: '13px',
                                lineHeight: '16px',
                                color: '#333333',
                              }}>
                                {attachment.name ?? attachment.key}
                              </Typography>

                              {attachment.size != null &&
                                <Typography sx={{
                                  fontWeight: '400',
                                  fontSize: '13px',
                                  lineHeight: '16px',
                                  color: '#33333380',
                                }}>
                                  {humanFileSize(attachment.size, true)}
                                </Typography>
                              }
                            </Box>
                          </Box>

                          <IconButton onClick={() => documentRequestAttachmentClicked(attachment)}>
                            <FileDownloadOutlinedIcon fontSize='small' />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>

                    {(section.files.length > 0 || canUploadFiles) &&
                      <Divider  sx={{
                        marginTop: '16px',
                        bgcolor: '#33333314',
                      }} />
                    }
                  </Box>
                }

                {section.files.map((file, index) => (
                  <Box
                    key={file.key}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <FileRow
                      file={file}
                      canDelete={true}
                      previewClicked={previewFile != null ? () => previewFileClicked(file) : undefined}
                      downloadClicked={() => downloadFileClicked(file)}
                      moreClicked={(e) => fileMenuClicked(e, file)} />

                    {index < section.files.length - 1 &&
                      <Divider sx={{
                        marginTop: '20px',
                        bgcolor: '#33333314'
                      }} />
                    }
                  </Box>
                ))}

                {canUploadFiles &&
                  <FileUploadBox
                    loading={loading}
                    onFilesDropped={onDocumentRequestFilesDropped} />
                }
              </Box>
            )}
          </div>

          <div
            role="tabpanel"
            hidden={section.documentRequest == null || currentTab !== 1}
            id={`tabpanel-documents`}
            aria-labelledby={`tab-documents`}>
            {currentTab === 1 && (
              <Box sx={{
                paddingTop: '24px',
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '8px 16px',
                  alignItems: 'center',
                  border: '1px solid rgba(51, 51, 51, 0.2)',
                  borderRadius: '8px',
                }}>
                  <Avatar
                    sx={{
                      width: '32px',
                      height: '32px',
                    }}
                    alt={'your account'}
                    src={account?.profileImageUrl} />

                  <TextField
                    sx={{
                      display: 'flex',
                      flex: 1,
                      paddingLeft: '12px',
                      paddingRight: '12px',
                    }}
                    variant='standard'
                    placeholder={'Write a message...'}
                    disabled={!account?.currentSubscription?.isActive}
                    value={currentComment}
                    onChange={(e) => setCurrentComment(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                    }} />

                  <IconButton
                    sx={{
                      width: '36px',
                      height: '36px',
                    }}
                    color='secondary'
                    disabled={currentComment.length === 0 || !account?.currentSubscription?.isActive}
                    onClick={addCommentClicked}>
                    <NorthIcon sx={{fontSize: '14px'}} />
                  </IconButton>
                </Box>

                {messageEvents.length > 0 &&
                  <Divider sx={{
                    marginTop: '24px',
                  }} />
                }

                {messageEvents.map((event, index) => (
                  <CommentRow
                    key={event._id}
                    avatarSize='small'
                    avatarImageUrl={participantsMap[event.accountId]?.accountProfileImageUrl}
                    name={`${participantsMap[event.accountId]?.accountName ?? 'Unknown'}${event.accountId === account?._id ? ' (you)' : ''}`}
                    comment={event.message ?? ''}
                    date={event.createdDt} />
                ))}
              </Box>
            )}
          </div>
        </Box>

        <Menu
          open={menuAnchorElem != null}
          anchorEl={menuAnchorElem}
          onClose={() => setMenuAnchorElem(undefined)}>
          <MenuItem onClick={copyLinkClicked}>
            <LinkRoundedIcon
              sx={{color: '#33333380'}}
              fontSize='small' />
            <Typography variant='menuItem'>
              {'Copy link'}
            </Typography>
          </MenuItem>
          {account?.currentSubscription?.isActive
           && (account.type === 'sponsor' || account.type === 'lender')
           && section.documentRequest != null
           && section.documentRequest.securityLevel !== 'confidential' &&
            <MenuItem onClick={makeConfidentialClicked}>
              <LockOutlinedIcon
                sx={{color: '#33333380'}}
                fontSize='small' />
              <Typography variant='menuItem'>
                {'Make confidential'}
              </Typography>
            </MenuItem>
          }
          {account?.currentSubscription?.isActive
           && (account.type === 'sponsor' || account.type === 'lender')
           && section.documentRequest != null && 
            <MenuItem onClick={setAsPriorityClicked}>
              <PriorityHighIcon
                sx={{color: '#33333380'}}
                fontSize='small' />
              <Typography variant='menuItem'>
                {(account.type === 'sponsor' && section.documentRequest.isLenderPriority) || (account.type === 'lender' && section.documentRequest.isSponsorPriority)
                  ? 'Remove as priority'
                  : 'Set as priority'
                }                
              </Typography>
            </MenuItem>
          }
          {account?.currentSubscription?.isActive
           && (account.type === 'sponsor' || account.type === 'lender')
           && section.documentRequest != null
           && section.documentRequest.securityLevel === 'confidential' &&
            <MenuItem onClick={manageAccessClicked}>
              <PersonAddOutlinedIcon
                sx={{color: '#33333380'}}
                fontSize='small' />
              <Typography variant='menuItem'>
                {'Manage access'}
              </Typography>
            </MenuItem>
          }
          {account?.currentSubscription?.isActive
           && account?.type === 'lender'
           && section.documentRequest != null
           && section.documentRequest.status !== 'accepted' &&
            <Box>
              <Divider />

              <MenuItem onClick={deleteClicked}>
                <DeleteOutlinedIcon
                  sx={{color: '#33333380'}}
                  fontSize='small' />
                <Typography variant='menuItem'>
                  {'Delete'}
                </Typography>
              </MenuItem>
            </Box>
          }
        </Menu>

        <Menu
          open={fileMenuAnchorElem != null}
          anchorEl={fileMenuAnchorElem}
          onClose={() => setFileMenuAnchorElem(undefined)}>
          <MenuItem onClick={copyFileLinkClicked}>
            <LinkRoundedIcon
              sx={{color: '#33333380'}}
              fontSize='small' />
            <Typography variant='menuItem'>
              {'Copy link'}
            </Typography>
          </MenuItem>
          {account?.currentSubscription?.isActive
           && (section.documentRequest == null 
            || ((section.documentRequest.status === 'pending' || section.documentRequest.status === 'rejected') && account?.type === 'sponsor' && account?._id === deal?.accountId)) &&
            <MenuItem onClick={deleteFileClicked}>
              <DeleteOutlinedIcon
                sx={{color: '#33333380'}}
                fontSize='small' />
              <Typography variant='menuItem'>
                {'Delete file'}
              </Typography>
            </MenuItem>
          }
        </Menu>
      </Collapse>
    </Card>
  );
});

export default DocumentRequestSection;
