import React, { useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { IAccountTeam, IUser, IAccountTeamInvitation } from '../../schemas';
import LoadingButton from '../LoadingButton';
import { useCancelTeamInvitationMutation, useRemoveTeamAccessMutation } from '../../features/accounts-api';
import CREEDDialogTitle from '../CREEDDialogTitle';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';


interface Props extends DialogProps {
  readonly team?: IAccountTeam;
  readonly user?: IUser;
  readonly invitation?: IAccountTeamInvitation;
}

export default function RemoveAccessDialog({team, user, invitation, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [removeAccess, { isLoading: isRemoveAccessLoading }] = useRemoveTeamAccessMutation();
  const [cancelInvitation, { isLoading: isCancelInvitationLoading }] = useCancelTeamInvitationMutation();

  const removeAccessClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      if (user != null) {
        await removeAccess({ userId: user._id }).unwrap();
      } else if (invitation != null) {
        await cancelInvitation({ invitationId: invitation._id }).unwrap();
      }

      if (dialogProps.onClose != null) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed removing access',
        severity: 'error',
      }));
    }
  }, [user, invitation, dialogProps, removeAccess, cancelInvitation, dispatch]);

  return (
    <Dialog {...dialogProps}>
      <CREEDDialogTitle
        title='Remove Access'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />

      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to remove ${user != null ? `${user.firstName} ${user.lastName} (${user.email})` : invitation != null ? invitation.email : ''} from your team?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          disabled={isRemoveAccessLoading || isCancelInvitationLoading}
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={isRemoveAccessLoading || isCancelInvitationLoading}
          onClick={removeAccessClicked}>
          {'Remove Access'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
