import { Box, IconButton, Tooltip } from "@mui/material";
import { Fragment, useCallback, useMemo } from "react";
import { ReactElement } from "react-markdown/lib/react-markdown";
import { IDeal } from "../../schemas";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded';
import jsPDF from "jspdf";
import { snacked } from "../../features/snackMessage-slice";
import { copyToClipboard, downloadStringAsFile } from "../../utils/utils";
import { useAppDispatch } from "../../app/hooks";


const data = [
  {
    propertyType: 'Residential/Multifamily',
    businessPlan: 'Stabilized',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Current Rent Roll',
          'Trailing 12 monthly operating statement',
          'Borrower operating budget',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Historical Occupancy',
          '3 years historical operating statements (if available)',
          'Trailing 3 month collections',
          'Property OM (if available) ',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Old Appraisal (if available)',
          'Sponsor local market experience',
          'Market Rent Comp and Occupancy Comp Analysis',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Residential/Multifamily',
    businessPlan: 'Renovation / Lease-up',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Current Rent Roll',
          'Trailing 12 monthly operating statement',
          'Borrower operating budget',
          'Pro Forma Operating Statements',
          'Pro Forma Unit Mix and Rent Roll',
          'Detailed renovation budget',
          'Renovation and lease-up timeline',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Post-renovation Market Rent Comp and Occupancy Comp Analysis',
          '3 years historical operating statements (if available)',
          'Trailing 3 month collections',
          'Property OM (if available) ',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Sponsor renovation experience',
          'Sponsor local market experience',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Residential/Multifamily',
    businessPlan: 'Construction',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Land acquisition detail (purchase price and timing)',
          'Status of zoning/entitlements?',
          'Status of approvals and permits?',
          'Detailed Construction Budget',
          'Detailed Project Timeline (pre-development through construction and lease-up)',
          'Pro Forma Operating Statements',
          'Pro Forma Unit Mix and Rent Roll',
          'Property Renderings',
          'Working Group Information (GC, Architect, etc.)',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Property OM (if available) ',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Market Rent Comp and Occupancy Comp Analysis',
          'Sponsor construction experience',
          'Sponsor local market experience',
          'Any available 3rd party reports (appraisal, feasability, etc.)',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Industrial',
    businessPlan: 'Stabilized',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Current Rent Roll',
          'Trailing 12 monthly operating statement',
          'Borrower operating budget',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Historical occupancy',
          '3 years historical operating statements (if available)',
          'Aged Receivables',
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Old Appraisal (if available)',
          'Sponsor local market experience',
          'Market Rent Comp and Occupancy Comp Analysis',
          'Top Tenant Summaries',
          'Significant Tenant Leases/Abstracts',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Industrial',
    businessPlan: 'Renovation / Lease-up',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Current Rent Roll',
          'Trailing 12 monthly operating statement',
          'Borrower operating budget',
          'Pro Forma Operating Statements',
          'Pro Forma Unit Mix and Rent Roll',
          'Detailed renovation budget',
          'Renovation and lease-up timeline',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Post-renovation Market Rent Comp and Occupancy Comp Analysis',
          '3 years historical operating statements (if available)',
          'Aged Receivables',
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Sponsor renovation experience',
          'Sponsor local market experience',
          'ARGUS Model',
          'Pre-leasing information (if any)',
          'Significant Tenant Leases/Abstracts',
          'Prospective/target tenant information',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Industrial',
    businessPlan: 'Construction',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Land acquisition detail (purchase price and timing)',
          'Status of zoning/entitlements?',
          'Status of approvals and permits?',
          'Detailed Construction Budget',
          'Detailed Project Timeline (pre-development through construction and lease-up)',
          'Pro Forma Operating Statements',
          'Pro Forma Unit Mix and Rent Roll',
          'Property Renderings',
          'Working Group Information (GC, Architect, etc.)',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Market Rent Comp and Occupancy Comp Analysis',
          'Sponsor construction experience',
          'Sponsor local market experience',
          'Any available 3rd party reports (appraisal, feasability, etc.)',
          'ARGUS Model',
          'Pre-leasing information (if any)',
          'Prospective/target tenant information',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Retail',
    businessPlan: 'Stabilized',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Current Rent Roll',
          'Trailing 12 monthly operating statement',
          'Borrower operating budget',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Historical occupancy',
          '3 years historical operating statements (if available)',
          'Aged Receivables',
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Old Appraisal (if available)',
          'Sponsor local market experience',
          'Market Rent Comp and Occupancy Comp Analysis',
          'Top Tenant Summaries',
          'Significant Tenant Leases/Abstracts',
          'Tenant gross annual sales (past 3-years if available)',
          'Aged receivables (late payment rent detail)',
          'ARGUS Model',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Retail',
    businessPlan: 'Renovation / Lease-up',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Current Rent Roll',
          'Trailing 12 monthly operating statement',
          'Borrower operating budget',
          'Pro Forma Operating Statements',
          'Pro Forma Unit Mix and Rent Roll',
          'Detailed renovation budget',
          'Renovation and lease-up timeline',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Post-renovation Market Rent Comp and Occupancy Comp Analysis',
          '3 years historical operating statements (if available)',
          'Aged Receivables',
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Sponsor renovation experience',
          'Sponsor local market experience',
          'ARGUS Model',
          'Pre-leasing information (if any)',
          'Significant Tenant Leases/Abstracts',
          'Tenant gross annual sales (past 3-years if available)',
          'Prospective/target tenant information',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Retail',
    businessPlan: 'Construction',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Land acquisition detail (purchase price and timing)',
          'Status of zoning/entitlements?',
          'Status of approvals and permits?',
          'Detailed Construction Budget',
          'Detailed Project Timeline (pre-development through construction and lease-up)',
          'Pro Forma Operating Statements',
          'Pro Forma Unit Mix and Rent Roll',
          'Property Renderings',
          'Working Group Information (GC, Architect, etc.)',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Market Rent Comp and Occupancy Comp Analysis',
          'Sponsor construction experience',
          'Sponsor local market experience',
          'Any available 3rd party reports (appraisal, feasability, etc.)',
          'ARGUS Model',
          'Pre-leasing information (if any)',
          'Prospective/target tenant information',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Office',
    businessPlan: 'Stabilized',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Current Rent Roll',
          'Trailing 12 monthly operating statement',
          'Borrower operating budget',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Historical occupancy',
          '3 years historical operating statements (if available)',
          'Aged Receivables',
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Old Appraisal (if available)',
          'Sponsor local market experience',
          'Market Rent Comp and Occupancy Comp Analysis',
          'Top Tenant Summaries',
          'Significant Tenant Leases/Abstracts',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Office',
    businessPlan: 'Renovation / Lease-up',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Current Rent Roll',
          'Trailing 12 monthly operating statement',
          'Borrower operating budget',
          'Pro Forma Operating Statements',
          'Pro Forma Unit Mix and Rent Roll',
          'Detailed renovation budget',
          'Renovation and lease-up timeline',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Post-renovation Market Rent Comp and Occupancy Comp Analysis',
          '3 years historical operating statements (if available)',
          'Aged Receivables',
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Sponsor renovation experience',
          'Sponsor local market experience',
          'ARGUS Model',
          'Pre-leasing information (if any)',
          'Significant Tenant Leases/Abstracts',
          'Prospective/target tenant information',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Office',
    businessPlan: 'Construction',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'Land acquisition detail (purchase price and timing)',
          'Status of zoning/entitlements?',
          'Status of approvals and permits?',
          'Detailed Construction Budget',
          'Detailed Project Timeline (pre-development through construction and lease-up)',
          'Pro Forma Operating Statements',
          'Pro Forma Unit Mix and Rent Roll',
          'Property Renderings',
          'Working Group Information (GC, Architect, etc.)',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Market Rent Comp and Occupancy Comp Analysis',
          'Sponsor construction experience',
          'Sponsor local market experience',
          'Any available 3rd party reports (appraisal, feasability, etc.)',
          'ARGUS Model',
          'Pre-leasing information (if any)',
          'Prospective/target tenant information',
          'Property manager information',
        ]
      }
    ]
  },
  {
    propertyType: 'Hotel',
    businessPlan: 'Stabilized',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'STR Report',
          'Trailing 12 monthly operating statement (including occupancy, ADR, RevPAR)',
          'Trailing 3-years annual operating statement (including occupancy, ADR, RevPAR)',
          'Borrower operating budget',
          'Franchise detail',
          'Property management detail',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Franchise Agreement',
          'Property Management Agreement',
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Any available 3rd party reports (appraisal, etc.)',
          'Sponsor local market experience',
          'Any relevant comps outside of STR Report',
          'Description of hotel elements: F&B, parking, event space, etc.',
          'Significant corporate events or corporate account detail',
        ]
      }
    ]
  },
  {
    propertyType: 'Hotel',
    businessPlan: 'Renovation / Lease-up',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'STR Report',
          'Trailing 12 monthly operating statement (including occupancy, ADR, RevPAR)',
          'Trailing 3-years annual operating statement (including occupancy, ADR, RevPAR)',
          'Borrower operating budget',
          'Franchise detail',
          'Property management detail',
          'Pro Forma Operating Statements',
          'Detailed renovation budget',
          'Renovation and stabilization timeline',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Franchise Agreement',
          'Property Management Agreement',
          'Feasability Study (if available)',
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Any available 3rd party reports (appraisal, etc.)',
          'Any relevant comps outside of STR Report',
          'Description of hotel elements: F&B, parking, event space, etc.',
          'Significant corporate events or corporate account detail',
          'Sponsor renovation experience',
          'Sponsor local market experience',
        ]
      }
    ]
  },
  {
    propertyType: 'Hotel',
    businessPlan: 'Construction',
    sections: [
      {
        title: 'Core Items',
        items: [
          'OM',
          'STR Report',
          'Status of zoning/entitlements?',
          'Status of approvals and permits?',
          'Detailed Construction Budget',
          'Detailed Project Timeline (pre-development through construction and stabilization)',
          'Franchise detail',
          'Property management detail',
          'Pro Forma Operating Statements',
          'Property Renderings',
          'Working Group Information (GC, Architect, etc.)',
        ]
      },
      {
        title: 'Recommended Items',
        items: [
          'Property OM (if available)',
          'Sources and Uses of loan proceeds',
          'If refinance, original acquisition date and borrower cost basis',
          'If refinance, existing mortgage details (loan amount and maturity date)',
          'Market Rent Comp and Occupancy Comp Analysis',
          'Sponsor construction experience',
          'Sponsor local market experience',
          'Any available 3rd party reports (appraisal, feasability, etc.)',
          'Property manager information',
        ]
      }
    ]
  },
];

export function getSuggestedProjectFiles(propertyType: string, businessPlan:string): typeof data[number] | undefined {
  return data.find(d => d.propertyType === propertyType && d.businessPlan === businessPlan);
}

interface Props {
  deal?: IDeal;
  children: ReactElement;
}

export default function RecommendedFilesTooltop({deal, children}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const item = useMemo(() => {
    return data.find(d => d.propertyType === deal?.dealInfo?.propertyType?.optionName && d.businessPlan === deal.dealInfo.businessPlan?.optionName);
  }, [deal?.dealInfo?.businessPlan?.optionName, deal?.dealInfo?.propertyType?.optionName]);

  const title = useMemo(() => {
    if (item == null) {
      return 'Please upload any items relevant to this project';
    }

    return (
      <>
        {item.sections.map(section => (
          <Fragment key={section.title}>
            <b>{section.title}</b>
            <ol>
              {section.items.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ol>
          </Fragment>
        ))}
      </>
    );
  }, [item]);

  const copySuggestedProjectFilesClicked = useCallback(async () => {
    if (item == null) {
      return;
    }

    const lines = item.sections.flatMap(section => [section.title, ...section.items.map((item, i) => `${i+1}. ${item}`), '\n']);
    const content = lines.join('\n');

    await copyToClipboard(content);
    
    dispatch(snacked({
      message: 'Suggested project files copied to clipboard',
      severity: 'info',
    }));
  }, [item, dispatch]);

  const downloadSuggestedProjectFilesCSVClicked = useCallback(() => {
    if (item == null) {
      return;
    }

    const csv = item.sections
      .flatMap(s => [s.title, ...s.items, ''])
      .map(r => `"${r}"`)
      .join('\n');

    downloadStringAsFile(csv, 'suggested-project-files.csv', 'text/csv');
  }, [item]);

  const downloadSuggestedProjectFilesPDFClicked = useCallback(() => {
    if (item == null) {
      return;
    }

    const doc = new jsPDF('p', 'pt', 'a4');
    doc.html((
`
<html>
<head>
<style>

</style>
</head>
<body>
<div style="width:100vw;font-size:12px;">
${item.sections.map(s => `${s.title}<ol>${s.items.map(i => `<li>${i}</li>`).join('')}</ol>`).join('')}
</div>
</body>
</html>
`
    ), {
      margin: 32,
      callback: (d) =>  d.save('suggested-project-files.pdf'),
    });
  }, [item]);
  
  return (
    <Tooltip
      sx={{
        whiteSpace: 'pre-wrap',
      }}
      title={(
        <Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
            <Tooltip 
              title='Copy to clipboard'
              enterDelay={500}
              placement='top'
              arrow>
              <IconButton
                sx={{
                  color: 'white'
                }}
                size='small'
                onClick={copySuggestedProjectFilesClicked}>
                <ContentCopyRoundedIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip 
              title='Download as CSV'
              enterDelay={500}
              placement='top'
              arrow>
              <IconButton
                sx={{
                  color: 'white'
                }}
                size='small'
                onClick={downloadSuggestedProjectFilesCSVClicked}>
                <BorderAllRoundedIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip 
              title='Download as PDF'
              enterDelay={500}
              placement='top'
              arrow>
              <IconButton
                sx={{
                  color: 'white'
                }}
                size='small'
                onClick={downloadSuggestedProjectFilesPDFClicked}>
                <PictureAsPdfRoundedIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </Box>
          {title}
        </Box>
      )}>
      {children}
    </Tooltip>
  );
}
