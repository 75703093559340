import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';


interface Props {
  readonly contentType?: string;
  readonly color?: string;
}

export default function FileIcon({contentType, color}: Props): JSX.Element {
  if (contentType?.includes('pdf')) {
    return (<FontAwesomeIcon size='lg' color={color} icon={regular('file-pdf')} />);
  } else if (contentType?.includes('doc')) {
    return (<FontAwesomeIcon size='lg' color={color} icon={regular('file-word')} />);
  } else if (contentType?.includes('zip')) {
    return (<FontAwesomeIcon size='lg' color={color} icon={regular('file-zipper')} />);
  }

  return (<FontAwesomeIcon size='lg' color={color} icon={regular('file')} />);
}
