import { FormEvent, useCallback, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogProps, TextField, Typography } from '@mui/material';
import LoadingButton from '../LoadingButton';
import { IDeal } from '../../schemas';
import FileUploadBox from '../FileUploadBox';
import axios from 'axios';
import { useCreateDealDocumentRequestMutation, useGetDealDocumentRequestAttachmentUploadUrlMutation } from '../../features/document-requests-api';
import CREEDDialogTitle from '../CREEDDialogTitle';


interface Props extends DialogProps {
  deal?: IDeal;
}

export default function CreateDocumentRequestDialog({deal, ...dialogProps}: Props): JSX.Element {
  
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [attachmentFiles, setAttachmentFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const [createDocumentRequest] = useCreateDealDocumentRequestMutation();
  const [getAttachmentUploadUrl] = useGetDealDocumentRequestAttachmentUploadUrlMutation();

  const filesDropped = useCallback(async (files: File[]) => {
    setAttachmentFiles([...attachmentFiles, ...files]);
  }, [setAttachmentFiles, attachmentFiles]);

  const fileDeleted = useCallback((file: File) => {
    setAttachmentFiles(attachmentFiles.filter(f => f !== file));
  }, [setAttachmentFiles, attachmentFiles]);

  const formSubmitted = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deal?._id == null) {
      return;
    }

    setLoading(true);

    try {
      // Create the document request
      const {documentRequestId} = await createDocumentRequest({
        dealId: deal._id,
        name: name,
        description: description,
      }).unwrap();

      // Upload any files to the new document request
      const fileUploadUrlResponses = await Promise.all(attachmentFiles.map(f => getAttachmentUploadUrl({
        dealId: deal?._id!,
        documentRequestId: documentRequestId,
        contentType: f.type,
        fileName: f.name,
      }).unwrap()));

      await Promise.all(fileUploadUrlResponses.map((response, index) => {
        if (!response.url) {
          return null;
        }

        const file = attachmentFiles[index];
        return axios.put(response.url, file, {
          headers: {
            'Content-Type': file.type
          },
        });
      }));

      setName('');
      setDescription('');
      setAttachmentFiles([]);

      if (dialogProps.onClose) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [setLoading, deal, dialogProps, name, description, attachmentFiles, createDocumentRequest, getAttachmentUploadUrl]);
  
  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='New Checklist Item'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
        
      <form onSubmit={formSubmitted}>
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
        }}>
          <TextField
            id='name'
            label='Name'
            required
            disabled={loading}
            helperText='Name of checklist item'
            value={name}
            onChange={e => setName(e.target.value)} />

          <TextField
            id='description'
            label='Description'
            multiline
            required
            disabled={loading}
            helperText='The kinds of files that should be uploaded to this checklist item'
            minRows={3}
            maxRows={8}
            value={description}
            onChange={e => setDescription(e.target.value)} />

          <Box>
            <Typography>
              {'Attachments'}
            </Typography>
            {attachmentFiles.length > 0 &&
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                paddingTop: '4px',
                paddingBottom: '12px',
                gap: '8px',
              }}>
                {attachmentFiles.map((attachment, index) => (
                  <Chip
                    key={`${attachment.name}${index}`}
                    label={attachment.name}
                    onDelete={() => fileDeleted(attachment)} />
                ))}
              </Box>
            }
            
            <FileUploadBox
              disabled={loading}
              onFilesDropped={filesDropped} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              flex: 1,
            }}
            disabled={loading}
            onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
            {'Cancel'}
          </Button>

          <LoadingButton
            sx={{
              flex: 1,
            }}
            type='submit'
            variant='contained'
            loading={loading}>
            {'Create Checklist Item'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
