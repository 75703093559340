import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';
import Logo from '../images/light-creed-logo.png';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Auth } from 'aws-amplify';
import { loggedIn } from '../features/auth-slice';
import mixpanel from 'mixpanel-browser';
import { useGetCurrentAccountQuery, useGetCurrentUserQuery } from '../features/accounts-api';
import LoadingButton from './LoadingButton';
import { AuthData } from './AuthForm';


interface Props {
  readonly onSuccess?: (data: AuthData) => void;
  readonly onError?: (error: any) => void;
  readonly onRegisterClicked?: () => void;
}

export default function LoginForm(props: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {shouldBeLoggedIn} = useAppSelector(state => state.auth);

  const {data: account, isSuccess: accountIsSuccess} = useGetCurrentAccountQuery();
  const {data: user, isSuccess: userIsSuccess} = useGetCurrentUserQuery();

  const loginFormSubmitted = useCallback(async (event: React.SyntheticEvent) => {
    event.preventDefault();

    setLoading(true);
    setErrorMessage('');

    try {
      const loginResult = await Auth.signIn(email, password);
      console.log(loginResult);
      dispatch(loggedIn(email));
    } catch (error: any) {
      console.error(error);
      setErrorMessage(error.message);
      setLoading(false);

      if (props.onError != null) {
        props.onError(error);
      }
    }
  }, [setLoading, email, password, dispatch, props]);

  useEffect(() => {
    if (shouldBeLoggedIn && accountIsSuccess && account != null && userIsSuccess && user != null) {
      mixpanel.identify(user._id);
      mixpanel.people.set({
        $email: user.email,
        $name: `${user.firstName} ${user.lastName}`,
        'Account ID': account._id,
        'Account Type': account.type,
      });
      mixpanel.track('login');
      
      if (props.onSuccess != null) {
        props.onSuccess({email, password});
      }
    }
  }, [shouldBeLoggedIn, accountIsSuccess, userIsSuccess, account, user, props, email, password]);

  return (
    <form onSubmit={loginFormSubmitted}>
      <Grid container>
        <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
          <img src={Logo} alt='CREED' style={{width: 100, marginBottom: 50}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
          <TextField
            style={{width: 300}}
            required
            id='email'
            type='email'
            label='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
          <TextField
            style={{width: 300}}
            required
            id='password'
            type='password'
            label='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{textAlign: 'center', marginTop: '20px'}}>
          <LoadingButton 
            variant='contained' 
            type='submit'
            loading={loading}
            sx={{ width: 300}}>
            {'Login and Accept Invitation'}
          </LoadingButton>
          <Typography
            variant='body2'
            style={{
              marginTop: 8
            }}>
            <a href='/reset-pwd'>
              {'Reset my password'}
            </a>
          </Typography>
        </Grid>
        
        {props.onRegisterClicked != null &&
          <Grid item xs={12} style={{textAlign: 'center', marginTop: '20px'}}>
            {
              errorMessage !== ''
              ? <div><Typography variant='error'>{errorMessage}</Typography></div>
              : null
            }
            <Button 
              variant='text' 
              disabled={loading}
              onClick={props.onRegisterClicked}
              style={{height: '100%', width: 300}}>
              {'Sign up'}
            </Button>
          </Grid>
        }
      </Grid>
    </form>
  );
}
