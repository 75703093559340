import { capitalize } from "lodash";
import { IDealInfo, ISearchInputParam, ISearchInputParamArray } from "../schemas";
import { formatNumericString } from "../utils/utils";
import { useCallback } from "react";
import dayjs from "dayjs";


export enum SearchFieldID {
  PropertyAddress = 'propertyAddress',
  PropertyCity = 'propertyCity',
  BusinessPlan = 'businessPlan',
  PropertyType = 'propertyType',
  PropertySubtype = 'propertySubtype',
  AcquisitionType = 'refinanceOrAcquisition',
  CurrentLandOwnership = 'currentLandOwnership',
  LoanType = 'loanType',
  FirstMortgageLoanAmount = 'firstMortgageLoanAmount',
  LoanAmount = 'loanAmount',
  PropertyValue = 'propertyValue',
  LTV = 'ltv',
  IRR = 'irr',
  InvestorLevelIRR = 'investorLevelIrr',
  YieldOnCost = 'yieldOnCost',
  // CashOnCash = 'cashOnCash',
  ProjectDuration = 'projectDuration',
  ClosingTimeline = 'closingTimeline',
  Recourse = 'recourse',
  RecourseType = 'recourseType', // Deprecated, maintained for backwards compatibility
  EquityDebt = 'equityDebt',
  EquityType = 'equityType',
  EquityAmount = 'equityAmount',
  ExpectedClosingDate = 'expectedClosingDate',
  OtherEquity = 'otherEquity',
}

export type SearchFieldValue = 
  ISearchInputParam
| ISearchInputParamArray
| string
| number;

export type SearchFieldType = 'select' | 'number' | 'location' | 'date';
export type SearchFieldFormatting = 'comma-separated';

export default interface SearchField {
  readonly id: SearchFieldID;
  readonly type: SearchFieldType;
  readonly multiselect?: boolean;
  readonly formatting?: SearchFieldFormatting;
  readonly prefix?: string;
  readonly slug?: string;
  readonly required?: boolean;
  readonly extraValidation?: (values: Partial<IDealInfo>, fields: SearchField[]) => string | undefined;
  readonly title: string | ((values: Partial<IDealInfo>) => string);
  readonly visible?: (values: Partial<IDealInfo>) => boolean;
  readonly onValueChanged?: (value: SearchFieldValue, allValues: IDealInfo) => {[id in SearchFieldID]?: SearchFieldValue};
  readonly dependsOn?: SearchFieldID[];
  readonly parentValueSlugMap?: {[SearchFieldID: string]: {[value: string]: string}};
  readonly formatter?: (value: SearchFieldValue, allValues?: Partial<IDealInfo>) => string;
}

export const fields: SearchField[] = [
  {
    id: SearchFieldID.PropertyCity,
    type: 'location',
    slug: 'propertyCity',
    required: true,
    title: 'Property Address',
  },
  {
    id: SearchFieldID.PropertyType,
    type: 'select',
    title: 'Property Type',
    slug: 'propertyType',
    required: true,
  },
  {
    id: SearchFieldID.PropertySubtype,
    type:'select',
    title: 'Property Subtype',
    slug: 'propertySubtype',
    required: true,
    dependsOn: [SearchFieldID.PropertyType],
    parentValueSlugMap: {
      [SearchFieldID.PropertyType]: {
        'Residential/Multifamily': "residentialPropertySubType",
        Industrial: "industrialPropertySubType",
        Office: "officePropertySubType",
        Lodging: "lodgingPropertySubType",
        Healthcare: "healthcarePropertySubType",
        Retail: "retailPropertySubType",
        Storage: "storagePropertySubType",
        Other: "otherPropertySubType",
      }
    },
  },
  {
    id: SearchFieldID.BusinessPlan,
    type: 'select',
    slug: 'businessPlan',
    required: true,
    title: 'Business Plan',
  },
  {
    id: SearchFieldID.AcquisitionType,
    type: 'select',
    title: 'Refinance or Acquisition',
    slug: 'refinanceOrAcquisition',
    required: true,
    visible: (values) => values.businessPlan?.optionName !== 'Construction',
  },
  {
    id: SearchFieldID.CurrentLandOwnership,
    type: 'select',
    slug: 'currentLandOwnership',
    required: true,
    title: 'Do you currently own the land?',
    visible: (values) => values.businessPlan?.optionName === 'Construction',
  },
  {
    id: SearchFieldID.EquityDebt,
    type: 'select',
    title: 'Equity or Debt',
    slug: 'equityDebt',
    required: true,
  },
  {
    id: SearchFieldID.EquityType,
    type: 'select',
    multiselect: true,
    title: 'Equity Type',
    slug: 'equityType',
    required: true,
    dependsOn: [SearchFieldID.EquityDebt],
    visible: (values) => values.equityDebt?.optionName === 'Equity',
    formatter: (value) => {
      const options = (value as any).optionNames;
      if (options.length === 1) {
        return options[0];
      } else {
        return options.map((o: string) => o.replace('Equity', '').trim()).join(' / ');
      }
    }
  },
  {
    id: SearchFieldID.LoanType,
    type: 'select',
    title: 'Loan Type',
    slug: 'loanType',
    required: true,
    dependsOn: [SearchFieldID.EquityDebt],
    visible: (values) => values.equityDebt?.optionName === 'Debt',
  },
  {
    id: SearchFieldID.PropertyValue,
    type: 'number',
    title: (values) => {
      const acqTypeValue = values.refinanceOrAcquisition?.optionName;
      const busPlanValue = values.businessPlan?.optionName;

      if (busPlanValue != null && busPlanValue !== 'Stabilized') {
        return 'Total Project Cost';
      } else if (acqTypeValue === 'Refinance') {
        return 'Estimated Property Appraised Value';
      } else if (acqTypeValue === 'Acquisition') {
        return 'Expected Purchase Price';
      }

      return 'Property Value';
    },
    formatting: 'comma-separated',
    prefix: '$',
    required: true,
    onValueChanged: (value, allValues) => {
      const propertyValue = value as number;
      if (allValues.loanType?.optionName === 'Pref Equity' || allValues.loanType?.optionName === 'Mezzanine') {
        const firstMortageAmt = allValues.firstMortgageLoanAmount;
        const loanAmount = allValues.loanAmount;

        if (firstMortageAmt == null || loanAmount == null || propertyValue == null) {
          return {
            [SearchFieldID.LTV]: undefined,
          };
        }

        const ltc = ((firstMortageAmt + loanAmount) / propertyValue) * 100.0;

        return {
          [SearchFieldID.LTV]: ltc <= 100.0 ? ltc : undefined,
        };
      } else {
        const loanAmount = allValues.loanAmount;

        if (loanAmount == null || propertyValue == null) {
          return {
            [SearchFieldID.LTV]: undefined,
          };
        }
  
        const ltv = (loanAmount / propertyValue) * 100.0;
  
        return {
          [SearchFieldID.LTV]: !isNaN(ltv) && ltv <= 100.0 ? ltv : undefined,
        };
      }
    },
    formatter: (value) => {
      return `$${formatNumericString((value as number).toFixed(0))} estimated value`;
    }
  },
  {
    id: SearchFieldID.FirstMortgageLoanAmount,
    type: 'number',
    required: true,
    title: 'First Mortgage Loan Amount (USD)',
    formatting: 'comma-separated',
    visible: (values) => {
      return values.equityDebt?.optionName === 'Debt'
        && (values.loanType?.optionName === 'Pref Equity'
            || values.loanType?.optionName === 'Second Mortgage'
            || values.loanType?.optionName === 'Mezzanine');
    },
    onValueChanged: (value, allValues) => {
      const firstMortageAmt = value as number;
      const loanAmount = allValues.loanAmount;
      const propertyValue = allValues.propertyValue;

      if (firstMortageAmt == null || loanAmount == null || propertyValue == null) {
        return {
          [SearchFieldID.LTV]: undefined,
        };
      }

      const ltc = ((firstMortageAmt + loanAmount) / propertyValue) * 100.0;

      return {
        [SearchFieldID.LTV]: !isNaN(ltc) && ltc <= 100.0 ? ltc : undefined,
      };
    },
    formatter: (value) => `$${formatNumericString((value as number).toFixed(0))} first mortgage loan amount`,
  },
  {
    id: SearchFieldID.LoanAmount,
    type: 'number',
    required: true,
    title: (values) => {
      const equityDebtValue = values.equityDebt?.optionName;
      if (equityDebtValue === 'Equity') {
        return 'Equity Amount Needed (USD)';
      } else if (equityDebtValue === 'Debt') {
        if (values.loanType?.optionName === 'Mezzanine') {
          return 'Mezzanine Loan Amount (USD)';
        } else if (values.loanType?.optionName === 'Pref Equity') {
          return 'Pref Equity Amount (USD)';
        }

        return 'Loan Amount (USD)';
      }

      return 'Amount Needed (USD)';
    },
    extraValidation: (values, fields) => {
      const loanAmountStr = values[SearchFieldID.LoanAmount];
      const propertyValueStr = values[SearchFieldID.PropertyValue];
      if (loanAmountStr == null || propertyValueStr == null) {
        return undefined;
      }

      const loanAmount = Number.parseFloat(`${loanAmountStr}`.split(',').join(''));
      const propertyValue = Number.parseFloat(`${propertyValueStr}`.split(',').join(''));
      if (isNaN(loanAmount) || isNaN(propertyValue)) {
        return undefined;
      }

      if (loanAmount > propertyValue) {
        const loanAmountField = fields.find(f => f.id === SearchFieldID.LoanAmount);
        const propertyValueField = fields.find(f => f.id === SearchFieldID.PropertyValue);
        const loanAmountTitle = typeof loanAmountField?.title === 'function' ? loanAmountField.title(values) : loanAmountField?.title;
        const propertyValueTitle = typeof propertyValueField?.title === 'function' ? propertyValueField.title(values) : propertyValueField?.title;
        return capitalize(`${loanAmountTitle ?? 'loan amount'} must not exceed the ${propertyValueTitle ?? 'expected purchase price'}`);
      }

      return undefined;
    },
    formatting: 'comma-separated',
    prefix: '$',
    onValueChanged: (value, allValues) => {
      if (allValues.loanType?.optionName === 'Pref Equity' || allValues.loanType?.optionName === 'Mezzanine') {
        const firstMortageAmt = allValues.firstMortgageLoanAmount;
        const loanAmount = value as number;
        const propertyValue = allValues.propertyValue;

        if (firstMortageAmt == null || loanAmount == null || propertyValue == null) {
          return {
            [SearchFieldID.LTV]: undefined,
          };
        }

        const ltc = ((firstMortageAmt + loanAmount) / propertyValue) * 100.0;

        return {
          [SearchFieldID.LTV]: !isNaN(ltc) && ltc <= 100.0 ? ltc : undefined,
        };
      } else {
        const loanAmount = value as number;
        const propertyValue = allValues.propertyValue;
        if (loanAmount == null || propertyValue == null) {
          return {
            [SearchFieldID.LTV]: undefined,
          };
        }
  
        const ltv = (loanAmount / propertyValue) * 100.0;
        
        return {
          [SearchFieldID.LTV]: !isNaN(ltv) && ltv <= 100.0 ? ltv : undefined,
        };
      }
    },
    formatter: (value, allValues) => {
      const equityDebt = allValues?.equityDebt?.optionName;
      if (equityDebt === 'Equity') {
        return `$${formatNumericString((value as number).toFixed(0))} equity amount`;
      }

      return `$${formatNumericString((value as number).toFixed(0))} loan amount`;
    }
  },
  {
    id: SearchFieldID.LTV,
    type: 'number',
    required: true,
    slug: 'ltv',
    title: (values) => {
      const businessPlanValue = values.businessPlan?.optionName;
      if (businessPlanValue != null && ['Reposition', 'Renovation / Lease-up', 'Construction', 'Construction Take Out'].includes(businessPlanValue)) {
        return 'LTC (%)';
      }

      return 'LTV (%)';
    },
    visible: (values) => values.equityDebt?.optionName === 'Debt',
    extraValidation: (values, fields) => {
      const ltv = values.ltv;
      if (ltv == null) {
        return undefined;
      }

      if (ltv < 0.0 || ltv > 100.0) {
        const ltvField = fields.find(f => f.id === SearchFieldID.LTV);
        const ltvTitle = typeof ltvField?.title === 'function' ? ltvField.title(values) : ltvField?.title;
        return `${ltvTitle ?? 'LTV (%)'} must be between 0 and 100`;
      }

      return undefined;
    },
    onValueChanged: (value, allValues) => {
      if (allValues.loanType?.optionName === 'Pref Equity' || allValues.loanType?.optionName === 'Mezzanine') {
        const ltc = value as number;
        const firstMortageAmt = allValues.firstMortgageLoanAmount;
        const propertyValue = allValues.propertyValue;

        if (ltc == null || firstMortageAmt == null || propertyValue == null) {
          return {
            [SearchFieldID.LoanAmount]: undefined,
          };
        }

        const loanAmount = ((ltc / 100.0) * propertyValue) - firstMortageAmt;
        
        return {
          [SearchFieldID.LoanAmount]: !isNaN(loanAmount) && loanAmount >= 1.0 ? Math.floor(loanAmount) : undefined,
        };
      } else {
        const propertyValue = allValues.propertyValue;
        const ltv = value as number;
        if (ltv == null || propertyValue == null) {
          return {
            [SearchFieldID.LoanAmount]: undefined,
          };
        }
  
        const loanAmount = ((ltv / 100.0) * propertyValue);
  
        return {
          [SearchFieldID.LoanAmount]: !isNaN(loanAmount) && loanAmount >= 1.0 ? Math.floor(loanAmount) : undefined,
        };
      }
    },
    formatter: (value, allValues) => {
      const valueStr = (value as number).toFixed(0);
      const businessPlanValue = allValues?.businessPlan?.optionName;
      if (businessPlanValue != null && ['Reposition', 'Renovation / Lease-up', 'Construction', 'Construction Take Out'].includes(businessPlanValue)) {
        return `${valueStr}% loan to cost`;
      }

      return `${valueStr}% loan to value`;
    },
  },
  {
    id: SearchFieldID.IRR,
    title: 'Project Level IRR (%)',
    type: 'number',
    required: true,
    slug: 'irr',
    visible: (values) => values.equityDebt?.optionName === 'Equity',
    extraValidation: (values, fields) => {
      const irr = values.irr;
      if (irr == null) {
        return undefined;
      }

      if (irr < 0.0 || irr > 100.0) {
        const irrField = fields.find(f => f.id === SearchFieldID.IRR);
        const irrTitle = typeof irrField?.title === 'function' ? irrField.title(values) : irrField?.title; 
        return `${irrTitle ?? 'Project level IRR (%)'} must be between 0 and 100`;
      }

      return undefined;
    },
    formatter: (value) => `${(value as number).toFixed(1)}% project level IRR`,
  },
  {
    id: SearchFieldID.InvestorLevelIRR,
    title: 'Investor IRR (%)',
    type: 'number',
    required: true,
    slug: 'investorLevelIrr',
    visible: (values) => values.equityDebt?.optionName === 'Equity',
    extraValidation: (values, fields) => {
      const irr = values.investorLevelIrr;
      if (irr == null) {
        return undefined;
      }

      if (irr < 0.0 || irr > 100.0) {
        const irrField = fields.find(f => f.id === SearchFieldID.InvestorLevelIRR);
        const irrTitle = typeof irrField?.title === 'function' ? irrField.title(values) : irrField?.title; 
        return `${irrTitle ?? 'Investor IRR (%)'} must be between 0 and 100`;
      }

      return undefined;
    },
    formatter: (value) => `${(value as number).toFixed(1)}% investor IRR`,
  },
  {
    id: SearchFieldID.YieldOnCost,
    title: 'Yield on Cost (%)',
    type: 'number',
    visible: (values) => values.equityDebt?.optionName === 'Equity',
    extraValidation: (values, fields) => {
      const value = values.yieldOnCost;
      if (value == null) {
        return undefined;
      }

      if (value < 0.0 || value > 100.0) {
        const field = fields.find(f => f.id === SearchFieldID.YieldOnCost);
        const title = typeof field?.title === 'function' ? field.title(values) : field?.title;
        return `${title ?? 'Yield on Cost (%)'} must be between 0 and 100`;
      }

      return undefined;
    },
    formatter: (value) => `${(value as number).toFixed(2)}% yield on cost`,
  },
  {
    id: SearchFieldID.ProjectDuration,
    title: 'Project Duration/Hold Period (Years)',
    type: 'number',
    required: true,
    formatting: 'comma-separated',
    visible: (values) => values.equityDebt?.optionName === 'Equity',
    formatter: (value) => `${formatNumericString((value as number).toFixed(0))} year project duration`,
  },
  {
    id: SearchFieldID.ExpectedClosingDate,
    title: 'Expected Closing Date',
    type: 'date',
    required: true,
    slug: 'expectedClosingDate',
    visible: (values) => values.equityDebt?.optionName === 'Equity',
    extraValidation: (values) => {
      const value = values.expectedClosingDate;
      if (value == null) {
        return undefined;
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (Date.parse(value) < today.getTime()) {
        return 'Please enter a future date'
      }

      return undefined;
    },
    formatter: (value) => `Closing Date: ${dayjs(value as string).format('MM/DD/YYYY')}`,
  },
  {
    id: SearchFieldID.ClosingTimeline,
    type: 'select',
    title: 'Closing Timeline',
    required: true,
    slug: 'closingTimeline',
    visible: (values) => values.equityDebt?.optionName === 'Debt',
    formatter: (value) => `Closing Timeline: ${(value as ISearchInputParam).optionName}`,
  },
  {
    id: SearchFieldID.Recourse,
    type: 'select',
    title: 'Recourse',
    slug: 'recourse',
    required: true,
    visible: (values) => {
      return values.equityDebt?.optionName === 'Debt'
        && values.loanType?.optionName !== 'Pref Equity'
        && values.loanType?.optionName !== 'Mezzanine'
        && values.recourseType?.optionName == null;
    },
    formatter: (value) => (value as ISearchInputParam).optionName === 'Yes' ? 'Recourse' : 'Non-Recourse',
  },
  {
    id: SearchFieldID.RecourseType,
    type: 'select',
    title: 'Recourse/Non-Recourse',
    slug: 'recourseType',
    required: true,
    visible: (values) => {
      return values.equityDebt?.optionName !== 'Equity'
        && values.recourse == null
        && values.recourseType != null;
    },
  },
  {
    id: SearchFieldID.OtherEquity,
    title: 'Other',
    type: 'select',
    multiselect: true,
    slug: 'otherEquity',
    required: false,
    visible: (values) => values.equityDebt?.optionName === 'Equity',
  },
];


export function useDealSearchFields() {

  const formatFieldForDisplay = useCallback((params: {
    fieldId?: SearchFieldID,
    field?: SearchField,
    dealInfo?: IDealInfo,
    useFormatter?: boolean,
  }): string | undefined => {
    if (params.fieldId == null && params.field == null) {
      return undefined;
    }

    if (params.dealInfo == null) {
      return undefined;
    }

    const field = params.field ?? fields.find(f => f.id === params.fieldId);
    if (field == null) {
      return undefined;
    }

    const value = params.dealInfo[field.id as keyof IDealInfo];
    if (value == null) {
      return undefined;
    }

    if (params.useFormatter && field.formatter != null) {
      return field.formatter(value, params.dealInfo);
    } else {
      let retValue;
      switch (field.type) {
        case 'select':
          if (field.multiselect) {
            retValue = (value as ISearchInputParamArray).optionNames.join(', ');
          } else {
            retValue = (value as ISearchInputParam).optionName;
          }
          break;
        case 'number':
          if (field.formatting === 'comma-separated') {
            retValue = formatNumericString((value as number).toFixed(0));
          } else {
            retValue = (value as number).toFixed(0);
          }
          break;
        case 'date':
          retValue = dayjs(value as string).format('MM/DD/YYYY [at] h:mm A'); // (value as string).toString();
          break;
        case 'location':
          retValue = (value as string).toString();
          break;
      }

      if (field.prefix != null) {
        retValue = `${field.prefix}${retValue}`;
      }

      return retValue;
    }
  }, []);

  const buildFieldDisplayList = useCallback((dealInfo?: IDealInfo, params?: {useFormatters?: boolean}): {title: string, value?: string}[] => {
    if (dealInfo == null) {
      return [];
    }

    return fields
      .filter(field => field.visible == null || field.visible(dealInfo))
      .map(field => {
        const title = typeof field.title === 'string'
          ? field.title
          : field.title(dealInfo);

        return {
          title: title,
          value: formatFieldForDisplay({
            field,
            dealInfo,
            useFormatter: params?.useFormatters,
          }),
        };
      });
  }, [formatFieldForDisplay]);
  
  return {
    dealSearchFields: fields,
    formatFieldForDisplay,
    buildFieldDisplayList,
  };
}
