import { createApi } from '@reduxjs/toolkit/query/react';
import { baseUrls, createBaseQuery } from './apiHelper';
import { ILender, ILenderMetadata } from '../schemas';


export const lendersApiSlice = createApi({
  reducerPath: 'api-lenders',
  tagTypes: ['LenderMetadata'],
  baseQuery: createBaseQuery(baseUrls.lenders),
  endpoints: (build) => ({
    autocompleteLenders: build.query<ILender[], {name: string}>({
      query: ({name}) => `/lenders/autocomplete?name=${encodeURIComponent(name)}`,
    }),
    getLenderMetadataForLenders: build.query<ILenderMetadata[], {lenderNames: string[]}>({
      providesTags: (metadata) => metadata?.map(md => ({ type: 'LenderMetadata', id: md.lenderName })) ?? [],
      query: ({lenderNames}) => `/lenders/metadata?lenderNames=${encodeURIComponent(JSON.stringify(lenderNames))}`,
    }),
    getLenderMetadata: build.query<ILenderMetadata, {lenderName: string}>({
      providesTags: (metadata) => metadata != null ? [{type: 'LenderMetadata', id: metadata.lenderName}] : [],
      query: ({lenderName}) => `/lenders/${encodeURIComponent(lenderName)}/metadata`,
    }),

    createLenderReview: build.mutation<{message: string}, {lenderName: string, content: string}>({
      invalidatesTags: (_result, _error, args) => [{type: 'LenderMetadata', id: args.lenderName}],
      query: ({lenderName, content}) => {
        return {
          url: `/lenders/${encodeURIComponent(lenderName)}/metadata/reviews`,
          method: 'POST',
          body: {
            content,
          }
        };
      }
    }),
  }),
})

export const {
  useLazyAutocompleteLendersQuery,
  useGetLenderMetadataForLendersQuery,
  useGetLenderMetadataQuery,

  useCreateLenderReviewMutation,
} = lendersApiSlice;
