import { createApi } from '@reduxjs/toolkit/query/react';
import { IDeal, IDealInvitation, ISearchInputChoices, ISharedDeal, ILender, IDealStatusOption, IAccount, IDealFileZip, AccountType, IDealQuoteTerms, IEquityDealReview, IInvestorDealResponse } from '../schemas';
import { baseUrls, createBaseQuery } from './apiHelper';
import { snacked } from './snackMessage-slice';
import { setIsLoading, setFinishedLoading } from './loading-slice';


export const dealsApiSlice = createApi({
  reducerPath: 'deals-api',
  tagTypes: [
    'DealStatus', 'Deal', 'EquityMarketplaceDeal', 'EquityReview', 'EquityReviewForDeal', 'SearchResults',
    'ContactedLenders', 'SharedDeal', 'DealParticipant', 'DealInvitation',
    'DocumentRequest', 'DealFileZip', 'SearchInputChoice', 'FavoritedLenders',
    'NoContactLenders', 'InvestorDealResponse',
  ],
  baseQuery: createBaseQuery(baseUrls.main),
  endpoints: (build) => ({
    getDealStatusOptions: build.query<IDealStatusOption[], void>({
      providesTags: ['DealStatus'],
      query: () => '/deals/statuses',
    }),
    getDeals: build.query<{deals: IDeal[], sharedDeals: ISharedDeal[]}, {accountId?: string}>({
      providesTags: (dealData) => {
        return [
          'Deal',
          'SharedDeal',
          ...dealData?.deals?.map(d => ({ type: 'Deal', id: d._id })) ?? [],
          ...dealData?.sharedDeals?.map(sd => ({ type: 'SharedDeal', id: sd._id })) ?? [],
        ] as any;
      },
      query: ({accountId}) => {
        return `/deals${accountId != null ? `?accountId=${encodeURIComponent(accountId)}` : ''}`;
      }
    }),
    getEquityDealReviewForDeal: build.query<IEquityDealReview, {dealId: string}>({
      providesTags: (review, _error, args) => [
        { type: 'EquityReviewForDeal', id: args.dealId },
        { type: 'EquityReview', id: review?._id },
      ],
      query: ({dealId}) => `/deals/${encodeURIComponent(dealId)}/equity-review`,
    }),
    getSharedDealsForDeal: build.query<ISharedDeal[], {dealId: string, accountType: 'all' | AccountType}>({
      providesTags: (sharedDeals) => sharedDeals?.map(sd => ({ type: 'SharedDeal', id: sd._id })) ?? [],
      query: ({dealId, accountType}) => `/deals/${dealId}/shared-deals?accountType=${accountType}`,
    }),
    getMarketplaceDeals: build.query<IDeal[], {type: 'all' | 'rejected' | 'active', filtered?: boolean}>({
      providesTags: (deals) => deals?.flatMap(d => ([{ type: 'Deal', id: d._id }, { type: 'EquityMarketplaceDeal', id: d._id }])) ?? [],
      query: ({type, filtered}) => `/deals/marketplace/?type=${encodeURIComponent(type)}&filtered=${filtered ?? false}`,
    }),
    getDeal: build.query<IDeal, {dealId: string}>({
      providesTags: (deal) => deal != null ? [({ type: 'Deal', id: deal._id })] : [],
      query: ({dealId}) => `/deals/${encodeURIComponent(dealId)}`,
    }),
    getDealParticipants: build.query<IAccount[], {dealId: string}>({
      providesTags: (accounts) => accounts?.map(a => ({ type: 'DealParticipant', id: a._id })) ?? [],
      query: ({dealId}) => `/deals/${dealId}/participants`,
    }),
    getSearchOptions: build.query<ISearchInputChoices, string>({
      providesTags: ['SearchInputChoice'],
      query: (searchInputSlug) => `/searchoptions/${encodeURIComponent(searchInputSlug)}`,
    }),
    getDealInvitations: build.query<IDealInvitation[], string>({
      providesTags: (invitations) => invitations?.map(i => ({ type: 'DealInvitation', id: i._id })) ?? [],
      query(dealId: string) {
        return `/deals/${dealId}/invitations`;
      }
    }),
    getDealFileDownloadUrl: build.query<{url: string}, {
      dealId: string,
      fileType: 'file' | 'quote-option-term-sheet' | 'loan-application-date',
      fileKey?: string,
      lenderName?: string,
      optionId?: string,
    }>({
      query: ({dealId, ...queryParams}) => {
        const queryString = Object.keys(queryParams)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent((queryParams as any)[key])}`)
          .join('&');
        
        return `/deals/${dealId}/files/download?${queryString}`;
      },
    }),
    getDealFileZip: build.query<IDealFileZip, {dealId: String}>({
      providesTags: (zip) => zip != null ? [{ type: 'DealFileZip', id: zip._id }] : [],
      query: ({dealId}) => `/deals/${dealId}/zip`,
    }),
    getDealFileZipUrl: build.query<{url: string}, {dealId: string}>({
      query: ({dealId}) => `/deals/${dealId}/zip/download`,
    }),
    searchLenders: build.query<ILender[], {dealId: string, filter: 'search' | 'favorites' | 'no-contact' | 'contacted'}>({
      providesTags: (_result, _error, meta) => {
        switch (meta.filter) {
          case 'search':
            return [{ type: 'SearchResults', id: meta.dealId }];
          case 'favorites':
            return [{ type: 'FavoritedLenders', id: meta.dealId }];
          case 'no-contact':
            return [{ type: 'NoContactLenders', id: meta.dealId }];
          case 'contacted':
            return [{ type: 'ContactedLenders', id: meta.dealId }];
        }
      },
      query: ({dealId, filter}) => `/deals/${dealId}/lenders?filter=${encodeURIComponent(filter)}`,
    }),
    getInvestorDealResponses: build.query<IInvestorDealResponse[], {dealId: string}>({
      providesTags: (responses) => responses != null ? [
        'InvestorDealResponse',
        ...responses.map(r => ({ type: 'InvestorDealResponse', id: r._id })) as never,
      ] : [],
      query: ({dealId}) => `/deals/${encodeURIComponent(dealId)}/investor-responses`,
    }),
    // getDealContactedLenderFileDownloadUrl: build.query<{url: string}, {dealId: string, lenderName: string, fileType: 'term-sheet'}>({
    //   query: ({dealId, lenderName, fileType}) => `/deals/${encodeURIComponent(dealId)}/contacted-lenders/${encodeURIComponent(lenderName)}/files?fileType=${encodeURIComponent(fileType)}`,
    // }),

    // MUTATIONS
    createDealFileUploadUrl: build.mutation<{url: string}, {
      dealId: string,
      fileType: 'file' | 'diligence-lender-logo' | 'diligence-loan-application-date',
      fileName: string,
      contentType: string,
    }>({
      query: ({dealId, fileType, fileName, contentType}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/files/upload`,
          method: 'POST',
          body: {
            fileType,
            fileName,
            contentType,
          }
        };
      }
    }),
    getDealImageUploadUrl: build.mutation<{url: string}, {dealId: string, fileName: string, contentType: string}>({
      query: ({dealId, fileName, contentType}) => {
        return {
          url: `/deals/${dealId}/image/upload`,
          method: 'POST',
          body: {
            fileName: fileName,
            contentType: contentType,
          },
        };
      },
    }),
    getDealFileUploadUrlsMultipart: build.mutation<{uploadId: string, presignedUrls: string[], partSize: number}, {
      dealId: string,
      fileName: string,
      contentType: string,
      size: number,
    }>({
      query: ({dealId, fileName, contentType, size}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/files`,
          method: 'POST',
          body: {
            fileName,
            contentType,
            size,
          }
        };
      },
    }),
    createDeal: build.mutation<{deal?: IDeal}, {dealInfo?: Record<string, any>}>({
      query: ({dealInfo}) => {
        return {
          url: '/deals',
          method: 'POST',
          body: {
            dealInfo: dealInfo,
          }
        };
      },
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          const deal = result.data.deal;
          if (deal != null) {
            dispatch(
              dealsApiSlice.util.updateQueryData('getDeals', {}, (draft) => {
                draft.deals = [
                  deal,
                  ...draft.deals,
                ];
              })
            );
          }
        } catch {}
      }
    }),
    updateDeal: build.mutation<{message: string}, {
      dealId: string,
      status?: string,
      equityDealReviewId?: string,
      privacy?: 'public' | 'private',
      whiteGloveStatus?: 'requested' | 'enabled',
      feedbackStatus?: 'requested',
      dealInfo?: Record<string, any>,
      description?: string,
    }>({
      invalidatesTags: (_result, _error, args) => [
        'Deal',
        { type: 'Deal', id: args.dealId },
        { type: 'EquityReviewForDeal', id: args.dealId },
      ],
      query: ({ dealId, status, privacy, whiteGloveStatus, feedbackStatus, dealInfo, description }) => {
        return {
          url: `/deals/${dealId}`,
          method: 'PATCH',
          body: {
            status,
            privacy,
            whiteGloveStatus,
            feedbackStatus,
            dealInfo,
            description,
          }
        };
      },
    }),
    deleteDealFile: build.mutation<IDeal, {id: string, fileId?: string, fileKey?: string}>({
      query: ({ id, fileId, fileKey }) => {
        return {
          url: `/deals/files/`,
          method: 'DELETE',
          body: {
            dealId: id,
            fileId: fileId,
            fileKey: fileKey,
          }
        };
      },
      invalidatesTags: ['Deal'],
      async onQueryStarted({id, fileId, fileKey}, { dispatch, queryFulfilled }) {
        dispatch(setIsLoading());
        const patchResult = dispatch(
          dealsApiSlice.util.updateQueryData('getDeal', {dealId: id}, (draft) => {
            if (fileKey != null) {
              draft.files = draft.files?.filter(f => f.Key !== fileKey);
            } else if (fileId != null) {
              draft.files = draft.files?.filter(f => f._id !== fileId);
            }
          })
        );

        try {
          await queryFulfilled;
          dispatch(snacked({message: 'File deleted'}));
        } catch {
          patchResult.undo();
          dispatch(snacked({
            message: 'Network error. Please try again.',
            severity: 'error'
          }));
        } finally {
          dispatch(setFinishedLoading());
        }
      }
    }),
    inviteUsersToDeal: build.mutation<{message: string}, {dealId: string, users?: {_id: string, email: string}[], emails?: string[]}>({
      invalidatesTags: ['DealInvitation'],
      query: ({dealId, users, emails}) => {
        return {
          url: `/deals/${dealId}/invitations/invite`,
          method: 'POST',
          body: {
            userIds: users?.map(u => u._id),
            emails,
          }
        }
      },
      onQueryStarted: async ({dealId, users, emails}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          dealsApiSlice.util.updateQueryData('getDealInvitations', dealId, (draft) => {
            draft = [
              ...users?.map(u => {
                return {
                  accountId: '',
                  dealId: dealId,
                  invitingUserId: '',
                  invitingAccountId: '',
                  acceptingUserId: u._id,
                  email: u.email,
                  status: 'pending',
                  createdDt: new Date().toISOString(),
                } as IDealInvitation;
              }) ?? [],
              ...emails?.map(email => {
                return {
                  accountId: '',
                  dealId: dealId,
                  invitingUserId: '',
                  invitingAccountId: '',
                  email: email,
                  status: 'pending',
                  createdDt: new Date().toISOString(),
                } as IDealInvitation;
              }) ?? [],
              ...draft,
            ];
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          dispatch(snacked({
            message: 'Network error. Please try again.',
            severity: 'error',
          }));
        }
      }
    }),
    resendDealInvitation: build.mutation<{message: string}, {dealId: string, invitationId: string}>({
      invalidatesTags: ['DealInvitation'],
      query: ({dealId, invitationId}) => {
        return {
          url: `/deals/${dealId}/invitations/${invitationId}/resend`,
          method: 'POST',
        }
      },
      onQueryStarted: async ({dealId, invitationId}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          dealsApiSlice.util.updateQueryData('getDealInvitations', dealId, (draft) => {
            const index = draft.findIndex(inv => inv._id === invitationId);
            if (index >= 0) {
              draft[index] = {
                ...draft[index],
                _id: '',
                status: 'pending',
                createdDt: new Date().toISOString(),
              };
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    cancelDealInvitation: build.mutation<any, {dealId: string, invitationId: string}>({
      query: ({dealId, invitationId}) => {
        return {
          url: `/deals/${dealId}/invitations/${invitationId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['DealInvitation'],
      onQueryStarted: async ({dealId, invitationId}, {dispatch, queryFulfilled}) => {
        dispatch(setIsLoading());

        const patchResult = dispatch(
          dealsApiSlice.util.updateQueryData('getDealInvitations', dealId, (draft) => {
            draft = draft.filter(inv => inv._id !== invitationId);
          })
        );

        try {
          await queryFulfilled;
          dispatch(snacked({message: 'Invitation canceled'}));
        } catch {
          patchResult.undo();
          dispatch(snacked({
            message: 'Network error. Please try again.',
            severity: 'error',
          }));
        } finally {
          dispatch(setFinishedLoading());
        }
      }
    }),
    removeDealAccess: build.mutation<any, {dealId: string, accountId: string, lenderName: string}>({
      query: ({dealId, accountId, lenderName}) => {
        return {
          url: `/deals/${dealId}/access/`,
          method: 'DELETE',
          body: {
            accountId: accountId,
            lenderName: lenderName,
          },
        };
      },
      invalidatesTags: ['Deal'],
      onQueryStarted: async ({dealId, accountId}, {dispatch, queryFulfilled}) => {
        dispatch(setIsLoading());

        const dealPatchResult = dispatch(
          dealsApiSlice.util.updateQueryData('getDeal', {dealId}, (draft) => {
            draft.capProviderAccountIds = draft.capProviderAccountIds?.filter(i => i !== accountId);
            
            draft.capProviderStatuses = {...draft.capProviderStatuses};
            delete draft.capProviderStatuses[accountId];

            draft.capProviderMetadata = {...draft.capProviderMetadata};
            delete draft.capProviderMetadata[accountId];
          })
        );

        const invitationPatchResult = dispatch(
          dealsApiSlice.util.updateQueryData('getDealInvitations', dealId, (draft) => {
            draft = draft.filter(inv => inv.acceptingAccountId !== accountId);
          })
        );

        try {
          await queryFulfilled;
          dispatch(snacked({message: 'Access removed'}));
        } catch {
          dealPatchResult.undo();
          invitationPatchResult.undo();
          dispatch(snacked({
            message: 'Network error. Please try again.',
            severity: 'error',
          }));
        } finally {
          dispatch(setFinishedLoading());
        }
      }
    }),
    favoriteSearchResult: build.mutation<{message: string}, {
      dealId: string,
      lenderId?: string,
      lenderName: string,
      isFavorite: boolean,
    }>({
      invalidatesTags: (_result, _error, args) => {
        if (args.isFavorite) {
          return [
            // {type: 'Deal', id: args.dealId},
            {type: 'FavoritedLenders', id: args.dealId}
          ]
        }
        
        return [];
      },
      query: ({dealId, lenderName, isFavorite}) => {
        return {
          url: `/deals/${dealId}/lenders/favorites`,
          method: 'POST',
          body: {
            lenderName: lenderName,
            isFavorite,
          }
        }
      },
      onQueryStarted: async ({dealId, lenderId, lenderName, isFavorite}, {dispatch, queryFulfilled}) => {
        const patchResultLenders = dispatch(
          dealsApiSlice.util.updateQueryData('searchLenders', {dealId, filter: 'favorites'}, (lenders) => {
            if (isFavorite) {
              const newLenders = [
                ...lenders,
                {
                  _id: lenderId ?? `${Math.random()}`,
                  Lender: lenderName,
                },
              ];

              newLenders.sort((l1, l2) => (l1.Lender as string).localeCompare(l2.Lender as string));
              return newLenders;
            } else {
              return lenders.filter(l => l.Lender !== lenderName);
            }
          })
        );

        const patchResultDeal = dispatch(
          dealsApiSlice.util.updateQueryData('getDeal', {dealId}, (deal) => {
            if (isFavorite) {
              deal.favoriteLenders = [
                ...deal.favoriteLenders ?? [],
                {
                  _id: lenderId ?? `${Math.random()}`,
                  name: lenderName,
                }
              ];
            } else {
              deal.favoriteLenders = (deal.favoriteLenders ?? []).filter(l => l.name !== lenderName);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResultLenders.undo();
          patchResultDeal.undo();
        }
      },
    }),
    addNoContactLender: build.mutation<{message: string}, {
      dealId: string,
      lenderName: string,
      action: 'add' | 'remove',
    }>({
      invalidatesTags: (_result, _error, args) => {
        if (args.action === 'add') {
          return [
            {type: 'Deal', id: args.dealId},
            {type: 'NoContactLenders', id: args.dealId},
          ];
        }

        return [];
      },
      query: ({dealId, lenderName, action}) => {
        return {
          url: `/deals/${dealId}/lenders/no-contact`,
          method: 'POST',
          body: {
            lenderName,
            action,
          }
        };
      },
      onQueryStarted: async ({dealId, lenderName, action}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          dealsApiSlice.util.updateQueryData('getDeal', {dealId}, (deal) => {
            switch (action) {
              case 'add':
                deal.noContactLenders = [
                  ...deal.noContactLenders ?? [],
                  {
                    _id: `${Math.random()}`,
                    name: lenderName,
                  }
                ];
                break;
              case 'remove':
                deal.noContactLenders = (deal.noContactLenders ?? []).filter(l => l.name !== lenderName);
                break;
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    updateFavoritedLender: build.mutation<{status: string}, {
      dealId: string,
      lenderName: string,
      comment?: string,
    }>({
      invalidatesTags: (_result, _error, args) => [
        { type: 'Deal', id: args.dealId },
        { type: 'FavoritedLenders', id: args.dealId },
      ],
      query: ({dealId, lenderName, ...changes}) => {
        return {
          url: `/deals/${dealId}/lenders/favorites`,
          method: 'PATCH',
          body: {
            lenderName,
            ...changes,
          },
        };
      }
    }),
    updateNoContactLender: build.mutation<{status: string}, {
      dealId: string,
      lenderName: string,
      comment?: string,
    }>({
      invalidatesTags: (_result, _error, args) => [
        { type: 'Deal', id: args.dealId },
        { type: 'NoContactLenders', id: args.dealId },
      ],
      query: ({dealId, lenderName, ...changes}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/lenders/no-contact`,
          method: 'PATCH',
          body: {
            lenderName,
            ...changes,
          },
        };
      }
    }),
    addLenderToLenderOutreach: build.mutation<{message: string, status: string}, {dealId: string, lenderNames: string[]}>({
      invalidatesTags: (_result, _error, args) => [{ type: 'Deal', id: args.dealId }],
      query: ({dealId, lenderNames}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/lenders/contacted`,
          method: 'POST',
          body: {
            lenderNames,
          }
        };
      }
    }),
    recordContactedLenderFeedback: build.mutation<{message: string, status: string}, {
      dealId: string,
      lenderName: string,
      status: 'pending' | 'accepted' | 'rejected',
      message: string,
    }>({
      invalidatesTags: (_result, _error, args) => [{ type: 'Deal', id: args.dealId }],
      query: ({dealId, lenderName, status, message}) => {
        return {
          url:  `/deals/${encodeURIComponent(dealId)}/lenders/contacted/${encodeURIComponent(lenderName)}/feedback`,
          method: 'POST',
          body: {
            status,
            message,
          }
        };
      }
    }),
    recordContactedLenderQuote: build.mutation<{message: string, status: string}, {
      dealId: string,
      lenderName: string,
      type: 'soft' | 'firm',
      optionId?: string,
      optionName?: string,
      optionInfo?: string,
      terms: IDealQuoteTerms,
    }>({
      invalidatesTags: (_result, _error, args) => [{type: 'Deal', id: args.dealId}],
      query: ({dealId, lenderName, type, optionId, optionName, optionInfo, terms}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/lenders/contacted/${encodeURIComponent(lenderName)}/quotes`,
          method: 'POST',
          body: {
            type,
            optionId,
            optionName,
            optionInfo,
            terms,
          }
        };
      }
    }),
    createQuoteOptionFileUploadUrl: build.mutation<{url: string}, {
      dealId: string,
      lenderName: string,
      optionId: string,
      fileType: 'term-sheet',
      fileName: string,
      contentType: string,
    }>({
      query: ({dealId, lenderName, optionId, fileType, fileName, contentType}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/lenders/contacted/${encodeURIComponent(lenderName)}/quote-options/${encodeURIComponent(optionId)}/files/upload`,
          method: 'POST',
          body: {
            fileType,
            fileName,
            contentType,
          }
        };
      }
    }),
    createDiligenceContact: build.mutation<{message: string, status: string}, {
      dealId: string,
      type: 'lender' | 'borrower' | 'insurance' | 'legal' | 'title',
      role?: string,
      name: string,
      email: string,
      phone?: string,
      address?: string,
    }>({
      invalidatesTags: (_result, _error, args) => [{type: 'Deal', id: args.dealId}],
      query: ({dealId, type, role, name, email, phone, address}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/diligence/contacts/${encodeURIComponent(type)}`,
          method: 'POST',
          body: {
            role,
            name,
            email,
            phone,
            address,
          }
        };
      }
    }),
    updateDiligenceContact: build.mutation<{message: string, status: string}, {
      dealId: string,
      type: 'lender' | 'borrower' | 'insurance' | 'legal' | 'title',
      contactId: string,
      role?: string,
      name: string,
      email: string,
      phone?: string,
      address?: string,
    }>({
      invalidatesTags: (_result, _error, args) => [{type: 'Deal', id: args.dealId}],
      query: ({dealId, type, contactId, role, name, email, phone, address}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/diligence/contacts/${encodeURIComponent(type)}/${encodeURIComponent(contactId)}`,
          method: 'PUT',
          body: {
            role,
            name,
            email,
            phone,
            address,
          }
        };
      }
    }),
    deleteDiligenceContact: build.mutation<{message: string, status: string}, {
      dealId: string,
      type: 'lender' | 'borrower' | 'insurance' | 'legal' | 'title',
      contactId: string,
    }>({
      invalidatesTags: (_result, _error, args) => [{type: 'Deal', id: args.dealId}],
      query: ({dealId, type, contactId}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/diligence/contacts/${encodeURIComponent(type)}/${encodeURIComponent(contactId)}`,
          method: 'DELETE',
        };
      }
    }),
    updateDiligence: build.mutation<{message: string, status: string}, {
      dealId: string,
      diligence: {
        loanApplicationDt?: string | null,
        targetClosingDt?: string | null,

        loanApplicationDtFile?: null,

        lenderKickoffCompletedDt?: string | null,
        appraiserEngagedDt?: string | null,
        attorneysEngagedDt?: string | null,
        insuranceBrokersEngagedDt?: string | null,
        titleCompanyEngagedDt?: string | null,
        siteVisitCompleteDt?: string | null,
        insurance?: {
          notes?: string | null,
        },
        thirdPartyReports?: {
          appraisalOrderDt?: string | null,
          appraisalDueDt?: string | null,
          notes?: string | null,
        },
        siteVisit?: {
          siteVisitDt?: string | null,
          notes?: string | null,
        },
        legal?: {
          notes?: string | null,
        },
        title?: {
          notes?: string | null,
        },
      }
    }>({
      invalidatesTags: (_result, _error, args) => [{type: 'Deal', id: args.dealId}],
      query: ({dealId, diligence}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/diligence`,
          method: 'PATCH',
          body: diligence,
        };
      }
    }),
    reorderLenderQuotes: build.mutation<{message: string, status: string}, {
      dealId: string,
      orderedOptionIds: string[],
    }>({
      query: ({dealId, orderedOptionIds}) => {
        return {
          url: `/deals/${dealId}/lenders/contacted/order`,
          method: 'PUT',
          body: {
            orderedOptionIds,
          }
        };
      },
      onQueryStarted: async ({dealId, orderedOptionIds}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          dealsApiSlice.util.updateQueryData('getDeal', {dealId}, (deal) => {
            deal.quoteOptionOrdering = orderedOptionIds;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    shareDeal: build.mutation<ISharedDeal, {
      dealId: string,
      recipientAccountId: string,
      recipientName: string,
      message: string,
    }>({
      invalidatesTags: (_result, _error, meta) => [{type: 'ContactedLenders', id: meta.dealId}],
      query: ({dealId, recipientAccountId, recipientName, message}) => {
        return {
          url: '/deals/share',
          method: 'POST',
          body: {
            dealId,
            recipientAccountId,
            recipientName,
            message,
          }
        };
      },
      onQueryStarted: async ({dealId, recipientAccountId, recipientName, message}, {dispatch, queryFulfilled}) => {
        const dealPatchResult = dispatch(
          dealsApiSlice.util.updateQueryData('getDeal', {dealId}, (draft) => {
            draft.capProviderAccountIds = Array.from(new Set([...draft.capProviderAccountIds ?? [], recipientAccountId]));
            draft.capProviderStatuses = {
              ...draft.capProviderStatuses ?? {},
              [recipientAccountId]: 'under-review',
            };
            draft.capProviderMetadata = {
              ...draft.capProviderMetadata ?? {},
              [recipientName]: {
                name: recipientName,
                accountId: recipientAccountId,
                status: 'under-review',
                sharedDt: new Date().toISOString(),
              }
            };
          })
        );

        try {
          const result = await queryFulfilled;

          dispatch(
            dealsApiSlice.util.updateQueryData('getSharedDealsForDeal', {dealId, accountType: 'lender'}, (draft) => {
              Object.assign(draft, [...draft, result.data]);
            })
          );
        } catch {
          dealPatchResult.undo();
        }
      }
    }),
    
    // sendDealQuote: build.mutation<{message: string}, {sharedDealId: string, dealId: string, type: 'soft' | 'firm', terms: IDealQuoteTerms}>({
    //   query: ({sharedDealId, type, terms}) => {
    //     return {
    //       url: `/shared-deals/${sharedDealId}/quotes`,
    //       method: 'POST',
    //       body: {
    //         type,
    //         terms,
    //       }
    //     };
    //   },
    //   onQueryStarted: async ({sharedDealId, dealId, type, terms}, {dispatch, queryFulfilled}) => {
    //     const patchResult = dispatch(
    //       dealsApiSlice.util.updateQueryData('getSharedDealsForDeal', {dealId: dealId, accountType: 'all'}, (draft) => {
    //         const index = draft.findIndex(sd => sd._id === sharedDealId);
    //         if (index >= 0) {
    //           draft[index] = {
    //             ...draft[index],
    //             lenderQuotes: [
    //               ...draft[index].lenderQuotes ?? [],
    //               {
    //                 type: type,
    //                 terms: terms,
    //                 createdDt: new Date().toISOString(),
    //               }
    //             ]
    //           }
    //         }
    //       })
    //     );

    //     try {
    //       await queryFulfilled;
    //     } catch {
    //       patchResult.undo();
    //     }
    //   }
    // }),
    deleteDeal: build.mutation<{deleted: boolean}, {dealId: string}>({
      query: ({dealId}) => {
        return {
          url: `/deals`,
          method: 'DELETE',
          body: {
            _id: dealId,
          }
        };
      },
      onQueryStarted: async ({dealId}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          dealsApiSlice.util.updateQueryData('getDeals', {}, (draft) => {
            draft.deals = draft.deals.filter(deal => deal._id !== dealId);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    publishEquityDeal: build.mutation<{state: 'published' | 'unpublished' | 'in-review'}, {dealId: string, published: boolean}>({
      invalidatesTags: (_result, _error, args) => [
        { type: 'Deal', id: args.dealId },
        { type: 'EquityReviewForDeal', id: args.dealId },
      ],
      query: ({dealId, published}) => {
        return {
          url: `/deals/${dealId}/publish`,
          method: 'POST',
          body: {
            published,
          }
        };
      },
    }),
    respondToEquityMarketplaceDeal: build.mutation<{message: string, sharedDeal?: ISharedDeal}, {accountId: string, dealId: string, status: 'accepted' | 'rejected', comment?: string}>({
      invalidatesTags: ['Deal', 'SharedDeal', 'InvestorDealResponse'],
      query: ({dealId, status, comment}) => {
        return {
          url: `/deals/marketplace/${dealId}`,
          method: 'POST',
          body: {
            status,
            comment,
          }
        };
      },
      onQueryStarted: async ({accountId, dealId, status, comment}, {dispatch, queryFulfilled}) => {
        try {
          const {sharedDeal} = (await queryFulfilled).data;
          switch (status) {
            case 'accepted':
              dispatch(
                dealsApiSlice.util.updateQueryData('getSharedDealsForDeal', {dealId, accountType: 'all'}, (draft) => {
                  if (sharedDeal != null) {
                    draft.push(sharedDeal);
                  }
                })
              );
              break;
            case 'rejected':
              dispatch(
                dealsApiSlice.util.updateQueryData('getDeal', {dealId}, (draft) => {
                  draft = {
                    ...draft,
                    marketplaceStats: {
                      viewedByAccountIds: draft.marketplaceStats?.viewedByAccountIds ?? [],
                      chattedWithAccountIds: draft.marketplaceStats?.chattedWithAccountIds ?? [],
                      rejectedByAccountIds: [
                        ...draft.marketplaceStats?.rejectedByAccountIds ?? [],
                        accountId,
                      ]
                    }
                  }
                })
              );
              break;
          }
        } catch {}
      }
    }),
  }),
});


export const {
  useGetDealStatusOptionsQuery,
  useGetDealsQuery,
  useGetEquityDealReviewForDealQuery,
  useGetSharedDealsForDealQuery,
  useGetMarketplaceDealsQuery,
  useGetDealQuery,
  useGetDealParticipantsQuery,
  useGetSearchOptionsQuery,
  useGetDealInvitationsQuery,
  useLazyGetDealFileDownloadUrlQuery,
  useGetDealFileZipQuery,
  useLazyGetDealFileZipUrlQuery,
  useSearchLendersQuery,
  useGetInvestorDealResponsesQuery,
  
  useCreateDealFileUploadUrlMutation,
  useGetDealImageUploadUrlMutation,
  useGetDealFileUploadUrlsMultipartMutation,
  useCreateDealMutation,
  useUpdateDealMutation,
  useDeleteDealFileMutation,
  useInviteUsersToDealMutation,
  useResendDealInvitationMutation,
  useCancelDealInvitationMutation,
  useRemoveDealAccessMutation,
  useFavoriteSearchResultMutation,
  useAddNoContactLenderMutation,
  useUpdateFavoritedLenderMutation,
  useUpdateNoContactLenderMutation,
  useAddLenderToLenderOutreachMutation,
  useRecordContactedLenderFeedbackMutation,
  useRecordContactedLenderQuoteMutation,
  useCreateQuoteOptionFileUploadUrlMutation,
  useCreateDiligenceContactMutation,
  useUpdateDiligenceContactMutation,
  useDeleteDiligenceContactMutation,
  useUpdateDiligenceMutation,
  useReorderLenderQuotesMutation,
  useShareDealMutation,
  useDeleteDealMutation,
  usePublishEquityDealMutation,
  useRespondToEquityMarketplaceDealMutation,
} = dealsApiSlice;
