import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import AuthForm, { AuthData, AuthType } from '../AuthForm';
import { useAcceptInvitationMutation } from '../../features/invitations-api';
import PageContainer from '../PageContainer';


export default function AcceptDealInvitation(): JSX.Element {

  const {invitationId} = useParams();
  let navigate = useNavigate();

  const { shouldBeLoggedIn } = useAppSelector((state) => state.auth);

  const [errorMessage, setErrorMessage] = useState('');
  const [state, setState] = useState<'accepting' | 'auth' | 'error' | 'accepted'>('accepting');

  const [acceptInvitationQuery] = useAcceptInvitationMutation();

  // Rerenders higher up the tree (or React strict mode) is causing the useEffect
  // to run twice when it should only be running once so keep track of whether
  // or not it has been run already
  const invitationAcceptedRef = useRef(false);

  const acceptInvitation = useCallback(async () => {
    if (invitationId == null || invitationAcceptedRef.current) {
      return;
    }
    
    invitationAcceptedRef.current = true;

    try {
      const acceptResult = await acceptInvitationQuery({ id: invitationId }).unwrap();
      console.log(acceptResult);
      setState('accepted');
    } catch (error: any) {
      setErrorMessage(error.data.message);
      setState('error');
    }
  }, [invitationId, setState, setErrorMessage, acceptInvitationQuery]);
  
  const onAuthSuccess = useCallback(async (type: AuthType, data: AuthData) => {
    switch (type) {
      case 'login':
        setState('accepting');
        if (!invitationAcceptedRef.current) {
          acceptInvitation();
        }
        break;
      case 'register':
        // Registration process already accepting invite, so no need to do it again
        navigate(`/signup-complete?email=${encodeURIComponent(data.email)}`);
        break;
    }
  }, [setState, acceptInvitation, navigate]);

  const viewSharedDealsClicked = useCallback(async () => {
    navigate(`/shared-deals`);
  }, [navigate]);

  useEffect(() => {
    if (shouldBeLoggedIn) {
      if (!invitationAcceptedRef.current) {
        acceptInvitation();
      }
    } else {
      setState('auth');
    }
  }, [acceptInvitation, shouldBeLoggedIn]);

  const renderContent = useCallback(() => {
    switch (state) {
      case 'accepting':
        return (
          <CircularProgress />
        );
      case 'auth':
        return (
          <AuthForm
            defaultState='login'
            prompt='To accept your invite to access a deal, first create your account or login if you already have one'
            metadata={{
              invitationId: invitationId,
            }}
            onSuccess={onAuthSuccess} />
        );
      case 'error':
        return (
          <Box sx={{width: '300px'}} alignItems='center'>
            <Grid container>
              <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
                <Typography>
                  {'Error'}
                </Typography>
                {errorMessage !== '' &&
                  <Typography variant='error'>
                    {errorMessage}
                  </Typography>
                }
              </Grid>
            </Grid>
          </Box>
        );
      case 'accepted':
        return (
          <Box sx={{width: '300px'}} alignItems='center'>
            <Grid container>
              <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
                <Typography>
                  {'The invitation has been accepted and you can access it from the "Shared with Me" tab in your personal organization.'}
                </Typography>
                <Button 
                  variant='contained'
                  sx={{marginTop: 2}}
                  onClick={viewSharedDealsClicked}>
                  {'Go to Shared Deals'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        );
      // case 'accepted-confirm':
      //   return (
      //     <Box sx={{width: '300px'}} alignItems='center'>
      //       <Grid container>
      //         <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
      //           <Typography>
      //             {'Your account has been created and the invitation has been accepted. Please check your email to confirm your account in order to login and view the deal.'}
      //           </Typography>
      //         </Grid>
      //       </Grid>
      //     </Box>
      //   );
          
    }
  }, [state, errorMessage, onAuthSuccess, viewSharedDealsClicked, invitationId]);

  return (
    <PageContainer>
      <Box sx={{ display: 'flex' }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ paddingTop: '5vh', }}>
          <Grid item xs={3}>
            {renderContent()}
          </Grid>   
        </Grid> 
      </Box>
    </PageContainer>
  );
}
