import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseUrls, createBaseQuery } from "./apiHelper";
import { dealsApiSlice } from "./deals-api";
import { IInvitationAutocompleteResult } from "../schemas";


export const invitationsApiSlice = createApi({
  reducerPath: 'api-invitations',
  tagTypes: ['Invitation'],
  baseQuery: createBaseQuery(baseUrls.main),
  endpoints: (build) => ({
    getEmailAutocompleteResults: build.query<IInvitationAutocompleteResult[], {email: string}>({
      query: ({email}) => `/invitations/autocomplete?email=${encodeURIComponent(email)}`,
    }),
    acceptInvitation: build.mutation<any, {id: string}>({
      query: ({ id }) => {
        return {
          url: `/invitations/${id}`,
          method: 'PATCH',
        }
      },
      onQueryStarted: ({id}, {dispatch}) => {
        dispatch(dealsApiSlice.util.invalidateTags(['Deal', 'SharedDeal']));
      }
    }),
  }),
});

export const {
  useGetEmailAutocompleteResultsQuery,
  useLazyGetEmailAutocompleteResultsQuery,

  useAcceptInvitationMutation,
} = invitationsApiSlice;
