import React, { useCallback, useMemo, useState } from 'react';
import DealParameterFormControl from './DealParameterFormControl';
import { useDealParameter, useEffectDebounced } from '../utils/hooks';
import { DealParameterProps } from './DealParameter';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';


interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  structured_formatting?: StructuredFormatting;
}

const autocompleteService = { current: null };

export default function DealParameterLocation({field, formValues, dealInfo, error, setFormValue}: DealParameterProps): JSX.Element {
  
  const {
    title,
    disabled,
    dependingFieldTitles,
  } = useDealParameter({ field, dealInfo });

  const [options, setOptions] = useState<PlaceType[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const structuredValue: PlaceType = useMemo(() => {
    return {
      description: (formValues[field.id] as string) ?? '',
    };
  }, [formValues, field.id]);

  const valueChanged = useCallback((event: React.SyntheticEvent<Element, Event>, value: any) => {
    setOptions(value ? [value, ...options] : options);
    if (value?.description != null) {
      setFormValue(value.description);
    } else {
      setFormValue('');
    }
  }, [setOptions, setFormValue, options]);

  useEffectDebounced(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(structuredValue.description ? [structuredValue] : []);
    } else {
      (autocompleteService.current as any).getPlacePredictions({ input: inputValue, componentRestrictions: { country: 'us' } }, (results?: PlaceType[]) => {
          if (active) {
            setOptions(results ?? []);
          }
        }
      );
    }

    return () => {
      active = false;
    };
  }, 200, [structuredValue, inputValue, fetch]);
  
  return (
    <DealParameterFormControl
      field={field}
      disabled={disabled}
      dependingFieldTitles={dependingFieldTitles}>
      <Autocomplete
        id={field.id}
        getOptionLabel={option => typeof option === 'string' ? option : option.description}
        isOptionEqualToValue={(option, value) => option.description === value.description}
        freeSolo
        options={options}
        autoComplete
        value={structuredValue}
        onChange={valueChanged}
        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error != null}
            helperText={error}
            label={title}
            fullWidth
            required={field.required}
            InputProps={{
              ...params.InputProps,
              style: {
                borderRadius: '8px',
              }
            }} />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {option.structured_formatting != null ? (
                    <>
                      <span>
                        {option?.structured_formatting?.main_text}
                      </span>
                      <Typography variant="body2" color="text.secondary">
                        {option?.structured_formatting?.secondary_text}
                      </Typography>
                    </>
                  ) : (
                    <span>
                      {option.description}
                    </span>
                  )}
                </Grid>
              </Grid>
            </li>
          );
        }} 
        />
    </DealParameterFormControl>
  );
}
