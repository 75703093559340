import { useCallback, useMemo } from "react";
import { ListItem, Box, Avatar, Typography, Button, Tooltip, IconButton, ListItemProps } from "@mui/material";
import { IAccount, IDeal, IDealInvitation, ISharedDeal } from "../../../schemas";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import { useAppDispatch } from "../../../app/hooks";
import { snacked } from "../../../features/snackMessage-slice";
import { useResendDealInvitationMutation, useCancelDealInvitationMutation, useRemoveDealAccessMutation } from "../../../features/deals-api";
import dayjs from "dayjs";


interface Props extends ListItemProps {
  deal?: IDeal;
  account?: IAccount;
  invitation?: IDealInvitation;
  sharedDeal?: ISharedDeal;
}

export default function DealAccessListItem({deal, account, invitation, sharedDeal, ...listItemProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [resendInvitation] = useResendDealInvitationMutation();
  const [cancelInvitation] = useCancelDealInvitationMutation();
  const [removeAccess] = useRemoveDealAccessMutation();
  
  const title = account?.name ?? sharedDeal?.recipientName ?? invitation?.email;

  const isInvitationExpired = useMemo(() => {
    if (invitation == null) {
      return false;
    }

    const elapsedTime = (new Date().getTime() - new Date(Date.parse(invitation.createdDt)).getTime()) / 1000.0 / 60.0 / 60.0;
    return elapsedTime > 48.0;
  }, [invitation]);
  
  const resendInvitationClicked = useCallback((invitation: IDealInvitation) => {
    if (deal?._id == null) {
      return;
    }

    resendInvitation({
      dealId: deal._id,
      invitationId: invitation._id,
    });
  }, [deal?._id, resendInvitation]);

  const copyInvitationLinkClicked = useCallback(async (invitation: IDealInvitation) => {
    const url = `${window.location.protocol}//${window.location.host}/accept-invitation/${invitation._id}`;
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(url);
    } else {
      document.execCommand('copy', true, url);
    }

    dispatch(snacked({
      message: 'Link copied to clipboard',
      severity: 'info',
    }));
  }, [dispatch]);

  const removeAccessClicked = useCallback(() => {
    if (deal?._id == null) {
      return;
    }

    if (invitation != null && invitation.status === 'pending') {
      cancelInvitation({
        dealId: deal._id,
        invitationId: invitation._id,
      });
    } else if (account != null && sharedDeal != null) {
      removeAccess({
        dealId: deal._id,
        accountId: account._id,
        lenderName: sharedDeal.recipientName ?? account.name,
      });
    }
  }, [deal?._id, invitation, account, sharedDeal, cancelInvitation, removeAccess]);

  return (
    <ListItem
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
      disablePadding
      {...listItemProps}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '100%',
      }}>
        <Avatar
          sx={{
            width: '40px',
            height: '40px',
          }}
          alt={title}
          src={account?.profileImageUrl} />

        <Box sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          rowGap: '4px',
          paddingLeft: '12px',
        }}>
          <Typography sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17px',
          }}>
            {title}
          </Typography>

          {invitation != null &&
            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#33333380',
            }}>
              {`Sent ${dayjs(invitation.createdDt).format('M/DD/YYYY [at] h:mm A')}`}
            </Typography>
          }
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          {invitation != null && isInvitationExpired &&
            <Button
              sx={{
                marginRight: '12px',
              }}
              variant='text'
              // color='secondary'
              onClick={() => resendInvitationClicked(invitation)}>
              {'Resend invite'}
            </Button>
          }

          {invitation != null && invitation.status === 'pending' && !isInvitationExpired &&
            <Tooltip
              title='Copy invitation link'
              placement='top'
              enterDelay={2000}
              arrow>
              <IconButton
                sx={{
                  marginRight: '12px',
                }}
                color='info'
                onClick={() => copyInvitationLinkClicked(invitation)}>
                <LinkRoundedIcon sx={{
                  color: '#333333BB',
                  fontSize: '16px',
                }} />
              </IconButton>
            </Tooltip>
          }

          {sharedDeal?.lock?.status !== 'locked' &&
            <IconButton
              sx={{
                color: '#33333380'
              }}
              onClick={removeAccessClicked}>
              <DeleteOutlineRoundedIcon sx={{
                color: '#333333BB',
                fontSize: '16px',
              }} />
            </IconButton>
          }
        </Box>
      </Box>
    </ListItem>
  );
}
