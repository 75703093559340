import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Dialog, DialogContent, DialogContentText, DialogProps, TextField } from "@mui/material";
import { IDealFavoritedLender } from "../../../schemas";
import CREEDDialogTitle from "../../CREEDDialogTitle";
import LoadingButton from "../../LoadingButton";
import { useAppDispatch } from "../../../app/hooks";
import { snacked } from "../../../features/snackMessage-slice";
import CommentRow from "../../CommentRow";
import { useGetLenderMetadataQuery, useCreateLenderReviewMutation } from "../../../features/lenders-api";


interface Props extends DialogProps {
  lender?: IDealFavoritedLender;
}

export default function LenderReviewsDialog({lender, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [review, setReview] = useState('');

  const {data: metadata, isFetching: isMetadataLoading} = useGetLenderMetadataQuery({ lenderName: lender?.name! }, { skip: lender == null });

  const [addLenderReview, {isLoading: isAddReviewLoading} ] = useCreateLenderReviewMutation();

  const reviews = useMemo(() => {
    if (metadata?.reviews == null) {
      return undefined;
    }

    const reviews = [...metadata.reviews];
    reviews.sort((a, b) => Date.parse(b.createdDt) - Date.parse(a.createdDt));

    return reviews;
  }, [metadata?.reviews]);

  const addReviewClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (lender == null) {
      return;
    }
    
    try {
      await addLenderReview({
        lenderName: lender.name,
        content: review,
      }).unwrap();

      setReview('');

      dispatch(snacked({
        message: 'Review saved',
        severity: 'success',
      }));
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed saving comment',
        severity: 'error',
      }));
    }
  }, [lender, addLenderReview, review, dispatch]);

  useEffect(() => {
    setReview('');
  }, [lender]);

  return (
    <Dialog
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title={`Ratings and Reviews for ${lender?.name}`}
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />

      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}>
        {isMetadataLoading ? (
          <CircularProgress size='24px' />
        ) : (
          reviews ? (
            <Box sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              overflowY: 'scroll',
              paddingRight: '32px',
            }}>
              {reviews.map((review, index) => (
                <Box key={review._id}>
                  <CommentRow
                    hideAvatar
                    name={review.reviewerName}
                    comment={review.content}
                    date={review.createdDt} />

                  {index < reviews.length - 1 &&
                    <Box sx={{
                      width: '100%',
                      height: '1px',
                      background: '#33333312',
                    }} />
                  }
                </Box>
              ))}
            </Box>
          ) : (
            <DialogContentText>
              {'No reviews for this lender'}
            </DialogContentText>
          )
        )}

        <Box sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: '16px',
        }}>
          <TextField
            InputProps={{
              sx: {
                borderRadius: '8px',
              }
            }}
            placeholder='Type review here'
            disabled={isAddReviewLoading}
            fullWidth
            multiline
            minRows={7}
            maxRows={18}
            value={review}
            onChange={e => setReview(e.target.value)} />

          <LoadingButton 
            sx={{
              flex: 1,
            }}
            variant='contained'
            disabled={review.length === 0}
            loading={isAddReviewLoading}
            onClick={addReviewClicked}>
            {'Add Review'}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
