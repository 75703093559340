export const log = (...args: any[]) => {
  if (
    process.env.REACT_APP_ENV !== 'production' ||
    process.env.REACT_APP_DEBUG === 'true'
  ) {
    console.log(...args.map((a) => a || ''));
  }
};

export const sleep = (millis) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), millis);
  });