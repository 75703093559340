import { Theme } from '@mui/material/styles';
import sharedStylesClasses from '../../theme/sharedStylesClasses';


const classes = (theme: Theme) => ({
  ...sharedStylesClasses(theme),
  dialog: {
    '.MuiPaper-root': {
      padding: 0,
      paddingTop: '8px',
      minWidth: '700px',
      // maxHeight: 'calc(100vh - 2 * 80px)',
    },
  },
  dialogTitle: {
    fontWeight: '500',
    fontSize: 20,
  },
  dialogSubtitle: {
    fontWeight: '400',
    fontSize: 14,
  },
  dialogContent: {
    padding: 0,
  },
  dialogContentBox: {
    width: '100%',
    paddingRight: 1,
    border: 1,
    borderRadius: '8px',
    borderColor: '#333333BF',
  },
  emailTextField: {
    flex: 1,
    "& fieldset": { border: 'none' },
  },
  inviteButtonSpinner: {
    position: 'absolute',
    alignSelf: 'center',
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  listItemTitle: {
    fontWeight: '500',
    fontSize: 16,
  },
  listItemSubtitle: {
    fontWeight: '400',
    fontSize: 12,
  },
  statusPill: {
    display: 'flex',
    padding: '2px',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: 4.0,
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F962694F',
  },
  statusPillText: {
    fontWeight: '400',
    fontSize: 12,
    color: 'rgba(31, 31, 31, 0.8)',
  },
  copyLinkText: {
    marginLeft: 2,
    fontWeight: '400',
    fontSize: 12,
  },
  resendInvitationText: {
    marginLeft: 2,
    fontWeight: '400',
    fontSize: 12,
  },
  removeAccessDiv: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  removeAccessText: {
    fontWeight: '400',
    fontSize: 12,
  },
  listItemDivider: {
    width: '100%',
  },
  dialogActions: {
    paddingLeft: 3,
    paddingRight: 3,
  }
});

export default classes;
