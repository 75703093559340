import React, { useMemo } from 'react';
import { DealQuoteTermField } from '../../types/DealQuoteTermFields';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';


interface Props {
  readonly field: DealQuoteTermField;
  readonly value?: string;
  readonly error?: string;
  readonly onValueChange?: (value: string) => void;
}

export default function SendDealQuoteField({field, value, error, onValueChange}: Props): JSX.Element {

  const inputType = useMemo(() => {
    return field.type === 'number' && field.formatting !== 'comma-separated' ? 'number' : 'text';
  }, [field]);

  switch (field.type) {
    case 'text':
    case 'number':
    case 'computed':
      return (
        <TextField
          sx={{
            width: '400px',
          }}
          InputProps={{
            style: {
              borderRadius: '8px',
            },
            readOnly: field.readonly,
          }}
          InputLabelProps={{
            shrink: Boolean(value),
          }}
          size='small'
          label={field.title}
          multiline={field.multiline}
          maxRows={6}
          value={value}
          error={error != null}
          helperText={error}
          type={inputType}
          required={field.required}
          onChange={e => onValueChange ? onValueChange(e.target.value) : null} />
      );
    case 'select':
      return (
        <FormControl size='small'>
          <InputLabel id={field.key}>{field.title}</InputLabel>
          <Select
            sx={{
              width: '400px',
              borderRadius: '8px',
            }}
            labelId={field.key}
            label={field.title}
            value={value}
            onChange={e => onValueChange ? onValueChange(e.target.value) : null}>
            {field.options?.map(option => (
              <MenuItem value={option}>
                {field.optionFormatter ? field.optionFormatter(option) : option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
  }
}
