import React from 'react';
import { Box, Popover, PopoverProps, Typography } from '@mui/material';
import { IDeal } from '../../../schemas';
import { useCREEDScore } from '../../../types/CREEDScoreFields';


interface Props extends PopoverProps {
  readonly deal?: IDeal;
}

export default function DealCREEDScorePopover({deal, ...popoverProps}: Props): JSX.Element {
  
  const {
    fields,
    averageScore,
  } = useCREEDScore(deal);

  return (
    <Popover {...popoverProps}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '300px',
        alignItems: 'center',
        padding: '20px',
      }}>
        <Typography sx={{
          fontWeight: '400',
          fontSize: '36px',
          paddingBottom: '24px',
        }}>
          {averageScore != null ? `${averageScore.toFixed(0)}/100` : 'TBD'}
        </Typography>

        <Typography sx={{
          fontWeight: '400',
          fontSize: '20px',
          lineHeight: '24px',
        }}>
          {'CREED Score'}
        </Typography>

        <Box sx={{
          width: '100%',
          paddingTop: '24px',
        }}>
          {fields.map(field => (
            <Box
              key={field.key}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '8px',
                paddingBottom: '8px',
              }}>
              <Typography sx={{
                fontWeight: '500',
                fontSize: '14px',
              }}>
                {field.title}
              </Typography>

              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
              }}>
                {field.value?.toFixed(0) ?? 'TBD'}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Popover>
  );
}
