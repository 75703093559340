import React, { PropsWithChildren } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import SearchField from '../types/SearchField';


type Props = PropsWithChildren<{
  readonly field: SearchField;
  readonly title?: string;
  readonly disabled: boolean;
  readonly error?: string;
  readonly dependingFieldTitles?: string;
}>;

export default function DealParameterFormControl({field, title, error, children}: Props): JSX.Element {
  return (
    <FormControl
      sx={{
        display: 'flex',
        width: '100%',
        margin: '0 auto',
      }}
      required={field.required}
      error={error != null}>
      {title != null &&
        <InputLabel id={field.id}>
          {title}
        </InputLabel>
      }
      {children}
    </FormControl>
  );
}
