import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageContainer from '../PageContainer';
import { Auth } from 'aws-amplify';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';
import LoadingButton from '../LoadingButton';


export default function RegisterComplete(): JSX.Element {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');
  const code = searchParams.get('code');

  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState(code ?? '');

  const verifyClicked = useCallback(async () => {
    if (!email) {
      return;
    }

    setLoading(true);

    try {
      await Auth.confirmSignUp(email.trim(), verificationCode.trim());
      navigate('/login');

      dispatch(snacked({
        message: 'Account activated',
        severity: 'success',
      }));
    } catch (error: any) {
      console.error(error);

      dispatch(snacked({
        message: error.message,
        severity: 'error',
      }));
    } finally {
      setLoading(false);
    }
  }, [email, verificationCode, dispatch, navigate, setLoading]);

  const resendClicked = useCallback(async () => {
    if (email == null) {
      return;
    }

    try {
      await Auth.resendSignUp(email.trim());
      dispatch(snacked({
        message: 'Email sent',
        severity: 'success',
      }));
    } catch (error: any) {
      dispatch(snacked({
        message: error.message,
        severity: 'error',
      }));
    }
  }, [email, dispatch]);

  return (
    <PageContainer>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingTop: '10vh',
        alignItems: 'center',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '400px',
          padding: '40px',
          paddingTop: '48px',
        }}>
          <Typography
            sx={{
              paddingBottom: '32px',
              textAlign: 'center',
            }}
            variant='h3'>
            {'First, let\'s verify your email'}
          </Typography>

          <Typography sx={{
            textAlign: 'center',
            fontWeight: '400',
            fontSize: 14,
            color: '#333333',
          }}>
            {email != null
              ? `Check ${email} to verify your account and get started.`
              : 'Check your email to verify your account and get started.'
            }
          </Typography>

          <Typography sx={{
            textAlign: 'center',
            fontWeight: '400',
            fontSize: 14,
            color: '#333333',
          }}>
            {'The email will come from support@creed.tech. Remember to check your spam.'}
          </Typography>

          <TextField
            sx={{
              marginTop: '48px',
              width: '100%',
            }}
            label={'Email activation code'}
            value={verificationCode}
            disabled={loading}
            onChange={(e) => setVerificationCode(e.target.value)} />

          <LoadingButton
            sx={{
              marginTop: '16px',
              width: '100%'
            }}
            variant='contained'
            loading={loading}
            disabled={!verificationCode}
            onClick={verifyClicked}>
            {'Activate my account'}
          </LoadingButton>

          {email &&
            <Button
              sx={{
                marginTop: '16px',
                width: '100%',
              }}
              onClick={resendClicked}>
              {'Resend email'}
            </Button>
          }
        </Box>
      </Box>
    </PageContainer>
  );
}
