import { Dialog, DialogProps } from "@mui/material";
import CREEDDialogTitle from "../../CREEDDialogTitle";
import { useState } from "react";
import { IDeal } from "../../../schemas";
import { WhiteGloveInfoContent } from "./WhiteGloveInfoContent";
import WhiteGloveCompleteContent from "./WhiteGloveCompleteContent";


interface Props extends DialogProps {
  deal?: IDeal;
}

export default function EnableWhiteGloveDialog({deal, ...dialogProps}: Props): JSX.Element {

  const [state, setState] = useState<'info' | 'complete'>('info');

  return (
    <Dialog
      fullWidth
      maxWidth={state === 'info' ? 'lg' : 'sm'}
      {...dialogProps}>
      <CREEDDialogTitle
        title={'Activate CREED\'s White Glove Service'}
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      {state === 'info' ? (
        <WhiteGloveInfoContent
          dialogProps={dialogProps}
          deal={deal}
          setState={setState} />
      ) : (
        <WhiteGloveCompleteContent
          dialogProps={dialogProps}
          deal={deal}
          setState={setState} />
      )}
    </Dialog>
  );
}
