import { useCallback, useEffect, useMemo, useState } from 'react';
import { DealLenderSource, IDeal, IDealContactedLender, IDealFavoritedLender, ILender } from '../../../schemas';
import { useAddLenderToLenderOutreachMutation, useAddNoContactLenderMutation, useFavoriteSearchResultMutation, useSearchLendersQuery } from '../../../features/deals-api';
import { Box, Avatar, Typography, IconButton, Tooltip, Button, Badge, Chip } from '@mui/material';
import { wrapColumnsInTooltips } from '../../../utils/componentUtils';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import LenderAutocomplete from './LenderAutocomplete';
import SponsorAddInfoLenderDialog from '../Dialogs/SponsorAddInfoLenderDialog';
import { usePopoverContext } from '../../../utils/hooks';
import { useAppDispatch } from '../../../app/hooks';
import { snacked } from '../../../features/snackMessage-slice';
import LenderReviewsDialog from '../Dialogs/LenderReviewsDialog';
import RemoveFavoriteLenderDialog from '../Dialogs/RemoveFavoriteLenderDialog';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import { useGetLenderMetadataForLendersQuery } from '../../../features/lenders-api';
import { DataGridPro, GridActionsCellItem, GridColDef, GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useGetCurrentAccountQuery } from '../../../features/accounts-api';
import { generateCSVString, downloadStringAsFile } from '../../../utils/utils';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


interface FavoritesToolbarProps {
  lenders?: IDealFavoritedLender[];
  contactedLenders?: IDealContactedLender[];
  refLenders?: ILender[];
}

function FavoritesToolbar({lenders, contactedLenders, refLenders}: FavoritesToolbarProps) {
  
  const downloadCSVClicked = useCallback(() => {
    if (lenders == null) {
      return;
    }

    const csvString = generateCSVString({
      columns: [
        {key: 'lenderName', name: 'Lender Name'},
        {key: 'loanType', name: 'Loan Type'},
        {key: 'lendingInstitution', name: 'Lending Institution'},
        {key: 'lenderContacted', name: 'Lender Contacted'},
      ],
      rows: lenders.map(lender => {
        const refLender = refLenders?.find(l => l.Lender === lender.name);

        return {
          lenderName: lender.name,
          loanType: refLender != null ? refLender['Loan Type'] : undefined,
          lendingInstitution: refLender != null ? refLender['Lending Institution'] : undefined,
          lenderContacted: contactedLenders?.some(l => l.name === lender.name) ?? false,
        };
      })
    });

    downloadStringAsFile(csvString, 'favorites.csv', 'text/csv');
  }, [contactedLenders, lenders, refLenders]);
  
  return (
    <GridToolbarContainer>
      <Button
        sx={{
          gap: 1,
        }}
        size='small'
        onClick={downloadCSVClicked}>
        <FileDownloadOutlinedIcon sx={{fontSize: '18px'}} />
        {'Download as CSV'}
      </Button>
    </GridToolbarContainer>
  );
}

interface Props {
  readonly deal?: IDeal;
}

export default function LenderSearchFavoritesInfo({deal}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const {
    props: lenderReviewsDialogProps,
    showDialog: showLenderReviews,
    currentData: lenderReviewLender,
  } = usePopoverContext<IDealFavoritedLender>();

  const {
    props: lenderInfoDialogProps,
    showDialog: showLenderInfo,
    currentData: lenderInfoLender,
  } = usePopoverContext<IDealFavoritedLender>();

  const {
    props: removeFavoriteLenderDialogProps,
    showDialog: showRemoveFavoriteLender,
    currentData: removeFavoriteLenderName,
  } = usePopoverContext<string>();

  const [favoriteLenderName, setFavoriteLenderName] = useState('');
  const [noContactLenderName, setNoContactLenderName] = useState('');
  const [unclickableLenders, setUnclickableLenders] = useState<string[]>([]);

  const {data: account} = useGetCurrentAccountQuery();
  const {data: favoritedLenders, isLoading: isLoadingFavoritedLenders} = useSearchLendersQuery({ dealId: deal?._id!, filter: 'favorites' }, { skip: deal?._id == null });
  const {data: noContactLenders, isLoading: isNoContactLendersLoading} = useSearchLendersQuery({ dealId: deal?._id!, filter: 'no-contact' }, { skip: deal?._id == null });
  const {data: lenderMetadata} = useGetLenderMetadataForLendersQuery({ lenderNames: deal?.favoriteLenders?.map(l => l.name)! }, { skip: deal?.favoriteLenders == null });

  const [addFavoriteLender] = useFavoriteSearchResultMutation();
  const [addNoContactLender, { error: addNoContactLenderError }] = useAddNoContactLenderMutation();
  const [addLenderToLenderOutreach] = useAddLenderToLenderOutreachMutation();

  const addFavoriteLenderClicked = useCallback(async () => {
    if (deal?._id == null || !Boolean(favoriteLenderName)) {
      return;
    }

    addFavoriteLender({
      dealId: deal._id,
      isFavorite: true,
      lenderName: favoriteLenderName,
    });

    dispatch(snacked({
      message: `${favoriteLenderName} added to Contact List`,
      severity: 'success',
    }));

    setFavoriteLenderName('');
  }, [addFavoriteLender, deal?._id, dispatch, favoriteLenderName]);

  const addNoContactLenderClicked = useCallback(async () => {
    if (deal?._id == null || !Boolean(noContactLenderName)) {
      return;
    }

    const fl = deal.favoriteLenders?.find(l => l.name === noContactLenderName);
    if (fl != null) {
      showRemoveFavoriteLender(fl.name);
    } else {
      addNoContactLender({
        dealId: deal._id,
        lenderName: noContactLenderName,
        action: 'add',
      });

      dispatch(snacked({
        message: `${noContactLenderName} added to Do Not Contact List`,
        severity: 'success',
      }));
    }

    setNoContactLenderName('');
  }, [addNoContactLender, deal?._id, deal?.favoriteLenders, dispatch, noContactLenderName, showRemoveFavoriteLender]);

  const unfavoriteLenderClicked = useCallback((lender: IDealFavoritedLender) => {
    if (deal?._id == null) {
      return;
    }
    
    addFavoriteLender({
      dealId: deal._id,
      lenderName: lender.name,
      isFavorite: false,
    });
  }, [addFavoriteLender, deal?._id]);

  const removeDoNotContactLenderClicked = useCallback(async (lender: IDealFavoritedLender) => {
    if (deal?._id == null) {
      return;
    }
    
    addNoContactLender({
      dealId: deal._id,
      lenderName: lender.name,
      action: 'remove',
    });
  }, [addNoContactLender, deal?._id]);

  const addToLenderOutreachClicked = useCallback(async (lender: IDealFavoritedLender) => {
    if (deal?._id == null) {
      return;
    }
    
    try {
      setUnclickableLenders(p => [...p, lender.name]);

      await addLenderToLenderOutreach({
        dealId: deal._id,
        lenderNames: [lender.name],
      }).unwrap();

      dispatch(snacked({
        message: `${lender.name} added to Lender Outreach`,
        severity: 'success',
      }));
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed adding lender to Lender Outreach',
        severity: 'error',
      }));
      
      setUnclickableLenders(p => p.filter(l => l === lender.name));
    }
  }, [addLenderToLenderOutreach, deal?._id, dispatch]);

  const contactColumns: GridColDef<IDealFavoritedLender>[] = useMemo(() => {
    const columns: (GridColDef<IDealFavoritedLender> | null)[] = [
      {
        field: 'source',
        headerName: 'Source',
        width: 50,
        valueFormatter: (params) => (params.value as string)?.toUpperCase() ?? 'CA',
        renderCell: (params) => {
          let title = '';
          switch (params.value as DealLenderSource) {
            case 'ca':
              title = '(CREED Add) This lender was added by CREED';
              break;
            case 'cia':
              title = '(CREED Individual Add) This lender was added by CREED and it did not already exist in CREED\'s system';
              break;
            case 'sa':
              title = '(Sponsor Add) This lender was added by you';
              break;
            case 'sia':
              title = '(Sponsor Individual Add) This lender was was added by you and it did not already exist in CREED\'s system';
              break;
          }
          
          return (
            <Tooltip
              title={title}
              enterDelay={1000}>
              <div style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
                {params.formattedValue}
              </div>
            </Tooltip>
          );
        },
      },
      {
        field: 'logo',
        headerName: '',
        width: 56,
        valueGetter: (params) => favoritedLenders?.find(l => l.Lender === params.row.name)?.LogoUrl,
        renderCell: (params) => (
          <Avatar
            sx={{
              width: '36px',
              height: '36px',
            }}
            variant='rounded'
            src={params.value} />
        )
      },
      {
        field: 'name',
        headerName: 'Lender Name',
        flex: 1,
      },
      {
        field: 'Loan Type',
        headerName: 'Loan Type',
        valueGetter: (params) => {
          const lender = favoritedLenders?.find(l => l.Lender === params.row.name);
          if (lender != null) {
            return lender['Loan Type'];
          }

          return undefined;
        },
        valueFormatter: (params) => params.value != null ? params.value : '-',
        width: 130,
      },
      {
        field: 'Lending Institution',
        headerName: 'Lending Institution',
        width: 90,
        valueGetter: (params) => {
          const lender = favoritedLenders?.find(l => l.Lender === params.row.name);
          if (lender != null) {
            return lender['Lending Institution'];
          }

          return undefined;
        },
        valueFormatter: (params) => params.value != null ? params.value : '-',
      },
      {
        field: 'info',
        headerName: 'Ratings / Reviews',
        width: 70,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
          const source = params.row.source;

          if (source !== 'ca' && source !== 'sa') {
            return null;
          }
          
          return (
            <Badge
              invisible={!Boolean(lenderMetadata?.find(m => m.lenderName === params.row.name)?.reviews)}
              color='warning'
              overlap='circular'
              variant='dot'>
              <IconButton
                disabled={source !== 'ca' && source !== 'sa'}
                onClick={() => showLenderReviews(params.row)}>
                <NotesRoundedIcon
                  sx={{
                    color: 'inherit',
                  }}
                  fontSize='small' />
              </IconButton>
            </Badge>
          );
        }
      },
      {
        field: 'contacted',
        headerName: 'Lender Contacted',
        valueGetter: (params) => deal?.contactedLenders?.some(l => l.name === params.row.name) ?? false,
        renderCell: (params) => {
          const contacted = deal?.contactedLenders?.some(l => l.name === params.row.name) ?? false;
          if (!contacted) {
            return (<></>);
          }

          return (
            <Chip
              color={contacted ? 'success' : 'info'}
              label={contacted ? 'Yes' : 'No'} />
          );
        }
      },
      deal?.whiteGloveStatus !== 'enabled' || (deal?.whiteGloveStatus === 'enabled' && account?.isAdmin) ? {
        field: 'lenderOutreach',
        headerName: 'Lender Outreach',
        width: 70,
        renderCell: (params) => (
          <Tooltip
            title='Add this lender to the Lender Outreach tab'
            enterDelay={1000}>
            <IconButton
              disabled={unclickableLenders.some(name => params.row.name === name) || deal?.contactedLenders?.some(l => l.name === params.row.name)}
              onClick={() => addToLenderOutreachClicked(params.row)}>
              <ArrowRightAltRoundedIcon
                sx={{
                  color: 'inherit',
                }}
                fontSize='small' />
            </IconButton>
          </Tooltip>
        )
      } : null,
      {
        field: 'actions',
        type: 'actions',
        width: 30,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<StarBorderRoundedIcon />}
            label='Un-favorite lender'
            onClick={() => unfavoriteLenderClicked(params.row)}
            showInMenu />
        ]
      }
    ];

    return wrapColumnsInTooltips(columns.filter(c => c != null) as GridColDef<IDealFavoritedLender>[]);
  }, [deal?.whiteGloveStatus, deal?.contactedLenders, account?.isAdmin, favoritedLenders, lenderMetadata, showLenderReviews, unclickableLenders, addToLenderOutreachClicked, unfavoriteLenderClicked]);

  const noContactColumns: GridColDef<IDealFavoritedLender>[] = useMemo(() => {
    return wrapColumnsInTooltips([
      {
        field: 'LogoUrl',
        headerName: '',
        width: 56,
        valueGetter: (params) => noContactLenders?.find(l => l.Lender === params.row.name)?.LogoUrl,
        renderCell: (params) => (
          <Avatar
            sx={{
              width: '36px',
              height: '36px',
            }}
            variant='rounded'
            src={params.value} />
        )
      },
      {
        field: 'name',
        headerName: 'Lender Name',
        flex: 1,
      },
      {
        field: 'info',
        headerName: 'Additional Information',
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <IconButton onClick={() => showLenderInfo(params.row)}>
            <NotesRoundedIcon
              sx={{
                color: 'inherit',
              }}
              fontSize='small' />
          </IconButton>
        )
      },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteRoundedIcon />}
            label='Remove lender'
            onClick={() => removeDoNotContactLenderClicked(params.row)}
            showInMenu />
        ]
      }
    ]);
  }, [noContactLenders, removeDoNotContactLenderClicked, showLenderInfo]);

  useEffect(() => {
    if (addNoContactLenderError != null) {
      console.error(addNoContactLenderError);
      dispatch(snacked({
        message: 'Network error, please try again',
        severity: 'error',
      }));
    }
  }, [addNoContactLenderError, dispatch]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: '32px',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 3,
        overflow: 'hidden',
        gap: '16px',
      }}>
        <Typography sx={{
          fontWeight: '400',
          fontSize: '22px',
        }}>
          {'Contact List'}
        </Typography>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
        }}>
          <LenderAutocomplete
            sx={{
              flex: 1,
              minWidth: '250px',
              maxWidth: '350px',
            }}
            value={favoriteLenderName}
            onChange={setFavoriteLenderName}
            choiceFilter={lender => !deal?.favoriteLenders?.some(l => l.name === lender.Lender) && !deal?.noContactLenders?.some(l => l.name === lender.Lender)} />

          <Button
            variant='contained'
            disabled={!Boolean(favoriteLenderName)}
            onClick={addFavoriteLenderClicked}>
            {'Add Lender'}
          </Button>
        </Box>

        <DataGridPro
          sx={{    
            fontWeight: '400',
            fontSize: '12px',
            '& .MuiDataGrid-columnHeaderTitle': {
              whiteSpace: 'normal',
              lineHeight: 'normal',
            },
            '& .MuiDataGrid-columnHeader': {
              // Forced to use important since overriding inline styles
              height: 'unset !important'
            },
            '& .MuiDataGrid-columnHeaders': {
              // Forced to use important since overriding inline styles
              maxHeight: '168px !important'
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            }
          }}
          columns={contactColumns}
          rows={deal?.favoriteLenders ?? []}
          getRowId={r => r._id}
          columnHeaderHeight={50}
          autoHeight
          disableColumnSelector
          disableColumnReorder
          hideFooter={(favoritedLenders ?? []).length <= 100}
          loading={isLoadingFavoritedLenders}
          isRowSelectable={() => false}
          slotProps={{
            toolbar: {
              lenders: deal?.favoriteLenders,
              contactedLenders: deal?.contactedLenders,
              refLenders: favoritedLenders,
            }
          }}
          slots={{
            toolbar: FavoritesToolbar,
            noRowsOverlay: () => (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '22px',
              }}>
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '12px',
                }}>
                  {'No lenders have been favorited.'}
                </Typography>
              </Box>
            ),
          }} />
      </Box>

      {deal?.whiteGloveStatus === 'enabled' &&
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 2,
          overflow: 'hidden',
          gap: '16px',
        }}>
          <Typography sx={{
            fontWeight: '400',
            fontSize: '22px',
          }}>
            {'Do Not Contact List'}
          </Typography>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
          }}>
            <LenderAutocomplete
              sx={{
                flex: 1,
                minWidth: '250px',
                maxWidth: '350px',
              }}
              value={noContactLenderName}
              onChange={setNoContactLenderName}
              choiceFilter={lender => !deal?.noContactLenders?.some(l => l.name === lender.Lender)} />

            <Button
              variant='contained'
              disabled={!Boolean(noContactLenderName)}
              onClick={addNoContactLenderClicked}>
              {'Add Lender'}
            </Button>
          </Box>

          <DataGridPro
            sx={{
              fontWeight: '400',
              fontSize: '12px',
              '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'normal',
                lineHeight: 'normal',
              },
              '& .MuiDataGrid-columnHeader': {
                // Forced to use important since overriding inline styles
                height: 'unset !important'
              },
              '& .MuiDataGrid-columnHeaders': {
                // Forced to use important since overriding inline styles
                maxHeight: '168px !important'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
              }
            }}
            columns={noContactColumns}
            rows={deal?.noContactLenders ?? []}
            getRowId={r => r._id}
            columnHeaderHeight={50}
            autoHeight
            disableColumnSelector
            disableColumnReorder
            hideFooter={(noContactLenders ?? []).length <= 100}
            isRowSelectable={r => false}
            loading={isNoContactLendersLoading}
            slots={{
              noRowsOverlay: () => (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingTop: '22px',
                }}>
                  <Typography sx={{
                    fontWeight: '400',
                    fontSize: '12px',
                  }}>
                    {'No lenders added.'}
                  </Typography>
                </Box>
              ),
            }} />
        </Box>
      }

      <LenderReviewsDialog
        {...lenderReviewsDialogProps}
        lender={lenderReviewLender} />

      <SponsorAddInfoLenderDialog
        {...lenderInfoDialogProps}
        deal={deal}
        lender={lenderInfoLender} />

      <RemoveFavoriteLenderDialog
        {...removeFavoriteLenderDialogProps}
        deal={deal}
        lenderName={removeFavoriteLenderName} />
    </Box>
  );
}
