import PageContainer from '../PageContainer';
import { Avatar, Box, Button, Divider, Typography } from '@mui/material';
import { useGetCurrentUserQuery } from '../../features/accounts-api';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { usePopoverContext } from '../../utils/hooks';
import DeleteAccountDialog from '../Dialogs/DeleteAccountDialog';


export default function UserProfile(): JSX.Element {

  const {data: user} = useGetCurrentUserQuery();

  const {
    props: deleteAccountDialogProps,
    showDialog: showDeleteAccountDialog,
  } = usePopoverContext();

  return (
    <PageContainer>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingTop: 6,
        paddingBottom: 6,
        alignItems: 'center',
        rowGap: '30px',
      }}>
        <Avatar
          sx={{
            width: '156px',
            height: '156px',
            background: '#3333330A',
            color: '#3333334D',
          }} />

        <Typography
          variant='h3'>
            {`${user?.firstName} ${user?.lastName}`}
          </Typography>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '30px',
            rowGap: '22px',
          }}>
            <Box sx={{
              alignSelf: 'flex-start',
              paddingLeft: '14px',
              paddingRight: '14px',
              paddingTop: '15.5px',
              paddingBottom: '15.5px',
              width: '400px',
              borderRadius: '8px',
              background: '#3333330A',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.12px',
              }}>
                {user?.firstName}
              </Typography>
            </Box>

            <Box sx={{
              alignSelf: 'flex-start',
              paddingLeft: '14px',
              paddingRight: '14px',
              paddingTop: '15.5px',
              paddingBottom: '15.5px',
              width: '400px',
              borderRadius: '8px',
              background: '#3333330A',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.12px',
              }}>
                {user?.lastName}
              </Typography>
            </Box>

            <Box sx={{
              alignSelf: 'flex-start',
              paddingLeft: '14px',
              paddingRight: '14px',
              paddingTop: '15.5px',
              paddingBottom: '15.5px',
              width: '400px',
              borderRadius: '8px',
              background: '#3333330A',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '17.12px',
              }}>
                {user?.email}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{
            width: '400px',
          }} />

          <Button
            sx={{
              width: '400px',
              gap: '8px',
            }}
            variant='outlined'
            onClick={() => showDeleteAccountDialog()}>
            <WarningRoundedIcon fontSize='inherit' color='inherit' />
            {'Delete My Account'}
          </Button>
      </Box>

      <DeleteAccountDialog
        {...deleteAccountDialogProps} />
    </PageContainer>
  );
}
