import { useState } from "react";
import { IconButton, InputAdornment, TextField, TextFieldProps, Tooltip } from "@mui/material";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';


type Props = {
  
} & TextFieldProps;

export default function PasswordField({...textFieldProps}: Props): JSX.Element {

  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <TextField
      {...textFieldProps}
      type={passwordVisible ? 'text' : 'password'}
      InputProps={{
        sx: {
          borderRadius: '8px',
        },
        endAdornment: textFieldProps.value ? (
          <InputAdornment position='end'>
            <Tooltip
              title={passwordVisible ? 'Hide password' : 'Show password'}
              placement='right'
              enterDelay={500}
              arrow>
              <IconButton
                edge='end'
                size='small'
                onClick={() => setPasswordVisible(!passwordVisible)}>
                {passwordVisible ? <VisibilityOffRoundedIcon fontSize='inherit' /> : <VisibilityRoundedIcon fontSize='inherit' />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ) : undefined,
      }} />
  );
}
