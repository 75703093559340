import React, { PropsWithChildren, forwardRef } from 'react';
import { Box, SxProps, Theme } from '@mui/material';


interface Props {
  readonly sx?: SxProps<Theme>;
  readonly onClick?: () => void;
}

const Card = forwardRef<HTMLElement, PropsWithChildren<Props>>(({sx, onClick, children}, ref): JSX.Element => {
  return (
    <Box
      ref={ref}
      sx={{
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingTop: '24px',
        paddingBottom: '24px',
        borderRadius: '16px',
        boxShadow: '0px 0px 12px rgba(51, 51, 51, 0.04), 0px 0px 12px rgba(51, 51, 51, 0.04), inset -4px 4px 4px rgba(255, 255, 255, 0.35)',
        background: 'linear-gradient(249.96deg, rgba(255, 255, 255, 0.8) 18.86%, rgba(255, 255, 255, 0) 62.55%), rgba(255, 255, 255, 0.5)',
        ...sx ?? {},
      }}
      onClick={onClick}>
      {children}
    </Box>
  );
});

export default Card;
