import { Chip, ChipProps, SxProps, Theme } from '@mui/material';
import React, { useMemo } from 'react';


interface Props {
  readonly sx?: SxProps<Theme>;
  readonly status: 'accepted' | 'rejected';
}

export default function InvestorStatusChip({sx, status}: Props): JSX.Element {

  const chipProps: ChipProps = useMemo(() => {
    switch (status) {
      case 'accepted':
        return {
          label: 'Interested',
          color: 'success',
          sx: {
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '15.9px',
            ...sx ?? {},
          },
        };
      case 'rejected':
        return {
          label: 'Passed',
          color: 'error',
          sx: {
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '15.9px',
            ...sx ?? {},
          }
        };
    }
  }, [status, sx]);

  return (
    <Chip {...chipProps} />
  );
}
