import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import LoadingButton from './LoadingButton';


interface Props {
  loading?: boolean;
  disabled?: boolean;
  footer?: ReactNode;
  onFilesDropped: (files: File[]) => Promise<any>;
}

export default function FileUploadBox({loading, disabled, footer, onFilesDropped}: Props): JSX.Element {

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop: onFilesDropped,
  });

  return (
    <Box component="div" {...getRootProps()} 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 4,
        paddingBottom: footer != null ? 1 : 3,
        paddingLeft: '16px',
        paddingRight: '16px',
        rowGap: '12px',
        backgroundColor: '#3333330D',
        borderRadius: '8px',
      }}>
      {!loading && !disabled &&
        <Box component='input' {...getInputProps()} />
      }

      <LoadingButton
        variant='contained'
        color='secondary'
        loading={loading}
        disabled={disabled}>
        <FileUploadOutlinedIcon fontSize='small' />
        {'Upload new files'}
      </LoadingButton>

      <Typography sx={{
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#33333380',
      }}>
        {isDragActive
          ? 'Drop the files here ...'
          : 'Drag & drop one or multiple files in this zone. Files can be up to 5GB.'
        }
      </Typography>

      {footer &&
        <Box sx={{
          display: 'flex',
          width: '100%',
          paddingTop: 1,
          fontWeight: '400',
          fontSize: '12px',
          color: '#33333380',
        }}>
          {footer}
        </Box>
      }
    </Box>
  );
}
