import { useCallback, useEffect, useState } from "react";
import { Box, Typography, Tooltip, IconButton, Button } from "@mui/material";
import Card from "../../Card";
import DealDiligenceMilestoneRow from "./DealDiligenceMilestoneRow";
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { IDeal, IDealDiligence } from "../../../schemas";
import { useGetCurrentAccountQuery } from "../../../features/accounts-api";
import { useUpdateDiligenceMutation } from "../../../features/deals-api";
import LoadingButton from "../../LoadingButton";
import * as Sentry from '@sentry/react';
import { useAppDispatch } from "../../../app/hooks";
import { snacked } from "../../../features/snackMessage-slice";


interface Props {
  deal?: IDeal;
}

export default function DiligenceMilestonesCard({deal}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [editing, setEditing] = useState(false);
  const [diligence, setDiligence] = useState<Partial<IDealDiligence>>({});

  const {data: account} = useGetCurrentAccountQuery();

  const [updateDiligence, { isLoading: isUpdateDiligenceLoading }] = useUpdateDiligenceMutation();

  const canEditDiligence = account?.isAdmin || (deal?.whiteGloveStatus !== 'enabled' && (account?.type === 'sponsor' || account?.type === 'broker'));

  const cancelClicked = useCallback(() => {
    setEditing(false);
    setDiligence(deal?.diligence ?? {});
  }, [deal?.diligence]);

  const fieldChanged = useCallback((key: keyof IDealDiligence, date?: Date) => {
    setDiligence({
      ...diligence,
      [key]: date?.toISOString() ?? null,
    });
  }, [diligence]);

  const saveClicked = useCallback(async () => {
    if (deal?._id == null) {
      return;
    }

    try {
      await updateDiligence({
        dealId: deal._id,
        diligence: {
          lenderKickoffCompletedDt: diligence.lenderKickoffCompletedDt,
          appraiserEngagedDt: diligence.appraiserEngagedDt,
          attorneysEngagedDt: diligence.attorneysEngagedDt,
          insuranceBrokersEngagedDt: diligence.insuranceBrokersEngagedDt,
          titleCompanyEngagedDt: diligence.titleCompanyEngagedDt,
          siteVisitCompleteDt: diligence.siteVisitCompleteDt,
        }
      }).unwrap();

      dispatch(snacked({
        message: 'Saved diligence milestones',
        severity: 'success',
      }));

      setEditing(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed saving diligence milestones',
        severity: 'error',
      }));
    }
  }, [deal?._id, diligence.appraiserEngagedDt, diligence.attorneysEngagedDt, diligence.insuranceBrokersEngagedDt, diligence.lenderKickoffCompletedDt, diligence.siteVisitCompleteDt, diligence.titleCompanyEngagedDt, dispatch, updateDiligence]);

  useEffect(() => {
    if (!editing) {
      setDiligence(deal?.diligence ?? {});
    }
  }, [editing, deal]);

  return (
    <Card sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '16px',
        }}>
          {'Diligence Milestones'}
        </Typography>
        {canEditDiligence && !editing &&
          <Tooltip
            title='Edit diligence milestones'
            enterDelay={500}>
            <IconButton
              size='small'
              disabled={editing}
              onClick={() => setEditing(true)}>
              <ModeEditRoundedIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        }
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}>
        <DealDiligenceMilestoneRow
          title='Lender Kickoff Completed'
          value={diligence?.lenderKickoffCompletedDt != null}
          date={diligence?.lenderKickoffCompletedDt}
          editing={editing}
          onChange={d => fieldChanged('lenderKickoffCompletedDt', d)} />
        <DealDiligenceMilestoneRow
          title='Appraiser Engaged'
          value={diligence?.appraiserEngagedDt != null}
          date={diligence?.appraiserEngagedDt}
          editing={editing}
          onChange={d => fieldChanged('appraiserEngagedDt', d)} />
        <DealDiligenceMilestoneRow
          title='Attorneys Engaged'
          value={diligence?.attorneysEngagedDt != null}
          date={diligence?.attorneysEngagedDt}
          editing={editing}
          onChange={d => fieldChanged('attorneysEngagedDt', d)} />
        <DealDiligenceMilestoneRow
          title='Insurance Brokers Engaged'
          value={diligence?.insuranceBrokersEngagedDt != null}
          date={diligence?.insuranceBrokersEngagedDt}
          editing={editing}
          onChange={d => fieldChanged('insuranceBrokersEngagedDt', d)} />
        <DealDiligenceMilestoneRow
          title='Title Company Engaged'
          value={diligence?.titleCompanyEngagedDt != null}
          date={diligence?.titleCompanyEngagedDt}
          editing={editing}
          onChange={d => fieldChanged('titleCompanyEngagedDt', d)} />
        <DealDiligenceMilestoneRow
          title='Site Visit Completed'
          value={diligence?.siteVisitCompleteDt != null}
          date={diligence?.siteVisitCompleteDt}
          editing={editing}
          onChange={d => fieldChanged('siteVisitCompleteDt', d)} />
        {editing &&
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 2,
            gap: 1,
          }}>
            <Button
              sx={{
                flex: 1,
              }}
              onClick={cancelClicked}>
              {'Cancel'}
            </Button>
            <LoadingButton
              sx={{
                flex: 1,
              }}
              variant='contained'
              loading={isUpdateDiligenceLoading}
              onClick={saveClicked}>
              {'Save Changes'}
            </LoadingButton>
          </Box>
        }
      </Box>
    </Card>
  );
}
