import { PropsWithChildren } from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import dayjs from 'dayjs';


type Props = PropsWithChildren<{
  avatarImageUrl?: string;
  hideAvatar?: boolean;
  avatarSize?: 'small' | 'large';
  name: string;
  comment?: string;
  date: string;
}>;

export default function CommentRow({avatarImageUrl, hideAvatar, avatarSize = 'large', name, comment, date, children}: Props): JSX.Element {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      paddingTop: '18px',
      paddingBottom: '18px',
    }}>
      {!hideAvatar &&
        <Avatar
          sx={{
            width: avatarSize === 'large' ? '48px' : '32px',
            height: avatarSize === 'large' ? '48px' : '32px',
          }}
          src={avatarImageUrl} />
      }

      <Box sx={{
        flex: 1,
      }}>
        <Typography sx={{
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '19.57px',
          color: '#000000',
        }}>
          {name}
        </Typography>
        {comment != null &&
          <Typography sx={{
            whiteSpace: 'pre-wrap',
            marginTop: '8px',
            ontWeight: '400',
            fontSize: '16px',
            lineHeight: '19.57px',
            color: '#333333BF',
          }}>
            {comment}
          </Typography>
        }
        <Typography sx={{
          marginTop: '12px',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '17px',
          color: '#33333380',
        }}>
          {dayjs(date).format('MMM D, YYYY [at] h:mm A')}
        </Typography>

        {children}
      </Box>
    </Box>
  );
}
