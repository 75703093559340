import { Theme } from '@mui/material/styles';
import sharedStylesClasses from "../../theme/sharedStylesClasses";


const classes = (theme: Theme) => ({
  ...sharedStylesClasses(theme),
  appBarSpacer: theme.mixins.toolbar,
  root: {
    width: '100%',
  },
  parentBox: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    minWidth: '100%',
    alignItems: 'center',
  },
  childBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(4),
    width: '80vw',
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
  },
  loadingIndicatorBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
});

export default classes;