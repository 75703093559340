import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useLoanFeeStructure } from "../../utils/hooks";


export default function LoanFeeStructureTable(): JSX.Element {

  const { tiers } = useLoanFeeStructure();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }}>
      <Typography sx={{
        fontSize: '14px',
        padding: '8px',
        borderRadius: '8px',
        background: '#33333311',
      }}>
        {'CREED Loan Fee Structure'}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{'Tier'}</TableCell>
              <TableCell>{'Financing Amount Range'}</TableCell>
              <TableCell>{'Fee Amount'}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tiers.map(tier => (
              <TableRow key={tier.name}>
                <TableCell>{tier.name}</TableCell>
                <TableCell>{tier.range}</TableCell>
                <TableCell>{tier.feeAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
