import React from 'react';
import { useGetNotificationsQuery } from '../features/notifications-api';
import { Avatar, Box, List, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import { INotification } from '../schemas';


interface Props {
  readonly notificationClicked?: (notification: INotification) => void;
}

export default function NotificationsList(props: Props): JSX.Element {

  const {data: notifications} = useGetNotificationsQuery();

  return (
    <List>
      {notifications?.map(notification => (
        <ListItemButton
          key={typeof notification._id === 'string' ? notification._id : notification.notificationId}
          sx={{
            paddingRight: !notification.isRead ? '24px' : undefined,
          }}
          onClick={() => props.notificationClicked != null ? props.notificationClicked(notification) : null}>
          <ListItemAvatar>
            <Avatar alt={notification.senderAccount?.name} src={notification.senderAccount?.profileImageUrl} />
          </ListItemAvatar>
          <ListItemText
            primary={(
              <Typography sx={{
                fontSize: '0.875rem',
              }}>
                {notification.title}
                {notification.type === 'deal' ? ' shared a deal with you' : undefined}
                {notification.type === 'deal_marketplace' ? ' added a new deal to the equity marketplace' : undefined}
              </Typography>
            )}
            secondary={(
              <Typography sx={{
                fontSize: '0.875rem',
                color: 'gray'
              }}>
                {notification.preview}
              </Typography>
            )} />

          {!notification.isRead &&
            <Box sx={{
              position: 'absolute',
              top: 12,
              right: 12,
              width: '8px',
              height: '8px',
              borderRadius: '4px',
              background: '#F96269',
            }} />
          }
        </ListItemButton>
      ))}
    </List>
  );
}
