import { useCallback, useMemo } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import CREEDDialogTitle from '../../../CREEDDialogTitle';
import LoadingButton from '../../../LoadingButton';
import { useGetEquityDealReviewForDealQuery, usePublishEquityDealMutation } from '../../../../features/deals-api';
import { IDeal } from '../../../../schemas';
import { useAppDispatch } from '../../../../app/hooks';
import { snacked } from '../../../../features/snackMessage-slice';
import EquityFeeStructureTable from '../../../Dialogs/EquityFeeStructureTable';
import AnticipatedEquityFeeAmountTable from '../../../Dialogs/AnticipatedEquityFeeAmountTable';
import { useGetCurrentAccountQuery } from '../../../../features/accounts-api';


interface Props extends DialogProps {
  readonly deal?: IDeal;
}

export default function SponsorPublishEquityDealDialog({deal, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const {data: account} = useGetCurrentAccountQuery();
  const {data: dealReview} = useGetEquityDealReviewForDealQuery({ dealId: deal?._id! }, { skip: deal?._id == null });
  
  const [publishDeal, { isLoading: isPublishDealLoading }] = usePublishEquityDealMutation();

  const mode = useMemo(() => {
    switch (dealReview?.status) {
      case 'approved':
        return 'approved';
      default:
        return 'agree-fee';
    }
  }, [dealReview?.status]);

  const publishClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (deal == null) {
      return;
    }

    try {
      await publishDeal({
        dealId: deal._id!,
        published: true,
      }).unwrap();

      if (dialogProps.onClose != null) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed publishing project.',
        severity: 'error',
      }));
    }
  }, [deal, publishDeal, dialogProps, dispatch]);

  return (
    <Dialog
      fullWidth
      maxWidth={mode === 'agree-fee' ? 'lg' : 'sm'}
      {...dialogProps}>
      <CREEDDialogTitle
        title={mode === 'agree-fee' ? 'Anticipated Fee Structure' : 'Publish Project'}
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}>
        {mode === 'agree-fee' ? (
          <>
            <DialogContentText>
              {'We are excited to help you with your project.'}
            </DialogContentText>
            <DialogContentText>
              {'To the extent an investor responds, CREED will have 6-month exclusivity with that investor for this deal.'}
            </DialogContentText>
            <DialogContentText>
              {'You will only be charged a fee on a success basis at closing. The estimated success fee for this project would be as follows:'}
            </DialogContentText>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '32px',
              paddingTop: '16px',
              paddingBlock: '16px',
            }}>
              <EquityFeeStructureTable feeStructure={account?.equityFeeStructure} />
              
              <AnticipatedEquityFeeAmountTable
                amount={deal?.dealInfo?.loanAmount}
                feeStructure={account?.equityFeeStructure} />
            </Box>
            </>
          ) : (
            <DialogContentText>
              {'Your project will be visible to investors on the Equity Marketplace.'}
            </DialogContentText>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={isPublishDealLoading}
          onClick={publishClicked}>
          {mode === 'agree-fee' ? 'Agree and Publish' : 'Publish'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
