import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from "@mui/material";
import { IDeal } from "../../../schemas";
import CREEDDialogTitle from "../../CREEDDialogTitle";
import LoadingButton from "../../LoadingButton";
import { useUpdateDealMutation } from "../../../features/deals-api";
import { useCallback } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { snacked } from "../../../features/snackMessage-slice";


interface Props extends DialogProps {
  deal?: IDeal;
}

export default function CREEDFeedbackDialog({deal, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [updateDeal, { isLoading: isUpdateDealLoading }] = useUpdateDealMutation();

  const requestFeedbackClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (deal?._id == null) {
      return;
    }
    
    try {
      await updateDeal({
        dealId: deal._id,
        feedbackStatus: 'requested',
      }).unwrap();

      dispatch(snacked({
        message: 'Feedback requested for this project',
        severity: 'success',
      }));

      if (dialogProps.onClose != null) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed requesting feedback',
        severity: 'error',
      }));
    }
  }, [deal?._id, dialogProps, dispatch, updateDeal]);

  return (
    <Dialog {...dialogProps}>
      <CREEDDialogTitle
        title='CREED Feedback'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent>
        <DialogContentText>
          {'CREED will review the project and give you preliminary feedback in 24-48 hours.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={isUpdateDealLoading}
          onClick={requestFeedbackClicked}>
          {'Request Feedback'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
