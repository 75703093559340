import { Box, Dialog, DialogContent, DialogProps, Typography } from "@mui/material";
import { IDeal, IDealContactedLender } from "../../../schemas";
import CREEDDialogTitle from "../../CREEDDialogTitle";
import CommentRow from "../../CommentRow";
import { useMemo } from "react";


interface Props extends DialogProps {
  deal?: IDeal;
  lender?: IDealContactedLender;
}

export default function LenderFeedbackDialog({deal, lender, ...dialogProps}: Props): JSX.Element {

  const feedback = useMemo(() => {
    if (lender?.feedback == null) {
      return undefined;
    }

    const feedback = [...lender.feedback];
    feedback.sort((a, b) => Date.parse(b.createdDt) - Date.parse(a.createdDt));
    
    return feedback;
  }, [lender?.feedback]);

  return (
    <Dialog
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title={`Feedback from ${lender?.name}`}
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent>
        {feedback != null && feedback.length > 0 ? (
          feedback.map((fb, index) => (
            <Box key={`${index}`}>
              <CommentRow
                name={`Update ${feedback.length - index}`}
                comment={fb.message}
                date={fb.createdDt}
                hideAvatar />
              {index < feedback.length - 1 &&
                <Box sx={{
                  width: '100%',
                  height: '1px',
                  background: '#33333312',
                }} />
              }
            </Box>
          ))
        ) : (
          <Typography sx={{
            fontWeight: '400',
            fontSize: '12px',
          }}>
            {'No feedback from the lender yet'}
          </Typography>
        )}

        
      </DialogContent>
    </Dialog>
  );
}
