import React from 'react';
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Typography } from '@mui/material';
import { IDeal } from '../../schemas';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DealParametersForm from '../DealParametersForm';
import { SearchFieldID } from '../../types/SearchField';


interface Props extends DialogProps {
  readonly deal?: IDeal;
}

export default function EditDealDialog({deal, ...dialogProps}: Props): JSX.Element {
  return (
    <Dialog {...dialogProps}>
      <DialogTitle sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '16px',
        paddingBottom: '0px',
      }}>
        <IconButton
          sx={{
            width: '24px',
            height: '24px',
            color: '#333333',
            background: '#3333330A',
          }}
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          <CloseRoundedIcon sx={{
            fontSize: '17px'
          }} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{padding: 0}}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '32px',
          minWidth: '450px',
          padding: '16px',
        }}>
          <Typography sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24.46px',
          }}>
            {'Update search parameters'}
          </Typography>

          <DealParametersForm
            deal={deal}
            disabledFields={[SearchFieldID.PropertyCity, SearchFieldID.EquityDebt]}
            onDealSaved={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
