import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { IDocumentRequest } from '../../schemas';
import LoadingButton from '../LoadingButton';
import CREEDDialogTitle from '../CREEDDialogTitle';


interface Props extends DialogProps {
  documentRequest?: IDocumentRequest;
  onUnsubmitClicked?: () => Promise<void>;
}

export default function UnsubmitDocumentRequestDialog({documentRequest, onUnsubmitClicked, ...dialogProps}: Props): JSX.Element {

  const [loading, setLoading] = useState(false);

  const submitClicked = useCallback(async () => {
    if (onUnsubmitClicked != null) {
      setLoading(true);
      await onUnsubmitClicked();
      setLoading(false);
    }

    if (dialogProps.onClose != null) {
      dialogProps.onClose({}, 'escapeKeyDown');
    }
  }, [onUnsubmitClicked, dialogProps]);

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='Un-submit Document Request'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to un-submit the document request "${documentRequest?.name}" with ${documentRequest?.files.length} file(s)?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={(e) => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={loading}
          onClick={submitClicked}>
          {'Un-submit'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
