import { configureStore } from "@reduxjs/toolkit";
import snackMessageReducer from '../features/snackMessage-slice';
import authReducer from '../features/auth-slice';
import loadingReducer from '../features/loading-slice';
import chatReducer from '../features/messaging-slice';
import dealsReducer from '../features/deals-slice';
import { dealsApiSlice } from '../features/deals-api';
import { accountsApiSlice } from '../features/accounts-api';
import { invitationsApiSlice } from '../features/invitations-api';
import { messagingApiSlice } from "../features/messaging-api";
import { paymentsApiSlice } from '../features/payments-api';
import { notificationsApiSlice } from '../features/notifications-api';
import { utilsApiSlice } from '../features/utils-api';
import { documentRequestsApiSlice } from "../features/document-requests-api";
import { lendersApiSlice } from "../features/lenders-api";
import { sharedDealsApiSlice } from '../features/shared-deals-api';


export const store = configureStore({
  reducer: {
    snackMessage: snackMessageReducer,
    auth: authReducer,
    loading: loadingReducer,
    chat: chatReducer,
    deals: dealsReducer,
    [dealsApiSlice.reducerPath]: dealsApiSlice.reducer,
    [accountsApiSlice.reducerPath]: accountsApiSlice.reducer,
    [invitationsApiSlice.reducerPath]: invitationsApiSlice.reducer,
    [messagingApiSlice.reducerPath]: messagingApiSlice.reducer,
    [paymentsApiSlice.reducerPath]: paymentsApiSlice.reducer,
    [notificationsApiSlice.reducerPath]: notificationsApiSlice.reducer,
    [utilsApiSlice.reducerPath]: utilsApiSlice.reducer,
    [lendersApiSlice.reducerPath]: lendersApiSlice.reducer,
    [documentRequestsApiSlice.reducerPath]: documentRequestsApiSlice.reducer,
    [sharedDealsApiSlice.reducerPath]: sharedDealsApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    // allows to configure things like cache lifetime
    return getDefaultMiddleware()
      .concat(dealsApiSlice.middleware)
      .concat(accountsApiSlice.middleware)
      .concat(invitationsApiSlice.middleware)
      .concat(messagingApiSlice.middleware)
      .concat(paymentsApiSlice.middleware)
      .concat(notificationsApiSlice.middleware)
      .concat(utilsApiSlice.middleware)
      .concat(lendersApiSlice.middleware)
      .concat(documentRequestsApiSlice.middleware)
      .concat(sharedDealsApiSlice.middleware);
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
