import React, { useCallback, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogProps, Typography } from '@mui/material';
import { IDeal, ILender } from '../../schemas';
import UploadFilesContent from './UploadFilesContent';
import MessageContent from './MessageContent';
import { useShareDealMutation } from '../../features/deals-api';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';
import LoadingButton from '../LoadingButton';
import DialogCloseButton from '../DialogCloseButton';


interface Props extends DialogProps {
  readonly deal?: IDeal;
  readonly lender?: ILender;
}

export default function ShareDealDialog({deal, lender, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [state, setState] = useState<'files' | 'message'>('files');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [message, setMessage] = useState('');

  const [shareDeal] = useShareDealMutation();

  const nextClicked = useCallback(async () => {
    switch (state) {
      case 'files':
        setState('message');
        break;
      case 'message':
        if (deal?._id == null || lender == null) {
          return;
        }

        setButtonLoading(true);

        try {
          await shareDeal({
            dealId: deal?._id,
            recipientAccountId: lender['CREEDAccountId'] as string,
            recipientName: lender['Lender'] as string,
            message: message,
          }).unwrap();

          dispatch(snacked({
            message: 'Project shared',
            severity: 'success',
          }));
        } catch (error) {
          dispatch(snacked({
            message: 'Failed sharing project',
            severity: 'error',
          }));
        } finally {
          if (dialogProps.onClose != null) {
            dialogProps.onClose({}, 'escapeKeyDown');
          }

          setButtonLoading(false);
          setMessage('');
          setState('files');
        }
        break;
    }
  }, [state, setButtonLoading, dispatch, message, deal?._id, lender, shareDeal, dialogProps]);

  const closeClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setButtonLoading(false);
    setMessage('');
    setState('files');

    if (dialogProps.onClose != null) {
      dialogProps.onClose(event, 'escapeKeyDown');
    }
  }, [setButtonLoading, setMessage, setState, dialogProps]);

  return (
    <Dialog
      {...dialogProps}
      onClose={closeClicked}>
      <DialogContent>
        <Box sx={{
           display: 'flex',
           flexDirection: 'row',
           alignItems: 'center',
           justifyContent: 'space-between',
        }}>
          <Typography sx={{
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '20px',
          }}>
            {`Re: ${deal?.name ?? ''}`}
          </Typography>

          <DialogCloseButton onClick={closeClicked} />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '500px',
          rowGap: '22px',
        }}>
          <Typography sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24px',
          }}>
            {`To: ${lender != null ? lender['Lender'] : ''}`}
          </Typography>

          {state === 'files' ? (
            <UploadFilesContent
              deal={deal}
              lender={lender} />
          ) : (
            <MessageContent
              lender={lender}
              message={message}
              onMessageChange={(m) => setMessage(m)} />
          )}

          <LoadingButton
            sx={{
              marginTop: '16px',
              alignSelf: 'center',
            }}
            variant='contained'
            loading={buttonLoading}
            onClick={nextClicked}>
            {state === 'files' ? 'Next' : 'Send project info to lender'}
          </LoadingButton>
        </Box>
      </DialogContent>
      <DialogActions>
        
      </DialogActions>
    </Dialog>
  );
}
