import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, TextField } from '@mui/material';
import { IDocumentRequest } from '../../schemas';
import LoadingButton from '../LoadingButton';
import CREEDDialogTitle from '../CREEDDialogTitle';


interface Props extends DialogProps {
  documentRequest?: IDocumentRequest;
  onRejectClicked?: (rejectionReason: string) => Promise<void>;
}

export default function RejectDocumentRequestDialog({documentRequest, onRejectClicked, ...dialogProps}: Props): JSX.Element {

  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');

  const rejectClicked = useCallback(async () => {
    if (onRejectClicked != null) {
      setLoading(true);
      await onRejectClicked(reason);
      setLoading(false);
    }

    if (dialogProps.onClose != null) {
      dialogProps.onClose({}, 'escapeKeyDown');
    }

    setReason('');
  }, [onRejectClicked, reason, dialogProps]);

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='Return Document Request with Comment'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
      }}>
        <DialogContentText>
          {`Are you sure you want to return the document request "${documentRequest?.name}" with ${documentRequest?.files?.length} file(s)?`}
        </DialogContentText>

        <TextField
          sx={{
            width: '100%',
          }}
          id='comment'
          label={'Comment'}
          value={reason}
          multiline
          minRows={3}
          maxRows={10}
          disabled={loading}
          onChange={(e) => setReason(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={(e) => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={loading}
          onClick={rejectClicked}>
          {'Comment'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
