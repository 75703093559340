import React, { useState } from 'react';
import { Auth } from 'aws-amplify';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import sxClasses from '../Styles/ResetPwd.Styles';
import useCustomClassesAndTheme from '../useCustomClassesAndTheme';
import PageContainer from '../PageContainer';

const ResetPwd = () => {
  const classes = useCustomClassesAndTheme(sxClasses);

  const [resetState, setResetState] = useState({
    emailSent: false,
    verificationCode: '',
    done: false
  })
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  // let navigate = useNavigate();

  function validateForm() {
    return email.length > 0;
  }

  const handleReset = async(event: React.SyntheticEvent) => {
    event.preventDefault();
    try {
      const res = await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      console.log('reset pwd res: ', res);
      setResetState({
        ...resetState,
        done: true
      });
      setSuccess('Your password has be reset successfully.');
      setError('');
    } catch (e: any) {
      setResetState({
        ...resetState,
        done: false
      })
      console.log('error: ', e.message);
      setError(e.message);
    }
  }

  const handleSubmit = async(event: React.SyntheticEvent) => {
    event.preventDefault();
    try {
      const res = await Auth.forgotPassword(email)
      setError('');
      console.log('forgotPassword res: ', res);
      
      setResetState({
        ...resetState,
        emailSent: true
      });
      setError('');
      setSuccess('Verification code sent to your email. Make sure to check your spam or newsletter folders.');
    } catch (e: any) {
      console.log('error: ', e.message);
      setError(e.message);
      setResetState({
        ...resetState,
        emailSent: false
      });
    }
  }

  return (
    <PageContainer>
      {
        !resetState.emailSent
        ? <form onSubmit={handleSubmit}>
            <Grid container style={{marginTop: 100}}>
              {/* <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8}}>
                <img src={DarkModeLogo} />
              </Grid> */}
              <Grid item xs={12} sx={classes.centerCtr}>
                <TextField
                  style={{width: 300}}
                  required
                  id='email'
                  type='email'
                  label='Your Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <Button 
                  variant='contained' 
                  type='submit'
                  disabled={!validateForm() && !resetState.emailSent}
                  style={{height: '100%', width: 300}}>
                  Send confirmation code
                </Button>
                {
                  resetState.emailSent
                  ? <Typography variant='body1'>Verification code sent to your email. Make sure to check your spam or newsletter folders.</Typography>
                  : null
                }
                
                {
                  error !== ''
                  ? <div>{error}</div>
                  : null
                }
              </Grid>
            </Grid>
          </form>
        : <form onSubmit={handleReset}>
            <Grid container style={{marginTop: 100}}>
              <Grid item xs={12} style={{textAlign: 'center', marginBottom: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <TextField
                  style={{width: 300, marginTop: 8}}
                  required
                  id='code'
                  type='string'
                  label='Verification code (received by email)'
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
                <TextField
                  style={{width: 300, marginTop: 8}}
                  required
                  id='code'
                  type='password'
                  label='New Password'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <Button 
                  variant='contained' 
                  type='submit'
                  disabled={!validateForm() && !resetState.emailSent}
                  style={{height: '100%', width: 300}}>
                  Reset Password
                </Button>
                {
                  resetState.emailSent
                  ? <Typography variant='body1'>{success}</Typography>
                  : null
                }
                
                {
                  error !== ''
                  ? <div>{error}</div>
                  : null
                }
              </Grid>
            </Grid>
          </form>
      }
    </PageContainer>
  );
};

export default ResetPwd;