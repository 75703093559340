import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CREEDDialogTitle from './CREEDDialogTitle';


interface Props {
  readonly open: boolean;
  readonly closeClicked?: () => void;
}

export default function IncompleteProfileDialog(props: Props): JSX.Element {

  const navigate = useNavigate();

  return (
    <Dialog open={props.open}>
      <CREEDDialogTitle
        title='Incomplete Profile'
        closeClicked={props.closeClicked} />
      <DialogContent>
        <DialogContentText>
          {'Before you can start a new project, complete your organization profile'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={props.closeClicked}>
          {'Close'}
        </Button>
        <Button
          sx={{
            flex: 1,
          }}
          variant='contained'
          onClick={() => navigate('/organization')}>
          {'Go to profile'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
