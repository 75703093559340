import { useMemo } from 'react';
import { Box, FormHelperText, MenuItem, Select, Typography } from '@mui/material';
import { useGetSearchOptionsQuery } from '../features/deals-api';
import { DealParameterProps } from './DealParameter';
import { useDealParameter } from '../utils/hooks';
import DealParameterFormControl from './DealParameterFormControl';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { IDealInfo, ISearchInputParam } from '../schemas';


export default function DealParameterSelect({field, formValues, dealInfo, error, setFormValue}: DealParameterProps): JSX.Element {

  const {
    title,
    disabled,
    dependingFieldTitles,
  } = useDealParameter({ field, dealInfo });

  const searchSlug = useMemo(() => {
    if (field.parentValueSlugMap != null && field.dependsOn != null && field.dependsOn.length > 0) {
      const dependingFieldId = field.dependsOn[0];
      const dependingValue = dealInfo[dependingFieldId as keyof IDealInfo] as ISearchInputParam;
      if (dependingValue != null) {
        const mapping = field.parentValueSlugMap[dependingFieldId];
        if (mapping != null) {
          return mapping[dependingValue.optionName];
        }
      }

      return undefined;
    }

    return field.slug;
  }, [field, dealInfo]);

  const {data: options, isFetching} = useGetSearchOptionsQuery(searchSlug!, { skip: searchSlug == null });

  return (
    <DealParameterFormControl
      field={field}
      title={title}
      disabled={disabled}
      error={error}
      dependingFieldTitles={dependingFieldTitles}>
      <>
        <Select
          sx={{
            textAlign: 'left',
            borderRadius: '8px',
          }}
          id={field.id}
          labelId={field.id}
          label={title}
          multiple={field.multiselect}
          disabled={disabled || isFetching}
          value={formValues[field.id] ?? (field.multiselect ? [] : '')}
          error={error != null}
          defaultValue={''}
          renderValue={selected => Array.isArray(selected) ? selected.join(', ') : selected.toString()}
          onChange={e => setFormValue(e.target.value)}>
          {field.multiselect &&
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '16px',
              
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '14px',
                fontStyle: 'italic',
                color: '#333333AA',
              }}>
                {'Select all that apply'}
              </Typography>
            </Box>
          }
          {options?.select?.options.map((option: any) => (
            <MenuItem
              key={option.optionName}
              value={option.optionName}>
              {field.multiselect &&
                <CheckRoundedIcon sx={{
                  fontSize: '18px',
                  color: '#333333AA',
                  opacity: (formValues[field.id] ?? [] as any).includes(option.optionName) ? 1.0 : 0.0,
                }} />
              }
              {option.optionName}
            </MenuItem>
          ))}
        </Select>
        
        {error != null &&
          <FormHelperText>
            {error}
          </FormHelperText>
        }
      </>
    </DealParameterFormControl>
  );
}
