import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { useGetCurrentAccountQuery } from '../features/accounts-api';
import { useDefaultPath } from '../utils/hooks';


interface Props {
  element: () => JSX.Element;
}

/**
 * A react-router-dom route that automatically redirects to the home
 * when visited by a user that has a valid session
 */
export default function NoSessionRoute({element: Element}: Props): JSX.Element {
  
  const location = useLocation();
  const defaultPath = useDefaultPath();

  const {data: account} = useGetCurrentAccountQuery();
  
  const shouldBeLoggedIn = useAppSelector(state => state.auth.shouldBeLoggedIn);

  if (shouldBeLoggedIn) {
    if (!(location.pathname === '/login'
        && !account?.currentSubscription?.isActive
        && account?.isOnboarding)) {
      return (<Navigate to={defaultPath} />);
    }
  }
  
  return (
    <Element />
  );
}
