import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material/Alert';

interface SnackMessageState {
  message: string;
  severity: AlertColor;
}

const initialState: SnackMessageState = {
  message: '',
  severity: 'success'
};

const snackMessageSlice = createSlice({
  name: 'snackMessage',
  initialState,
  reducers: {
    snacked(state, action: PayloadAction<{message: string, severity?: AlertColor}>) {
      state.message = action.payload.message;
      state.severity = action.payload.severity || 'success';
    },
  }
})

export const { snacked } = snackMessageSlice.actions;
export default snackMessageSlice.reducer;
