import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { createApi } from '@reduxjs/toolkit/query/react';
import IMessageItem, { IAccount, IThread } from '../schemas';
import { baseUrls, createBaseQuery } from './apiHelper';
import { snacked } from './snackMessage-slice';


interface SendChatMessageParams {
  readonly threadId?: string;
  readonly senderUserId: string;
  readonly senderAccountId: string;
  readonly senderFullName: string;
  readonly recipientAccountIds?: string[];
  readonly identifier?: string;
  readonly dealId?: string;
  readonly message: string;
  readonly attachments: string[];
}

export const messagingApiSlice = createApi({
  reducerPath: 'api-messaging',
  tagTypes: ['Thread', 'ThreadParticipants'],
  baseQuery: createBaseQuery(baseUrls.messaging),
  endpoints: (build) => ({
    getThread: build.query<IThread, {threadId: string}>({
      providesTags: (thread) => thread != null ? [{ type: 'Thread', id: thread._id }] : [],
      query: ({threadId}) => `/threads/${threadId}`,
    }),
    getThreadParticpants: build.query<IAccount[], {threadId: string}>({
      providesTags: (participants, error, meta) => [{ type: 'ThreadParticipants', id: meta.threadId }],
      query: ({threadId}) => `/threads/${threadId}/participants`
    }),
    getChatThreadByRecipients: build.query<IThread, {recipientAccountIds: string[], identifier?: string}>({
      query: ({recipientAccountIds, identifier}) => {
        return {
          url: '/threads/getByRecipientIds/',
          method: 'POST',
          body: {
            recipientIds: recipientAccountIds,
            identifier: identifier,
          }
        };
      },
    }),

    sendChatMessage: build.mutation<any, SendChatMessageParams>({
      invalidatesTags: (_r, _e, meta) => [{ type: 'Thread', id: meta.threadId }],
      query: (params) => {
        return {
          url: `/messages`,
          method: 'POST',
          body: {
            recipientIds: params.recipientAccountIds,
            threadId: params.threadId,
            identifier: params.identifier,
            dealId: params.dealId,
            message: params.message,
            attachments: params.attachments.map(i => {
              return {
                type: 'thread',
                fileId: i,
              };
            }),
          }
        };
      },
      onQueryStarted: async (params, {dispatch, queryFulfilled}) => {
        const message: IMessageItem = {
          message: params.message,
          senderAccountId: params.senderAccountId,
          senderUserId: params.senderUserId,
          senderFullName: params.senderFullName,
          createdDt: new Date().toISOString(),
        };

        let patchResult: PatchCollection | null = null;
        if (params.threadId != null) {
          patchResult = dispatch(
            messagingApiSlice.util.updateQueryData('getThread', {threadId: params.threadId}, (draft) => {
              draft.messages.push(message)
            })
          );
        } else if (params.recipientAccountIds != null) {
          patchResult = dispatch(
            messagingApiSlice.util.updateQueryData('getChatThreadByRecipients', {recipientAccountIds: params.recipientAccountIds, identifier: params.identifier}, (draft) => {
              draft.messages.push(message)
            })
          );
        }

        try {
          await queryFulfilled;
        } catch {
          if (patchResult != null) {
            patchResult.undo();
          }

          dispatch(snacked({
            message: 'Network error. Please try again.',
            severity: 'error',
          }));
        }
      }
    }),
    getMessageAttachmentDownloadUrl: build.mutation<{url: string}, {threadId: string, fileKey: string}>({
      query: ({threadId, fileKey}) => `/threads/${threadId}/attachments/${fileKey}`,
    }),
    setLastReadMessageForThread: build.mutation<{message: string, status: string}, {threadId: string, messageId: string}>({
      invalidatesTags: (_r, _e, args) => [{type: 'Thread', id: args.threadId}],
      query: ({threadId, messageId}) => {
        return {
          url: `/threads/${threadId}/last-read-message`,
          method: 'PATCH',
          body: {
            messageId,
          }
        }
      },
      // onQueryStarted: async ({threadId, messageId}, {dispatch, queryFulfilled}) => {
      //   const patchResult = dispatch(messagingApiSlice.util.updateQueryData('getThread', {threadId}, (thread) => {
      //     const index = 
      //   }))
      // }
    })
  })
})

export const {
  useGetThreadQuery,
  useLazyGetThreadQuery,
  useGetThreadParticpantsQuery,
  useGetChatThreadByRecipientsQuery,

  useSendChatMessageMutation,
  useGetMessageAttachmentDownloadUrlMutation,
  useSetLastReadMessageForThreadMutation,
} = messagingApiSlice;
