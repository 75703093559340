import { Box, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


interface Props {
  title: string;
  value?: boolean;
  date?: string;
  editing?: boolean;
  onChange?: (date?: Date) => void;
}

export default function DealDiligenceMilestoneRow({title, value, date, editing, onChange}: Props): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
      }}>
      <Typography sx={{
        display: 'flex',
        flex: 1,
        fontWeight: '500',
        fontSize: '14px',
        color: '#33333380',
      }}>
        {title}
      </Typography>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
      }}>
        {editing ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              slotProps={{
                textField: {
                  size: 'small',
                  error: false,
                  InputProps: {
                    sx: {
                      width: '180px',
                      borderRadius: '8px',
                    }
                  }
                }
              }}
              value={date != null ? dayjs(date) : null}
              onChange={(d: Dayjs | null) => onChange ? onChange(d?.toDate()) : null} />
          </LocalizationProvider>
        ) : (
          value && date != null &&
            <Typography sx={{
              fontWeight: '400',
              fontSize: '12px',
              color: '#333333CC'
            }}>
              {dayjs(date).format('MM/DD/YYYY')}
            </Typography>
        )}

        {value ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '16px',
            height: '16px',
            borderRadius: '8px',
            color: 'white',
            background: '#439775',
          }}>
            <DoneIcon sx={{
              fontSize: '10px',
            }} />
          </Box>
        ) : (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '16px',
            height: '16px',
            borderRadius: '8px',
            background: '#33333344',
          }}>
            <Box sx={{
              width: '12px',
              height: '12px',
              borderRadius: '6px',
              background: 'white',
            }} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
