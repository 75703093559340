import { useCallback, useRef } from 'react';
import PageContainer from '../PageContainer';
import { Box, Typography } from '@mui/material';
import DealParametersForm from '../DealParametersForm';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { markDealCreated } from '../../features/deals-slice';


function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}


export default function CreateDeal(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GCLOUD_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }
  
    loaded.current = true;
  }

  const dealSaved = useCallback((dealId: string) => {
    dispatch(markDealCreated({dealId}));
    navigate(`/deal/${dealId}`);
  }, [dispatch, navigate]);

  return (
    <PageContainer>
      <Box sx={{
         display: 'flex',
         flexDirection: 'column',
         width: '100%',
         paddingTop: '5vh',
         alignItems: 'center',
      }}>
        <Box sx={{
          width: '450px',
          alignItems: 'center',
        }}>
          <Typography sx={{
            marginBottom: '40px',
            fontWeight: '400',
            fontSize: '28px',
            lineHeight: '34.24px',
          }}>
            {'Start a new project'}
          </Typography>
          
          <DealParametersForm onDealSaved={dealSaved} />
        </Box>
      </Box>
    </PageContainer>
  );
}
