import { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ILender, IDeal } from '../../schemas';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';
import FileRow from '../FileRow';
import FileUploadBox from '../FileUploadBox';
import { useCreateDealFileUploadUrlMutation } from '../../features/deals-api';
import { uploadFileToUrl } from '../../utils/utils';


interface Props {
  readonly deal?: IDeal;
  readonly lender?: ILender;
}

export default function UploadFilesContent({deal, lender}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [uploadLoading, setUploadLoading] = useState(false);

  const [createDealFileUploadUrl] = useCreateDealFileUploadUrlMutation();

  const onFilesDropped = useCallback(async (acceptedFiles: File[]) => {
    if (deal?._id == null) {
      return;
    }

    setUploadLoading(true);

    try {
      const uploadUrlResponses = await Promise.all(acceptedFiles.map(f => createDealFileUploadUrl({
        dealId: deal?._id!,
        fileType: 'file',
        fileName: f.name,
        contentType: f.type,
      }).unwrap()));

      await Promise.all(
        uploadUrlResponses.map((response, index) => uploadFileToUrl(response.url, acceptedFiles[index]))
      );
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed uploading files',
        severity: 'error',
      }));
    } finally {
      setUploadLoading(false);
    }
  }, [createDealFileUploadUrl, deal?._id, dispatch]);
  
  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'scroll',
        width: '100%',
        height: '160px',
      }}>
        {(deal?.files ?? []).length === 0 ? (
          <Typography sx={{
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '15px',
          }}>
            {'No files attached yet.'}
          </Typography>
        ) : (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            paddingBottom: '8px',
          }}>
            {deal?.files?.map(file => (
              <FileRow
                key={file.Key}
                file={file as any}
                variant='compact' />
            ))}
          </Box>
        )}
      </Box>

      <FileUploadBox
        onFilesDropped={onFilesDropped}
        loading={uploadLoading} />

      {/* Suggested Documents: rent roll, property financials (historical, YTD and budget), OM or any other relevant deal-specific documents */}
    </>
  );
}
