import React from 'react';
import SearchField from '../types/SearchField';
import DealParameterSelect from './DealParameterSelect';
import DealParameterNumber from './DealParameterNumber';
import DealParameterDate from './DealParameterDate';
import DealParameterLocation from './DealParameterLocation';
import { IDealInfo } from '../schemas';
import { FormValue } from './DealParametersForm';


export interface DealParameterProps {
  readonly field: SearchField;
  readonly formValues: Record<string, FormValue | undefined>;
  readonly dealInfo: Partial<IDealInfo>;
  readonly error?: string;
  readonly setFormValue: (value: any) => void;
}

export default function DealParameter(props: DealParameterProps): JSX.Element {
  switch (props.field.type) {
    case 'select':
      return (<DealParameterSelect {...props} />);
    case 'number':
      return (<DealParameterNumber {...props} />);
    case 'date':
      return (<DealParameterDate {...props} />);
    case 'location':
      return (<DealParameterLocation {...props} />);
  }
}
