import { useMemo } from 'react';
import Card from '../../Card';
import { Box, Typography, Button } from '@mui/material';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { IDeal, ISharedDeal } from '../../../schemas';
import InvestorStatusChip from '../Chips/InvestorStatusChip';
import { useGetInvestorDealResponsesQuery } from '../../../features/deals-api';


interface Props {
  readonly deal?: IDeal;
  readonly sharedDeal?: ISharedDeal;
  readonly interestedClicked?: () => void;
  readonly rejectDealClicked?: () => void;
}

export default function DealInvestorResponseCard({deal, sharedDeal, interestedClicked, rejectDealClicked}: Props): JSX.Element {
  
  const {data: responses} = useGetInvestorDealResponsesQuery({dealId: deal?._id!}, {skip: deal?._id == null});

  const response = responses != null ? responses[0] : undefined;

  const accepted = useMemo(() => {
    return sharedDeal != null && sharedDeal.investorStatus === 'accepted';
  }, [sharedDeal]);

  const rejected = response != null && response.status === 'rejected';

  const status = useMemo(() => {
    if (rejected) {
      return 'rejected';
    }

    if (accepted) {
      return 'accepted';
    }

    return undefined;
  }, [accepted, rejected]);
  
  return (
    <Card>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '32px',
      }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '22px',
        }}>
          {'Indicate Status to Sponsor'}
        </Typography>

        {status != null &&
          <InvestorStatusChip status={status} />
        }
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '22px',
        paddingTop: '22px',
      }}>
        <Button
          variant='contained'
          disabled={status != null}
          onClick={interestedClicked}>
          {'Interested'}
        </Button>
        <Button
          sx={{
            gap: '12px',
          }}
          variant='contained'
          color='secondary'
          disabled={rejected}
          onClick={rejectDealClicked}>
          <RemoveCircleRoundedIcon sx={{fontSize: '16px'}} />
          {'Pass on Project'}
        </Button>
      </Box>
    </Card>
  );
}
