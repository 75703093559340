import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';


declare module '@mui/material/styles' {
  export interface Theme {}
  interface TypographyVariants {
    error: React.CSSProperties;
    link: React.CSSProperties;
  }
  interface ThemeOptions {}
  interface TypographyVariantsOptions {
    error?: React.CSSProperties;
    link?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    error: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    menuItem: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true,
  }
}


export const theme = createTheme({
  typography: {
    fontFamily: [
      'Commissioner',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    error: {
      color: '#F96269'
    },
    h1: {
      fontWeight: 500,
      fontSize: '3rem'
    },
    h2: {
      fontWeight: 400,
      fontSize: '2rem',
      '@media (max-width:900px)': {
        fontSize: '1.8rem',
      },
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      }
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.6rem'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: 'linear-gradient(0deg, rgba(221, 207, 178, 0.1), rgba(221, 207, 178, 0.1)), #FFFFFF',
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: {
            variant: 'menuItem'
          },
          style: {
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '19.57px',
          }
        }
      ],
      styleOverrides: {
        root: {
          color: '#333333',
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          color: '#333333',
        },
        contained: ({ownerState}) => {
          const common = {
            boxShadow: '0px 2px 12px 0px #33333329',
            '&:hover': {
              boxShadow: '0px 4px 24px 0px #3333333D',
            },
            '&.Mui-disabled': {
              color: ownerState.color === 'secondary' ? '#ffffff44' : undefined,
            }
          };

          switch (ownerState.color) {
            case 'primary':
              return {
                color: '#333333',
                background: 'linear-gradient(135deg, rgba(221,207,178,1) 0%, rgba(217,179,97,1) 100%)',
                ...common,
              };
            case 'secondary':
              return {
                color: '#ffffff',
                background: 'linear-gradient(90.87deg, #333333 47.63%, #DDCFB2 152.29%)',
                ...common,
              };
            default:
              return common;
          }
        },
        text: {
          background: 'transparent'
        },
        outlined: ({ownerState}) => {
          const common = {
            borderWidth: '0.5px',
            borderRadius: '8px',
            borderColor: '#33333333',
            background: 'transparent',
            '&:hover': {
              borderWidth: '0.5px',
              borderColor: '#33333354',
              background: '#33333305',
            }
          };

          switch (ownerState.color) {
            case 'primary':
              return {
                ...common,
                borderColor: 'rgba(221,207,178,0.7)',
                background: 'linear-gradient(135deg, rgba(221,207,178,0.2) 0%, rgba(217,179,97,0.2) 100%)',
                '&:hover': {
                  borderWidth: '0.5px',
                  borderColor: 'rgba(221,207,178,9)',
                  background: 'linear-gradient(135deg, rgba(221,207,178,0.25) 0%, rgba(217,179,97,0.25) 100%)',
                }
              };
            default:
              return common;
          }
        } 
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          sx: {
            borderRadius: '8px',
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        colorInfo: {
          background: '#33333314',
          color: '#333333',
        },
        colorWarning: {
          background: '#FA79381A',
          color: '#B33F05',
        },
        colorSuccess: {
          background: '#4397751A',
          color: '#2C634D',
        },
        colorError: {
          background: '#F8333C1A',
          color: '#B1060F',
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: 'black',
          background: 'linear-gradient(135deg, rgba(221,207,178,0.9) 0%, rgba(217,179,97,0.9) 100%)', 
        },
        colorSecondary: {
          color: '#ffffff',
          background: 'linear-gradient(90.87deg, #333333 47.63%, #DDCFB2 152.29%)',
          '&.Mui-disabled': {
            color: '#ffffffaa',
          }
        },
        colorInfo: {
          color: '#333333',
          background: '#3333330A',
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          '&.Mui-checked': {
            color: '#DDCFB2',
          },
          '&.Mui-disabled': {
            color: '#DDCFB266',
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        menu: {
          '& .MuiPaper-root': {
            borderRadius: '8px',
            boxShadow: '0px 0px 16px 0px #3333331F',
          }
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#F2EDE1',
          color: 'black',
          boxShadow: 'none',
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontWeight: '400',
          fontSize: '14px',
          color: '#333333AA',
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#F2EDE1',
        },
        colorSecondary: {
          color: 'black',
        },
      }
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          background: '#F8333C1A',
          color: '#B1060F',
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: '#333333'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#33333380',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '17.12px',
          '&.Mui-selected': {
            color: '#333333',
          },
        },
        
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          '&.MuiPopover-paper': {
            borderRadius: '8px',
            boxShadow: '0px 0px 16px 0px #3333331F',
          },
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          '&.MuiPopover-paper': {
            borderRadius: '8px',
            boxShadow: '0px 0px 16px 0px #3333331F',
          },
        },
        list: {
          padding: 0,
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: '56px',
          gap: '14px',
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          boxShadow: '0px 0px 16px 0px #3333331F',
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '8px',
          borderRadius: '8px',
          background: '#333333',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '15px',
        },
        arrow: {
          color: '#333333'
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: '#3333330A',
          color: '#3333334D',
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: 'none',
        },
        standardWarning: {
          background: '#FA79381A',
          color: '#B33F05',
        },
      }
    }
  }
});
