import { Badge, Box, Button } from "@mui/material";
import CommentRow from "../CommentRow";
import { ISharedDeal } from "../../schemas";
import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch } from "../../app/hooks";
import { showChatForThread } from "../../features/messaging-slice";
import { useGetThreadQuery } from "../../features/messaging-api";
import { useGetCurrentAccountQuery } from "../../features/accounts-api";


interface Props {
  sharedDeal: ISharedDeal;
  setUnreadMessages?: (messages: number) => void;
}

export default function EquityDealInvestorResponseRow({sharedDeal, setUnreadMessages}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const {data: account} = useGetCurrentAccountQuery();
  const {data: thread} = useGetThreadQuery({ threadId: sharedDeal.threadId! }, {skip: sharedDeal.threadId == null});

  const unreadMessages = useMemo(() => {
    if (thread == null) {
      return undefined;
    }

    const lastReadMessage = thread.lastReadMessages?.find(l => l.accountId === account?._id);
    if (lastReadMessage == null) {
      return thread.messages
        .filter(m => m.senderAccountId !== account?._id)
        .length;
    }

    const lastReadIndex = lastReadMessage != null ? thread.messages.findIndex(m => m._id === lastReadMessage.messageId) : 0;
    if (lastReadIndex < 0) {
      return 0;
    }

    return thread.messages
      .slice(lastReadIndex + 1)
      .filter(m => m.senderAccountId !== account?._id)
      .length;
  }, [account?._id, thread]);

  const chatWithInvestorClicked = useCallback(() => {
    if (sharedDeal.threadId == null) {
      return;
    }

    dispatch(showChatForThread({
      key: 'chat',
      threadId: sharedDeal.threadId,
      closable: true,
    }));
  }, [dispatch, sharedDeal.threadId]);

  useEffect(() => {
    if (setUnreadMessages != null) {
      setUnreadMessages(unreadMessages ?? 0);
    }
  }, [setUnreadMessages, unreadMessages]);

  return (
    <CommentRow
      key={sharedDeal._id}
      avatarImageUrl={sharedDeal.account?.profileImageUrl}
      name={sharedDeal.account?.name ?? 'Unknown'}
      // comment={sharedDeal.investorComment ?? 'Unknown'}
      date={sharedDeal.createdDt}>
      {sharedDeal.threadId != null &&
        <Box sx={{
          paddingTop: '12px',
        }}>
            <Badge
              badgeContent={unreadMessages != null && unreadMessages > 0 ? unreadMessages : undefined}
              color='primary'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={chatWithInvestorClicked}>
                {'Chat with investor'}
              </Button>
            </Badge>
          </Box>
        }
    </CommentRow>
  );
}
