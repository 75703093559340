import { useMemo } from 'react';
import { Chip } from '@mui/material';
import { AccountType, DocumentRequestStatus } from '../../schemas';


interface Props {
  readonly status: DocumentRequestStatus;
  readonly accountType?: AccountType;
}

export default function DocumentRequestStatusChip({status, accountType}: Props): JSX.Element {

  const backgroundColor = useMemo(() => {
    switch (accountType) {
      case 'sponsor':
        switch (status) {
          case 'pending': return '#FA79381A';
          case 'submitted': return '#33333314';
          case 'accepted': return '#4397751A';
          case 'rejected': return '#F8333C1A';
        }
        break;
      default:
        switch (status) {
          case 'pending': return '#33333314';
          case 'submitted': return '#FA79381A';
          case 'accepted': return '#4397751A';
          case 'rejected': return '#F8333C1A';
        }
    }
  }, [status, accountType]);

  const textColor = useMemo(() => {
    switch (accountType) {
      case 'sponsor':
        switch (status) {
          case 'pending': return '#B33F05';
          case 'submitted': return '#333333';
          case 'accepted': return '#2C634D';
          case 'rejected': return '#B1060F';
        }
        break;
      default:
        switch (status) {
          case 'pending': return '#333333';
          case 'submitted': return '#B33F05';
          case 'accepted': return '#2C634D';
          case 'rejected': return '#B1060F';
        }
    }
  }, [status, accountType]);

  const label = useMemo(() => {
    switch (accountType) {
      case 'sponsor':
        switch (status) {
          case 'pending': return 'To do';
          case 'submitted': return 'Under review';
          case 'accepted': return 'Approved';
          case 'rejected': return 'Under review with comments';
        }
        break;
      default:
        switch (status) {
          case 'pending': return 'Pending';
          case 'submitted': return 'To do';
          case 'accepted': return 'Approved';
          case 'rejected': return 'Under review with comments';
        }
    }
  }, [status, accountType]);

  return (
    <Chip
      sx={{
        fontWeight: '500',
        fontSize: '13px',
        background: backgroundColor,
        color: textColor,
      }}
      label={label}
      size='medium' />
  );
}
