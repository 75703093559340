import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PageContainer from '../PageContainer';
import { useAccountProfileInsights } from '../../utils/hooks';
import IncompleteProfileDialog from '../IncompleteProfileDialog';
import DealsDataGrid from '../DealsDataGrid';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';


dayjs.extend(relativeTime);


export default function Deals(): JSX.Element {
  const navigate = useNavigate();

  const {data: account} = useGetCurrentAccountQuery();

  const profileInsights = useAccountProfileInsights(account);

  const [incompleteProfileVisible, setIncompleteProfileVisible] = useState(false);

  const newProjectClicked = useCallback(() => {
    if (profileInsights.isComplete) {
      navigate('/new-deal')
    } else {
      setIncompleteProfileVisible(true);
    }
  }, [profileInsights, navigate, setIncompleteProfileVisible]);

  return (
    <PageContainer>
      <Typography sx={{
        marginBottom: '40px',
        fontWeight: '400',
        fontSize: '28px',
        lineHeight: '34.24px',
      }}>
        {`My projects`}
      </Typography>
      
      <Button
        sx={{
          alignSelf: 'flex-start',
          marginBottom: '30px',
        }}
        variant='contained'
        disabled={!account?.currentSubscription?.isActive}
        onClick={newProjectClicked}>
        {'Create new project'}
      </Button>

      <Box sx={{
        width: '100%',
        height: 'calc(100vh - 300px)',
      }}>
        <DealsDataGrid />
      </Box>

      <IncompleteProfileDialog
        open={incompleteProfileVisible}
        closeClicked={() => setIncompleteProfileVisible(false)} />
    </PageContainer>
  )
}
