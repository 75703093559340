import { Box, Divider, IconButton, SxProps, Theme, Typography } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


interface Props {
  sx?: SxProps<Theme>;
  name: string;
  role?: string;
  email?: string;
  phone?: string;
  address?: string;
  onEditClicked?: () => void;
  onDeleteClicked?: () => void;
}

export default function ContactInfoCard({sx, role, name, email, phone, address, onEditClicked, onDeleteClicked}: Props): JSX.Element {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      width: '392px',
      padding: '24px',
      borderRadius: '12px',
      boxShadow: '0px 0px 16px 0px #3333331F',
      background: 'white',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Typography sx={{
            fontWeight: '500',
            fontSize: '20px',
          }}>
            {name}
          </Typography>
          {role != null &&
            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
              color: '#333333BF',
            }}>
              {role}
            </Typography>
          }
          <Typography sx={{
            fontWeight: '400',
            fontSize: '14px',
            color: '#333333BF',
          }}>
            {email}
          </Typography>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 0.5,
        }}>
          {onEditClicked != null &&
            <IconButton
              sx={{
                fontSize: '18px',
              }}
              size='small'
              onClick={onEditClicked}>
              <ModeEditOutlineOutlinedIcon fontSize='inherit' />
            </IconButton>
          }
          {onDeleteClicked != null &&
            <IconButton
              sx={{
                fontSize: '18px',
              }}
              size='small'
              onClick={onDeleteClicked}>
              <DeleteOutlineOutlinedIcon fontSize='inherit' />
            </IconButton>
          }
        </Box>
      </Box>

      <Divider />

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
      }}>
        <CallIcon
          sx={{
            marginTop: '1px',
            color: '#33333380',
            fontSize: '14px',
          }} />

        <Box>
          <Typography sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17.12px',
            color: '#33333380',
          }}>
            {'Phone'}
          </Typography>
          <Typography sx={{
            fontWeight: '400',
            fontSize: '14px',
            color: '#333333BF',
          }}>
            {phone ?? '-'}
          </Typography>
        </Box>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
      }}>
        <LocationOnOutlinedIcon
          sx={{
            marginTop: '1px',
            color: '#33333380',
            fontSize: '14px',
          }} />

        <Box>
          <Typography sx={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '17.12px',
            color: '#33333380',
          }}>
            {'Address'}
          </Typography>
          <Typography sx={{
            fontWeight: '400',
            fontSize: '14px',
            color: '#333333BF',
          }}>
            {address ?? '-'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
