import { Autocomplete, AutocompleteChangeReason, Box, Button, Card, Chip, Dialog, DialogActions, DialogContent, DialogProps, TextField, Typography } from "@mui/material";
import CREEDDialogTitle from "../CREEDDialogTitle";
import { MapCustomizations, USAMap } from "react-usa-map-fc";
import { useCallback, useEffect, useRef, useState } from "react";
import { IAccountEquityMarketplaceFilters } from "../../schemas";
import { useEffectDebounced } from "../../utils/hooks";


interface Props extends DialogProps {
  filters?: IAccountEquityMarketplaceFilters;
  saveFilters?: (states: string[], cities: {city: string, state: string}[]) => void;
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function EquityMarketplaceFilterGeographicFocusDialog({filters, saveFilters, ...dialogProps}: Props): JSX.Element {

  const loaded = useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GCLOUD_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }
  
    loaded.current = true;
  }

  const stateFillColor = '#1976d2';
  
  const [customize, setCustomize] = useState<MapCustomizations>({});
  const [states, setStates] = useState<string[]>([]);
  const [cityOptions, setCityOptions] = useState<{city: string, state: string}[]>([]);
  const [cityRaw, setCityRaw] = useState('');
  const [city] = useState<{city: string, state: string} | null | undefined>();
  const [cities, setCities] = useState<{city: string, state: string}[]>([]);
  const [autocompleteKey, setAutocompleteKey] = useState<number>(Math.random());

  const stateClicked = useCallback((state: string) => {
    if (states.includes(state)) {
      setStates(states.filter(s => s !== state));
      setCustomize({ ...customize, [state]: {}});
    } else {
      setStates([...states, state]);
      setCustomize({ ...customize, [state]: { fill: stateFillColor}});
    }
  }, [customize, states]);

  const stateRemoved = useCallback((state: string) => {
    setStates(states.filter(s => s !== state));
    setCustomize({ ...customize, [state]: {}});
  }, [customize, states]);

  const cityRemoved = useCallback((value: typeof city) => {
    setCities(cities.filter(c => c !== value));
  }, [cities]);

  const onCityAutoCompleteChanged = useCallback((_event: React.SyntheticEvent<Element, Event>, value: {city: string, state: string} | null, reason: AutocompleteChangeReason) => {
    if (reason === 'selectOption') {
      if (value != null) {
        setCities(previous => [...previous, value]);
        setCityOptions([]);
        setCityRaw('');
        setAutocompleteKey(Math.random());
      }
    }
  }, []);

  const saveClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (saveFilters == null) {
      return;
    }

    saveFilters(states, cities);

    if (dialogProps.onClose != null) {
      dialogProps.onClose(event, 'escapeKeyDown');
    }
  }, [cities, dialogProps, saveFilters, states]);

  useEffect(() => {
    setStates(filters?.states ?? []);
    setCities(filters?.cities ?? []);

    const statesCustomization = filters?.states?.reduce((obj, state) => ({...obj, [state]: {fill: stateFillColor}}), {}) ?? {};
    setCustomize(previous => ({...previous, ...statesCustomization}));
  }, [filters?.states, filters?.cities]);

  useEffectDebounced(() => {
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return;
    }

    if (cityRaw.length === 0) {
      setCityOptions([]);
    } else {
      (autocompleteService.current as any).getPlacePredictions({ input: cityRaw, types: ['(cities)'], componentRestrictions: { country: 'us' } }, (results?: any[]) => {
        setCityOptions(results?.map(r => ({ city: r.terms[0].value, state: r.terms[1].value })) ?? []);
      });
    }
  }, 200, [city, cityRaw]);
  
  return (
    <Dialog
      maxWidth='xl'
      fullWidth
      {...dialogProps}>
      <CREEDDialogTitle
        title='Geographic Focus'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      
      <DialogContent>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '16px',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            // gap: 1,
            alignItems: 'center',
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '14px',
              fontStyle: 'italic',
              color: '#333333BB',
            }}>
              {'Select all states that apply'}
            </Typography>
            <USAMap
              onClick={stateClicked}
              customize={customize}
              width={1100}
              defaultFill='#33333333' />
          </Box>
          <Card sx={{
            flex: 1,
            padding: 2,
          }}
          variant='outlined'>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              // paddingTop: '16px',
              gap: '8px',
            }}>
              <Typography sx={{
                fontWeight: '500',
                fontSize: '14px',
              }}>
                {'Cities'}
              </Typography>
              <Autocomplete
                key={autocompleteKey} // Hack to clear input on value selected, bug w/ MUI Autocomplete
                autoComplete
                selectOnFocus
                handleHomeEndKeys
                options={cityOptions}
                getOptionLabel={option => typeof option === 'string' ? option : `${option.city}, ${option.state}`}
                isOptionEqualToValue={(option, value) => option.city === value.city && option.state === value.state}
                value={city}
                inputValue={cityRaw}
                onChange={onCityAutoCompleteChanged}
                onInputChange={(_e, value) => setCityRaw(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='New City'
                    placeholder='Enter city name'
                    fullWidth
                    size='small'
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        borderRadius: '8px',
                      }
                    }} />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography>
                      {`${option.city}, ${option.state}`}
                    </Typography>
                  </li>
                )}
                filterOptions={options => {
                  return options.filter(o => !cities.some(c => c.city === o.city && c.state === o.state));
                }} />
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '8px',
                paddingTop: '8px',
              }}>
                {cities.map(city => (
                  <Chip
                    key={`${city.city}, ${city.state}`}
                    label={`${city.city}, ${city.state}`}
                    size='small'
                    onDelete={() => cityRemoved(city)} />
                ))}
              </Box>
            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '16px',
              gap: '8px',
            }}>
              <Typography sx={{
                fontWeight: '500',
                fontSize: '14px',
              }}>
                {'States'}
              </Typography>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '8px',
              }}>
                {states.map(state => (
                  <Chip
                    key={state}
                    label={state}
                    size='small'
                    onDelete={() => stateRemoved(state)} />
                ))}
              </Box>
            </Box>
          </Card>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{flex: 2.75}} />
        <Box sx={{display: 'flex', flex: 1, gap: 1,}}>
          <Button
            sx={{flex: 1}}
            onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
            {'Cancel'}
          </Button>
          <Button
            sx={{flex: 1}}
            variant='contained'
            onClick={saveClicked}>
            {'Save'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
