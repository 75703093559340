import { Box, SxProps, Theme, Typography } from "@mui/material";


interface Props {
  sx?: SxProps<Theme>;
  name: string;
  role?: string;
  email?: string;
  phone?: string;
  address?: string;
  onEditClicked?: () => void;
}

export default function ContactInfo({sx, role, name, email, phone, address, onEditClicked}: Props): JSX.Element {
  return (
    <Box
      sx={{
        padding: 0.5,
        borderRadius: '8px',
        ':hover': onEditClicked != null ? {
          background: '#33333308',
          cursor: 'pointer',
        } : undefined,
        ...sx ?? {},
      }}
      onClick={() => onEditClicked ? onEditClicked() : null}>
      {role != null &&
        <Typography sx={{
          fontWeight: '500',
          fontSize: '14px',
        }}>
          {role}
        </Typography>
      }
      <Typography sx={{
        flex: 1,
        fontWeight: '500',
        fontSize: '14px',
      }}>
        {name}
      </Typography>
      {email != null &&
        <Typography sx={{
          fontWeight: '400',
          fontSize: '14px',
        }}>
          {email}
        </Typography>
      }
      {phone != null &&
        <Typography sx={{
          fontWeight: '400',
          fontSize: '14px',
        }}>
          {phone}
        </Typography>
      }
      {address != null &&
        <Typography sx={{
          fontWeight: '400',
          fontSize: '14px',
        }}>
          {address}
        </Typography>
      }
    </Box>
  );
}
