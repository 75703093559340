import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Box } from '@mui/material';
import SearchField, { SearchFieldID, SearchFieldValue, useDealSearchFields } from '../types/SearchField';
import DealParameter from './DealParameter';
import { formatNumericString, parseNumericString } from '../utils/utils';
import LoadingButton from './LoadingButton';
import { IDeal, IDealInfo, ISearchInputParam, ISearchInputParamArray } from '../schemas';
import { useCreateDealMutation, useGetEquityDealReviewForDealQuery, useUpdateDealMutation } from '../features/deals-api';
import dayjs, { Dayjs } from 'dayjs';
import { useErrorHandler } from '../utils/hooks';
import * as Sentry from '@sentry/react';


export type FormValue = string | string[] | Dayjs;

interface Props {
  readonly deal?: IDeal;
  readonly disabledFields?: SearchFieldID[];
  readonly onDealSaved?: (dealId: string) => void;
}

export default function DealParametersForm({deal, disabledFields, onDealSaved}: Props): JSX.Element {

  const {handleError} = useErrorHandler();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<Record<string, FormValue | undefined>>({});
  const [dealInfo, setDealInfo] = useState<IDealInfo>({});
  const [errors, setErrors] = useState<Record<string, string>>({});

  const {data: equityDealReview} = useGetEquityDealReviewForDealQuery({ dealId: deal?._id! }, { skip: deal?._id == null || deal?.dealInfo?.equityDebt?.optionName !== 'Equity' });

  const [createDeal] = useCreateDealMutation();
  const [updateDeal] = useUpdateDealMutation();

  const {
    dealSearchFields,
  } = useDealSearchFields();

  const packageValue = useCallback((field: SearchField, value?: FormValue): SearchFieldValue => {
    switch (field.type) {
      case 'date':
      case 'location':
        return value as string;
      case 'number':
        if (field.formatting === 'comma-separated') {
          return Math.floor(parseNumericString(value as string) as number);
        } else {
          return parseNumericString(value as string) as number;
        }
      case 'select': {
        let searchSlug = field.slug!;
        if (field.parentValueSlugMap != null && field.dependsOn != null && field.dependsOn.length > 0) {
          const dependingFieldId = field.dependsOn[0];
          const dependingValue = formValues[dependingFieldId] as string;
          if (dependingValue != null) {
            const mapping = field.parentValueSlugMap[dependingFieldId];
            if (mapping != null) {
              searchSlug = mapping[dependingValue];
            }
          }
        }

        if (field.multiselect) {
          return {
            searchInputSlug: searchSlug,
            optionNames: (value as string[]).filter(v => v != null),
          };
        }

        return {
          searchInputSlug: searchSlug,
          optionName: value as string,
        };
      }
    }
  }, [formValues]);

  const unpackageValue = useCallback((field: SearchField, value?: SearchFieldValue): FormValue | undefined => {
    if (value == null) {
      return undefined;
    }
    
    switch (field.type) {
      case 'date':
        return dayjs(value as string);
      case 'location':
        return value as string;
      case 'number': {
        switch (field.formatting) {
          case 'comma-separated':
            return formatNumericString((value as number).toFixed(0));
          default:
            return (value as number).toFixed(1);
        }
      }
      case 'select': {
        if (field.multiselect) {
          return (value as ISearchInputParamArray)?.optionNames.filter(v => v != null);
        }

        return (value as ISearchInputParam).optionName;
      }
    }
  }, []);

  const cleanDealInfo = useCallback((dealInfo: IDealInfo): IDealInfo => {
    const newDealInfo = {...dealInfo};
    for (let key of Object.keys(newDealInfo)) {
      if (newDealInfo[key as keyof IDealInfo] == null) {
        delete newDealInfo[key as keyof IDealInfo];
      }
    }

    return newDealInfo;
  }, []);

  const setFormValue = useCallback((field: SearchField, value?: FormValue) => {
    switch (field.formatting) {
      case 'comma-separated':
        value = formatNumericString(value as string);
        break;
    }

    const packagedValue = packageValue(field, value);

    const packagedDownstreamChanges: Record<string, SearchFieldValue> = field.onValueChanged != null
      ? field.onValueChanged(packagedValue, dealInfo)
      : {};

    const downstreamChanges: typeof formValues = Object.keys(packagedDownstreamChanges).reduce((obj, key) => {
      const downstreamField = dealSearchFields.find(f => f.id === key);
      const visible = downstreamField?.visible == null || downstreamField.visible(dealInfo);
      if (downstreamField == null || !visible) {
        return obj;
      }

      return {
        ...obj,
        [key]: unpackageValue(downstreamField, packagedDownstreamChanges[key]),
      };
    }, {});

    for (let key of Object.keys(packagedDownstreamChanges)) {
      if (!Object.hasOwn(downstreamChanges, key)) {
        delete packagedDownstreamChanges[key];
      }
    }
    
    const newDealInfo: Partial<IDealInfo> = {
      ...dealInfo,
      [field.id]: packagedValue,
      ...packagedDownstreamChanges,
    };

    const newFormValues: typeof formValues = {
      ...formValues,
      [field.id]: value,
      ...downstreamChanges,
    };
    
    const dependentFields = dealSearchFields.filter(otherField => otherField.dependsOn?.find(fid => fid === field.id) != null);
    for (let depField of dependentFields) {
      delete newFormValues[depField.id];
    }

    const validatedFields = dealSearchFields.filter(f => f.extraValidation != null && (f.visible == null || f.visible(dealInfo)))
    const newErrors = validatedFields.reduce((obj, f) => {
      const errorMessage = f.extraValidation != null ? f.extraValidation(newDealInfo, dealSearchFields) : null;
      if (!errorMessage) {
        return obj;
      }

      return {
        ...obj,
        [f.id]: errorMessage,
      };
    }, {});

    setFormValues(newFormValues);
    setDealInfo(newDealInfo);
    setErrors(newErrors);
  }, [packageValue, dealInfo, formValues, dealSearchFields, unpackageValue]);

  const validateFormData = useCallback(() => {
    const newErrors = dealSearchFields.reduce((obj, field) => {
      const visible = !disabledFields?.includes(field.id) && (field.visible == null || field.visible(dealInfo));
      if (visible && field.required) {
        const value = formValues[field.id];
        if (value == null || (typeof value === 'string' && value.length === 0)) {
          return {
            ...obj,
            [field.id]: 'This field is required',
          };
        }

        const errorMessage = field.extraValidation != null ? field.extraValidation(dealInfo, dealSearchFields) : null;
        if (errorMessage) {
          return {
            ...obj,
            [field.id]: errorMessage,
          };
        }
      }
      
      return obj;
    }, {});

    console.log(`Errors: ${JSON.stringify(newErrors, null, 2)}`);

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }, [dealSearchFields, disabledFields, formValues, dealInfo]);

  const saveProjectClicked = useCallback(async () => {
    const isValid = validateFormData();
    if (!isValid) {
      return;
    }

    setLoading(true);
    
    try {
      if (deal == null) {
        const response = await createDeal({ dealInfo }).unwrap();
        const createdDeal = response.deal;
        
        if (createdDeal?._id != null) {
          if (onDealSaved != null) {
            onDealSaved(createdDeal?._id);
          }
        }
      } else {
        await updateDeal({
          dealId: deal._id!,
          equityDealReviewId: equityDealReview?._id,
          dealInfo: dealInfo,
        }).unwrap();
        
        if (onDealSaved != null) {
          onDealSaved(deal._id ?? '');
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      handleError({
        error,
        message: 'Failed saving project',
      });
    } finally {
      setLoading(false);
    }
  }, [validateFormData, deal, createDeal, dealInfo, onDealSaved, updateDeal, equityDealReview?._id, handleError]);

  useLayoutEffect(() => {
    if (deal?._id == null) {
      setFormValues({});
    } else {
      const dealInfo = cleanDealInfo(deal.dealInfo ?? {});

      setFormValues(Object.keys(dealInfo).reduce((obj, key) => {
        const field = dealSearchFields.find(f => f.id === key);
        if (field == null) {
          return obj;
        }

        return {
          ...obj,
          [key]: unpackageValue(field, dealInfo[key as keyof IDealInfo]),
        };
      }, {}));

      setDealInfo(dealInfo);
    }
    // eslint-disable-next-line
  }, [deal?.updatedDt]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      rowGap: '22px',
    }}>
      {dealSearchFields.map(field => (
        (field.visible == null || field.visible(dealInfo)) && !disabledFields?.includes(field.id) &&
          <DealParameter
            key={field.id}
            field={field}
            formValues={formValues}
            dealInfo={dealInfo}
            error={errors[field.id]}
            setFormValue={value => setFormValue(field, value)} />
      ))}

      <LoadingButton
        variant='contained'
        loading={loading}
        onClick={saveProjectClicked}>
        {'Save Project'}
      </LoadingButton>
    </Box>
  );
}
