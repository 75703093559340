import React, { useMemo } from 'react';
import PageContainer from '../PageContainer';
import { Avatar, Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useGetSponsorAccountDirectoryQuery } from '../../features/utils-api';
import { IAccount } from '../../schemas';
import { wrapColumnsInTooltips } from '../../utils/componentUtils';


export default function SponsorDirectory(): JSX.Element {

  const {
    data: sponsors,
    isFetching: isSponsorsFetching,
  } = useGetSponsorAccountDirectoryQuery();

  const columns: GridColDef<Pick<IAccount, '_id' | 'profileImageUrl' | 'profile'>>[] = useMemo(() => {
    return wrapColumnsInTooltips([
      {
        field: 'logo',
        width: 60,
        headerName: 'CREED Sponsor',
        valueGetter: (params) => params.row.profileImageUrl,
        renderCell: (params) => (
          <Box sx={{
            width: '100%',
          }}>
            <Avatar
              sx={{
                width: '42px',
                height: '42px',
              }}
              src={params.value} />
          </Box>
        ),
      },
      {
        field: 'hq',
        width: 140,
        headerName: 'HQ',
        valueGetter: (params) => params.row.profile?.companyHeadquarters ?? '-',
      },
      {
        field: 'targetAssetTypes',
        width: 190,
        headerName: 'Target Asset Types',
        valueGetter: (params) => params.row.profile?.assetClasses ?? '-',
      },
      {
        field: 'investmentStrategy',
        width: 190,
        headerName: 'Investment Strategy',
        valueGetter: (params) => params.row.profile?.investmentStrategy ?? '-',
      },
      {
        field: 'geographicFocus',
        width: 190,
        headerName: 'Geographic Focus',
        valueGetter: (params) => params.row.profile?.geographicFocus ?? '-',
      },
      {
        field: 'yearsOfExperience',
        width: 120,
        headerName: 'Years of Experience',
        valueGetter: (params) => params.row.profile?.experience ?? '-',
      },
      {
        field: 'holdPeriod',
        width: 130,
        headerName: 'Hold Period',
        valueGetter: (params) => params.row.profile?.typicalHoldPeriod ?? '-',
      },
      {
        field: 'historicalAverageIRR',
        width: 130,
        headerName: 'Historical Average IRR',
        valueGetter: (params) => params.row.profile?.historicalAverageIRR ?? '-',
      },
      {
        field: 'targetIRR',
        width: 130,
        headerName: 'Target IRR',
        valueGetter: (params) => params.row.profile?.targetIRR ?? '-',
      },
      {
        field: 'assetsUnderManagement',
        width: 130,
        headerName: 'Assets Under Management',
        valueGetter: (params) => params.row.profile?.assetsUnderManagement ?? '-',
      }
    ]);
  }, []);

  return (
    <PageContainer>
      <Typography sx={{
        marginBottom: '40px',
        fontWeight: '400',
        fontSize: '28px',
        lineHeight: '34.24px',
      }}>
        {'CREED Sponsor Directory'}
      </Typography>

      <DataGrid
        sx={{
          fontWeight: '400',
          fontSize: '12px',
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
          },
          '& .MuiDataGrid-columnHeader': {
            // Forced to use important since overriding inline styles
            height: 'unset !important'
          },
          '& .MuiDataGrid-columnHeaders': {
            // Forced to use important since overriding inline styles
            maxHeight: '168px !important'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        }}
        rows={sponsors ?? []}
        getRowId={r => r._id}
        columns={columns}
        rowHeight={60}
        checkboxSelection={false}
        rowSelection={false}
        disableColumnSelector
        autoPageSize
        loading={isSponsorsFetching && sponsors == null} />
    </PageContainer>
  );
}
