import { createSlice } from '@reduxjs/toolkit';
  
interface LoadingState {
  isLoading: boolean
}

const initialState: LoadingState = {
  isLoading: false
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setIsLoading(state) {
      // it's ok to do this because inner makes it immutable under the hood
      state.isLoading = true;
    },
    setFinishedLoading(state) {
      state.isLoading = false;
    }
  }
})

export const { setIsLoading, setFinishedLoading } = loadingSlice.actions;
export default loadingSlice.reducer;