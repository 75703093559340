import { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, TextField } from '@mui/material';
import CREEDDialogTitle from '../../CREEDDialogTitle';
import LoadingButton from '../../LoadingButton';
import { IDeal } from '../../../schemas';
import { useRespondToEquityMarketplaceDealMutation } from '../../../features/deals-api';
import { useAppDispatch } from '../../../app/hooks';
import { snacked } from '../../../features/snackMessage-slice';


interface Props extends DialogProps {
  readonly deal?: IDeal;
}

export default function InvestorRejectDealDialog({deal, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [comment, setComment] = useState('');

  const [respondToDeal, { isLoading: isRespondLoading }] = useRespondToEquityMarketplaceDealMutation();

  const passClicked = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deal?._id == null) {
      return;
    }

    try {
      await respondToDeal({
        accountId: '',
        dealId: deal._id,
        status: 'rejected',
        comment: comment.trim(),
      }).unwrap();

      if (dialogProps.onClose != null) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed responding to deal.',
        severity: 'error',
      }));
    }
  }, [deal?._id, respondToDeal, comment, dialogProps, dispatch]);

  return (
    <Dialog {...dialogProps}>
      <CREEDDialogTitle
        title='Pass on Project'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <form onSubmit={passClicked}>
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}>
          <DialogContentText>
            {'Are you sure you\'d like to pass on this project? While not required, providing a reason for your decision is highly valued by sponsors and can be entered in the text box below. Your insights are appreciated and help improve our offerings.'}
          </DialogContentText>
          <TextField
            sx={{
              width: '100%',
            }}
            InputProps={{
              style: {
                borderRadius: '8px',
              }
            }}
            label='Comment'
            multiline
            maxRows={10}
            rows={6}
            value={comment}
            onChange={e => setComment(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              flex: 1,
            }}
            variant='text'
            onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
            {'Cancel'}
          </Button>
          <LoadingButton
            sx={{
              flex: 1,
            }}
            type='submit'
            variant='contained'
            loading={isRespondLoading}
            disabled={deal == null}>
            {'Pass'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
