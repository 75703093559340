import React from 'react';
import { Box, Typography, Tooltip, IconButton, useMediaQuery, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { humanFileSize } from '../utils/utils';
import FileIcon from './FileIcon';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { IFile } from '../schemas';


interface Props {
  readonly variant?: 'full' | 'compact';
  readonly file: IFile;
  readonly canDelete?: boolean;
  readonly previewClicked?: () => void;
  readonly downloadClicked?: () => void;
  readonly moreClicked?: (e: React.MouseEvent<HTMLElement>) => void;
}

export default function FileRow(props: Props): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  return (
    <Box sx={{
      display: 'inline-flex',
      alignItems: 'center',
      height: '32px',
    }}>
      <Box sx={{ marginRight: '20px' }}>
        <FileIcon
          contentType={props.file.contentType}
          color={'#33333380'} />
      </Box>

      <Typography 
        sx={{
          display: 'flex',
          flex: 1,
          // width: '100%',
          fontWeight: '400',
          fontSize: '13px',
          lineHeight: '16px',
          textOverflow: 'ellipsis'
        }}
        variant='body1'>
        {props.file.name}
      </Typography>

      {!isMobile &&
        <Typography 
          sx={{
            display: 'flex',
            flex: 0.3,
            paddingLeft: 2,
            fontWeight: '400',
            fontSize: '13px',
            lineHeight: '16px',
          }}
          variant='body1'>
          {props.file.size != null ? humanFileSize(props.file.size, true) : ''}
        </Typography>
      }

      <Typography 
        sx={{
          display: 'flex',
          flex: 0.3,
          fontWeight: '400',
          fontSize: '13px',
          lineHeight: '16px',
        }}
        variant='body1'>
        {props.variant === 'compact'
          ? dayjs(props.file.uploadedDt).format('MM/D/YYYY')
          : dayjs(props.file.uploadedDt).format('MMM D, YYYY [at] h:mm A')
        }
      </Typography>

      {props.previewClicked != null &&
        <Tooltip title='Preview' arrow>
          <IconButton onClick={props.previewClicked}>
            <RemoveRedEyeOutlinedIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      }

      {props.downloadClicked != null &&
        <Tooltip title='Download' arrow>
          <IconButton onClick={props.downloadClicked}>
            <FileDownloadOutlinedIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      }

      {props.canDelete &&
        <IconButton onClick={props.moreClicked}>
          <MoreHorizOutlinedIcon fontSize='small' />
        </IconButton>
      }
    </Box>
  );
}
