import { useMemo } from "react";
import { IDeal, IDealCREEDScores } from "../schemas";


export interface CREEDScoreField {
  readonly title: string;
  readonly key: keyof IDealCREEDScores;
  readonly value?: number;
}

export function useCREEDScore(deal?: IDeal) {

  const fields: CREEDScoreField[] = useMemo(() => {
    return [
      {
        title: 'Borrower Strength',
        key: 'borrowerStrength',
        value: deal?.creedScores?.borrowerStrength,
      },
      {
        title: 'Geographic Market Rank',
        key: 'geographicMarketRank',
        value: deal?.creedScores?.geographicMarketRank,
      },
      {
        title: 'Location Rank',
        key: 'locationRank',
        value: deal?.creedScores?.locationRank,
      },
      {
        title: 'Project Cash Flow',
        key: 'projectCashFlow',
        value: deal?.creedScores?.projectCashFlow,
      },
      {
        title: 'Leverage',
        key: 'leverage',
        value: deal?.creedScores?.leverage,
      },
      {
        title: 'Cost Basis',
        key: 'costBasis',
        value: deal?.creedScores?.costBasis,
      },
    ];
  }, [
    deal?.creedScores?.borrowerStrength,
    deal?.creedScores?.geographicMarketRank,
    deal?.creedScores?.locationRank,
    deal?.creedScores?.projectCashFlow,
    deal?.creedScores?.leverage,
    deal?.creedScores?.costBasis,
  ]);
  
  const averageScore = useMemo(() => {
    let sum: number | undefined = 0.0;
    for (let field of fields) {
      if (field.value == null) {
        sum = undefined;
        break;
      }

      sum += field.value;
    }

    if (sum == null) {
      return undefined;
    }

    return sum / fields.length;
  }, [fields]);
  
  return {
    averageScore,
    fields,
  };
}
