import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import PageContainer from '../PageContainer';
import { Box, Typography, CircularProgress, Chip, Menu, MenuItem, useTheme, Button, Alert } from '@mui/material';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useGetDealStatusOptionsQuery, useGetDealImageUploadUrlMutation, useDeleteDealFileMutation, useGetSharedDealsForDealQuery, useGetEquityDealReviewForDealQuery, useLazyGetDealFileZipUrlQuery, useGetDealFileZipQuery, useLazyGetDealFileDownloadUrlQuery, useCreateDealFileUploadUrlMutation } from '../../features/deals-api';
import { snacked } from '../../features/snackMessage-slice';
import { ILender, IDealFile, IDeal, IFile } from '../../schemas';
import { useDealSearchFields } from '../../types/SearchField';
import DealAccessDialog from '../Dialogs/DealAccessDialog/DealAccessDialog';
import ShareDealDialog from '../ShareDealDialog/ShareDealDialog';
import useCustomClassesAndTheme from '../useCustomClassesAndTheme';
import sxClasses from '../Styles/Deal.Styles';
import FileUploadBox from '../FileUploadBox';
import FileRow from '../FileRow';
import { useGetCurrentAccountQuery, useGetCurrentUserQuery } from '../../features/accounts-api';
import DealSearchResults from './DealSearchResults';
import DealBaseHeader from './DealBaseHeader';
import EditDealDialog from './EditDealDialog';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AccountProfileDialog from '../AccountProfileDialog';
import { closeChat, showChatForThread } from '../../features/messaging-slice';
import DealQuoteTerms from './DealQuoteTerms';
import Card from '../Card';
import SendDealQuoteDialog from './SendDealQuoteDialog';
import RejectDealDialog from './RejectDealDialog';
import { usePopoverContext } from '../../utils/hooks';
import LenderCommentDialog from './LenderCommentDialog';
import DealCREEDScorePopover from './Dialogs/DealCREEDScorePopover';
import DealLenderResponseCard from './Cards/DealLenderResponseCard';
import { clearCreatedDeal } from '../../features/deals-slice';
import EquitySponsorView from './DealContentViews/EquitySponsorView';
import EquityInvestorView from './DealContentViews/EquityInvestorView';
import EditEquityDealWarningDialog from './Dialogs/EditEquityDealWarningDialog';
import LoadingButton from '../LoadingButton';
import { downloadFileFromUrl, uploadFileToUrl } from '../../utils/utils';
import DealDescriptionSection from './DealDescriptionSection';
import EnableWhiteGloveDialog from '../Dialogs/EnableWhiteGloveDialog/EnableWhiteGloveDialog';
import { useLazyGetThreadQuery } from '../../features/messaging-api';
import CREEDFeedbackDialog from './Dialogs/CREEDFeedbackDialog';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import RecommendedFilesTooltop from './RecommendedFilesTooltop';
import { useUpdateSharedDealMutation } from '../../features/shared-deals-api';

export const DealDialogContext = createContext<{
  readonly showCREEDScoreDialog?: (element: HTMLElement) => void;
  readonly showSponsorProfileDialog?: () => void;
  readonly showDealAccessDialog?: () => void;
}>({});

export const LenderSearchContext = createContext<{
  readonly showShareDealWithLenderDialog?: (lender: ILender) => void;
  readonly showLenderCommentDialog?: (comment: string) => void;
}>({});

interface Props {
  readonly deal?: IDeal;
  readonly isDealFetching: boolean;
  readonly refetchDeal: () => Promise<any>;
}

export default function DealBase(props: Props): JSX.Element {
  const classes = useCustomClassesAndTheme(sxClasses);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const dealsState = useAppSelector(state => state.deals);

  const [, setCreatedDeal] = useState(dealsState.createdDealIds.includes(props.deal?._id ?? ''));
  const [dealImageLoading, setDealImageLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [dealInvitationsOpen, setDealInvitationsOpen] = useState(false);
  const [sponsorProfileOpen, setSponsorProfileOpen] = useState(false);
  const [sendQuoteType, setSendQuoteType] = useState<'soft' | 'firm' | undefined>();
  const [rejectDealOpen, setRejectDealOpen] = useState(false);
  const [sharingLender, setSharingLender] = useState<ILender | undefined>();
  const [editingDeal, setEditingDeal] = useState(false);
  const [fileMenuAnchorElem, setFileMenuAnchorElem] = useState<HTMLElement | undefined>();
  const [selectedFile, setSelectedFile] = useState<IDealFile | undefined>();

  const {
    buildFieldDisplayList,
  } = useDealSearchFields();

  const {
    props: CREEDScorePopoverProps,
    showDialog: showCREEDScore,
    currentData: creedScoreButtonElem,
  } = usePopoverContext<HTMLElement>();

  const {
    props: editEquityDealWarningDialogProps,
    showDialog: showEditEquityDealWarning,
    hideDialog: hideEditEquityDealWarning,
  } = usePopoverContext();

  const {
    props: lenderCommentDialogProps,
    showDialog: showLenderComment,
    currentData: lenderComment,
  } = usePopoverContext<string>();

  const {
    props: CREEDFeedbackDialogProps,
    showDialog: showCREEDFeedback,
  } = usePopoverContext();

  const {
    props: whiteGloveDialogProps,
    showDialog: showWhiteGlove,
  } = usePopoverContext();

  const {data: account, isLoading: isAccountLoading} = useGetCurrentAccountQuery();
  const {data: user, isLoading: isUserLoading} = useGetCurrentUserQuery();
  const {data: dealFileZip} = useGetDealFileZipQuery({ dealId: props.deal?._id! }, { skip: props.deal?._id == null || props.deal?.files == null || props.deal.files.length === 0, pollingInterval: 5000 });
  const {data: equityDealReview, isLoading: isReviewLoading} = useGetEquityDealReviewForDealQuery({ dealId: props.deal?._id! }, {
    skip: props.deal?._id == null || props.deal?.accountId !== account?._id || props.deal?.dealInfo?.equityDebt?.optionName !== 'Equity'
  });
  const {data: sharedDeals, isLoading: isSharedDealsLoading} = useGetSharedDealsForDealQuery({ dealId: props.deal?._id!, accountType: 'all' }, { skip: props.deal?._id == null, pollingInterval: 5000 });
  const {data: dealStatusOptions, isLoading: isStatusOptionsLoading} = useGetDealStatusOptionsQuery();
  
  const [getFileDownloadUrl] = useLazyGetDealFileDownloadUrlQuery();
  const [createFileUploadUrl] = useCreateDealFileUploadUrlMutation();
  const [getThread] = useLazyGetThreadQuery();
  const [getDealZipUrl, { isFetching: isGetDealZipUrlFetching }] = useLazyGetDealFileZipUrlQuery();
  const [deleteFile] = useDeleteDealFileMutation();
  const [getDealImageUploadUrl] = useGetDealImageUploadUrlMutation();
  // const [getDealFileUploadUrlsMultipart] = useGetDealFileUploadUrlsMultipartMutation();
  const [updateSharedDeal] = useUpdateSharedDealMutation();

  const loading = props.isDealFetching || isAccountLoading || isUserLoading || isSharedDealsLoading || isReviewLoading || isStatusOptionsLoading;

  const dealStatusOptionNames: Record<string, string> = useMemo(() => {
    return dealStatusOptions?.reduce((obj, option) => {
      return {
        ...obj,
        [option.slug]: option.name,
      }
    }, {}) ?? {};
  }, [dealStatusOptions]);

  const dealInfo = useMemo(() => {
    return buildFieldDisplayList(props.deal?.dealInfo, { useFormatters: true })
      .filter(info => info.value != null);
  }, [buildFieldDisplayList, props.deal?.dealInfo]);

  const sharedDeal = useMemo(() => {
    if (sharedDeals == null || user == null) {
      return undefined;
    }

    const orgSharedDeal = sharedDeals.find(sd => sd.accountId === user.accountId);
    const individualSharedDeal = sharedDeals.find(sd => sd.accountId === user?.individualAccountId);

    return orgSharedDeal ?? individualSharedDeal;
  }, [user, sharedDeals]);

  const softTerms = useMemo(() => {
    if (sharedDeals == null || sharedDeals.length === 0) {
      return undefined;
    }

    const sd = sharedDeals[0];
    const quotes = sd.lenderQuotes?.filter(q => q.type === 'soft');
    if (quotes == null) {
      return undefined;
    }

    const quote = quotes[quotes.length - 1];
    return quote?.terms;
  }, [sharedDeals]);

  const firmTerms = useMemo(() => {
    if (sharedDeals == null || sharedDeals.length === 0) {
      return undefined;
    }

    const sd = sharedDeals[0];
    const quotes = sd.lenderQuotes?.filter(q => q.type === 'firm');
    if (quotes == null) {
      return undefined;
    }

    const quote = quotes[quotes.length - 1];
    return quote?.terms;
  }, [sharedDeals]);

  const deleteFileClicked = useCallback(async () => {
    if (props.deal?._id == null || selectedFile == null) {
      setFileMenuAnchorElem(undefined);
      setSelectedFile(undefined);
      return;
    }
    
    try {
      deleteFile({
        id: props.deal._id,
        fileKey: selectedFile.Key,
      });
    } finally {
      setFileMenuAnchorElem(undefined);
      setSelectedFile(undefined);
    }
  }, [selectedFile, setSelectedFile, setFileMenuAnchorElem, deleteFile, props.deal]);

  const downloadFileClicked = useCallback(async (file: IFile) => {
    if (props.deal?._id == null) {
      return;
    }

    try {
      const result = await getFileDownloadUrl({
        dealId: props.deal._id,
        fileType: 'file',
        fileKey: file.key,
      }).unwrap();
  
      downloadFileFromUrl(result.url, file.name);
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed downloading file',
        severity: 'error',
      }));
    }
  }, [dispatch, getFileDownloadUrl, props.deal?._id]);

  const fileMenuClicked = useCallback((event: React.MouseEvent<HTMLElement>, file: IDealFile) => {
    setSelectedFile(file);
    setFileMenuAnchorElem(event.currentTarget);
  }, [setSelectedFile, setFileMenuAnchorElem]);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (props.deal?._id == null) {
      return;
    }

    setFileUploadLoading(true);

    try {
      const uploadUrlResponses = await Promise.all(acceptedFiles.map(file => createFileUploadUrl({
        dealId: props.deal?._id!,
        fileType: 'file',
        fileName: file.name,
        contentType: file.type,
      }).unwrap()));

      await Promise.all(
        uploadUrlResponses.map((response, index) => uploadFileToUrl(response.url, acceptedFiles[index]))
      );
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed uploading files',
        severity: 'error',
      }));
    } finally {
      setFileUploadLoading(false);
    }
  }, [props.deal?._id, createFileUploadUrl, dispatch]);

  const {
    getInputProps: dealImageInputProps,
    open: openDealImageFileDialog,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: async (acceptedFiles) => {
      if (props.deal?._id == null || !account?.currentSubscription?.isActive) {
        return;
      }

      const file = acceptedFiles[0];
      if (file == null) {
        return;
      }

      setDealImageLoading(true);

      try {
        const urlResponse = await getDealImageUploadUrl({
          dealId: props.deal._id,
          fileName: file.name,
          contentType: file.type,
        }).unwrap();
  
        await axios.put(urlResponse.url, file, {
          headers: {
            'Content-Type': file.type
          },
        });

        setTimeout(async () => {
          setDealImageLoading(false);
        }, 500);
      } catch (error) {
        console.error(error);
        
        dispatch(snacked({
          message: 'Error uploading image',
          severity: 'error',
        }));
        
        setDealImageLoading(false);
      }
    }
  });

  const dealImageClicked = useCallback(async () => {
    if (props.deal?._id == null || account?._id !== props.deal.accountId || !account?.currentSubscription?.isActive) {
      return;
    }

    openDealImageFileDialog();
  }, [props.deal, account, openDealImageFileDialog]);

  const downloadZipClicked = useCallback(async () => {
    if (props.deal?._id == null || dealFileZip == null || dealFileZip.status !== 'complete') {
      return;
    }
    
    try {
      const response = await getDealZipUrl({ dealId: props.deal._id }).unwrap();
      downloadFileFromUrl(response.url, `${props.deal.name}-files.zip`);
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed downloading project zip file.',
        severity: 'error',
      }));
    }
  }, [dealFileZip, dispatch, getDealZipUrl, props.deal?._id, props.deal?.name]);

  const shareDealClicked = useCallback((lender: ILender) => {
    setSharingLender(lender);
  }, [setSharingLender]);

  const editDealClicked = useCallback(() => {
    if (props.deal?.dealInfo?.equityDebt?.optionName === 'Equity' && ((equityDealReview != null && ['pending', 'approved'].includes(equityDealReview.status)) || props.deal.privacy !== 'private')) {
      showEditEquityDealWarning();
    } else {
      setEditingDeal(true);
    }
  }, [props.deal, showEditEquityDealWarning, setEditingDeal, equityDealReview]);

  const acceptEditEquityDealWarningClicked = useCallback(() => {
    hideEditEquityDealWarning();
    setEditingDeal(true);
  }, [hideEditEquityDealWarning, setEditingDeal]);

  const quoteSubmitted = useCallback(async () => {
    if (sharedDeal == null) {
      return;
    }

    if (sharedDeal.lenderStatus === 'under-review') {
      await updateSharedDeal({
        sharedDealId: sharedDeal?._id!,
        dealId: sharedDeal?.dealId,
        changes: {
          lenderStatus: 'quoted',
        }
      });
    }
  }, [sharedDeal, updateSharedDeal]);

  const openProjectChatroom = useCallback(async (threadId: string) => {
    const thread = await getThread({ threadId }).unwrap();
    if (thread == null || thread.recipientIds.length > 1) {
      dispatch(showChatForThread({
        key: 'project-chat',
        threadId: threadId,
        windowTitle: 'Project chatroom',
        windowSubtitle: 'Group chat for all project participants',
        closable: false,
        minimizable: true,
        minimized: true,
      }));
    }
  }, [dispatch, getThread]);

  useEffect(() => {
    if (props.deal?._id != null) {
      if (dealsState.createdDealIds.includes(props.deal._id)) {
        setCreatedDeal(true);
        dispatch(clearCreatedDeal({dealId: props.deal._id}));
      }
    }
  }, [dispatch, setCreatedDeal, props.deal?._id, dealsState.createdDealIds]);

  useEffect(() => {
    if (account?.currentSubscription?.isActive) {
      if (props.deal?.threadId && (props.deal.accountId === account._id || sharedDeal != null) && props.deal.dealInfo?.equityDebt?.optionName !== 'Equity') {
        openProjectChatroom(props.deal.threadId);
      }
    }

    return () => {
      dispatch(closeChat({ key: 'project-chat' }));
    };
  }, [props.deal?.threadId, account?.creedContact?.accountId, props.deal?.dealInfo?.equityDebt?.optionName, account?.currentSubscription?.isActive, dispatch, account?._id, props.deal?.accountId, sharedDeal, openProjectChatroom, sharedDeals?.length]);

  return (
    <PageContainer
      loading={loading}
      contentSx={{
        paddingBottom: '120px',
      }}>
      <DealDialogContext.Provider value={{
        showCREEDScoreDialog: showCREEDScore,
        showSponsorProfileDialog: () => setSponsorProfileOpen(true),
        showDealAccessDialog: () => setDealInvitationsOpen(true),
      }}>
        <DealBaseHeader deal={props.deal} />

        {/* {dealCreated && props.deal?.dealInfo?.equityDebt?.optionName === 'Equity' &&
          <Box sx={{
            paddingBottom: '48px',
          }}>
            <Alert icon={<CheckRoundedIcon fontSize='inherit' />}>
              <Typography>
                {'Your deal has been published on CREED\'s Equity Marketplace! CREED Investors will reach out to you directly here if they are interested in this project.'}
              </Typography>
            </Alert>
          </Box>
        } */}

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          gap: '22px',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}>
          <Box sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
            <Box sx={classes.dealInfoBox}>
              <input {...dealImageInputProps()} />
              <Box
                component={'div'}
                sx={classes.dealImageClickableBox}
                onClick={dealImageClicked}>
                
                <img
                  style={classes.dealImage}
                  alt={props.deal?.name ?? 'Deal image'}
                  src={props.deal?.imageUrl ? props.deal.imageUrl : require('../../images/default-creed-deal.jpg')} />

                <Box
                  component={'div'}
                  sx={{
                    ...classes.dealImageOverlay,
                    background: dealImageLoading ? '#00000040' : '#00000000',
                    opacity: dealImageLoading ? 1.0 : 0.0,
                    ':hover': (account?._id === props.deal?.accountId && account?.currentSubscription?.isActive) ? {
                      opacity: 1.0,
                      background: '#00000040',
                    } : undefined,
                  }}>
                  <Box sx={classes.dealImageOverlayContentBox}>
                    {dealImageLoading ? (
                      <CircularProgress
                        sx={classes.dealImageLoadingIndicator}
                        size={25} />
                    ) : (
                      <Typography
                        variant='h3'
                        sx={classes.dealImageUploadText}>
                        {'Edit image'}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box sx={classes.dealInfoFieldsBox}>
                <Chip
                  sx={{
                    alignSelf: 'flex-start',
                    marginBottom: '4px',
                  }}
                  label={props.deal?.status ? dealStatusOptionNames[props.deal.status] : 'Unknown'}
                  size='small'
                  color='info' />
                
                {dealInfo.map(item => (
                  <Typography
                    key={item.title}
                    sx={{
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '15px',
                      color: '#333333',
                    }}>
                    {item.value}
                  </Typography>
                ))}

                {props.deal?.status === 'search'
                 && props.deal?.accountId === account?._id &&
                  <Button
                    sx={{
                      marginTop: '8px',
                      alignSelf: 'flex-start',
                      gap: '10px',
                      fontSize: '14px',
                    }}
                    variant='outlined'
                    color='secondary'
                    onClick={editDealClicked}>
                    <EditRoundedIcon sx={{fontSize: '16px'}} />
                    {'Update search'}
                  </Button>
                }
              </Box>
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: '12px',
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
              }}>
                <Typography sx={{
                  fontWeight: '500',
                  fontSize: '22px',
                }}>
                  {'Project Files'}
                </Typography>
              </Box>
              <LoadingButton
                variant='text'
                color='secondary'
                size='small'
                disabled={dealFileZip == null || dealFileZip.status !== 'complete'}
                loading={isGetDealZipUrlFetching}
                onClick={downloadZipClicked}>
                {'Download All Files'}
              </LoadingButton>
            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '12px',
            }}>
              {props.deal && props.deal.files != null && props.deal.files.length > 0 ? props.deal.files?.map((file, fileIdx) => (
                <FileRow
                  key={`file-${fileIdx}`}
                  variant='compact'
                  file={{...file, bucket: file.Bucket, key: file.Key,}}
                  canDelete
                  downloadClicked={() => downloadFileClicked({...file, bucket: file.Bucket, key: file.Key,})}
                  moreClicked={(e) => fileMenuClicked(e, file)} />
                )) : (
                  <Typography sx={{
                    fontWeight: '400',
                    fontSize: '12px',
                  }}>
                    {'No files uploaded yet'}
                  </Typography>
                )
              }

              {account?.currentSubscription?.isActive && (props.deal?.accountId === account._id || sharedDeal != null) &&
                <Box sx={{
                  marginTop: '24px',
                }}>
                  <FileUploadBox
                    loading={fileUploadLoading}
                    onFilesDropped={onDrop}
                    disabled={props.deal?.accountId !== account._id && sharedDeal == null} />
                </Box>
              }

              {account?.type === 'sponsor' && props.deal?.dealInfo?.equityDebt?.optionName === 'Debt' &&
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  alignItems: 'center',
                  paddingTop: 1,
                }}>
                  <RecommendedFilesTooltop deal={props.deal}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 0.5,
                      alignItems: 'center',
                      color: '#333333DD',
                    }}>
                      <Typography sx={{
                        fontWeight: '400',
                        fontSize: '14px',
                      }}>
                        {'Suggested project files'}
                      </Typography>
                      <InfoRoundedIcon sx={{fontSize: '14px'}} />
                    </Box>
                  </RecommendedFilesTooltop>

                  {/* <Tooltip
                    title='Download suggested project files list'
                    enterDelay={500}>
                    <IconButton
                      sx={{
                        color: '#333333DD',
                        fontSize: '16px',
                      }}
                      size='small'
                      onClick={e => setDownloadSuggestedAnchorElem(e.currentTarget)}>
                      <FileDownloadRoundedIcon fontSize='inherit' />
                    </IconButton>
                  </Tooltip> */}
                </Box>
              }
            </Box>

            {props.deal?.accountId === account?._id && props.deal?.dealInfo?.equityDebt?.optionName === 'Debt' &&
              (props.deal?.whiteGloveStatus === 'enabled' ? (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  paddingTop: 3,
                  fontWeight: '400',
                  fontSize: '14px',
                }}>
                  <Alert severity='warning' icon={false}>
                    {'CREED\'s White Glove Service has been activated'}
                  </Alert>
                  <Typography fontSize='inherit'>
                    {'Please contact our Capital Markets Team at any time for questions regarding your deal:'}
                  </Typography>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '48px',
                  }}>
                    <Box>
                      <Typography fontSize='inherit'>
                        {'Ryan Zihenni'}
                      </Typography>
                      <Typography fontSize='inherit'>
                        {'Analyst'}
                      </Typography>
                      <Typography fontSize='inherit'>
                        {'ryan@creed.tech'}
                      </Typography>
                      <Typography fontSize='inherit'>
                        {'(201) 509-0920'}
                      </Typography>

                      <Button
                        sx={{
                          marginTop: '8px',
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => window.open('https://calendar.google.com/calendar/appointments/AcZssZ3ycJgSWAWAOdsFjdO_T_8eo8Znjm_-3ok5HRs=?gv=true')}>
                        {'Book an Appointment'}
                      </Button>
                    </Box>
                    <Box>
                      <Typography fontSize='inherit'>
                        {'Sasha Terentiev'}
                      </Typography>
                      <Typography fontSize='inherit'>
                        {'Associate'}
                      </Typography>
                      <Typography fontSize='inherit'>
                        {'sasha@creed.tech'}
                      </Typography>
                      <Typography fontSize='inherit'>
                        {'(201) 245-6099'}
                      </Typography>

                      <Button
                        sx={{
                          marginTop: '8px',
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => window.open('https://calendar.google.com/calendar/appointments/AcZssZ3YDaOPfXsVzr_VuFNw5kHs0wcsYQM0n68OmM8=?gv=true')}>
                        {'Book an Appointment'}
                      </Button>
                    </Box>
                    <Box>
                      <Typography fontSize='inherit'>
                        {'Harris Heller'}
                      </Typography>
                      <Typography fontSize='inherit'>
                        {'Head Of Capital Markets'}
                      </Typography>
                      <Typography fontSize='inherit'>
                        {'harris@creed.tech'}
                      </Typography>
                      <Typography fontSize='inherit'>
                        {'(215) 221-2793'}
                      </Typography>

                      <Button
                        sx={{
                          marginTop: '8px',
                        }}
                        size='small'
                        variant='contained'
                        onClick={() => window.open('https://calendar.google.com/calendar/appointments/schedules/AcZssZ2riTYSoq44lQ7Db0Tp13Cz2pm5ORdknNmDTBn6YcfNFxUMMoZnClFpLUlp1irxnB8OsZ4cYgJ7?gv=true')}>
                        {'Book an Appointment'}
                      </Button>
                    </Box>

                    
                  </Box>
                </Box>
              ) : props.deal?.whiteGloveStatus == null ? (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingTop: '32px',
                  gap: '16px',
                }}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    disabled={props.deal?.feedbackStatus === 'requested'}
                    onClick={() => showCREEDFeedback()}>
                    {'CREED Feedback'}
                  </Button>
                  <Button
                    sx={{
                      flex: 1,
                    }}
                    variant='contained'
                    onClick={() => showWhiteGlove(undefined)}>
                    {'Activate CREED\'s White Glove Service'}
                  </Button>
                </Box>
              ) : (
                <></>
              ))
            }
          </Box>
        </Box>

        {(props.deal?.description || account?._id === props.deal?.accountId) &&
          <Box sx={{
            paddingTop: '24px',
          }}>
            <DealDescriptionSection deal={props.deal} />
          </Box>
        }

        {props.deal?.dealInfo?.equityDebt?.optionName === 'Debt' &&
          <Box sx={{
            paddingTop: '24px',
          }}>
            <Typography sx={{
              fontWeight: '500',
              fontSize: '22px',
            }}>
              {'Potential Lenders'}
            </Typography>

            <LenderSearchContext.Provider value={{
              showShareDealWithLenderDialog: shareDealClicked,
              showLenderCommentDialog: showLenderComment,
            }}>
              <DealSearchResults deal={props.deal} />
            </LenderSearchContext.Provider>
          </Box>
        }

        {props.deal?.accountId !== account?._id && account?.type === 'lender' && props.deal?.dealInfo?.equityDebt?.optionName !== 'Equity' &&
          <Box sx={{
            paddingTop: '48px',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <DealLenderResponseCard
                sharedDeal={sharedDeal}
                sendSoftQuoteClicked={() => setSendQuoteType('soft')}
                sendFirmQuoteClicked={() => setSendQuoteType('firm')}
                rejectDealClicked={() => setRejectDealOpen(true)} />
            </Box>

            {sharedDeal?.lenderStatus !== 'rejected' &&
              <Box sx={{
                display: 'flex',
                paddingTop: '48px',
                gap: '42px',
              }}>
                {softTerms == null ? (
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifySelf: 'flex-start',
                    width: '464px',
                    padding: '32px',
                    borderColor: '#33333344',
                    borderWidth: 0.5,
                    borderStyle: 'solid',
                    borderRadius: '8px',
                  }}>
                    <Typography sx={{
                      fontWeight: '500',
                      fontSize: '20px',
                      lineHeight: '24px',
                      color: '#333333CC',
                    }}>
                      {'Soft Terms'}
                    </Typography>
                    <Typography sx={{
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: '#333333CC',
                    }}>
                      {'Send your soft terms to the borrower. They\'ll show up here.'}
                    </Typography>
                  </Box>
                ) : (
                  <Card sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifySelf: 'flex-start',
                    alignSelf: 'flex-start',
                    rowGap: '24px',
                  }}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}>
                      <Typography sx={{
                        fontWeight: '500',
                        fontSize: '20px',
                        lineHeight: '24px',
                      }}>
                        {'Soft Terms'}
                      </Typography>
      
                    </Box>
                    
                    <DealQuoteTerms terms={softTerms} />
                  </Card>
                )}

                {firmTerms == null ? (
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifySelf: 'flex-start',
                    width: '464px',
                    padding: '32px',
                    borderColor: '#33333344',
                    borderWidth: 0.5,
                    borderStyle: 'solid',
                    borderRadius: '8px',
                  }}>
                    <Typography sx={{
                      fontWeight: '500',
                      fontSize: '20px',
                      lineHeight: '24px',
                      color: '#333333CC',
                    }}>
                      {'Firm Quote'}
                    </Typography>
                    <Typography sx={{
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: '#333333CC',
                    }}>
                      {'Send your firm quote to the borrower. It will show up here.'}
                    </Typography>
                  </Box>
                ) : (
                  <Card sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifySelf: 'flex-start',
                    alignSelf: 'flex-start',
                    rowGap: '24px',
                  }}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}>
                      <Typography sx={{
                        fontWeight: '500',
                        fontSize: '20px',
                      }}>
                        {'Firm Quote'}
                      </Typography>
                    </Box>

                    <DealQuoteTerms terms={firmTerms} />
                  </Card>
                )}
              </Box>
            }
          </Box>
        }

        {props.deal?.accountId !== account?._id && account?.type === 'investor' && props.deal?.dealInfo?.equityDebt?.optionName === 'Equity' &&
          <Box sx={{
            paddingTop: '48px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <EquityInvestorView
              account={account}
              user={user}
              deal={props.deal} />
          </Box>
        }

        {props.deal?.accountId === account?._id && props.deal?.dealInfo?.equityDebt?.optionName === 'Equity' &&
          <Box sx={{
            paddingTop: '48px',
          }}>
            <EquitySponsorView
              account={account}
              user={user}
              deal={props.deal} />
          </Box>
        }

        <Menu
          open={fileMenuAnchorElem != null}
          anchorEl={fileMenuAnchorElem}
          onClose={() => setFileMenuAnchorElem(undefined)}>
          <MenuItem onClick={deleteFileClicked}>{'Delete file'}</MenuItem>
        </Menu>

        <DealAccessDialog
          open={dealInvitationsOpen}
          deal={props.deal}
          onClose={() => setDealInvitationsOpen(false)} />

        {account?.type !== 'sponsor' &&
          <>
            <AccountProfileDialog
              open={sponsorProfileOpen}
              onClose={() => setSponsorProfileOpen(false)}
              account={props.deal?.account} />
          </>
        }

        {account?.type === 'sponsor' &&
          <>
            <EditEquityDealWarningDialog
              {...editEquityDealWarningDialogProps}
              deal={props.deal}
              updateClicked={acceptEditEquityDealWarningClicked} />
          </>
        }

        {account?.type === 'lender' &&
          <>
            <DealCREEDScorePopover
              {...CREEDScorePopoverProps}
              anchorEl={creedScoreButtonElem}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom'
              }}
              deal={props.deal} />

            <SendDealQuoteDialog
              open={sendQuoteType != null}
              onClose={() => setSendQuoteType(undefined)}
              sharedDeal={sharedDeal}
              quoteType={sendQuoteType}
              onSuccess={quoteSubmitted} />

            <RejectDealDialog
              open={rejectDealOpen}
              onClose={() => setRejectDealOpen(false)}
              sharedDeal={sharedDeal} />
          </>
        }

        <LenderCommentDialog
          {...lenderCommentDialogProps}
          comment={lenderComment} />

        <EditDealDialog
          open={editingDeal}
          deal={props.deal}
          onClose={() => setEditingDeal(false)} />

        <ShareDealDialog
          open={sharingLender != null}
          lender={sharingLender}
          deal={props.deal}
          onClose={() => setSharingLender(undefined)} />

        <CREEDFeedbackDialog
          {...CREEDFeedbackDialogProps}
          deal={props.deal} />

        <EnableWhiteGloveDialog
          {...whiteGloveDialogProps}
          deal={props.deal} />
      </DealDialogContext.Provider>
    </PageContainer>
  );
}
