import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { accountsApiSlice } from "./accounts-api";
import { baseUrls, createBaseQuery } from "./apiHelper";


export const paymentsApiSlice = createApi({
  reducerPath: 'api-payments',
  tagTypes: [],
  baseQuery: createBaseQuery(baseUrls.main),
  endpoints: (build) => ({
    checkout: build.mutation<{url: string}, void>({
      query: () => {
        return {
          url: '/checkout',
          method: 'POST',
        }
      },
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        const accountPatchResult = dispatch(
          accountsApiSlice.util.updateQueryData('getCurrentAccount', undefined, (draft) => {
            draft.isOnboarding = false;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          accountPatchResult.undo();
        }
      }
    }),
  }),
});

export const {
  useCheckoutMutation,
} = paymentsApiSlice;
