import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';
import PageContainer from '../PageContainer';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';


export default function CheckoutComplete(): JSX.Element {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [timedOut, setTimedOut] = useState(false);

  const {data: account} = useGetCurrentAccountQuery(undefined, {
    pollingInterval: 1000,
    skip: timedOut,
  });

  // Timeout after 10 seconds and report failure creating subscription
  useEffect(() => {
    setTimeout(() => {
      setTimedOut(true);
    }, 10_000);
  }, []);

  useEffect(() => {
    if (account?.currentSubscription?.isActive) {
      navigate('/home');
      dispatch(snacked({
        message: 'Subscription is active',
        severity: 'success',
      }));
    }
  }, [account?.currentSubscription?.isActive, dispatch, navigate]);

  return (
    <PageContainer showHeader={false}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '400px',
          padding: '40px',
          paddingTop: '48px',
        }}>
          <Typography
            sx={{
              paddingBottom: '32px',
              textAlign: 'center',
            }}
            variant='h3'>
            {timedOut ? 'Error' : 'Processing your subscription'}
          </Typography>

          {timedOut ? (
            <>
              <Typography sx={{
                textAlign: 'center',
                fontWeight: '400',
                fontSize: 14,
                color: '#333333',
              }}>
                {'There was a problem processing your subscription, please contact CREED support at support@creed.tech'}
              </Typography>
            </>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Box>
    </PageContainer>
  );
}
