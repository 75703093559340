import {AccountType} from '../schemas';


export interface IProfileField {
  readonly title: string;
  readonly key: string;
  readonly required?: boolean;
  readonly multiline?: boolean;
  readonly characterLimit?: number;
  readonly type?: 'boolean' | 'text' | 'number' | 'phone' | 'select' | 'radio' | 'row';
  readonly multiselect?: boolean;
  readonly options?: string[];
  readonly radioOptions?: {
    readonly value: string;
    readonly label: string;
  }[];
  readonly info?: string;
  readonly header?: string;
  readonly formatting?: 'comma-separated';
  readonly isVisible?: (values: Record<string, any>) => boolean;
  readonly children?: IProfileField[];
}

export interface IProfileSection {
  readonly fields: IProfileField[];
}

export function getProfileFields(accountType: AccountType): IProfileSection[] {
  switch (accountType) {
    case 'sponsor':
      return [
        {
          fields: [
            {
              title: 'Company Website',
              key: 'companyWebsite',
            },
          ]
        },
        {
          fields: [
            {
              title: 'Company Name',
              key: 'companyName',
            },
            {
              title: 'Phone Number',
              key: 'phoneNumber',
              type: 'phone',
            },
            {
              title: 'Company Headquarters',
              key: 'companyHeadquarters',
              info: 'City, State',
              required: true,
            },
            {
              title: 'Geographic Focus',
              key: 'geographicFocus',
              info: 'Primary geographic areas of investment',
              required: true,
            },
            {
              title: 'Assets Under Management',
              key: 'assetsUnderManagement',
              multiline: true,
              info: 'Total value of assets under management',
              required: true,
            },
            {
              title: 'Experience',
              key: 'experience',
              info: 'Years in business, investment approach, primary asset types, etc',
              required: true,
            },
            {
              title: 'Historical Average IRR',
              key: 'historicalAverageIRR',
              required: true,
            },
            {
              title: 'Investment Strategy',
              key: 'investmentStrategy',
              characterLimit: 500,
              multiline: true,
              info: 'Tell us about your investment approach - renovation, construction, etc',
            },
            {
              title: 'Asset Types/Class',
              key: 'assetClasses',
            },
            {
              title: 'Target IRR',
              key: 'targetIRR',
            },
            {
              title: 'Typical Hold Period',
              key: 'typicalHoldPeriod',
              info: 'Years',
            },
          ]
        }
      ];
    case 'broker':
      return [
        {
          fields: [
            {
              title: 'Company Website',
              key: 'companyWebsite',
            },
          ]
        },
        {
          fields: [
            {
              title: 'Company Name',
              key: 'companyName',
              required: true,
            },
            {
              title: 'Phone Number',
              key: 'phoneNumber',
              type: 'phone',
            },
            {
              title: 'Company Headquarters',
              key: 'companyHeadquarters',
              info: 'City, State',
              required: true,
            },
            {
              title: 'Geographic Focus',
              key: 'geographicFocus',
              info: 'Regional coverage, national, etc',
              required: true,
            },
            {
              title: 'Brokerage Experience',
              key: 'brokerageExperience',
              info: 'Tell us a little bit about your experience - number of years, total equity/debt brokerage in dollars, etc',
              characterLimit: 750,
              multiline: true,
              required: true,
            },
            {
              title: 'Broker Specializations',
              key: 'brokerSpecializations',
              info: 'Tell us a little bit about what you do best - areas of geographical focus/asset types/stabilized or repositions, etc',
              characterLimit: 750,
              multiline: true,
              required: true,
            },
          ]
        }
      ];
    case 'lender':
      return [
        {
          fields: [
            {
              title: 'Property Type(s)',
              key: 'propertyTypes',
              required: true,
              type: 'select',
              multiselect: true,
              options: [
                'Residential/Multifamily',
                'Retail',
                'Office',
                'Hotel/Lodging',
                'Industrial',
                'Self Storage',
                'Healthcare',
              ],
            },
            {
              title: 'Business Plan(s)',
              key: 'businessPlans',
              required: true,
              type: 'select',
              multiselect: true,
              options: [
                'Stabilized',
                'Renovation',
                'Reposition',
                'Vertical Construction',
                'Entitled Land',
                'Unentitled Land',
                'Predevelopment',
                'Horizontal Construction',
              ],
            },
            {
              title: '',
              key: '',
              type: 'row',
              children: [
                {
                  title: 'Min Loan Size ($)',
                  key: 'minLoanSize',
                  required: true,
                  type: 'number',
                  formatting: 'comma-separated',
                },
                {
                  title: 'Max Loan Size ($)',
                  key: 'maxLoanSize',
                  required: true,
                  type: 'number',
                  formatting: 'comma-separated',
                },
              ]
            },
            {
              title: 'Max LTV/LTC (%)',
              key: 'maxLTV',
              required: true,
              type: 'number',
            },
            {
              title: 'Current Interest Range',
              key: 'currentInterestRange',
              required: true,
            },
            {
              title: 'Origination Fee (%)',
              key: 'originationFee',
              required: true,
              type: 'number',
            },
            {
              title: 'Recourse',
              key: 'recourse',
              required: true,
              type: 'select',
              multiselect: true,
              options: [
                'Recourse',
                'Non-Recourse',
              ]
            },
            {
              title: 'Geographic Focus',
              key: 'geographicFocus',
              required: true,
            },
          ]
        }
      ];
    case 'investor':
      return [
        {
          fields: [
            {
              title: 'Equity Structure(s)',
              key: 'equityStructures',
              required: true,
              type: 'select',
              multiselect: true,
              options: [
                'LP Equity',
                'Co-GP Equity',
                'JV Equity',
                'Pref Equity',
              ]
            },
            {
              title: '',
              key: '',
              type: 'row',
              children: [
                {
                  title: 'Min Check Size ($)',
                  key: 'minCheckSize',
                  required: true,
                  type: 'number',
                  formatting: 'comma-separated',
                },
                {
                  title: 'Max Check Size ($)',
                  key: 'maxCheckSize',
                  required: true,
                  type: 'number',
                  formatting: 'comma-separated',
                },
              ],
            },
            {
              title: 'Total Capital to be Deployed in the Next 12 Months ($)',
              key: 'totalCapitalDeployed',
              required: true,
              type: 'number',
              formatting: 'comma-separated',
            },
            {
              title: 'Geographic Focus',
              key: 'geographicFocus',
              required: true,
            },
            {
              title: 'Target Property Type(s)',
              key: 'targetPropertyTypes',
              required: true,
              type: 'select',
              multiselect: true,
              options: [
                'Residential/Multifamily',
                'Retail',
                'Office',
                'Hotel/Lodging',
                'Industrial',
                'Self Storage',
                'Healthcare',
              ],
            },
            {
              title: 'Business Plan(s)',
              key: 'businessPlans',
              required: true,
              type: 'select',
              multiselect: true,
              options: [
                'Stabilized',
                'Renovation',
                'Reposition',
                'Vertical Construction',
                'Entitled Land',
                'Unentitled Land',
                'Predevelopment',
                'Horizontal Construction',
              ],
            },
            {
              title: 'Hold Period(s)',
              key: 'investmentHorizons',
              required: true,
              type: 'select',
              multiselect: true,
              options: [
                '<1 Year',
                '1-3 Years',
                '3-5 Years',
                '7-10 Years',
                '10+ Years',
              ],
            },
            {
              title: 'Return Hurdles',
              key: 'returnHurdles',
              required: true,
            },
            {
              title: 'Additional Notes (strategy or other)',
              key: 'additionalNotes',
              required: false,
              characterLimit: 750,
              multiline: true,
            }
          ]
        }
      ];
    default:
      return [];
  }
}
