import React, { useCallback, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';
import { ISharedDeal } from '../../schemas';
import DialogCloseButton from '../DialogCloseButton';
import { DealQuoteTermField, useDealQuoteTermFields } from '../../types/DealQuoteTermFields';
import LoadingButton from '../LoadingButton';
// import { useSendDealQuoteMutation } from '../../features/deals-api';
import { parseNumericString } from '../../utils/utils';
import SendDealQuoteField from './SendDealQuoteField';


interface Props extends DialogProps {
  readonly quoteType?: 'soft' | 'firm';
  readonly sharedDeal?: ISharedDeal;
  readonly onSuccess?: () => Promise<void>;
}

export default function SendDealQuoteDialog({quoteType, sharedDeal, onSuccess, ...dialogProps}: Props): JSX.Element {
  
  const [loading, setLoading] = useState(false);

  const {
    fields,
    fieldValues,
    onFieldValueChanged,
  } = useDealQuoteTermFields();
  
  // const [sendDealQuote] = useSendDealQuoteMutation();

  const onClose = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent> | {}) => {
    if (dialogProps.onClose != null) {
      dialogProps.onClose(event, 'escapeKeyDown');
    }
  }, [dialogProps]);

  const sendQuoteClicked = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (sharedDeal == null || quoteType == null) {
      return;
    }

    setLoading(true);

    // try {
    //   await sendDealQuote({
    //     sharedDealId: sharedDeal?._id!,
    //     dealId: sharedDeal.dealId,
    //     type: quoteType,
    //     terms: packageFieldValues(),
    //   }).unwrap();

    //   if (onSuccess != null) {
    //     await onSuccess();
    //   }

    //   onClose({});

    //   dispatch(snacked({
    //     message: 'Quote submitted',
    //     severity: 'success',
    //   }));
    // } catch (error) {
    //   console.error(error);
    //   dispatch(snacked({
    //     message: 'Failed submitting quote',
    //     severity: 'error',
    //   }));
    // } finally {
    //   setLoading(false);
    // }
  }, [setLoading, quoteType, sharedDeal]);
  
  const fieldError = useCallback((field: DealQuoteTermField) => {
    const value = fieldValues[field.key];
    if (value == null) {
      return undefined;
    }

    if (field.type !== 'number') {
      return undefined;
    }

    const parsedValue = typeof value === 'string' ? parseNumericString(value) : value;
    if (parsedValue == null) {
      return undefined;
    }

    if (field.min != null && parsedValue < field.min) {
      return `${field.title} must be greater than ${field.min}`;
    } else if (field.max != null && parsedValue > field.max) {
      return `${field.title} must be less than ${field.max}`;
    }

    return undefined;
  }, [fieldValues]);

  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}
      maxWidth={false}>
      <DialogTitle sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '24px',
        }}>
          {quoteType === 'soft' ? 'Soft Terms' : 'Firm Quote'}
        </Typography>
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <form onSubmit={sendQuoteClicked}>
        <DialogContent>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            flexBasis: 0.5,
            maxHeight: '75vh',
            gap: '24px',
            paddingRight: '24px',
          }}>
            {fields.map(field => (
              <SendDealQuoteField
                key={field.key}
                field={field}
                value={fieldValues[field.key]}
                error={fieldError(field)}
                onValueChange={value => onFieldValueChanged(field, value)} />
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={{
          padding: '22px',
        }}>
          <LoadingButton
            sx={{
              width: '100%',
            }}
            variant='contained'
            type='submit'
            loading={loading}>
            {quoteType === 'soft' ? 'Send Soft Terms' : 'Send Firm Quote'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}