import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageContainer from '../PageContainer';
import RegistrationFormV2 from '../RegistrationFormV2';
import { AuthData } from '../AuthForm';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { loggedIn } from '../../features/auth-slice';
import { IAccountTeam } from '../../schemas';
import { getDefaultPathForAccountType } from '../../utils/utils';
import { useAppDispatch } from '../../app/hooks';
import { useLazyGetCurrentAccountQuery, useLazyGetCurrentAccountTeamQuery, useLazyGetCurrentUserQuery } from '../../features/accounts-api';
import { useCheckoutMutation } from '../../features/payments-api';
import { isAccountProfileComplete } from '../../utils/hooks';


export default function Register(): JSX.Element {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const [getAccount] = useLazyGetCurrentAccountQuery();
  const [getTeam] = useLazyGetCurrentAccountTeamQuery();
  const [getUser] = useLazyGetCurrentUserQuery();

  const [checkout] = useCheckoutMutation();
  
  const subscription = useMemo(() => {
    const priceId = searchParams.get('priceId');
    const couponId = searchParams.get('couponId');
    if (!priceId || !couponId) {
      return undefined;
    }
    
    return {
      priceId,
      couponId,
    };
  }, [searchParams]);

  const offerId = searchParams.get('offerId');

  const onRegisterSuccess = useCallback(async (data: AuthData) => {
    if (offerId != null) {
      // If offer id was used, no need to confirm email, so log user in automatically
      try {
        await Auth.signIn(data.email, data.password);
        
        dispatch(loggedIn(data.email));
  
        const [account, user] = await Promise.all([
          getAccount().unwrap(),
          getUser().unwrap(),
        ]);
  
        let team: IAccountTeam | undefined = undefined;
        try {
          team = await getTeam().unwrap();
        } catch {}
  
        try {
          mixpanel.identify(user._id);
          mixpanel.people.set({
            $email: user.email,
            $name: `${user.firstName} ${user.lastName}`,
            'Account ID': account._id,
            'Account Type': account.type,
          });
          mixpanel.track('login');
        } catch {}
  
        const userInfo = team?.userInfo[user._id];
        const isOwner = account.level === 'individual' || userInfo?.accessLevel === 'owner';
  
        if (isOwner && account?.isOnboarding) {
          const response = await checkout().unwrap();
          window.location.assign(response.url);
        } else {
          if (isAccountProfileComplete(account)) {
            const defaultPath = getDefaultPathForAccountType(account?.type);
            navigate(defaultPath);
          } else {
            navigate('/organization');
          }
        }
      } catch (e: any) {
        console.log('error: ', e.message);
      }
    } else {
      navigate(`/signup-complete?email=${encodeURIComponent(data.email)}`);
    }
  }, [navigate, checkout, dispatch, getAccount, getUser, getTeam, offerId]);

  return (
    <PageContainer>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingTop: '5vh',
        alignItems: 'center',
      }}>
        <RegistrationFormV2
          subscription={subscription}
          offerId={offerId ?? undefined}
          allowedAccountTypes={['sponsor', 'lender', 'investor', 'broker']}
          onSuccess={onRegisterSuccess} />
      </Box>
    </PageContainer>
  );
}
