import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from "@mui/material";
import CREEDDialogTitle from "../../CREEDDialogTitle";
import LoadingButton from "../../LoadingButton";
import { useAddNoContactLenderMutation } from "../../../features/deals-api";
import { useCallback } from "react";
import { IDeal } from "../../../schemas";
import { useAppDispatch } from "../../../app/hooks";
import { snacked } from "../../../features/snackMessage-slice";


interface Props extends DialogProps {
  deal?: IDeal;
  lenderName?: string;
}

export default function RemoveFavoriteLenderDialog({deal, lenderName, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [addNoContactLender, { isLoading: isAddNoContactLenderLoading }] = useAddNoContactLenderMutation();

  const addClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (deal?._id == null || lenderName == null) {
      return;
    }

    addNoContactLender({
      dealId: deal._id,
      lenderName: lenderName,
      action: 'add',
    });

    dispatch(snacked({
      message: `${lenderName} added to Do Not Contact List`,
      severity: 'success',
    }));

    if (dialogProps.onClose != null) {
      dialogProps.onClose(event, 'escapeKeyDown');
    }
  }, [addNoContactLender, deal?._id, dialogProps, dispatch, lenderName]);

  return (
    <Dialog
      {...dialogProps}
      fullWidth>
      <CREEDDialogTitle
        title='Are you sure?'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent>
        <DialogContentText>
          {`${lenderName} has already been added to this project's favorites. Proceeding will remove the lender from the project's favorites and add it to its Do Not Contact List. Do you wish to continue?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={isAddNoContactLenderLoading}
          onClick={addClicked}>
          {'Add to Do Not Contact List'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
