import React from 'react';
import ReactDOM from 'react-dom/client';

import { Amplify } from 'aws-amplify';
import cognitoConfig from './services/cognitoConfig';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [],
  environment: process.env.REACT_APP_ENV,
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY ?? '');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: cognitoConfig.cognito.REGION,
    userPoolId: cognitoConfig.cognito.USER_POOL_ID,
    identityPoolId: cognitoConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: cognitoConfig.cognito.APP_CLIENT_ID
  }
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
