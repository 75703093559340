import { Theme } from '@mui/material/styles';
import sharedStylesClasses from "../../theme/sharedStylesClasses";


const classes = (theme: Theme) => ({
  ...sharedStylesClasses(theme),
  pageContainerContent: {
    maxWidth: 'md',
  },
  profileHeaderContainerBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 6,
    paddingBottom: 6,
    alignItems: 'center',
  },
  profileImageContainerBox: {
    width: '156px',
    height: '156px',
    borderRadius: '78px',
    // background: 'lightgray',
    position: 'relative',
  },
  profileImage: {
    width: '156px',
    height: '156px',
    borderRadius: '78px',
    objectFit: 'cover',
  },
  profileImageOverlayBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    background: '#00000040',
  },
  profileImageLoadingIndicator: {
    position: 'absolute',
    alignSelf: 'center',
  },
  profileImageUploadButton: {
    position: 'absolute',
    bottom: '4px',
    right: '4px',
    display: 'flex',
    width: '48px',
    height: '48px',
    borderRadius: '24px',
  },
  accountName: {
    marginTop: 4,
  },
  sectionContainerBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 1,
    paddingBottom: 1,
  },
  booleanFieldContainerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 1,
    paddingBottom: 1,
  },
  fieldContainerBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 2,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 2,
      paddingRight: 2,
    }
  },
  fieldDivider: {
    marginTop: 2,
    marginBottom: 2,
  },
  saveButton: {
    alignSelf: 'flex-start',
    [theme.breakpoints.down('md')]: {
      marginLeft: 2,
      marginRight: 2,
    }
  },
  saveButtonLoadingIndicator: {
    position: 'absolute',
    alignSelf: 'center',
  }
});

export default classes;
