import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';


export const baseUrls = {
  main: process.env.REACT_APP_API_URL ?? '',
  accounts: process.env.REACT_APP_ACCOUNTS_API_URL ?? '',
  messaging: process.env.REACT_APP_MESSAGING_API_URL ?? '',
  documentRequests: process.env.REACT_APP_DOCUMENT_REQUESTS_API_URL ?? '',
  utils: process.env.REACT_APP_UTILS_API_URL ?? '',
  lenders: process.env.REACT_APP_LENDERS_API_URL ?? '',
  sharedDeals: process.env.REACT_APP_SHARED_DEALS_API_URL ?? '',
};

const getNewJWTToken = async () => {
  const currentSession = await Auth.currentSession();
  const tok = currentSession.getIdToken().getJwtToken();
  return tok;
};

export const createBaseQuery = (baseUrl: string) => {
  return fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      const jwtToken = await getNewJWTToken();
      headers.set('Content-Type', 'application/json');
      headers.set('Authorization', `Bearer ${jwtToken ? jwtToken : ''}`);

      if (process.env.REACT_APP_ENV === 'dev') {
        const user = await Auth.currentAuthenticatedUser();
        headers.set('x-creed-email', user.attributes.email);
        headers.set('creed-origin', 'Web');
        headers.set('creed-platform', 'Web');
      }

      return headers;
    }
  });
};
