import { useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import CREEDDialogTitle from '../../CREEDDialogTitle';
import { IDeal } from '../../../schemas';
import { useGetEquityDealReviewForDealQuery } from '../../../features/deals-api';


interface Props extends DialogProps {
  readonly deal?: IDeal;
  readonly updateClicked?: () => void;
}

export default function EditEquityDealWarningDialog({deal, updateClicked, ...dialogProps}: Props): JSX.Element {
  
  const {data: dealReview} = useGetEquityDealReviewForDealQuery({ dealId: deal?._id! }, { skip: deal?._id == null });

  const text = useMemo(() => {
    if (deal?.privacy === 'public') {
      return 'Editing the parameters of this project will cause it to be removed from the Equity Marketplace and you will need to re-publish it when ready. Do you wish to continue?';
    }

    switch (dealReview?.status) {
      case 'pending':
        return 'Editing the parameters of this project will cancel its review by the CREED Team and you will need to re-publish it when ready. Do you wish to continue?';
      case 'approved':
        return 'Editing the paramters of this project will require that it be re-reviewed by the CREED Team and you will need to re-publish it when ready. Do you wish to continue?';
    }

    return '';
  }, [deal, dealReview]);
  
  return (
    <Dialog {...dialogProps}>
      <CREEDDialogTitle
        title='Are you sure?'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          variant='text'
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <Button
          sx={{
            flex: 1,
          }}
          variant='contained'
          onClick={updateClicked}>
          {'Update Search'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
