import { Autocomplete, SxProps, TextField, Theme, Typography, createFilterOptions } from "@mui/material";
import { ILender } from "../../../schemas";
import { useCallback, useState } from "react";
import { useEffectDebounced } from "../../../utils/hooks";
import { useLazyAutocompleteLendersQuery } from "../../../features/lenders-api";


const filter = createFilterOptions<ILender>();


interface Props {
  sx?: SxProps<Theme>;
  value?: string;
  onChange?: (lenderName: string) => void;
  choiceFilter?: (option: ILender) => boolean;
}

export default function LenderAutocomplete({sx, value, onChange, choiceFilter}: Props): JSX.Element {

  const [inputString, setInputString] = useState('');
  const [options, setOptions] = useState<ILender[]>([]);

  const [autocompleteLenders] = useLazyAutocompleteLendersQuery();

  const getOptions = useCallback(async (name: string) => {
    try {
      const options = await autocompleteLenders({ name }).unwrap();
      setOptions(options);
    } catch (error) {
      console.error(error);
    }
  }, [autocompleteLenders]);

  const onAutocompleteChange = useCallback((_e: React.SyntheticEvent<Element, Event>, value: string | ILender | null) => {
    const lenderName = typeof value === 'string' ? value : value?.Lender as string;
    if (onChange != null) {
      onChange(lenderName ?? '');
    }
  }, [onChange]);

  useEffectDebounced(() => {
    if (inputString.length > 0) {
      getOptions(inputString);
    } else {
      setOptions([]);
    }
  }, 500, [inputString, getOptions]);
  
  return (
    <Autocomplete
      sx={sx}
      freeSolo
      autoComplete
      includeInputInList
      selectOnFocus
      handleHomeEndKeys
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          label='Lender name'
          fullWidth
          size='small'
          InputProps={{
            ...params.InputProps,
            sx: {
              borderRadius: '8px',
            }
          }} />
      )}
      value={value}
      onChange={onAutocompleteChange}
      onInputChange={(_e, value) => setInputString(value)}
      filterOptions={(options, params) => {
        let filtered = filter(options, params);

        if (choiceFilter != null) {
          filtered = filtered.filter(choiceFilter);
        }

        const isExisting = options.some(o => o.Lender === params.inputValue.trim());
        if (!isExisting) {
          filtered.push({
            _id: params.inputValue,
            Lender: params.inputValue,
          });
        }

        return filtered;
      }}
      getOptionLabel={option => typeof option === 'string' ? option : option.Lender as string}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography>
            {option.Lender}
          </Typography>
        </li>
      )} />
  );
}
