import React, { useMemo, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import fileDownload from 'js-file-download';
import axios from 'axios';


interface Props extends DialogProps {
  readonly title?: string;
  readonly fileUrl?: string;
}

export default function PreviewFileDialog({title, fileUrl, ...dialogProps}: Props): JSX.Element {

  const documents = useMemo(() => fileUrl ? [{ uri: fileUrl }] : undefined, [fileUrl]);

  // Rerendering DocViewer causes a flicker, so putting it into useMemo prevents that
  const viewer = useMemo(() => {
    return (
      <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={documents ?? []}
        config={{
          header: {
            disableFileName: true,
            disableHeader: true,
          },
          pdfZoom: {
            zoomJump: 1,
            defaultZoom: 10,
          }
        }}
        prefetchMethod='GET'
        requestHeaders={{
          responseType: 'blob'
        }} />
    );
  }, [documents]);

  const downloadFileClicked = useCallback(async () => {
    if (fileUrl == null) {
      return;
    }

    const signedUrlResponse = await axios.get(fileUrl, {
      responseType: 'blob'
    });

    const { data: blob } = signedUrlResponse;
    fileDownload(blob, title ?? 'file');
  }, [title, fileUrl]);

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>
        {title ?? 'Preview file'}
      </DialogTitle>

      <DialogContent>
        {viewer}
      </DialogContent>

      <DialogActions>
        <Button onClick={downloadFileClicked}>
          {'Download file'}
        </Button>
        <Button onClick={() => dialogProps.onClose ? dialogProps.onClose({}, 'escapeKeyDown') : null}>
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
