import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';


interface Props extends ButtonProps {
  loading?: boolean;
}

export default function LoadingButton({loading, ...buttonProps}: Props): JSX.Element {
  return (
    <Button {...buttonProps} disabled={loading || buttonProps.disabled}>
      {buttonProps.children}
      {loading &&
        <CircularProgress
          sx={{
            position: 'absolute',
            alignSelf: 'center',
          }}
          size={buttonProps.size === 'small' ? 18 : 25} />
      }
    </Button>
  );
}
