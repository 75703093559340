import React, { useCallback } from 'react';
import { ListItem, Box, Typography, Link, Avatar } from '@mui/material';
import dayjs from 'dayjs';
import IMessageItem, { IAccount, IThread, MessageAttachment } from '../schemas';


interface Props {
  readonly side: 'left' | 'right';
  readonly account?: IAccount;
  readonly message: IMessageItem;
  readonly thread: IThread;
  readonly attachmentClicked?: (attachment: MessageAttachment) => void;
}

export default function ChatMessage(props: Props): JSX.Element {

  const getAttachmentName = useCallback((attachment: MessageAttachment) => {
    switch (attachment.type) {
      case undefined:
      case 'deal':
        return attachment.name ?? attachment.key;
      case 'thread':
        const attachmentData = props.thread.attachments;
        if (attachmentData == null) {
          return undefined;
        }

        const data = attachmentData[attachment.fileId];
        if (data == null) {
          return undefined;
        }

        return data.name ?? data.key;
    }
  }, [props.thread]);

  return (
    <ListItem sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: props.side === 'right' ? 'flex-end' : 'flex-start',
      padding: 0,
    }}>
      <Box sx={{
        display: 'flex',
      }}>
        {props.side === 'left' &&
          <Avatar
            sx={{
              marginRight: '10px',
              marginTop: '8px',
              width: '36px',
              height: '36px',
            }}
            alt={props.account?.name ?? 'profile'}
            src={props.account?.profileImageUrl} />
        }

        <Box sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '4px',
            alignSelf: props.side === 'right' ? 'flex-end' : 'flex-start'
          }}>
            <Typography sx={{
              fontWeight: '500',
              fontSize: 13,
            }}>
              {props.message.senderFullName}
            </Typography>

            <Typography sx={{
              marginLeft: '8px',
              fontWeight: '400',
              fontSize: 12,
              color: '#33333380',
            }}>
              {dayjs(props.message.createdDt).format('MM/DD/YYYY [at] h:mm A')}
            </Typography>
          </Box>

          <Box sx={{
            padding: '4px',
            paddingLeft: '8px',
            paddingRight: '8px',
            marginBottom: '8px',
            borderRadius: '8px',
            background: '#33333314',
            alignSelf: props.side === 'right' ? 'flex-end' : 'flex-start',
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: 13,
              whiteSpace: 'pre-wrap',
              overflowWrap: 'anywhere',
            }}>
              {props.message.message}
            </Typography>
          </Box>

          {props.message.attachments?.map((atmt, idx) => (
            <Box key={idx} sx={{
              padding: '4px',
              paddingLeft: '8px',
              paddingRight: '8px',
              marginBottom: '8px',
              borderRadius: '8px',
              background: '#33333314',
              alignSelf: props.side === 'right' ? 'flex-end' : 'flex-start',
            }}>
              <Link sx={{
                fontWeight: '400',
                fontSize: 13,
              }}
              onClick={() => props.attachmentClicked ? props.attachmentClicked(atmt) : null}>
                {getAttachmentName(atmt)}
              </Link>
            </Box>
            ))
          }
        </Box>

        {props.side === 'right' &&
          <Avatar
            sx={{
              marginLeft: '10px',
              marginTop: '8px',
              width: '36px',
              height: '36px',
            }}
            alt={props.account?.name ?? 'profile'}
            src={props.account?.profileImageUrl} />
        }
      </Box>
    </ListItem>
  );
}
