import React, { useCallback, useMemo } from 'react';
import { Box, Button, DialogContentText, MenuItem, Select, SxProps, Theme, Typography, styled } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useDeleteDealMutation, useGetDealsQuery, useGetDealStatusOptionsQuery, useUpdateDealMutation } from '../features/deals-api';
import { IDeal } from '../schemas';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DealStatusChip from './Deal/DealStatusChip';
import EnableWhiteGloveDialog from './Dialogs/EnableWhiteGloveDialog/EnableWhiteGloveDialog';
import { usePopoverContext } from '../utils/hooks';
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import TextDialog from './Dialogs/TextDialog';
import LoadingButton from './LoadingButton';
import * as Sentry from '@sentry/react';
import { useAppDispatch } from '../app/hooks';
import { snacked } from '../features/snackMessage-slice';


const StyledDataGridPro = styled(DataGridPro)({
  '& .row-whiteglove-enabled': {
    backgroundColor: '#FA793810',
    '&:hover': {
      backgroundColor: '#FA793804',
    }
  },
});

interface Props {
  readonly limit?: number;
  readonly sx?: SxProps<Theme>;
}

export default function DealsDataGrid(props: Props): JSX.Element {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    props: changeStatusDialogProps,
    showDialog: showChangeStatus,
    hideDialog: hideChangeStatus,
    currentData: changeStatusData,
  } = usePopoverContext<{deal: IDeal, status: string}>();

  const {
    props: whiteGloveDialogProps,
    showDialog: showWhiteGlove,
    currentData: whiteGloveDeal,
  } = usePopoverContext<IDeal>();

  const {data: dealStatusOptions, isLoading: isDealStatusesLoading} = useGetDealStatusOptionsQuery();
  const {data: dealData, isLoading: isDealsLoading} = useGetDealsQuery({});

  const [updateDeal, { isLoading: isUpdateDealLoading }] = useUpdateDealMutation();
  const [deleteDeal] = useDeleteDealMutation();

  const deals = useMemo(() => {
    if (dealStatusOptions == null || dealData == null) {
      return [];
    }

    // Section the deals by their statuses, ranked by importance, then recombine into
    // one list to be rendered
    const rows = [...dealStatusOptions]
      .sort((a, b) => b.importance - a.importance)
      .map(option => dealData.deals.filter(deal => deal.status === option.slug).sort((a, b) => Date.parse(b.createdDt as any) - Date.parse(a.createdDt as any)))
      .flatMap(section => section)
      .filter(deal => !deal.isDraft && dealData.sharedDeals.find(sd => sd.dealId === deal._id) == null);

    if (props.limit != null) {
      return rows.slice(0, props.limit);
    }

    return rows;
  }, [dealStatusOptions, props.limit, dealData]);

  const deleteDealClicked = useCallback((deal: IDeal) => {
    if (deal._id == null) {
      return;
    }
    
    deleteDeal({
      dealId: deal._id
    });
  }, [deleteDeal]);

  const changeStatusClicked = useCallback(async (deal: IDeal, value: string) => {
    try {
      await updateDeal({
        dealId: deal._id!,
        status: value as any,
      });

      dispatch(snacked({
        message: 'Status changed',
        severity: 'success',
      }));

      hideChangeStatus();
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
      dispatch(snacked({
        message: 'Failed updating project',
        severity: 'error',
      }));
    }
  }, [dispatch, hideChangeStatus, updateDeal]);

  const columns: GridColDef<IDeal>[] = useMemo(() => [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 280,
      hideable: false
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      hideable: false,
      valueGetter: (params) => params.row.status,
      renderCell: (params) => (
        <Select
          variant='standard'
          disableUnderline
          IconComponent={() => null}
          size='small'
          value={params.value}
          onChange={e => showChangeStatus({deal: params.row, status: e.target.value})}
          renderValue={value => (
            <DealStatusChip
              sx={{
                fontSize: '12px',
              }}
              status={value} />
          )}>
          <MenuItem
            value='search'
            disabled={
              params.row.whiteGloveStatus === 'enabled'
              || params.row.dealInfo?.equityDebt?.optionName === 'Equity'
              || params.row.status === 'search'
              || (params.row.contactedLenders != null && params.row.contactedLenders.length > 0)}>
            {'Search'}
          </MenuItem>
          <MenuItem
            value='lender-outreach'
            disabled={
              params.row.whiteGloveStatus === 'enabled'
              || params.row.dealInfo?.equityDebt?.optionName === 'Equity'
              || params.row.status === 'lender-outreach'
              || (params.row.contactedLenders == null || params.row.contactedLenders.length === 0)}>
            {'Lender Outreach'}
          </MenuItem>
          <MenuItem
            value='diligence'
            disabled={
              params.row.whiteGloveStatus === 'enabled'
              || params.row.dealInfo?.equityDebt?.optionName === 'Equity'
              || params.row.status === 'diligence'}>
            {'In Diligence'}
          </MenuItem>
        </Select>
      
      )
    },
    {
      field: 'projectType',
      headerName: 'Type',
      width: 90,
      hideable: false,
      valueGetter: (params) => params.row.dealInfo?.equityDebt?.optionName ?? '',
    },
    {
      field: 'privacy',
      headerName: 'Published',
      width: 90,
      hideable: false,
      valueGetter: (params) => params.row.dealInfo?.equityDebt?.optionName === 'Equity' ? params.row.privacy === 'public' ? 'Published' : 'Private' : 'N/A',
    },
    {
      field: 'updatedDt',
      headerName: 'Last Updated',
      width: 120,
      hideable: false,
      valueFormatter: (params) => params.value != null ? dayjs(params.value).fromNow() : '',
      sortComparator: (v1, v2) => {
        return Date.parse(v1) - Date.parse(v2);
      }
    },
    {
      field: 'createdDt',
      headerName: 'Created',
      width: 180,
      hideable: false,
      valueFormatter: (params) => params.value != null ? dayjs(params.value).format('MMM D, YYYY [at] h:mm A') : '',
      sortComparator: (v1, v2) => {
        return Date.parse(v1) - Date.parse(v2);
      }
    },
    {
      field: 'whiteGloveButton',
      headerName: 'CREED White Glove',
      width: 220,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        if (params.row.dealInfo?.equityDebt?.optionName !== 'Debt') {
          return null;
        }

        let title = '';
        switch (params.row.whiteGloveStatus) {
          case undefined:
          case 'disabled':
            title = 'Activate CREED White Glove';
            break
          case 'requested':
            title = 'CREED White Glove Requested';
            break;
          case 'enabled':
            title = 'CREED White Glove Enabled';
            break;
        }
        
        return (
          <Button
            disabled={params.row.whiteGloveStatus !== 'disabled' && params.row.whiteGloveStatus !== undefined}
            onClick={e => {
              e.stopPropagation();
              showWhiteGlove(params.row);
            }}>
            {title}
          </Button>
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams<IDeal>) => [
        <GridActionsCellItem
          icon={<DeleteRoundedIcon />}
          label='Delete project'
          onClick={() => deleteDealClicked(params.row)} 
          showInMenu />,
      ]
    }
  ], [deleteDealClicked, showChangeStatus, showWhiteGlove]);

  return (
    <>
      <StyledDataGridPro
        sx={{
          fontWeight: '400',
          fontSize: '12px',
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer'
          },
          ...props.sx,
        }}
        rows={deals}
        onRowClick={r => navigate(`/deal/${r.id}`)}
        getRowId={r => r._id!}
        autoHeight={props.limit != null}
        autoPageSize={props.limit == null}
        hideFooter={props.limit != null}
        columns={columns}
        loading={isDealsLoading || isDealStatusesLoading}
        checkboxSelection={false}
        disableColumnSelector
        disableColumnReorder
        getRowClassName={params => {
          if (params.row.whiteGloveStatus === 'enabled') {
            return 'row-whiteglove-enabled';
          }

          return '';
        }}
        slots={{
          noRowsOverlay: () => (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '22px',
            }}>
              <Typography sx={{
                fontWeight: '400',
                fontSize: '12px',
              }}>
                {'No projects found.'}
              </Typography>
            </Box>
          ),
        }} />

        <TextDialog
          {...changeStatusDialogProps}
          title='Change Project Status'
          customContent={(
            <DialogContentText>
              {`Are you sure you want to change the status of "${changeStatusData?.deal.name}" to ${dealStatusOptions?.find(o => o.slug === changeStatusData?.status)?.name}?`}
            </DialogContentText>
          )}
          actions={({close}) => (
            <>
              <Button
                sx={{
                  flex: 1,
                }}
                onClick={close}>
                {'Cancel'}
              </Button>
              <LoadingButton
                sx={{
                  flex: 1,
                }}
                variant='contained'
                loading={isUpdateDealLoading}
                onClick={() => changeStatusData != null ? changeStatusClicked(changeStatusData.deal, changeStatusData.status) : null}>
                {'Change Status'}
              </LoadingButton>
            </>
          )}>
        </TextDialog>

        <EnableWhiteGloveDialog
          {...whiteGloveDialogProps}
          deal={whiteGloveDeal} />
      </>
  );
}
