import React, { useCallback, useEffect, useMemo } from 'react';
import { Alert, Badge, Box, Button } from '@mui/material';
import { IAccount, IUser, IDeal, ISharedDeal } from '../../../schemas';
import DealInvestorResponseCard from '../Cards/DealInvestorResponseCard';
import { useGetInvestorDealResponsesQuery, useGetSharedDealsForDealQuery } from '../../../features/deals-api';
import { usePopoverContext } from '../../../utils/hooks';
import InvestorInterestedDialog from '../Dialogs/InvestorInterestedDialog';
import InvestorRejectDealDialog from '../Dialogs/InvestorRejectDealDialog';
import LoadingButton from '../../LoadingButton';
import CommentRow from '../../CommentRow';
import { showChatForThread } from '../../../features/messaging-slice';
import { useAppDispatch } from '../../../app/hooks';
import InvestorStatusChip from '../Chips/InvestorStatusChip';
import { useGetThreadQuery } from '../../../features/messaging-api';
import { useSearchParams } from 'react-router-dom';


interface Props {
  readonly account?: IAccount;
  readonly user?: IUser;
  readonly deal?: IDeal;
}

export default function EquityInvestorView({account, user, deal}: Props): JSX.Element {

  const dispatch = useAppDispatch();
  const [params] = useSearchParams()

  const {
    props: investorInterestedDialogProps,
    showDialog: showInvestorInterested,
  } = usePopoverContext();

  const {
    props: investorRejectDialogProps,
    showDialog: showInvestorReject,
  } = usePopoverContext();

  const {data: sharedDeals, isLoading: isSharedDealsLoading} = useGetSharedDealsForDealQuery({ dealId: deal?._id!, accountType: 'all' }, { skip: deal?._id == null || account == null || deal.accountId === account?._id });
  const {data: responses, isLoading: isResponsesLoading} = useGetInvestorDealResponsesQuery({dealId: deal?._id!}, { skip: deal?._id == null });
  
  const sharedDeal = useMemo(() => {
    if (sharedDeals == null || user == null) {
      return undefined;
    }

    const orgSharedDeal = sharedDeals.find(sd => sd.accountId === user.accountId);
    const individualSharedDeal = sharedDeals.find(sd => sd.accountId === user?.individualAccountId);

    return orgSharedDeal ?? individualSharedDeal;
  }, [user, sharedDeals]);

  const {data: thread} = useGetThreadQuery({ threadId: sharedDeal?.threadId! }, {skip: sharedDeal?.threadId == null});

  const unreadMessages = useMemo(() => {
    if (thread == null) {
      return undefined;
    }

    const lastReadMessage = thread.lastReadMessages?.find(l => l.accountId === account?._id);
    if (lastReadMessage == null) {
      return thread.messages
        .filter(m => m.senderAccountId !== account?._id)
        .length;
    }

    const lastReadIndex = lastReadMessage != null ? thread.messages.findIndex(m => m._id === lastReadMessage.messageId) : 0;
    if (lastReadIndex < 0) {
      return 0;
    }

    return thread.messages
      .slice(lastReadIndex + 1)
      .filter(m => m.senderAccountId !== account?._id)
      .length;
  }, [account?._id, thread]);

  const response = useMemo(() => {
    if (responses == null) {
      return undefined;
    }

    return responses[0];
  }, [responses]);

  const chatWithSponsorClicked = useCallback((sharedDeal: ISharedDeal) => {
    if (sharedDeal.threadId == null) {
      return;
    }

    dispatch(showChatForThread({
      key: 'chat',
      threadId: sharedDeal.threadId,
      closable: true,
    }));
  }, [dispatch]);

  useEffect(() => {
    if (sharedDeal != null || response != null) {
      return;
    }
    
    switch (params.get('investorAction')) {
      case 'interested':
        showInvestorInterested();
        break;
      case 'pass':
        showInvestorReject();
        break;
    }
  }, [params, response, sharedDeal, showInvestorInterested, showInvestorReject])

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
        <DealInvestorResponseCard
          deal={deal}
          sharedDeal={sharedDeal}
          interestedClicked={() => showInvestorInterested(undefined)}
          rejectDealClicked={() => showInvestorReject(undefined)} />

        <Box sx={{
          paddingTop: 4,
        }}>
          {unreadMessages != null && unreadMessages > 0 &&
            <Box sx={{
              paddingBottom: 4,
            }}>
              <Alert
                severity='error'>
                {'You have unread messages from sponsor of this project. You can view their responses and chat with them by clicking the "Chat with sponsor" button below.'}
              </Alert>
            </Box>
          }
          
          <LoadingButton
            sx={{
              fontWeight: 'bold',
              fontSize: '13px',
              lineHeight: '16px',
            }}
            loading={isSharedDealsLoading || isResponsesLoading}>
            {'My Response'}
          </LoadingButton>

          {sharedDeal != null &&
            <CommentRow
              avatarImageUrl={account?.profileImageUrl}
              name='You'
              date={sharedDeal.createdDt}>
              {sharedDeal.threadId != null &&
                <Box sx={{
                  paddingTop: '12px',
                }}>
                    <Badge
                      badgeContent={unreadMessages != null && unreadMessages > 0 ? unreadMessages : undefined}
                      color='primary'>
                      <Button
                        variant='outlined'
                        color='secondary'
                        onClick={() => chatWithSponsorClicked(sharedDeal)}>
                        {'Chat with sponsor'}
                      </Button>
                    </Badge>
                  </Box>
                }
            </CommentRow>
          }

          {response != null &&
            <CommentRow
              avatarImageUrl={account?.profileImageUrl}
              name='You'
              comment={response.comment ?? ''}
              date={response.createdDt}>
              <Box sx={{
                paddingTop: 2,
              }}>
                <InvestorStatusChip status={response.status as any} />
              </Box>
            </CommentRow>
          }
        </Box>
      </Box>

      <InvestorInterestedDialog
        {...investorInterestedDialogProps}
        deal={deal} />

      <InvestorRejectDealDialog
        {...investorRejectDialogProps}
        deal={deal} />
    </>
  );
}
