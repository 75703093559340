import { Theme } from '@mui/material/styles';
import sharedStylesClasses from '../../theme/sharedStylesClasses';

const classes = (theme: Theme) => ({
  ...sharedStylesClasses(theme),
  root: {
    display: 'flex',
    flexDirection: 'column',
    // paddingBottom: '40px',
  },
  welcomeBox: {
    display: 'flex',
    flexDirection: 'row',
    background: 'linear-gradient(52.06deg, rgba(217, 179, 97, 0.4) -27.94%, rgba(217, 179, 97, 0) 81.86%), linear-gradient(0deg, rgba(221, 207, 178, 0.4), rgba(221, 207, 178, 0.4)), #FFFFFF',
    boxShadow: '-4px 4px 16px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',    
  },
  welcomeBoxLeft: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '48px',
    paddingTop: '56px',
    paddingBottom: '56px',
    flex: 1,
  },
  welcomeToCreedText: {
    fontWeight: '500',
    fontSize: 24,
    color: '#333333',
  },
  welcomeMessageText: {
    marginTop: '24px',
    fontWeight: '400',
    fontSize: 14,
    color: '#33333380',
  },
  mobileAppBadgeBox: {
    marginTop: '24px',
  },
  iosAppBadgeAnchor: {
    marginLeft: '8px',
  },
  mobileAppBadge: {
    height: '40px',
  },
  actionBox: {
    gap: '24px',
    display: 'flex',
    flex: 1,
    height: '300px',
    width: '100%',
    // marginTop: '32px',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#3333330D'
  },
  actionButtonIcon: {
    marginLeft: '8px'
  }
});

export default classes;
