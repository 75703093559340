import { Dialog, DialogContent, DialogContentText, DialogProps } from "@mui/material";
import CREEDDialogTitle from "../CREEDDialogTitle";


interface Props extends DialogProps {

}

export default function DeleteAccountDialog({...dialogProps}: Props): JSX.Element {
  return (
    <Dialog {...dialogProps}>
      <CREEDDialogTitle
        title='Request Account Deletion'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent>
        <DialogContentText>
          Please send us an email at <a href={'mailto:support@creed.tech?subject=Account Deletion Request'}>support@creed.tech</a> letting us know that you would like your account deleted. Thank you.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
