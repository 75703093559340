import { useCallback, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import CREEDDialogTitle from '../../CREEDDialogTitle';
import LoadingButton from '../../LoadingButton';
import { useGetEquityDealReviewForDealQuery, usePublishEquityDealMutation } from '../../../features/deals-api';
import { IDeal } from '../../../schemas';
import { useAppDispatch } from '../../../app/hooks';
import { snacked } from '../../../features/snackMessage-slice';


interface Props extends DialogProps {
  readonly deal?: IDeal;
}

export default function SponsorUnpublishEquityDealDialog({deal, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const {data: dealReview} = useGetEquityDealReviewForDealQuery({ dealId: deal?._id! }, { skip: deal?._id == null });
  
  const [publishDeal, { isLoading: isPublishDealLoading }] = usePublishEquityDealMutation();

  const text = useMemo(() => {
    switch (dealReview?.status) {
      case 'pending':
        return 'Your project will be removed from the CREED review process until you re-publish it.';
      case 'approved':
        return 'Your project will be removed from the Equity Marketplace. You will be able to re-publish it to the Equity Marketplace later.';
    }

    return '';
  }, [dealReview?.status]);

  const unpublishClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (deal == null) {
      return;
    }

    try {
      await publishDeal({
        dealId: deal._id!,
        published: false,
      }).unwrap();

      if (dialogProps.onClose != null) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed un-publishing project.',
        severity: 'error',
      }));
    }
  }, [deal, publishDeal, dialogProps, dispatch]);

  return (
    <Dialog {...dialogProps}>
      <CREEDDialogTitle
        title='Are you sure you want to unpublish this project?'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={isPublishDealLoading}
          onClick={unpublishClicked}>
          {'Unpublish'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
