import React, { useCallback } from 'react';
import { Dialog, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';
import DialogCloseButton from '../DialogCloseButton';


interface Props extends DialogProps {
  readonly comment?: string;
}

export default function LenderCommentDialog({comment, ...dialogProps}: Props): JSX.Element {
  
  const onClose = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent> | {}) => {
    if (dialogProps.onClose != null) {
      dialogProps.onClose(event, 'escapeKeyDown');
    }
  }, [dialogProps]);
  
  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}>
      <DialogTitle sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '24px',
        }}>
          {'Lender Comment'}
        </Typography>
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent sx={{
        minWidth: '300px',
      }}>
        <Typography>
          {comment}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
