import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import mixpanel from 'mixpanel-browser';


const initialLoggedInState = () => window.localStorage.getItem('shouldBeLoggedIn') === 'true' ? true : false;
const initialLoggedEmailState = () => window.localStorage.getItem('email')  || '';
  
interface AuthState {
  shouldBeLoggedIn: boolean,
  email: string
}

const initialState: AuthState = {
  shouldBeLoggedIn: initialLoggedInState(),
  email: initialLoggedEmailState()
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedIn(state, action: PayloadAction<string>) {
      // it's ok to do this because inner makes it immutable under the hood
      state.shouldBeLoggedIn = true;
      state.email = action.payload;
      window.localStorage.setItem('shouldBeLoggedIn', 'true');
      window.localStorage.setItem('email', action.payload);
    },
    loggedOut(state) {
      if (state.shouldBeLoggedIn) {
        mixpanel.track('logout', undefined, () => mixpanel.reset());
      }
      
      state.shouldBeLoggedIn = false;
      state.email = '';
      window.localStorage.setItem('shouldBeLoggedIn', 'false');
      window.localStorage.removeItem('email');
    }
  }
})

export const { loggedIn, loggedOut } = authSlice.actions;
export default authSlice.reducer;