import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, TextField, Typography } from '@mui/material';
import DialogCloseButton from '../DialogCloseButton';
import LoadingButton from '../LoadingButton';
import { ISharedDeal } from '../../schemas';
import { useAppDispatch } from '../../app/hooks';
import { snacked } from '../../features/snackMessage-slice';
import { useUpdateSharedDealMutation } from '../../features/shared-deals-api';


interface Props extends DialogProps {
  readonly sharedDeal?: ISharedDeal;
}

export default function RejectDealDialog({sharedDeal, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  const [updateSharedDeal] = useUpdateSharedDealMutation();

  const onClose = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent> | {}) => {
    if (dialogProps.onClose != null) {
      dialogProps.onClose(event, 'escapeKeyDown');
    }

    setComment('');
  }, [dialogProps, setComment]);

  const passClicked = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (sharedDeal?._id == null) {
      return;
    }

    setLoading(true);

    try {
      await updateSharedDeal({
        sharedDealId: sharedDeal._id,
        dealId: sharedDeal.dealId,
        changes: {
          lenderStatus: 'rejected',
          lenderComment: comment,
        }
      }).unwrap();

      setComment('');

      onClose({});

      dispatch(snacked({
        message: 'Passed on project',
        severity: 'success',
      }));
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed passing on project',
        severity: 'error',
      }));
    } finally {
      setLoading(false);
    }
  }, [setLoading, sharedDeal, dispatch, comment, updateSharedDeal, setComment, onClose]);

  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}>
      <DialogTitle sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '24px',
        }}>
          {'Pass on the project'}
        </Typography>

        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <form onSubmit={passClicked}>
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}>
          <Typography sx={{
            fontWeight: '400',
            fontSize: '14px',
            color: '#333333AA',
          }}>
            {'Please provide a comment to the borrower explaining why you decided to pass:'}
          </Typography>

          <TextField
            sx={{
              width: '100%',
            }}
            InputProps={{
              style: {
                borderRadius: '8px',
              }
            }}
            label='Comment'
            multiline
            maxRows={10}
            required
            value={comment}
            onChange={e => setComment(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              flex: 1,
            }}
            variant='text'
            onClick={onClose}>
            {'Cancel'}
          </Button>
          <LoadingButton
            sx={{
              flex: 1,
            }}
            type='submit'
            variant='contained'
            loading={loading}>
            {'Pass'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
