import DealParameterFormControl from './DealParameterFormControl';
import { DealParameterProps } from './DealParameter';
import { useDealParameter } from '../utils/hooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormHelperText } from '@mui/material';


export default function DealParameterDate({field, formValues, dealInfo, error, setFormValue}: DealParameterProps): JSX.Element {
  
  const {
    title,
    disabled,
    dependingFieldTitles,
  } = useDealParameter({ field, dealInfo });
  
  return (
    <DealParameterFormControl
      field={field}
      disabled={disabled}
      error={error}
      dependingFieldTitles={dependingFieldTitles}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          slotProps={{
            textField: {
              required: field.required,
              error: error != null,
              InputProps: {
                sx: {
                  borderRadius: '8px',
                }
              }
            }
          }}
          label={title}
          disablePast
          value={formValues[field.id]}
          onChange={value => setFormValue(value)} />
      </LocalizationProvider>
      {error != null &&
        <FormHelperText>
          {error}
        </FormHelperText>
      }
    </DealParameterFormControl>
  );
}
