import { useEffect } from "react";
import { useGetCurrentAccountQuery } from "../../features/accounts-api";
import PageContainer from "../PageContainer";
import { useNavigate } from "react-router-dom";
import { useDefaultPath } from "../../utils/hooks";
import { Box, Typography } from "@mui/material";


export default function AccountStatusPending(): JSX.Element {

  const navigate = useNavigate();
  const defaultPath = useDefaultPath();

  const {data: account, isFetching: isAccountFetching} = useGetCurrentAccountQuery(undefined, {pollingInterval: 10_000});

  useEffect(() => {
    if (isAccountFetching) {
      return;
    }
    
    if (account?.status === 'approved') {
      if (account?.profileComplete) {
        navigate(defaultPath);
      } else {
        navigate('/organization');
      }
    }
  }, [account?.profileComplete, account?.status, defaultPath, isAccountFetching, navigate]);

  return (
    <PageContainer
      headerProps={{
        showBanners: false,
      }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingTop: '5vh',
        alignItems: 'center',
      }}>
        <Typography sx={{
          fontWeight: '500',
        }}>
          {'Welcome!'}
        </Typography>
        <Typography sx={{
          marginTop: '20px',
          width: '400px',
          textAlign: 'center',
        }}>
          {'Your account is being reviewed by the CREED Team. Once approved, you will be able to access the rest of the platform.'}
        </Typography>
      </Box>
    </PageContainer>
  );
}
