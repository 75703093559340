import React from 'react';
import { Box, DialogTitle, Typography } from '@mui/material';
import DialogCloseButton from './DialogCloseButton';


export interface CREEDDialogTitleProps {
  title: string;
  subtitle?: string;
  closeClicked?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function CREEDDialogTitle({title, subtitle, closeClicked}: CREEDDialogTitleProps): JSX.Element {
  return (
    <DialogTitle sx={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Typography sx={{
          paddingRight: '24px',
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '24px',
        }}>
          {title}
        </Typography>

        <DialogCloseButton onClick={closeClicked} />
      </Box>

      {subtitle && 
        <Typography sx={{
          marginTop: 1,
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '17px',
          color: '#333333BF',
        }}>
          {subtitle}
        </Typography>
      }
    </DialogTitle>
  );
}
