import { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, TextField, Typography } from '@mui/material';
import DialogCloseButton from '../../DialogCloseButton';
import LoadingButton from '../../LoadingButton';
import { useRespondToEquityMarketplaceDealMutation } from '../../../features/deals-api';
import { IDeal } from '../../../schemas';
import { useAppDispatch } from '../../../app/hooks';
import { snacked } from '../../../features/snackMessage-slice';


interface Props extends DialogProps {
  readonly deal?: IDeal;
}

export default function InvestorInterestedDialog({deal, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [comment, setComment] = useState('');

  const [respondToDeal, { isLoading: isRespondLoading } ] = useRespondToEquityMarketplaceDealMutation();

  const submitClicked = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (deal?._id == null) {
      return;
    }

    try {
      await respondToDeal({
        accountId: '',
        dealId: deal._id,
        status: 'accepted',
        comment: comment.trim(),
      }).unwrap();

      if (dialogProps.onClose != null) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed responding to project.',
        severity: 'error',
      }));
    }
  }, [comment, deal, respondToDeal, dialogProps, dispatch]);

  return (
    <Dialog {...dialogProps}>
      <DialogTitle sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '24px',
        }}>
          {'Interested in Project'}
        </Typography>

        <DialogCloseButton onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      </DialogTitle>
      <form onSubmit={submitClicked}>
        <DialogContent sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}>
          <DialogContentText>
            {'Please provide a comment to the sponsor explaining why you are interested in the project:'}
          </DialogContentText>

          <TextField
            sx={{
              width: '100%',
            }}
            InputProps={{
              style: {
                borderRadius: '8px',
              }
            }}
            label='Comment'
            multiline
            maxRows={10}
            rows={6}
            required
            value={comment}
            onChange={e => setComment(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              flex: 1,
            }}
            variant='text'
            onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
            {'Cancel'}
          </Button>
          <LoadingButton
            sx={{
              flex: 1,
            }}
            type='submit'
            variant='contained'
            disabled={deal == null}
            loading={isRespondLoading}>
            {'Submit'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
