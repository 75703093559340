import { useMemo } from 'react';
import { useGetMarketplaceDealsQuery } from '../features/deals-api';
import { IDeal } from '../schemas';
import { formatNumericString } from '../utils/utils';
import dayjs from 'dayjs';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { wrapColumnsInTooltips } from '../utils/componentUtils';
import { useGetCurrentAccountQuery } from '../features/accounts-api';
import InvestorStatusChip from './Deal/Chips/InvestorStatusChip';
import { DataGridPro, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { useGetSharedDealsQuery } from '../features/shared-deals-api';


export default function EquityMarketplaceDataGrid(): JSX.Element {

  const navigate = useNavigate();

  const {data: account, isLoading: isAccountLoading} = useGetCurrentAccountQuery();
  const {data: deals, isFetching: isDealsLoading} = useGetMarketplaceDealsQuery({type: 'all', filtered: account?.equityMarketplaceFilter === 'filtered'}, { skip: account == null });
  const {data: sharedDeals} = useGetSharedDealsQuery();

  const rows = useMemo(() => {
    if (deals == null || account == null) {
      return [];
    }

    const interestedRows = deals.filter(d => sharedDeals?.some(sd => sd.dealId === d._id));
    const rejectedRows = deals.filter(d => d.marketplaceStats?.rejectedByAccountIds?.includes(account._id));
    
    const usedIds = new Set([
      ...interestedRows.map(r => r._id),
      ...rejectedRows.map(r => r._id),
    ]);

    const normalRows = deals.filter(d => !usedIds.has(d._id));
    
    return [
      ...interestedRows ?? [],
      ...normalRows ?? [],
      ...rejectedRows ?? [],
    ];
  }, [account, deals, sharedDeals]);

  const columns: GridColDef<IDeal>[] = useMemo(() => {
    return wrapColumnsInTooltips([
      {
        field: 'name',
        headerName: 'Address',
        minWidth: 300,
        maxWidth: 500,
        flex: 1,
        renderCell: (params) => (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
            alignItems: 'center',
            minWidth: 0,
            width: '100%',
            justifyContent: 'space-between',
          }}>
            <Box sx={{
              display: 'flex',
              flexGrow: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
              <Typography sx={{
                fontWeight: 'inherit',
                fontSize: 'inherit',
              }}>
                {params.formattedValue}
              </Typography>
            </Box>

            {params.row.marketplaceStats?.rejectedByAccountIds?.includes(account?._id ?? '') &&
              <InvestorStatusChip status='rejected' />
            }

            {sharedDeals?.some(sd => sd.dealId === params.row._id) &&
              <InvestorStatusChip status='accepted' />
            }
          </Box>
        )
      },
      {
        field: 'equityAmount',
        headerName: 'Equity Amount',
        width: 110,
        valueGetter: (p) => p.row.dealInfo?.loanAmount,
        valueFormatter: (p) => p.value != null ? `$${formatNumericString(p.value.toFixed(0))}` : '',
      },
      {
        field: 'equityType',
        headerName: 'Equity Type',
        width: 140,
        valueGetter: (p) => p.row.dealInfo?.equityType?.optionNames,
        valueFormatter: (p) => {
          if (p.value == null || p.value.length === 0) {
            return '-';
          }

          if (p.value.length === 1) {
            return p.value[0];
          } else {
            return p.value.map((o: string) => o.replace('Equity', '').trim()).join(' / ');
          }
        },
      },
      {
        field: 'irr',
        headerName: 'Project Level IRR (%)',
        width: 70,
        valueGetter: (p) => p.row.dealInfo?.irr,
        valueFormatter: (p) => p.value != null ? `${(p.value as number).toFixed(1)}%` : '',
      },
      {
        field: 'investorLevelIrr',
        headerName: 'Investor IRR (%)',
        width: 90,
        valueGetter: (p) => p.row.dealInfo?.investorLevelIrr,
        valueFormatter: (p) => p.value != null ? `${(p.value as number).toFixed(1)}%` : '',
      },
      {
        field: 'yieldOnCost',
        headerName: 'Yield on Cost (%)',
        width: 70,
        valueGetter: (p) => p.row.dealInfo?.yieldOnCost,
        valueFormatter: (p) => p.value != null ? `${(p.value as number).toFixed(2)}%` : 'N/A',
      },
      {
        field: 'projectDuration',
        headerName: 'Hold Period (Years)',
        width: 60,
        valueGetter: (p) => p.row.dealInfo?.projectDuration,
      },
      {
        field: 'propertyType',
        headerName: 'Property Type',
        width: 150,
        valueGetter: (p) => p.row.dealInfo?.propertyType?.optionName,
      },
      {
        field: 'propertySubtype',
        headerName: 'Property Subtype',
        width: 90,
        valueGetter: (p) => p.row.dealInfo?.propertySubtype?.optionName,
      },
      {
        field: 'businessPlan',
        headerName: 'Business Plan',
        valueGetter: (p) => p.row.dealInfo?.businessPlan?.optionName,
      },
      {
        field: 'closingDate',
        headerName: 'Closing Date',
        width: 80,
        valueGetter: (p) => p.row.dealInfo?.expectedClosingDate,
        valueFormatter: (p) => p.value != null ? dayjs(p.value).format('M/DD/YY') : '',
      },
      {
        field: 'datePublished',
        headerName: 'Date Published',
        width: 80,
        valueGetter: (p) => p.row.marketplacePublishedDt,
        valueFormatter: (p) => p.value != null ? dayjs(p.value).format('M/DD/YY') : '',
      },
      {
        field: 'other',
        headerName: 'Mission-Driven Strategy',
        width: 130,
        valueGetter: (p) => p.row.dealInfo?.otherEquity?.optionNames,
        valueFormatter: (p: GridValueFormatterParams<string[]>) => {
          return p.value?.map(val => {
            switch (val) {
              case 'Opportunity Zone':
                return 'OZ';
              case 'Impact/Affordable':
                return 'Impact';
              case 'Green/Sustainable':
                return 'Green';
              default:
                return val;
            }
          }).join(', ');
        },

      }
    ]);
  }, [account?._id, sharedDeals]);

  return (
    <DataGridPro
      sx={{
        fontWeight: '400',
        fontSize: '12px',
        '& .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'normal',
          lineHeight: 'normal',
        },
        '& .MuiDataGrid-columnHeader': {
          // Forced to use important since overriding inline styles
          height: 'unset !important'
        },
        '& .MuiDataGrid-columnHeaders': {
          // Forced to use important since overriding inline styles
          maxHeight: '168px !important'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer'
        },
      }}
      columns={columns}
      rows={rows}
      getRowId={r => r._id ?? ''}
      onRowClick={r => navigate(`/deal/${encodeURIComponent(r.id)}`)}
      autoPageSize
      loading={isAccountLoading || isDealsLoading}
      checkboxSelection={false}
      disableColumnSelector
      slots={{
        noRowsOverlay: () => (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '22px',
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '12px',
            }}>
              {'No projects found.'}
            </Typography>
          </Box>
        ),
      }} />
  );
}
