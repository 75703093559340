import axios from "axios";
import { AccountType } from "../schemas";


export function getDefaultPathForAccountType(accountType?: AccountType, nullDefault?: boolean): string {
  switch (accountType) {
    case 'sponsor':
    case 'broker':
      return '/home';
    case 'investor':
      return '/equity-deals';
    case 'lender':
      return '/shared-deals';
    default:
      return nullDefault ? null as any : '/home';
  }
}

export function capitalize(word: string) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}


export function parseNumericString(value?: string | number | null): number | null {
  if (value == null) {
    return null;
  }

  const val = Number.parseFloat(`${value}`.split(',').join(''));
  if (isNaN(val)) {
    return null;
  }

  return val;
}


export function formatNumericString(str?: string | null): string | undefined {
  if (str == null) {
    return undefined;
  }

  if (str.trim().length === 0) {
    return undefined;
  }

  const numberValue = Number(str.replace(/\D/g, ""));
  return numberValue.toLocaleString('en-GB');
}


/**
 * Format bytes as human-readable text.
 * https://stackoverflow.com/a/14919494
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si: boolean = false, dp: number = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}


export interface CSVColumn {
  name: string;
  key: string;
}


export function generateCSVString(params: {columns: CSVColumn[], rows: any[]}): string {
  const rows = [
    params.columns.map(c => `"${c.name.replace(/"/g, '\\"')}"`).join(','),
    ...params.rows.map(row => {
      return params.columns.map(col => `"${(row[col.key] ?? '').toString().replace(/"/g, '\\"')}"`).join(',');
    }),
  ];

  return rows.join('\r\n');
}


export function uploadFileToUrl(url: string, file: File) {
  return axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    }
  });
}


export function downloadFileFromUrl(url: string, name?: string) {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = `${name ?? 'file'}.zip`;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}


export function downloadStringAsFile(content: string, name: string, contentType: string) {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  link.click();
}


export async function copyToClipboard(content: string) {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(content);
  } else {
    document.execCommand('copy', true, content);
  }
}
