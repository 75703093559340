/**
 * Add here all the common style classes that don't fit in themes,
 * but that we'd want all components accross all screens to be able to use.
 * For how to use, see Footer.Styles.ts
 * @param {*} theme 
 * @returns 
 */
const classes = (theme) => ({
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
})

export default classes;
