import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQuery, baseUrls } from "./apiHelper";
import { DocumentRequestStatus, IAccount, IDocumentRequest } from '../schemas';


export const documentRequestsApiSlice = createApi({
  reducerPath: 'api-document-requests',
  tagTypes: ['DocumentRequest'],
  baseQuery: createBaseQuery(baseUrls.documentRequests),
  endpoints: (build) => ({
    getDealDocumentRequests: build.query<IDocumentRequest[], {dealId: string}>({
      providesTags: (documentRequests) => documentRequests?.map(dr => ({ type: 'DocumentRequest', id: dr._id })) ?? [],
      query: ({dealId}) => `/deals/${dealId}/document-requests`,
    }),
    getDealDocumentRequest: build.query<IDocumentRequest, {dealId: string, documentRequestId: string}>({
      providesTags: (_dr, _e, args) => [{ type: 'DocumentRequest', id: args.documentRequestId }],
      query: ({dealId, documentRequestId}) => `/deals/${dealId}/document-requests/${documentRequestId}`,
    }),
    getDocumentRequestAttachmentUrl: build.query<{url: string}, {dealId: string, documentRequestId: string, fileKey: string}>({
      query: ({dealId, documentRequestId, fileKey}) => `/deals/${dealId}/document-requests/${documentRequestId}/attachments/${fileKey}`,
    }),
    getDocumentRequestFileUrl: build.query<{url: string}, {dealId: string, documentRequestId: string, fileKey: string}>({
      query: ({dealId, documentRequestId, fileKey}) => `/deals/${dealId}/document-requests/${documentRequestId}/submitted/${fileKey}`,
    }),

    createDealDocumentRequest: build.mutation<{documentRequestId: string, message: string}, {dealId: string, name: string, description: string}>({
      invalidatesTags: ['DocumentRequest'],
      query: ({dealId, name, description}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests`,
          method: 'POST',
          body: {
            name: name,
            description: description,
          }
        };
      },
    }),
    deleteDealDocumentRequest: build.mutation<{message: string}, {dealId: string, documentRequestId: string}>({
      invalidatesTags: ['DocumentRequest'],
      query: ({dealId, documentRequestId}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}`,
          method: 'DELETE',
        };
      },
    }),
    updateDealDocumentRequest: build.mutation<{message: string}, {dealId: string, documentRequestId: string, changes: Partial<IDocumentRequest>}>({
      invalidatesTags: (_r, _e, args) => [{ type: 'DocumentRequest', id: args.documentRequestId }],
      query: ({dealId, documentRequestId, changes}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}`,
          method: 'PATCH',
          body: changes,
        };
      },
    }),
    setDealDocumentRequestStatus: build.mutation<any, {dealId: string, documentRequestId: string, status: DocumentRequestStatus, rejectionReason?: string}>({
      invalidatesTags: (_r, _e, args) => [{ type: 'DocumentRequest', id: args.documentRequestId }],
      query: ({dealId, documentRequestId, status, rejectionReason}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/status`,
          method: 'PATCH',
          body: {
            status: status,
            rejectionReason: rejectionReason,
          }
        };
      },
    }),
    getDealDocumentRequestAttachmentUploadUrl: build.mutation<{url: string}, {dealId: string, documentRequestId: string, fileName: string, contentType: string}>({
      query: ({dealId, documentRequestId, fileName, contentType}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/attachments/upload`,
          method: 'POST',
          body: {
            fileName: fileName,
            contentType: contentType,
          },
        };
      },
    }),
    getDealDocumentRequestUploadUrl: build.mutation<{url: string}, {dealId: string, documentRequestId: string, fileName: string, contentType: string}>({
      query: ({dealId, documentRequestId, fileName, contentType}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/submitted/upload`,
          method: 'POST',
          body: {
            fileName: fileName,
            contentType: contentType,
          },
        };
      },
    }),
    deleteDocumentRequestFile: build.mutation<any, {dealId: string, documentRequestId: string, fileKey: string}>({
      query: ({dealId, documentRequestId, fileKey}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/submitted/${encodeURIComponent(fileKey)}`,
          method: 'DELETE',
        };
      },
      onQueryStarted: async ({dealId, documentRequestId, fileKey}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          documentRequestsApiSlice.util.updateQueryData('getDealDocumentRequests', {dealId}, (draft) => {
            const index = draft.findIndex(docReq => docReq._id === documentRequestId);
            draft[index] = {
              ...draft[index],
              files: draft[index].files.filter(f => f.key !== fileKey),
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    addDocumentRequestEvent: build.mutation<{message: string}, {dealId: string, documentRequestId: string, message: string, senderAccount: IAccount}>({
      invalidatesTags: (_r, _e, args) => [{ type: 'DocumentRequest', id: args.documentRequestId }],
      query: ({dealId, documentRequestId, message}) => {
        return {
          url: `/deals/${encodeURIComponent(dealId)}/document-requests/${encodeURIComponent(documentRequestId)}/events`,
          method: 'POST',
          body: {
            message: message,
          }
        };
      },
    })
  })
});

export const {
  useGetDealDocumentRequestsQuery,
  useGetDealDocumentRequestQuery,
  useLazyGetDocumentRequestAttachmentUrlQuery,
  useLazyGetDocumentRequestFileUrlQuery,

  useCreateDealDocumentRequestMutation,
  useDeleteDealDocumentRequestMutation,
  useUpdateDealDocumentRequestMutation,
  useSetDealDocumentRequestStatusMutation,
  useGetDealDocumentRequestAttachmentUploadUrlMutation,
  useGetDealDocumentRequestUploadUrlMutation,
  useDeleteDocumentRequestFileMutation,
  useAddDocumentRequestEventMutation,
} = documentRequestsApiSlice;
