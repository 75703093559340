import React from 'react';
import { Box } from '@mui/material';
import { IDealQuoteTerms } from '../../schemas';
import { useDealQuoteTermFields } from '../../types/DealQuoteTermFields';
import ReadonlyDataField from '../ReadonlyDataField';


interface Props {
  terms: IDealQuoteTerms;
}

export default function DealQuoteTerms({terms}: Props): JSX.Element {
  
  const {
    fields,
    fieldValues,
  } = useDealQuoteTermFields();
  
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
    }}>
      {fields.map(field => (
        <ReadonlyDataField
          key={field.key}
          field={field}
          value={fieldValues[field.key]} />
      ))}
    </Box>
  );
}
