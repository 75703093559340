import React from 'react';
import { Avatar, Box, Chip, Dialog, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';
import { IAccount } from '../schemas';
import DialogCloseButton from './DialogCloseButton';
import { getProfileFields } from '../types/ProfileFields';


interface Props extends DialogProps {
  readonly account?: IAccount;
}

export default function AccountProfileDialog({account, ...dialogProps}: Props): JSX.Element {

  const sections = React.useMemo(() => {
    if (account?.type == null) {
      return [];
    }

    return getProfileFields(account.type);
  }, [account?.type]);
  
  return (
    <Dialog {...dialogProps}>
      <DialogTitle sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '14px',
          alignItems: 'center',
        }}>
          <Avatar
            sx={{
              width: '56px',
              height: '56px',
            }}
            src={account?.profileImageUrl}
            alt={account?.name} />
          
          <Box>
            <Typography sx={{
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '24px',
            }}>
              {account?.name}
            </Typography>

            <Chip
              label={account?.type}
              variant='outlined'
              size='small' />
          </Box>
        </Box>

        <DialogCloseButton onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          rowGap: '28px',
          paddingTop: '28px',
        }}>
          {sections.map(section => (
            section.fields.map(field => (
              <Box sx={{
                position: 'relative',
                display: 'flex',
                alignSelf: 'flex-start',
                paddingLeft: '14px',
                paddingRight: '14px',
                paddingTop: '15.5px',
                paddingBottom: '15.5px',
                width: '400px',
                minHeight: '50px',
                alignItems: 'center',
                borderRadius: '8px',
                background: '#3333330A',
              }}>
                <Typography sx={{
                  position: 'absolute',
                  top: '-10px',
                  left: '0px',
                  paddingLeft: '14px',
                  paddingRight: '14px',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '17.12px',
                  opacity: 0.75,
                  
                }}>
                  {field.title}
                </Typography>
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '17.12px',
                }}>
                  {(account?.profile ?? {} as any)[field.key] ?? ''}
                </Typography>
              </Box>
            ))
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
