import React from 'react';
import { Box } from '@mui/material';
import { useGetInterestRatesQuery } from '../../features/utils-api';
import InterestRatesTable from '../InterestRatesTable';
import PageContainer from '../PageContainer';


export default function InterestRates(): JSX.Element {

  const {data: interestRatesData, isFetching} = useGetInterestRatesQuery();

  return (
    <PageContainer loading={isFetching}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        gap: '16px',
        justifyContent: 'center',
      }}>
        {interestRatesData?.map(data => (
          <InterestRatesTable
            key={data.title}
            data={data} />
        ))}
      </Box>
    </PageContainer>
  );
}
