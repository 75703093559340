import React, { useMemo } from 'react';
import { Chip, ChipProps, SxProps, Theme } from '@mui/material';
import { DealStatus } from '../../schemas';
import { useGetDealStatusOptionsQuery } from '../../features/deals-api';


interface Props {
  readonly sx?: SxProps<Theme>;
  readonly status?: DealStatus;
}

export default function DealStatusChip({sx, status}: Props): JSX.Element {

  const {data: statusOptions} = useGetDealStatusOptionsQuery();

  const dealStatusOptionNames: Record<string, string> = useMemo(() => {
    return statusOptions?.reduce((obj, option) => {
      return {
        ...obj,
        [option.slug]: option.name,
      }
    }, {}) ?? {};
  }, [statusOptions]);

  const chipProps: ChipProps = useMemo(() => {
    switch (status) {
      case 'diligence':
        return {
          color: 'success',
          sx: {
            fontSize: 'inherit',
            ...sx ?? {},
          }
        };
      default:
        return {
          color: 'info',
          sx: {
            fontSize: 'inherit',
            ...sx ?? {},
          }
        };
    }
  }, [sx, status]);

  return (
    <Chip
      {...chipProps}
      label={status ? dealStatusOptionNames[status] : 'Unknown'} />
  );
}
