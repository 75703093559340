import React, { useCallback, useState } from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import sxClasses from '../Styles/Home.Styles';
import PageContainer from '../PageContainer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from 'react-router-dom';
import useCustomClassesAndTheme from '../useCustomClassesAndTheme';
import { useAccountProfileInsights } from '../../utils/hooks';
import IncompleteProfileDialog from '../IncompleteProfileDialog';
import { useGetDealsQuery } from '../../features/deals-api';
import DealsDataGrid from '../DealsDataGrid';
import NotificationsList from '../NotificationsList';
import { INotification } from '../../schemas';
import { useAppDispatch } from '../../app/hooks';
import { showChatForThread } from '../../features/messaging-slice';
import { useGetNotificationsQuery, useSetNotificationReadMutation } from '../../features/notifications-api';
import SharedDealsDataGrid from '../SharedDealsDataGrid';
import { useGetCurrentAccountQuery, useGetCurrentUserQuery } from '../../features/accounts-api';


export default function Home(): JSX.Element {

  const classes = useCustomClassesAndTheme(sxClasses);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [incompleteProfileVisible, setIncompleteProfileVisible] = useState(false);

  const {data: account, isFetching: isAccountFetching} = useGetCurrentAccountQuery();
  const {data: user, isFetching: isUserFetching} = useGetCurrentUserQuery();
  const {data: dealsData} = useGetDealsQuery({});
  const {data: notifications} = useGetNotificationsQuery();

  const [setNotificationRead] = useSetNotificationReadMutation();

  const profileInsights = useAccountProfileInsights(account);

  const completeProfileClicked = useCallback(() => {
    navigate('/organization');
  }, [navigate]);

  const newProjectClicked = useCallback(() => {
    if (profileInsights.isComplete) {
      navigate('/new-deal')
    } else {
      setIncompleteProfileVisible(true);
    }
  }, [navigate, profileInsights, setIncompleteProfileVisible]);

  const notificationClicked = useCallback((notification: INotification) => {
    const notificationId = typeof notification._id === 'string'
      ? notification._id
      : notification.notificationId;

    if (notificationId && !notification.isRead) {
      setNotificationRead({
        notificationId: notificationId,
        isRead: true,
      });
    }

    switch (notification.type) {
      case 'message':
        if (notification.threadId) {
          dispatch(showChatForThread({
            key: 'chat',
            threadId: notification.threadId,
            closable: true,
          }));
        }
        break;

      case 'deal':  
      case 'deal_accepted':
      case 'deal_rejected':
        navigate(`/deal/${notification.dealId}`);
        break;

      default:
        if (notification.dealId != null) {
          if (notification.documentRequestId != null) {
            navigate(`/deal/${notification.dealId}?documentRequestId=${notification.documentRequestId}`);
          } else {
            navigate(`/deal/${notification.dealId}`);
          }
        }
    }
  }, [dispatch, navigate, setNotificationRead]);

  return (
    <PageContainer loading={isAccountFetching || isUserFetching}>
      <Box sx={classes.root}>
        {!user?.hasMobileApp &&
          <Box sx={classes.welcomeBox}>
            <Box sx={classes.welcomeBoxLeft}>
              <Typography sx={classes.welcomeToCreedText}>
                {'Welcome to CREED!'}
              </Typography>

              <Typography sx={classes.welcomeMessageText}>
                {'To get started and get the most out of our features, download our mobile app!'}
              </Typography>

              <Box sx={classes.mobileAppBadgeBox}>
                <a href='http://play.google.com/store/apps/details?id=com.creedmobileapp'
                  target='_blank'
                  rel='noreferrer'>
                  <img
                    style={classes.mobileAppBadge}
                    src={require('../../images/google-play-badge.png')}
                    alt='google play' />
                </a>

                <a href='https://apps.apple.com/us/app/creed-tech/id1633090492'
                  target='_blank'
                  rel='noreferrer'
                  style={classes.iosAppBadgeAnchor}>
                  <img
                    style={classes.mobileAppBadge}
                    src={require('../../images/appstore-badge.png')}
                    alt='apple appstore' />
                </a>
              </Box>
            </Box>
          </Box>
        }

        {account?.currentSubscription?.isActive &&
          <Box sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            paddingTop: user?.hasMobileApp ? 0 : '32px',
            gap: '32px',
          }}>
            {((['sponsor', 'broker'].includes(account.type) && (dealsData?.deals ?? []).length === 0) || !profileInsights.isComplete) && account.level === 'organization' ? (
              <Box sx={classes.actionBox}>
                {!profileInsights.isComplete &&
                  <Button 
                    variant='contained'
                    onClick={completeProfileClicked}>
                    {'Complete your organization profile'}
                    <AccountCircleIcon sx={classes.actionButtonIcon} />
                  </Button>
                }

                {(dealsData?.deals ?? []).length === 0 && (account?.type === 'sponsor' || account?.type === 'broker') &&
                  <Button 
                    variant='contained'
                    onClick={newProjectClicked}>
                    {'Start a new project'}
                    <ArrowRightAltIcon sx={classes.actionButtonIcon} />
                  </Button>
                }
              </Box>
            ) : (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                rowGap: '32px',
              }}>
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '28px',
                  lineHeight: '34.24px',
                }}>
                  {'Recent projects'}
                </Typography>

                <Box sx={{
                  display: 'grid',
                  flexDirection: 'column',
                  alignContent: 'flex-start',
                }}>
                  {(account?.type === 'sponsor' || account?.type === 'broker') ? (
                    <DealsDataGrid limit={6} />
                  ) : (
                    <SharedDealsDataGrid limit={6} />
                  )}
                </Box>
              </Box>
            )}

            {(notifications?.length ?? 0) > 0 &&
              <Box sx={{
                disply: 'flex',
                flex: 0.4,
                flexDirection: 'column',
                rowGap: '32px',
              }}>
                <Typography sx={{
                  fontWeight: '400',
                  fontSize: '28px',
                  lineHeight: '34.24px',
                }}>
                  {'Inbox'}
                </Typography>

                <NotificationsList notificationClicked={notificationClicked} />
              </Box>
            }
          </Box>
        }
        </Box>

        <IncompleteProfileDialog
          open={incompleteProfileVisible}
          closeClicked={() => setIncompleteProfileVisible(false)} />
    </PageContainer>
  );
}
