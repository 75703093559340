import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { DealQuoteTermField } from '../types/DealQuoteTermFields';
import { formatNumericString } from '../utils/utils';


interface Props {
  readonly field: DealQuoteTermField;
  readonly value?: string | number;
}

export default function ReadonlyDataField({field, value}: Props): JSX.Element {
  
  const formattedValue = useMemo(() => {
    if (value == null) {
      return '';
    }

    switch (field.type) {
      case 'text':
      case 'number':
      case 'computed': {
        let finalValue = `${value ?? ''}`;
        switch (field.formatting) {
          case 'comma-separated':
            finalValue = formatNumericString(finalValue) ?? '';
            break;
          default:
            break;
        }
    
        if (field.prefix) {
          finalValue = `${field.prefix}${finalValue}`;
        }
    
        if (field.suffix) {
          finalValue = `${finalValue}${field.suffix}`;
        }
    
        return finalValue;
      }
      case 'select': {
        if (field.optionFormatter != null) {
          return field.optionFormatter(value as string);
        }

        return `${value}`;
      }
    }
  }, [field, value]);
  
  return (
    <Box sx={{
      position: 'relative',
      display: 'flex',
      alignSelf: 'flex-start',
      paddingLeft: '14px',
      paddingRight: '14px',
      paddingTop: '15.5px',
      paddingBottom: '15.5px',
      width: '400px',
      minHeight: '50px',
      alignItems: 'center',
      borderRadius: '8px',
      background: '#3333330A',
    }}>
      <Typography sx={{
        position: 'absolute',
        top: '-10px',
        left: '0px',
        paddingLeft: '14px',
        paddingRight: '14px',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '17.12px',
        opacity: 0.7,
      }}>
        {field.title}
      </Typography>
      {value != null &&
        <Typography sx={{
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '17.12px',
        }}>
          {formattedValue}
        </Typography>
      }
    </Box>
  );
}
