import React from 'react';
import { Box, Typography } from '@mui/material';
import { IInterestRateData } from '../schemas';


interface Props {
  readonly data: IInterestRateData;
}

export default function InterestRatesTable(props: Props): JSX.Element {
  return (
    <Box sx={{
      minWidth: '400px',
      maxWidth: 'calc(100% / 2 - 8px)',
      width: 'calc(80vw / 2 - 8px)',
      padding: '20px',
      borderRadius: '8px',
      background: 'linear-gradient(0deg, rgba(217, 179, 97, 0.19), rgba(217, 179, 97, 0.19)), rgba(255, 255, 255, 0.03)',
    }}>
      <Typography sx={{
        fontWeight: '500',
        fontSize: '16px',
      }}>
        {props.data.title}
      </Typography>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: '12px',
      }}>
        {props.data.columns.map(col => (
          <Typography
            key={col}
            sx={{
              display: 'flex',
              flex: 1,
              fontWeight: '500',
              fontSize: '13px',
            }}>
            {col}
          </Typography>
        ))}
      </Box>

      {props.data.rows.map(row => (
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          marginTop: '8px',
        }}>
          {row.map((cell, cellIndex) => (
            <Typography
              key={cell}
              sx={{
                display: 'flex',
                flex: 1,
                fontWeight: cellIndex <= 1 ? '500' : '400',
                fontSize: '12px',
              }}>
              {cell ?? '-'}
            </Typography>
          ))}
        </Box>
      ))}

      {props.data.metadata?.map(metadata => (
        <Typography sx={{
          display: 'flex',
          justifySelf: 'flex-end',
          marginTop: '12px',
          fontWeight: '400',
          fontSize: '12px',
        }}>
          {metadata}
        </Typography>
      ))}
    </Box>
  );
}
