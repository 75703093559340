import React, { useState } from 'react';
import LoginForm from './LoginForm';
import RegistrationFormV2 from './RegistrationFormV2';


export type AuthType = 'login' | 'register';

export interface AuthData {
  readonly email: string;
  readonly password: string;
}

interface Props {
  defaultState?: 'login' | 'register';
  prompt?: string;
  variant?: 'full' | 'user-only';
  metadata?: {
    invitationId?: string;
    teamInvitationId?: string;
  };
  onSuccess?: (type: AuthType, data: AuthData) => Promise<void>;
  onError?: (error: any) => void;
}

export default function AuthForm(props: Props): JSX.Element {

  const [state, setState] = useState<'login' | 'register'>(props.defaultState ?? 'register');

  switch (state) {
    case 'login':
      return (
        <LoginForm
          onSuccess={(data) => props.onSuccess ? props.onSuccess('login', data) : null}
          onError={props.onError}
          onRegisterClicked={() => setState('register')} />
      );
    case 'register':
      return (
        <RegistrationFormV2
          prompt={props.prompt}
          variant={props.variant}
          allowedAccountTypes={['sponsor', 'broker', 'lender', 'investor']}
          invitationId={props.metadata?.invitationId}
          teamInvitationId={props.metadata?.teamInvitationId}
          onSuccess={async (data) => props.onSuccess ? await props.onSuccess('register', data) : undefined}
          onError={props.onError}
          onLoginClicked={() => setState('login')} />
      );
  }
}
