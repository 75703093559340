import React, { useState, useMemo, useEffect, useRef, useContext, useCallback } from 'react';
import { Box, Button, CircularProgress, MenuItem, Select, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DocumentRequestStatus, IDocumentRequest, IFile } from '../../schemas';
import { useGetDealFileZipQuery, useGetDealParticipantsQuery, useGetDealQuery, useLazyGetDealFileZipUrlQuery } from '../../features/deals-api';
import DocumentRequestSection from '../Deal/DocumentRequestSection';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Params, useLoaderData, useSearchParams } from 'react-router-dom';
import LoadingButton from '../LoadingButton';
import { DealActionContext } from '../Deal/DealDiligenceBase';
import { useGetDealDocumentRequestsQuery } from '../../features/document-requests-api';
import { downloadFileFromUrl } from '../../utils/utils';
import DiligenceProgressMeter from '../Deal/DiligenceProgressMeter';


export interface DocumentSection {
  readonly key: string;
  readonly title: string;
  readonly description?: string;
  readonly status?: DocumentRequestStatus;
  readonly files: IFile[];
  readonly documentRequest?: IDocumentRequest;
}

export default function DealDocuments(): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();

  const { dealId } = useLoaderData() as Params;
  
  const {createDocumentRequestClicked} = useContext(DealActionContext);

  const documentRequestId = searchParams.get('documentRequestId');
  const initialFilter = searchParams.get('filter');

  const [downloadZipLoading, setDownloadZipLoading] = useState(false);
  const [openDocumentRequestId, setOpenDocumentRequestId] = useState(documentRequestId);
  const [filter, setFilter] = useState<'all' | 'my-priorities' | 'set-priorities'>(initialFilter as any ?? 'all');

  const sectionElements = useRef<Record<string, HTMLElement>>({});

  const {data: deal} = useGetDealQuery({dealId: dealId!}, { skip: dealId == null });
  const {data: account} = useGetCurrentAccountQuery();
  const {data: dealFileZip} = useGetDealFileZipQuery({ dealId: deal?._id ?? '' }, { skip: deal?._id == null, pollingInterval: 5000 });
  const {data: participants} = useGetDealParticipantsQuery({ dealId: deal?._id ?? '' }, { skip: deal?._id == null });
  const {
    data: documentRequests,
    isFetching: documentRequestsFetching
  } = useGetDealDocumentRequestsQuery({ dealId: deal?._id ?? '' }, {
    skip: deal?._id == null,
    pollingInterval: 3000,
  });
  const [getDealFileZipUrl] = useLazyGetDealFileZipUrlQuery();

  const filterNames: Record<typeof filter, string> = useMemo(() => {
    return {
      'all': 'All',
      'my-priorities': 'My priority items',
      'set-priorities': 'Priority items I\'ve set',
    };
  }, []);

  const sections: DocumentSection[] | undefined = useMemo(() => {
    if (deal == null) {
      return undefined;
    }
    
    return [
      {
        key: 'general',
        title: 'General',
        files: (deal.files ?? []).map(file => {
          return {
            ...file,
            bucket: file.Bucket,
            key: file.Key,
            contentType: file.ContentType,
          };
        }),
      },
      ...(documentRequests ?? []).map(docReq => {
        return {
          key: docReq._id,
          title: docReq.name,
          description: docReq.description,
          status: docReq.status,
          files: (docReq.files ?? []).filter(f => f.status !== 'pending'),
          documentRequest: docReq,
        };
      })
    ]
    .filter((section: DocumentSection) => {
      switch (filter) {
        case 'all':
          return true;
        case 'my-priorities':
          if (account?.type === 'sponsor') {
            return section.documentRequest != null && section.documentRequest.isSponsorPriority;
          } else if (account?.type === 'lender') {
            return section.documentRequest != null && section.documentRequest.isLenderPriority;
          }

          return false;
        case 'set-priorities':
          if (account?.type === 'sponsor') {
            return section.documentRequest != null && section.documentRequest.isLenderPriority;
          } else if (account?.type === 'lender') {
            return section.documentRequest != null && section.documentRequest.isSponsorPriority;
          }
          
          return false;
      }

      return false;
    });
  }, [deal, account, filter, documentRequests]);

  const filterChanged = useCallback((value: typeof filter) => {
    setFilter(value);
    setSearchParams({
      ...searchParams,
      filter: value,
    });
  }, [setFilter, setSearchParams, searchParams]);

  const downloadDealPackageClicked = useCallback(async () => {
    if (deal?._id == null || dealFileZip == null || dealFileZip.status !== 'complete') {
      return;
    }

    setDownloadZipLoading(true);

    try {
      const {url} = await getDealFileZipUrl({ dealId: deal._id }).unwrap();
      downloadFileFromUrl(url, deal.name ?? 'project');
    } catch (error) {
      console.error(error);
      // TODO: Snack
    } finally {
      setDownloadZipLoading(false);
    }
  }, [deal, dealFileZip, getDealFileZipUrl]);

  useEffect(() => {
    if (documentRequestId != null) {
      const documentRequest = documentRequests?.find(dr => dr._id === documentRequestId);
      if (documentRequest != null) {
        setOpenDocumentRequestId(documentRequestId);
      }
    }
  }, [documentRequestId, documentRequests]);

  useEffect(() => {
    if (openDocumentRequestId != null && documentRequests != null) {
      const ref = sectionElements.current[documentRequestId!];
      if (ref != null) {
        ref.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [openDocumentRequestId, documentRequests, documentRequestId, setSearchParams]);
  
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '12px',
    }}>
      <DiligenceProgressMeter dealId={dealId} />

      <Box sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center',
        justifyContent: 'space-between',
        paddingTop: 2,
        paddingBottom: '4px',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '16px',
        }}>
          <Typography sx={{
            alignSelf: isMobile ? 'flex-start' : 'center',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '19.57px',
            color: '#333333',
          }}>
            {'Lender document requests'}
          </Typography>

          <Select
            sx={{
              height: '32px',
              borderRadius: '8px',
            }}
            inputProps={{
              sx: {
                paddingLeft: '16px !important',
                paddingRight: '16px !important'
              }
            }}
            renderValue={value => (
              <Typography sx={{
                justifySelf: 'center',
                fontWeight: '500',
                fontSize: '12px',
              }}>
                {`Filter by: ${filterNames[value]}`}
              </Typography>
            )}
            IconComponent={() => null}
            value={filter}
            onChange={e => filterChanged(e.target.value as any)}>
            <MenuItem value='all'>{filterNames['all']}</MenuItem>
            <MenuItem value='my-priorities'>{filterNames['my-priorities']}</MenuItem>
            <MenuItem value='set-priorities'>{filterNames['set-priorities']}</MenuItem>
          </Select>
        </Box>

        {account?.type === 'lender' && account?._id !== deal?.accountId &&
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px',
            paddingTop: isMobile ? '12px' : 0,
          }}>
            {dealFileZip != null &&
              <Tooltip
                title={dealFileZip.status !== 'complete' ? 'Deal package is being created, please check back soon' : null}
                placement='top'
                arrow>
                <span>
                  <LoadingButton
                    sx={{height: '36px'}}
                    variant='outlined'
                    color='secondary'
                    loading={downloadZipLoading}
                    disabled={dealFileZip.status !== 'complete'}
                    onClick={downloadDealPackageClicked}>
                    <FileDownloadOutlinedIcon
                      sx={{marginRight: '10px'}}
                      fontSize='small' />
                    {'Download deal package'}
                  </LoadingButton>
                </span>
              </Tooltip>
            }

            {account?.currentSubscription?.isActive &&
              <Button
                sx={{height: '36px'}}
                variant='contained'
                color='secondary'
                onClick={createDocumentRequestClicked}>
                <AddRoundedIcon
                  sx={{marginRight: '10px'}}
                  fontSize='small' />
                {'Add checklist item'}
              </Button>
            }
          </Box>
        }
      </Box>

      {documentRequestsFetching && sections == null ? (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '60px',
        }}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        sections?.map(section => (
          <DocumentRequestSection
            key={section.key}
            ref={(e) => {
              if (e != null && section.documentRequest != null) {
                sectionElements.current[section.documentRequest._id] = e;
              }
            }}
            open={openDocumentRequestId != null && section.key === openDocumentRequestId}
            deal={deal}
            participants={participants}
            section={section} />
        ))
      )}
    </Box>
  );
}
