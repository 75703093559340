import Routes from "./Routes";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { theme } from './theme/theme';
import mixpanel from 'mixpanel-browser';


try {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN!, {
    debug: process.env.REACT_APP_ENV === 'dev',
    verbose: process.env.REACT_APP_ENV === 'dev',
  });
} catch {}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App container py-3">
          <Routes />
        </div>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
