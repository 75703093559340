import { useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { IDeal } from '../../schemas';
import { useSearchLendersQuery } from '../../features/deals-api';
import LenderSearchInfo from './LenderSearchDataGrids/LenderSearchDataGrid';


interface Props {
  readonly deal?: IDeal;
}

export default function DealSearchResults({deal}: Props): JSX.Element {

  const [filter, setFilter] = useState<'all' | 'favorites' | 'contacted' | 'quote-matrix'>('all');

  const {data: searchResults} = useSearchLendersQuery({ dealId: deal?._id!, filter: 'search' }, { skip: deal?._id == null });

  const quotedLenderCount = useMemo(() => {
    return deal?.contactedLenders?.reduce((sum, lender) => {
      if (lender.quoteOptions != null) {
        return sum + lender.quoteOptions.filter(o => o.quotes != null && o.quotes.length > 0).length;
      }

      return sum;
    }, 0);
  }, [deal?.contactedLenders]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '128px',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '12px',
        paddingBottom: '12px',
        gap: '8px',
      }}>
        <Button
          variant='text'
          onClick={() => setFilter('all')}>
          <Typography sx={{
            fontWeight: filter === 'all' ? 'bold' : '400',
            fontSize: '13px',
            lineHeight: '16px',
          }}>
            {`All (${searchResults?.length ?? 0})`}
          </Typography>
        </Button>

        <Button
          variant='text'
          onClick={() => setFilter('favorites')}>
          <Typography sx={{
            fontWeight: filter === 'favorites' ? 'bold' : '400',
            fontSize: '13px',
            lineHeight: '16px',
          }}>
            {`Favorites (${deal?.favoriteLenders?.length ?? 0})`}
          </Typography>
        </Button>

        <Button
          variant='text'
          onClick={() => setFilter('contacted')}>
          <Typography sx={{
            fontWeight: filter === 'contacted' ? 'bold' : '400',
            fontSize: '13px',
            lineHeight: '16px',
          }}>
            {`Lender Outreach (${deal?.contactedLenders?.length ?? 0})`}
          </Typography>
        </Button>

        <Button
          variant='text'
          onClick={() => setFilter('quote-matrix')}>
          <Typography sx={{
            fontWeight: filter === 'quote-matrix' ? 'bold' : '400',
            fontSize: '13px',
            lineHeight: '16px',
          }}>
            {`Quote Matrix (${quotedLenderCount ?? 0})`}
          </Typography>
        </Button>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: filter === 'quote-matrix' ? undefined : 'calc(100vh - 200px)',
      }}>
        <LenderSearchInfo
          deal={deal}
          filter={filter} />
      </Box>
    </Box>
  );
}
