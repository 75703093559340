import { Theme } from '@mui/material/styles';
import sharedStylesClasses from "../../theme/sharedStylesClasses";


const classes = (theme: Theme) => ({
  ...sharedStylesClasses(theme),
  appBarSpacer: theme.mixins.toolbar,
  root: {
    // position: 'fixed',
    // top: 'calc(48px + 64px)',
    // right: 0,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    maxHeight: 'inherit',
    // width: '100%',
    // height: 'calc(100% - 94px - 48px)',
    alignItems: 'space-between',
    boxShadow: '0px 0px 16px rgba(51, 51, 51, 0.12)',
    background: 'white',
  },
  header: {
    display: 'flex',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: '22px',
    paddingBottom: '22px',
    width: '100%',
    justifyContent: 'space-between',
  },
  headerImageBox: {
    width: '36px',
    height: '36px',
    borderRadius: '18px',
    background: '#D9D9D9',
  },
  headerImage: {
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center', 
  },
  headerName: {
    // marginLeft: '10px',
    fontWeight: '500',
    fontSize: 16, 
  },
  messagesBox: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    padding: '20px',
    overflowY: 'scroll',
    paddingRight: '24px',
  },
  loadingIndicatorBox: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messagesList: {
    justifyContent: 'flex-end',
  },
  messagesEnd: {
    width: '100%',
    height: '2px',
  },
  footerBox: {
    display: 'flex',
    padding: '20px',
    width: '100%',
  },
  footerForm: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default classes;
