import React, {PropsWithChildren, useContext, useEffect, useMemo} from 'react';
import { useLoaderData, Params, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetDealParticipantsQuery, useGetDealQuery, useLazyGetDealFileDownloadUrlQuery } from '../../features/deals-api';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';
import DocumentRequestSection, { DocumentRequestSectionData } from './DocumentRequestSection';
import { Box } from '@mui/material';
import DealDiligenceBase, { DealActionContext } from './DealDiligenceBase';
import { IDeal, IDealFile, IDocumentRequest, IFile } from '../../schemas';
import { usePrevious } from '../../utils/hooks';
import { useGetDealDocumentRequestQuery, useLazyGetDocumentRequestFileUrlQuery } from '../../features/document-requests-api';
import { DealContext, PathItem } from '../routes/Deal';


export default function DealDiligenceDocumentRequest(): JSX.Element {
  
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { dealId, documentRequestId } = useLoaderData() as Params;

  const fileKey = searchParams.get('file');

  const {data: deal, isFetching: dealIsFetching} = useGetDealQuery({dealId: dealId!}, { skip: dealId == null, pollingInterval: 3000 });
  const {
    data: documentRequest,
    isFetching: documentRequestIsFetching
  } = useGetDealDocumentRequestQuery({
    dealId: dealId ?? '',
    documentRequestId: documentRequestId ?? ''
  }, {
    skip: dealId == null || documentRequestId == null || documentRequestId === 'general',
    pollingInterval: 3000,
  });
  const {data: participants} = useGetDealParticipantsQuery({ dealId: deal?._id ?? '' }, { skip: deal?._id == null });
  const {data: account} = useGetCurrentAccountQuery();

  const breadcrumbs: PathItem[] = useMemo(() => {
    switch (account?.type) {
      case 'sponsor':
      case 'broker':
        return [
          deal?.accountId === account?._id
            ? { title: 'My Projects', path: '/deals' }
            : { title: 'Shared with Me', path: '/shared-deals' },
          { title: deal?.name ?? 'Project', path: deal != null ? `/deal/${deal._id}/dashboard` : undefined },
          { title: documentRequestId === 'general' ? 'General' : documentRequest?.name ?? 'Unknown' },
        ];
      case 'investor':
      case 'lender':
        return [
          { title: 'Shared with Me', path: '/shared-deals' },
          { title: deal?.name ?? 'Project', path: deal != null ? `/deal/${deal._id}/dashboard` : undefined },
          { title: documentRequestId === 'general' ? 'General' : documentRequest?.name ?? 'Unknown' },
        ];
      default:
        return [];
    }
  }, [deal, documentRequest, account, documentRequestId]);

  const section: DocumentRequestSectionData | undefined = useMemo(() => {
    if (documentRequestId === 'general') {
      if (deal == null) {
        return undefined;
      }

      return {
        key: 'general',
        title: 'General',
        files: (deal.files ?? []).map(file => {
          return {
            ...file,
            bucket: file.Bucket,
            key: file.Key,
            contentType: file.ContentType,
          };
        }),
      };
    } else {
      if (documentRequest == null) {
        return undefined;
      }

      return {
        key: documentRequest._id,
        title: documentRequest.name,
        description: documentRequest.description,
        status: documentRequest.status,
        files: (documentRequest.files ?? []).filter(f => f.status !== 'pending'),
        documentRequest: documentRequest,
      };
    }
  }, [deal, documentRequest, documentRequestId]);

  useEffect(() => {
    if (deal != null) {
      if (documentRequestId !== 'general' && !documentRequestIsFetching && documentRequest == null) {
        navigate(`/deal/${deal._id}/documents`);
      }
    }
  }, [deal, documentRequest, documentRequestId, documentRequestIsFetching, navigate]);

  
  
  return (
    <DealContext.Provider value={{
      breadcrumbs,
    }}>
      <DealDiligenceBase
        deal={deal}
        loading={(dealIsFetching && deal == null) || (documentRequestIsFetching && documentRequest == null)}>
        <DocumentSectionContainer
          deal={deal}
          fileKey={fileKey ?? undefined}
          documentRequestId={documentRequestId}
          documentRequest={documentRequest}>
          {section != null &&
            <DocumentRequestSection
              open={true}
              closable={false}
              deal={deal}
              section={section}
              participants={participants} />
          }
        </DocumentSectionContainer> 
      </DealDiligenceBase>
    </DealContext.Provider>
  );
}


function DocumentSectionContainer({deal, fileKey, documentRequestId, documentRequest, children}: PropsWithChildren<{
  deal?: IDeal,
  fileKey?: string,
  documentRequestId?: string,
  documentRequest?: IDocumentRequest
}>): JSX.Element {

  const previousDeal = usePrevious(deal);
  const {previewFile} = useContext(DealActionContext);

  const [getDocumentRequestFileDownloadUrl] = useLazyGetDocumentRequestFileUrlQuery();
  const [getDealFileDownloadUrl] = useLazyGetDealFileDownloadUrlQuery();

  useEffect(() => {
    async function loadFile() {
      if (deal?._id != null && previousDeal == null && fileKey != null) {
        let file: IFile | IDealFile | undefined = undefined;
        let url: string | undefined = undefined;
        if (documentRequestId !== 'general' && documentRequest != null) {
          const result = await getDocumentRequestFileDownloadUrl({
            dealId: deal?._id!,
            documentRequestId: documentRequest._id,
            fileKey: fileKey,
          }).unwrap();
    
          file = documentRequest.files.find(f => f.key === fileKey);
          url = result.url;
        } else {
          const result = await getDealFileDownloadUrl({
            dealId: deal._id,
            fileType: 'file',
            fileKey: fileKey,
          }).unwrap();

          file = deal.files?.find(f => f.Key === fileKey);
          url = result.url;
        }
    
        if (previewFile != null && file != null && url != null) {
          previewFile(file.name, url);
        }
      }
    }

    loadFile();
  }, [deal, documentRequest, fileKey, previewFile, documentRequestId, getDocumentRequestFileDownloadUrl, previousDeal, getDealFileDownloadUrl]);

  return (
    <Box sx={{
      paddingTop: '48px',
    }}>
      {children}
    </Box>
  );
}