import { forwardRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { snacked } from '../features/snackMessage-slice';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackMessage = (props: any) => {
  const [showSnack, setShowSnack] = useState(false);

  const snackMessage = useAppSelector((state) => state.snackMessage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (snackMessage.message) {
      setShowSnack(true);
    }
  }, [snackMessage]);

  const handleClose = () => {
    setShowSnack(false);
    dispatch(snacked({message: ''}))
  }
  
  return (
    <>
      {
        snackMessage.message
        ? <Snackbar 
            open={showSnack}
            autoHideDuration={3000} 
            onClose={handleClose}>
            <Alert 
              onClose={handleClose}
              severity={snackMessage.severity} 
              sx={{ width: '100%' }}>
              {snackMessage.message}
            </Alert>
          </Snackbar>
        : null
      }
    </>
  )
}

export default SnackMessage;
