import React, { useEffect, useCallback, useState } from 'react';
import { IDeal } from '../../schemas';
import { Box, Tabs, Tab } from '@mui/material';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import DealDiligenceBase from './DealDiligenceBase';

interface Props {
  readonly deal?: IDeal;
  readonly isDealFetching: boolean;
  readonly refetchDeal: () => Promise<any>;
}

export default function DealDiligence({deal, isDealFetching, refetchDeal}: Props): JSX.Element {
  
  const navigate = useNavigate();
  const dashboardMatch = useMatch('/deal/:dealId/dashboard');
  const progressTableMatch = useMatch('/deal/:dealId/progress-table');
  const documentsMatch = useMatch('/deal/:dealId/documents');

  const [currentTab, setCurrentTab] = useState(dashboardMatch != null ? 0 : progressTableMatch ? 1 : documentsMatch != null ? 2 : 0);

  const tabChanged = useCallback((_event: React.SyntheticEvent<Element, Event>, value: number) => {
    if (deal != null) {
      switch (value) {
        case 0:
          navigate(`/deal/${deal._id}/dashboard`);
          break;
        case 1:
          navigate(`/deal/${deal._id}/progress-table`);
          break;
        case 2:
          navigate(`/deal/${deal._id}/documents`);
          break;
        default:
          console.error('Changed to unknown tab');
          break;
      }
    }

    setCurrentTab(value);
  }, [deal, navigate, setCurrentTab]);

  useEffect(() => {
    if (deal != null && dashboardMatch == null && progressTableMatch == null && documentsMatch == null) {
      navigate(`/deal/${deal._id}/dashboard`);
    }
  }, [deal, dashboardMatch, documentsMatch, navigate, progressTableMatch]);

  return (
    <DealDiligenceBase
      deal={deal}
      loading={isDealFetching && deal == null}>
      <Box sx={{
        paddingTop: '64px',
      }}>
        <Tabs
          indicatorColor='primary'
          value={currentTab}
          onChange={tabChanged}>
          <Tab label='Project Dashboard' />
          <Tab label='Progress Table' />
          <Tab label='Lender Diligence' />
        </Tabs>

        <Box sx={{
          paddingTop: '24px',
        }}>
          <Outlet />
        </Box>
      </Box>
    </DealDiligenceBase>
  );
}
