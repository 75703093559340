import React, { useMemo } from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { IDeal } from '../schemas';
import { useNavigate } from 'react-router-dom';
import { useGetDealsQuery } from '../features/deals-api';
import DealStatusChip from './Deal/DealStatusChip';


interface Props {
  readonly accountId?: string;
  readonly limit?: number;
  readonly sx?: SxProps<Theme>;
}

export default function SharedDealsDataGrid({accountId, limit, sx}: Props): JSX.Element {

  const navigate = useNavigate();

  const {data: dealData, isLoading: isDealsLoading} = useGetDealsQuery({accountId: accountId});

  const deals = useMemo(() => {
    const rows = dealData?.deals
      .filter(d => dealData.sharedDeals.find(sd => sd.dealId === d._id) != null)
      .sort((a, b) => {
        return Date.parse(b.createdDt as any) - Date.parse(a.createdDt as any);
      }) ?? [];

    if (limit != null) {
      return rows.slice(0, limit);
    }

    return rows;
  }, [dealData, limit]);

  const columns: GridColDef<IDeal>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 280,
      hideable: false
    },
    {
      field: 'sharedBy',
      headerName: 'Shared By',
      minWidth: 250,
      valueGetter: (params) => (dealData?.sharedDeals.find(sd => sd.dealId === params.row._id) as any)?.sharedByAccount.name,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      hideable: false,
      valueGetter: (params) => params.row.status,
      renderCell: (params) => (
        <DealStatusChip status={params.value} />
      )
    },
    {
      field: 'dealInfo.equityDebt',
      headerName: 'Type',
      width: 80,
      hideable: false,
      valueGetter: (params: any) => params.row.dealInfo?.equityDebt?.optionName ?? 'N/A'
    },
    {
      field: 'privacy',
      headerName: 'Published',
      width: 90,
      hideable: false,
      valueGetter: (params) => params.row.dealInfo?.equityDebt?.optionName === 'Equity' ? params.row.privacy === 'public' ? 'Published' : 'Private' : 'N/A',
    },
    {
      field: 'updatedDt',
      headerName: 'Last Updated',
      width: 120,
      hideable: false,
      valueFormatter: (params) => dayjs(params.value).fromNow(),
      sortComparator: (v1, v2) => {
        return Date.parse(v1) - Date.parse(v2);
      }
    },
    {
      field: 'createdDt',
      headerName: 'Created',
      width: 180,
      hideable: false,
      valueFormatter: (params) => dayjs(params.value).format('MMM D, YYYY [at] h:mm A'),
      sortComparator: (v1, v2, cellParams1) => {
        return Date.parse(v1) - Date.parse(v2);
      },
    }
  ];
  
  return (
    <DataGrid
      sx={{
        fontWeight: '400',
        fontSize: '12px',
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer'
        },
        ...sx,
      }}
      rows={deals}
      columns={columns}
      loading={isDealsLoading}
      checkboxSelection={false}
      disableColumnSelector
      autoHeight={limit != null}
      autoPageSize={limit == null}
      hideFooter={limit != null}
      onRowClick={r => navigate(`/deal/${r.id}`)}
      getRowId={r => r._id!}
      slots={{
        noRowsOverlay: () => (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '22px',
          }}>
            <Typography sx={{
              fontWeight: '400',
              fontSize: '12px',
            }}>
              {'No projects found.'}
            </Typography>
          </Box>
        ),
      }} />
  );
}
