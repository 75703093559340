import { useTheme } from '@mui/material/styles';

/**
 * 
 * @param customClasses 
 * @returns the custom classes imported for this component
 */
const useCustomClassesAndTheme = (customClasses?: Function) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();
  if (!customClasses) return {};
  return customClasses(theme);
  
}

export default useCustomClassesAndTheme