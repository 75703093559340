import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Typography from '@mui/material/Typography';
import PageContainer from '../PageContainer';
import { Box } from '@mui/material';
import SharedDealsDataGrid from '../SharedDealsDataGrid';
import { useGetCurrentAccountQuery } from '../../features/accounts-api';

dayjs.extend(relativeTime)


const SharedDeals = () => {

  const {data: account, isLoading: isAccountLoading} = useGetCurrentAccountQuery();

  return (
    <PageContainer loading={isAccountLoading}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '60px',
      }}>
        {/* {user?.individualAccountId != null &&
          <Box>
            <Typography sx={{
              marginBottom: '20px',
              fontWeight: '400',
              fontSize: '28px',
              lineHeight: '34.24px',
            }}>
              {`Shared with me`}
            </Typography>
            
            {individualDealData?.sharedDeals != null && individualDealData.sharedDeals.length > 0 ? (
              <SharedDealsDataGrid accountId={user?.individualAccountId} />
            ) : (
              <Typography sx={{
                marginBottom: '20px',
                fontWeight: '400',
                fontSize: '16px',
                color: '#333333BF',
              }}>
                {`Projects that are shared with you will appear here`}
              </Typography>
            )}
          </Box>
        } */}

        <Box>
          <Typography sx={{
            marginBottom: '20px',
            fontWeight: '400',
            fontSize: '28px',
            lineHeight: '34.24px',
          }}>
            {account?.type === 'investor' ? 'My Projects' : 'Shared with my team'}
          </Typography>

          <SharedDealsDataGrid />
        </Box>
      </Box>
    </PageContainer>
  )
}

export default SharedDeals;
