import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import CREEDDialogTitle from "../../CREEDDialogTitle";
import LoadingButton from "../../LoadingButton";
import { IDeal } from "../../../schemas";
import { DealQuoteTermField, useDealQuoteTermFields } from "../../../types/DealQuoteTermFields";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { parseNumericString } from "../../../utils/utils";
import SendDealQuoteField from "../SendDealQuoteField";
import { useRecordContactedLenderQuoteMutation } from "../../../features/deals-api";
import { snacked } from "../../../features/snackMessage-slice";


interface Props extends DialogProps {
  deal?: IDeal;
  lenderName?: string;
  optionId?: string;
}

export default function SponsorRecordQuoteDialog({deal, lenderName, optionId, ...dialogProps}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const [quoteType, setQuoteType] = useState<'soft' | 'firm'>('soft');
  const [quoteOption, setQuoteOption] = useState<'__new__' | string>('__new__');

  const {
    fields,
    fieldValues,
    setRawFieldValues,
    onFieldValueChanged,
    clearFieldValues,
    packageFieldValues,
  } = useDealQuoteTermFields();

  const [recordQuote, { isLoading: isRecordQuoteLoading }] = useRecordContactedLenderQuoteMutation();
  
  const lender = useMemo(() => {
    return deal?.contactedLenders?.find(l => l.name === lenderName);
  }, [deal?.contactedLenders, lenderName]);

  const previousQuote = useMemo(() => {
    if (quoteOption === '__new__') {
      return undefined;
    } else {
      const option = lender?.quoteOptions?.find(o => o._id === quoteOption);
      if (option != null && option.quotes != null && option.quotes.length > 0) {
        return option.quotes[option.quotes.length - 1];
      }
    }

    return undefined;
  }, [lender?.quoteOptions, quoteOption]);

  const loadPreviousTerms = useCallback(() => {
    if (previousQuote == null || previousQuote.terms == null) {
      return;
    }
    
    setRawFieldValues(previousQuote.terms);
  }, [previousQuote, setRawFieldValues]);

  const onClose = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent> | {}) => {
    if (dialogProps.onClose != null) {
      dialogProps.onClose(event, 'escapeKeyDown');
    }
  }, [dialogProps]);

  const clearTermsClicked = useCallback(() => {
    clearFieldValues();
  }, [clearFieldValues]);

  const sendQuoteClicked = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deal?._id == null || lenderName == null) {
      return;
    }

    try {
      await recordQuote({
        dealId: deal._id,
        lenderName: lenderName,
        type: quoteType,
        optionId: quoteOption !== '__new__' ? quoteOption : undefined,
        terms: packageFieldValues(),
      }).unwrap();

      onClose({});

      dispatch(snacked({
        message: 'Quote saved, it can be found in the Quote Matrix',
        severity: 'success',
      }));
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed submitting quote',
        severity: 'error',
      }));
    }
  }, [deal?._id, lenderName, recordQuote, quoteType, quoteOption, packageFieldValues, onClose, dispatch]);
  
  const fieldError = useCallback((field: DealQuoteTermField) => {
    const value = fieldValues[field.key];
    if (value == null) {
      return undefined;
    }

    if (field.type !== 'number') {
      return undefined;
    }

    const parsedValue = typeof value === 'string' ? parseNumericString(value) : value;
    if (parsedValue == null) {
      return undefined;
    }

    if (field.min != null && parsedValue < field.min) {
      return `${field.title} must be greater than ${field.min}`;
    } else if (field.max != null && parsedValue > field.max) {
      return `${field.title} must be less than ${field.max}`;
    }

    return undefined;
  }, [fieldValues]);

  useEffect(() => {
    if (optionId != null) {
      setQuoteOption(optionId);
    } else {
      setQuoteOption('__new__');
      clearFieldValues();
    }
  }, [clearFieldValues, lenderName, optionId]);

  useEffect(() => {
    setQuoteType(previousQuote?.type ?? 'soft');
  }, [previousQuote]);

  useEffect(() => {
    loadPreviousTerms();
  }, [loadPreviousTerms, quoteOption]);

  return (
    <Dialog
      {...dialogProps}
      onClose={onClose}
      maxWidth={false}>
      <CREEDDialogTitle
        title={`Record Quote from ${lenderName}`}
        closeClicked={onClose} />
      
      <form onSubmit={sendQuoteClicked}>
        <DialogContent>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
          }}>
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              onClick={clearTermsClicked}>
              {'Clear All Terms'}
            </Button>
            <FormControl size='small'>
              <InputLabel id='quoteType'>{'Quote Type'}</InputLabel>
              <Select
                label='Quote Type'
                value={quoteType}
                onChange={e => setQuoteType(e.target.value as typeof quoteType)}>
                <MenuItem value='soft'>{'Soft Terms'}</MenuItem>
                <MenuItem value='firm'>{'Firm Quote'}</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              size='small'
              disabled={optionId != null}>
              <InputLabel id='quoteOption'>{'Quote Option'}</InputLabel>
              <Select
                label='Quote Option'
                value={quoteOption}
                onChange={e => setQuoteOption(e.target.value as typeof quoteOption)}>
                {lender?.quoteOptions?.map((option, index) => (
                <MenuItem
                  key={option._id}
                  value={option._id}>
                  {`Option ${index + 1}`}
                </MenuItem>
                ))}
                <Divider />
                <MenuItem key='__new__' value={'__new__'}>{'Create New Option'}</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            paddingTop: 2,
            paddingBottom: 2.5,
            alignItems: 'center',
          }}>
            <Typography sx={{
              fontWeight: '500',
              fontSize: '14px',
            }}>
              {'Terms'}
            </Typography>
            <Divider
              sx={{
                flex: 1,
              }} />
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            flexBasis: 0.5,
            maxHeight: '65vh',
            gap: 2,
            paddingRight: '24px',
          }}>
            {fields.map(field => (
              <SendDealQuoteField
                key={field.key}
                field={field}
                value={fieldValues[field.key]}
                error={fieldError(field)}
                onValueChange={value => onFieldValueChanged(field, value)} />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              flex: 1,
            }}
            onClick={onClose}>
            {'Cancel'}
          </Button>
          <LoadingButton
            sx={{
              flex: 1,
            }}
            variant='contained'
            type='submit'
            loading={isRecordQuoteLoading}>
            {quoteType === 'soft' ? 'Save Soft Terms' : 'Save Firm Quote'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
