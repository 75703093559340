import { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { IDocumentRequest } from '../../schemas';
import { useUpdateDealDocumentRequestMutation } from '../../features/document-requests-api';
import LoadingButton from '../LoadingButton';
import { useErrorHandler } from '../../utils/hooks';
import CREEDDialogTitle from '../CREEDDialogTitle';
import * as Sentry from '@sentry/react';


interface Props extends DialogProps {
  readonly documentRequest?: IDocumentRequest;
}

export default function ConfidentialConfirmationDialog({documentRequest, ...dialogProps}: Props): JSX.Element {

  const {handleError} = useErrorHandler();

  const [loading, setLoading] = useState(false);

  const [updateDocumentRequest] = useUpdateDealDocumentRequestMutation();

  const confirmClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (documentRequest == null) {
      return;
    }

    setLoading(true);

    try {
      await updateDocumentRequest({
        dealId: documentRequest.dealId,
        documentRequestId: documentRequest._id,
        changes: {
          securityLevel: 'confidential',
        }
      }).unwrap();

      if (dialogProps.onClose != null) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      Sentry.captureException(error);
      handleError({
        error,
        message: 'Failed saving document request',
      });
    } finally {
      setLoading(false);
    }
  }, [documentRequest, updateDocumentRequest, dialogProps, handleError]);

  return (
    <Dialog {...dialogProps}>
      <CREEDDialogTitle
        title='Are you sure?'
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />     
      <DialogContent>
        <DialogContentText>
          {`All of the deal stakeholders will lose access to this document request and the files attached to it. You and the sponsor/lender will be the only ones authorized to view its content.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          color='secondary'
          disabled={loading}
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={loading}
          onClick={confirmClicked}>
          {'Confirm'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
