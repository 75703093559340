import React, { useEffect } from 'react';
import NoSessionRoute from './NoSessionRoute';
import ProtectedRoute from './ProtectedRoute';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';


interface Props {
  type: 'no-session' | 'protected' | 'unprotected';
  element: () => JSX.Element;
}

export default function AppRoute({type, element: Element}: Props): JSX.Element {

  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_OFFLINE !== 'true') {
      mixpanel.track('navigate web', {
        path: location.pathname,
      });
    }
  }, [location.pathname]);

  switch (type) {
    case 'unprotected':
      return (<Element />);
    case 'no-session':
      return (<NoSessionRoute element={Element} />);
    case 'protected':
      return (<ProtectedRoute element={Element} />)
  }
}
