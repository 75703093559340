import { Box, Typography } from "@mui/material";
import PageContainer from "../PageContainer";


export default function NotFound(): JSX.Element {
  return (
    <PageContainer showHeader={false}>
      <Box sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '15%',
      }}>
        <Typography sx={{
          fontWeight: '600',
          fontSize: '64px',
        }}>
          {'404'}
        </Typography>
        <Typography sx={{
          fontWeight: '400',
          fontSize: '16px',
        }}>
          {'PAGE NOT FOUND'}
        </Typography>
      </Box>
    </PageContainer>
  );
}
