import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import LenderStatusChip from '../LenderStatusChip';
import Card from '../../Card';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { ISharedDeal } from '../../../schemas';


interface Props {
  readonly sharedDeal?: ISharedDeal;
  readonly sendSoftQuoteClicked?: () => void;
  readonly sendFirmQuoteClicked?: () => void;
  readonly rejectDealClicked?: () => void;
}

export default function DealLenderResponseCard({sharedDeal, sendSoftQuoteClicked, sendFirmQuoteClicked, rejectDealClicked}: Props): JSX.Element {
  return (
    <Card>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '22px',
        }}>
          {'Indicate Status to Borrower'}
        </Typography>
        {sharedDeal?.lenderStatus != null &&
          <LenderStatusChip status={sharedDeal.lenderStatus} />
        }
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '22px',
        paddingTop: '22px',
      }}>
        <Button
          variant='outlined'
          color='primary'
          disabled={sharedDeal?.lenderStatus !== 'under-review' && sharedDeal?.lenderStatus !== 'quoted'}
          onClick={sendSoftQuoteClicked}>
          {'Send Soft Terms'}
        </Button>
        <Button
          variant='contained'
          disabled={sharedDeal?.lenderStatus !== 'under-review' && sharedDeal?.lenderStatus !== 'quoted'}
          onClick={sendFirmQuoteClicked}>
          {'Send Firm Quote'}
        </Button>
        <Button
          sx={{
            gap: '12px',
          }}
          variant='contained'
          color='secondary'
          onClick={rejectDealClicked}>
          <RemoveCircleRoundedIcon sx={{fontSize: '16px'}} />
          {'Pass on Project'}
        </Button>
      </Box>
    </Card>
  );
}
