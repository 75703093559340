import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export const CHAT_PRIORITY_CHAT_WITH_CREED = 1;
export const CHAT_PRIORITY_NORMAL = 0;


interface ChatWindow {
  readonly key: string;
  readonly priority: number;
  readonly title?: string;
  readonly subtitle?: string;
  readonly recipientAccountIds?: string[];
  readonly threadIdentifier?: string;
  readonly threadId?: string;
  readonly dealId?: string;
  readonly defaultMessage?: string;
  readonly initialSendAction?: 'request-white-glove';
  readonly closable?: boolean;
  readonly minimizable?: boolean;
  readonly minimized?: boolean;
}

interface ChatState {
  readonly windows: ChatWindow[];
}

const initialState: ChatState = {
  windows: [],
};

const messagingSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    showChat(state, action: PayloadAction<{
      key: string,
      windowTitle: string,
      windowSubtitle?: string,
      accountIds: string[],
      identifier?: string,
      dealId?: string,
      defaultMessage?: string,
      initialSendAction?: 'request-white-glove',
      closable?: boolean,
      minimizable?: boolean,
      minimized?: boolean,
    }>) {
      const window: ChatWindow = {
        key: action.payload.key,
        priority: 0,
        title: action.payload.windowTitle,
        subtitle: action.payload.windowSubtitle,
        recipientAccountIds: action.payload.accountIds,
        dealId: action.payload.dealId,
        defaultMessage: action.payload.defaultMessage,
        initialSendAction: action.payload.initialSendAction,
        closable: action.payload.closable,
        minimizable: action.payload.minimizable,
        minimized: action.payload.minimized,
      };

      const currentIndex = state.windows.findIndex(w => w.key === action.payload.key);
      if (currentIndex >= 0) {
        state.windows[currentIndex] = window;
      } else {
        state.windows.push(window);
      }
    },
    showChatForThread(state, action: PayloadAction<{
      key: string,
      threadId: string,
      windowTitle?: string,
      windowSubtitle?: string,
      defaultMessage?: string,
      initialSendAction?: 'request-white-glove',
      closable?: boolean,
      minimizable?: boolean,
      minimized?: boolean,
    }>) {
      const window: ChatWindow = {
        key: action.payload.key,
        priority: 0,
        title: action.payload.windowTitle,
        subtitle: action.payload.windowSubtitle,
        threadId: action.payload.threadId,
        defaultMessage: action.payload.defaultMessage,
        initialSendAction: action.payload.initialSendAction,
        closable: action.payload.closable,
        minimizable: action.payload.minimizable,
        minimized: action.payload.minimized,
      };

      const currentIndex = state.windows.findIndex(w => w.key === action.payload.key);
      if (currentIndex >= 0) {
        state.windows[currentIndex] = window;
      } else {
        state.windows.push(window);
      }
    },
    closeChat(state, action: PayloadAction<{
      key: string,
    }>) {
      state.windows = state.windows.filter(w => w.key !== action.payload.key);
    },
    clearChatState(state) {
      state.windows = [];
    },
    clearInitialChatData(state, action: PayloadAction<{
      key: string,
    }>) {
      const index = state.windows.findIndex(w => w.key === action.payload.key);
      if (index >= 0) {
        const newWindow = {...state.windows[index]};
        delete newWindow['initialSendAction'];
        delete newWindow['defaultMessage'];
        delete newWindow['dealId'];

        state.windows[index] = newWindow;
      }
    }
  }
})

export const {showChat, showChatForThread, closeChat, clearChatState, clearInitialChatData} = messagingSlice.actions;
export default messagingSlice.reducer;
