import { Box, LinearProgress, Typography, linearProgressClasses, styled } from "@mui/material";
import { useGetDealDocumentRequestsQuery } from "../../features/document-requests-api";
import { useMemo } from "react";


const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#333333' : '#308fe8',
  },
}));


interface Props {
  dealId?: string;
}

export default function DiligenceProgressMeter({dealId}: Props): JSX.Element {

  const {data: documentRequests} = useGetDealDocumentRequestsQuery({ dealId: dealId! }, { skip: dealId == null });

  const value = useMemo(() => {
    if (documentRequests == null || documentRequests.length === 0) {
      return 0;
    }

    const approved = documentRequests.filter(dr => dr.status === 'submitted' || dr.status === 'accepted');
    return (approved.length / documentRequests.length) * 100.0;
  }, [documentRequests]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    }}>
      <Box>
        <Typography sx={{
          fontWeight: '500',
          fontSize: '22px',
        }}>
          {`Document Progress Bar (${value.toFixed(0)}%)`}
        </Typography>
        <Typography sx={{
          fontWeight: '400',
          fontSize: '14px',
          color: '#333333AA'
        }}>
          {'Based on the lender document requests, the Document Progress Bar indicates the percentage of items submitted by the borrower to date.'}
        </Typography>
      </Box>
      <StyledLinearProgress
        variant='determinate'
        value={value} />
    </Box>
  );
}
