import { Box, Avatar, Typography, IconButton, Tooltip } from "@mui/material";
import Card from "../../Card";
import ContactInfo from "../ContactInfo";
import { IDeal, IDealDiligenceContact } from "../../../schemas";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DiligenceContactDialog from "../Dialogs/DiligenceContactDialog";
import { usePopoverContext } from "../../../utils/hooks";
import { useGetCurrentAccountQuery } from "../../../features/accounts-api";
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded';
import { useDropzone } from "react-dropzone";
import { useCreateDealFileUploadUrlMutation } from "../../../features/deals-api";
import * as Sentry from '@sentry/react';
import { useAppDispatch } from "../../../app/hooks";
import { snacked } from "../../../features/snackMessage-slice";
import { uploadFileToUrl } from "../../../utils/utils";
import { useState } from "react";


interface Props {
  deal?: IDeal;
}

export default function DiligenceContactsCard({deal}: Props): JSX.Element {

  const dispatch = useAppDispatch();

  const {
    props: lenderContactDialogProps,
    showDialog: showLenderContact,
    currentData: lenderContact,
  } = usePopoverContext<IDealDiligenceContact | undefined>();

  const {
    props: borrowerContactDialogProps,
    showDialog: showBorrowerContact,
    currentData: borrowerContact,
  } = usePopoverContext<IDealDiligenceContact | undefined>();

  const [logoUploading, setLogoUploading] = useState(false);

  const {data: account} = useGetCurrentAccountQuery();

  const [createFileUploadUrl] = useCreateDealFileUploadUrlMutation();

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    multiple: false,
    onDrop: async (acceptedFiles) => {
      if (deal?._id == null) {
        return;
      }

      const file = acceptedFiles[0];
      if (file != null) {
        setLogoUploading(true);

        try {
          const result = await createFileUploadUrl({
            dealId: deal._id,
            fileType: 'diligence-lender-logo',
            fileName: file.name,
            contentType: file.type,
          }).unwrap();

          await uploadFileToUrl(result.url, file);

          dispatch(snacked({
            message: 'Lender logo uploaded',
            severity: 'success',
          }));
        } catch (error) {
          Sentry.captureException(error);
          console.error(error);
          dispatch(snacked({
            message: 'Failed uploading lender logo',
            severity: 'error',
          }));
        } finally {
          setLogoUploading(false);
        }
      }
    }
  });

  const canEditDiligence = account?.isAdmin || (deal?.whiteGloveStatus !== 'enabled' && (account?.type === 'sponsor' || account?.type === 'broker'));

  return (
    <>
      <Card sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: 2,
        }}>
          <Box sx={{
            position: 'relative',
          }}>
            <Avatar
              sx={{
                width: '138px',
                height: '94px',
              }}
              variant='rounded'
              src={deal?.diligence?.lenderLogoUrl}
              alt={'lender'} />

            {canEditDiligence &&
              <Tooltip
                title='Upload lender logo'
                enterDelay={500}>
                <>
                  <input {...getInputProps()} />
                  <IconButton
                    sx={{
                      position: 'absolute',
                      right: 45,
                      bottom: -10,
                      background: '#00000055',
                      ':hover': {
                        background: '#00000033'
                      }
                    }}
                    disabled={logoUploading}
                    size='small'
                    {...getRootProps()}>
                    <InsertPhotoRoundedIcon
                      sx={{
                        color: '#ffffffEE'
                      }}
                      fontSize='inherit' />
                  </IconButton>
                </>
              </Tooltip>
            }
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography sx={{
              fontWeight: '500',
              fontSize: '14px',
            }}>
              {'Lender Contact(s)'}
            </Typography>
            {canEditDiligence &&
              <Tooltip
                title='Add a new lender contact'
                enterDelay={500}>
                <IconButton
                  size='small'
                  onClick={() => showLenderContact()}>
                  <AddCircleOutlineRoundedIcon fontSize='inherit' color='inherit' />
                </IconButton>
              </Tooltip>
            }
          </Box>

          {deal?.diligence?.lenderContacts?.map(contact => (
            <ContactInfo
              key={contact._id}
              name={contact.name}
              email={contact.email}
              phone={contact.phone}
              address={contact.address}
              onEditClicked={canEditDiligence ? () => showLenderContact(contact) : undefined} />
          ))}
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: 2,
        }}>
          <Avatar
            sx={{
              width: '138px',
              height: '94px',
            }}
            variant='rounded'
            src={deal?.account?.profileImageUrl}
            alt={'Borrower'} />

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography sx={{
              fontWeight: '500',
              fontSize: '14px',
            }}>
              {'Borrower Contact(s)'}
            </Typography>
            {canEditDiligence &&
              <Tooltip
                title='Add a new borrower contact'
                enterDelay={500}>
                <IconButton
                  size='small'
                  onClick={() => showBorrowerContact()}>
                  <AddCircleOutlineRoundedIcon fontSize='inherit' color='inherit' />
                </IconButton>
              </Tooltip>
            }
          </Box>

          {deal?.diligence?.borrowerContacts?.map(contact => (
            <ContactInfo
              key={contact._id}
              name={contact.name}
              email={contact.email}
              phone={contact.phone}
              address={contact.address}
              onEditClicked={canEditDiligence ? () => showBorrowerContact(contact) : undefined} />
          ))}
        </Box>
      </Card>

      <DiligenceContactDialog
        {...lenderContactDialogProps}
        deal={deal}
        type='lender'
        contact={lenderContact} />

      <DiligenceContactDialog
        {...borrowerContactDialogProps}
        deal={deal}
        type='borrower'
        contact={borrowerContact} />
    </>
  )
}
