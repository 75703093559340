import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, TextField } from "@mui/material";
import CREEDDialogTitle from "../../CREEDDialogTitle";
import LoadingButton from "../../LoadingButton";
import { IDeal, IDealFavoritedLender } from "../../../schemas";
import { useUpdateNoContactLenderMutation } from "../../../features/deals-api";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { snacked } from "../../../features/snackMessage-slice";


interface Props extends DialogProps {
  deal?: IDeal;
  lender?: IDealFavoritedLender;
}

export default function SponsorAddInfoLenderDialog({deal, lender, ...dialogProps}: Props): JSX.Element {
  
  const dispatch  = useAppDispatch();
  
  const [comment, setComment] = useState('');

  const [updateNoContactLender, { isLoading: isUpdateNoContactLenderLoading }] = useUpdateNoContactLenderMutation();
  
  const saveClicked = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (deal?._id == null || lender == null) {
      return;
    }
    
    try {
      await updateNoContactLender({
        dealId: deal._id,
        lenderName: lender.name,
        comment: comment,
      }).unwrap();

      dispatch(snacked({
        message: 'Additional info saved',
        severity: 'success',
      }));

      if (dialogProps.onClose != null) {
        dialogProps.onClose(event, 'escapeKeyDown');
      }
    } catch (error) {
      console.error(error);
      dispatch(snacked({
        message: 'Failed saving comment',
        severity: 'error',
      }));
    }
  }, [deal?._id, lender, updateNoContactLender, comment, dispatch, dialogProps]);

  useEffect(() => {
    setComment(lender?.comment ?? '');
  }, [lender?.comment]);

  return (
    <Dialog {...dialogProps} fullWidth>
      <CREEDDialogTitle
        title={`Additional Information for ${lender?.name}`}
        closeClicked={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null} />
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}>
        <DialogContentText>
          {'Please enter any additional information about the lender below.'}
        </DialogContentText>

        <TextField
          InputProps={{
            sx: {
              borderRadius: '8px',
            }
          }}
          fullWidth
          multiline
          minRows={12}
          maxRows={18}
          value={comment}
          onChange={e => setComment(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            flex: 1,
          }}
          onClick={e => dialogProps.onClose ? dialogProps.onClose(e, 'escapeKeyDown') : null}>
          {'Cancel'}
        </Button>
        <LoadingButton 
          sx={{
            flex: 1,
          }}
          variant='contained'
          loading={isUpdateNoContactLenderLoading}
          onClick={saveClicked}>
          {'Save'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
