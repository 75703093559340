import React, { useMemo } from 'react';
import { Chip, ChipProps, Tooltip } from '@mui/material';
import { DealPrivacy, EquityDealReviewStatus } from '../../../schemas';


interface Props {
  readonly reviewStatus?: EquityDealReviewStatus;
  readonly dealPrivacy: DealPrivacy;
}

export default function EquityDealReviewStatusChip({reviewStatus, dealPrivacy}: Props): JSX.Element {

  const chipProps: ChipProps = useMemo(() => {
    switch (reviewStatus) {
      case undefined:
        return {
          label: 'Private',
          color: 'info',
          sx: {
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '15.9px',
          }
        };
      case 'pending':
        return {
          label: 'In Review',
          color: 'info',
          sx: {
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '15.9px',
          }
        };
      case 'approved':
        switch (dealPrivacy) {
          case 'public':
            return {
              label: 'Published to Marketplace',
              color: 'success',
              sx: {
                fontWeight: '500',
                fontSize: '13px',
                lineHeight: '15.9px',
              },
            };
          case 'private':
            return {
              label: 'Private',
              color: 'warning',
              sx: {
                fontWeight: '500',
                fontSize: '13px',
                lineHeight: '15.9px',
              },
            };
        }
        break;
      case 'rejected':
        return {
          label: 'Rejected',
          color: 'error',
          sx: {
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '15.9px',
          }
        };
    }
  }, [reviewStatus, dealPrivacy]);

  const tooltip = useMemo(() => {
    switch (reviewStatus) {
      case undefined:
        return 'This project is not visible on the Equity Marketplace';
      case 'pending':
        return 'This project is being reviewed by the CREED Team and is not yet visible on the Equity Marketplace';
      case 'approved':
        switch (dealPrivacy) {
          case 'public':
            return 'This project is visible to investors on the CREED Equity Marketplace';
          case 'private':
            return 'This project has been approved by the CREED Team but is not visible on the CREED Equity Marketplace';
        }
        break;
      case 'rejected':
        return 'Your project has been rejected by the CREED Team';
    }
  }, [reviewStatus, dealPrivacy]);
  
  return (
    <Tooltip
      title={tooltip}
      placement='top'>
      <Chip {...chipProps} />
    </Tooltip>
  );
}
